import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import _map from 'lodash/map';
import Analytics from '@udacity/ureact-analytics';

import { Modal } from 'app/common/components/modal';
import { selectUid } from 'app/common/domains/user/user-selectors';

import * as reviewsQueueActions from 'app/queue/domains/reviews-queue/reviews-queue-actions';
import {
  getActiveSubmissionRequestProjects,
  getActiveSubmissionRequests,
  getQueueExpiration,
  getQueueSettings,
} from 'app/queue/domains/reviews-queue/reviews-queue-selectors';
import { Certification } from 'app/types/reviews';
import { useAppDispatch } from 'app/store';

import { QueueControlRow } from '../queue-control-row';
import { eventTypes } from '../queue-constants';

import { ReviewQueueSettings } from './review-queue-settings';
import { useQueueEnabledStateWithErrors } from './use-queue-enabled-state-with-errors';

export const ReviewQueue: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setQueueEnabled } = useQueueEnabledStateWithErrors();
  const [isReviewOptionsModalOpen, setReviewOptionsModalOpen] = useState(false);

  const activeSubmissionRequests = useSelector(getActiveSubmissionRequests);
  const activeSubmissionRequestProjects = useSelector(getActiveSubmissionRequestProjects);
  const reviewQueueExpiration = useSelector(getQueueExpiration);
  const reviewQueueSettings = useSelector(getQueueSettings);
  const currentUserUid = useSelector(selectUid);

  useEffect(() => {
    dispatch(reviewsQueueActions.fetchAll());
    return () => {
      dispatch(reviewsQueueActions.stopPollingSubmissionRequests());
    };
  }, [dispatch]);

  const toggleReviewQueue = () => {
    if (activeSubmissionRequests.length) {
      dispatch(reviewsQueueActions.stopPollingSubmissionRequests());
      dispatch(reviewsQueueActions.destroyAll());
      setQueueEnabled(false);
      Analytics.track(eventTypes.REVIEW_QUEUE_OFF, {
        timestamp: Date.now(),
        reviewerId: currentUserUid,
      });
    } else {
      setReviewOptionsModalOpen(true);
    }
  };

  const refreshReviewQueue = () => {
    dispatch(reviewsQueueActions.refreshAll());
  };

  const submitReviewQueue = (formData: { certifications: Certification[]; queueVolume: 'single' | 'max' }) => {
    // Store queue preferences in redux and localStorage
    // for reference later
    const projectIds = Array.from(new Set(_map(formData.certifications || [], 'project_id')));
    const settings = {
      queueVolume: formData.queueVolume,
      projects: projectIds,
    };

    dispatch(reviewsQueueActions.setSettings(settings));

    if (activeSubmissionRequests.length) {
      dispatch(reviewsQueueActions.updateAll(settings));
    }

    dispatch(reviewsQueueActions.startPollingSubmissionRequests(settings));
    setReviewOptionsModalOpen(false);
  };

  const selectedProjects = activeSubmissionRequestProjects.length
    ? activeSubmissionRequestProjects
    : _get(reviewQueueSettings, 'projects', null);

  return (
    <>
      <QueueControlRow
        name={t('common.reviews')}
        onOptionsClick={() => setReviewOptionsModalOpen(true)}
        onQueueClick={toggleReviewQueue}
        onRefreshClick={refreshReviewQueue}
        isQueueEnabled={!!activeSubmissionRequests.length}
        expiration={reviewQueueExpiration}
      />

      <Modal
        ariaLabel={t('queue.overview.reviewsQueueSettings')}
        onClose={() => setReviewOptionsModalOpen(false)}
        isOpen={isReviewOptionsModalOpen}
      >
        <ReviewQueueSettings
          handleSubmit={submitReviewQueue}
          selectedProjectIds={selectedProjects}
          isUpdate={!!activeSubmissionRequests.length}
          queueVol={_get(reviewQueueSettings, 'queueVolume', null)}
        />
      </Modal>
    </>
  );
};
