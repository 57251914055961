export enum SubmissionStatus {
  Passed = 'passed',
  Exceeded = 'exceeded',
  Processing = 'processing',
  DelayingReview = 'delaying_review',
  AwaitingReview = 'awaiting_review',
  InReview = 'in_review',
  Canceled = 'canceled',
}

export const SubmissionPendingStatuses = [
  SubmissionStatus.Processing,
  SubmissionStatus.DelayingReview,
  SubmissionStatus.AwaitingReview,
  SubmissionStatus.InReview,
];

export const SubmissionPassedStatuses = [SubmissionStatus.Passed, SubmissionStatus.Exceeded];
