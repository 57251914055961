import { Modal } from 'app/common/components/modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SeriesSelector } from './series-selector/series-selector';
import { ClaimSeriesConfirmation } from 'app/uconnect/landing/notifications/claim-meetings-modal/series-selector/claim-series-confirmation';

type ClaimMeetingsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ClaimMeetingsModal: React.FC<ClaimMeetingsModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [claimingSeriesId, setClaimingSeriesId] = useState<string | null>(null);
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('connect.claimMeetings')}>
      {claimingSeriesId ? (
        <ClaimSeriesConfirmation
          seriesId={claimingSeriesId}
          onCloseModal={onClose}
          onCancel={() => setClaimingSeriesId(null)}
        />
      ) : (
        <SeriesSelector onClaimSeriesClick={setClaimingSeriesId} />
      )}
    </Modal>
  );
};
