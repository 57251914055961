import { useState } from 'react';

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  const setInputValue = (event) => {
    const { id, name, value } = event.target;
    setValues({ ...fields, [id || name]: value });
  };

  const setValue = (event) => {
    const { id, name, value } = event;
    setValues({ ...fields, [id || name]: value });
  };

  const setCheckedValue = (event) => {
    const { id, name, checked } = event.target;
    setValues({ ...fields, [id || name]: checked });
  };

  return [
    fields,
    function (event) {
      if (event) {
        const type = event && event.target && event.target.type;
        switch (type) {
          case 'tel':
          case 'text':
          case 'textarea':
            setInputValue(event);
            break;
          case 'checkbox':
            setCheckedValue(event);
            break;
          default:
            setValue(event);
            break;
        }
      }
    },
  ];
}

export default useFormFields;
