import { delay } from 'redux-saga';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { actionCreators as uiActions } from 'app/common/domains/ui/ui-actions';
import { createAction } from 'app/helpers/action-helper';
import { errorMessageFromApiError } from 'app/helpers/api-error-helper';
import { getEligibleProjectLanguages } from 'app/common/domains/session/session-selectors';
import {
  getPlagiarismCaseRequests,
  createPlagiarismCaseRequest,
  refreshPlagiarismCaseRequest,
  updatePlagiarismCaseRequest,
  deletePlagiarismCaseRequest,
  getAssignedPlagiarismCases,
  getCompletedPlagiarismCases,
} from 'app/services/reviews-api-service';

import { actionTypes } from './plagiarism-case-queue-actions';
import constants from './plagiarism-case-queue-constants';
import {
  alertCreators,
  makeQueueRequestBody,
  havePlagiarismCaseRequestsCompleted,
  hasNewAssignments,
} from './plagiarism-case-queue-sagas-helpers';
import { selectAssignedPlagiarismCases, selectPendingPlagiarismCaseRequests } from './plagiarism-case-queue-selectors';
import { PlagiarismCaseRequestStatus } from './plagiarism-case-queue-types';

const { POLLING_INTERVAL_MS } = constants;

let isPolling = false;
export function* bootstrap() {
  yield put(createAction(actionTypes.FETCH_PLAGIARISM_CASE_REQUESTS_START));
  yield put(createAction(actionTypes.FETCH_ASSIGNED_PLAGIARISM_CASES_START));
  if (!isPolling) {
    yield fork(pollPlagiarismCaseRequests);
    yield fork(pollAssignedPlagiarismCases);
    isPolling = true;
  }
}

export function* fetchPlagiarismCaseRequests() {
  try {
    const response = yield call(getPlagiarismCaseRequests);
    yield put(createAction(actionTypes.FETCH_PLAGIARISM_CASE_REQUESTS_FULFILLED, response));
  } catch (error) {
    yield put(createAction(actionTypes.FETCH_PLAGIARISM_CASE_REQUESTS_REJECTED, error));
    const errorToast = alertCreators.fetchPlagiarismCaseRequestsFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* refreshPlagiarismCaseRequests() {
  try {
    const pendingPlagiarismCaseRequests = yield select(selectPendingPlagiarismCaseRequests);
    const refreshEffects = pendingPlagiarismCaseRequests.map((r) => call(refreshPlagiarismCaseRequest, r.id));
    const refreshedRequests = yield all(refreshEffects);
    yield put(createAction(actionTypes.REFRESH_PLAGIARISM_CASE_REQUESTS_FULFILLED, refreshedRequests));
  } catch (error) {
    yield put(createAction(actionTypes.REFRESH_PLAGIARISM_CASE_REQUESTS_REJECTED, error));
    const errorToast = alertCreators.refreshPlagiarismCaseRequestsFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* updatePlagiarismCaseRequests(action) {
  try {
    const pendingPlagiarismCaseRequests = yield select(selectPendingPlagiarismCaseRequests);
    const eligibleLanguages = yield select(getEligibleProjectLanguages);
    const requestBody = makeQueueRequestBody(action.payload, eligibleLanguages);
    const updateEffects = pendingPlagiarismCaseRequests.map((r) =>
      call(updatePlagiarismCaseRequest, r.id, requestBody)
    );
    const updatedApiRequests = yield all(updateEffects);
    yield put(createAction(actionTypes.UPDATE_PLAGIARISM_CASE_REQUESTS_FULFILLED, updatedApiRequests));

    const updatedPlagiarismCaseRequests = yield select(selectPendingPlagiarismCaseRequests);
    if (havePlagiarismCaseRequestsCompleted(pendingPlagiarismCaseRequests, updatedPlagiarismCaseRequests)) {
      yield put(createAction(actionTypes.FETCH_ASSIGNED_PLAGIARISM_CASES_START));
    }
  } catch (error) {
    yield put(createAction(actionTypes.UPDATE_PLAGIARISM_CASE_REQUESTS_REJECTED, error));
    const errorToast = alertCreators.updatePlagiarismCaseRequestsFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* joinQueue(action) {
  try {
    const eligibleLanguages = yield select(getEligibleProjectLanguages);
    const requestBody = makeQueueRequestBody(action.payload, eligibleLanguages);
    const response = yield call(createPlagiarismCaseRequest, requestBody);
    yield put(createAction(actionTypes.JOIN_PLAGIARISM_CASE_QUEUE_FULFILLED, response));

    // If the new plagiarism case request is already completed then fetch assigned plagiarism cases,
    // there should be a new plagiarism case for the user.
    if (response.status === PlagiarismCaseRequestStatus.FULFILLED) {
      yield put(createAction(actionTypes.FETCH_ASSIGNED_PLAGIARISM_CASES_START));
    }
  } catch (error) {
    yield put(createAction(actionTypes.JOIN_PLAGIARISM_CASE_QUEUE_REJECTED, error));
    const errorToast = alertCreators.joinQueueFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* leaveQueue() {
  try {
    const pendingPlagiarismCaseRequests = yield select(selectPendingPlagiarismCaseRequests);
    const deleteEffects = pendingPlagiarismCaseRequests.map((r) => call(deletePlagiarismCaseRequest, r.id));
    yield all(deleteEffects);
    yield put(createAction(actionTypes.LEAVE_PLAGIARISM_CASE_QUEUE_FULFILLED));
  } catch (error) {
    yield put(createAction(actionTypes.LEAVE_PLAGIARISM_CASE_QUEUE_REJECTED, error));
    const errorToast = alertCreators.leaveQueueFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* pollPlagiarismCaseRequests() {
  while (true) {
    yield delay(POLLING_INTERVAL_MS);
    yield call(checkPlagiarismCaseRequestCompletion);
  }
}

export function* checkPlagiarismCaseRequestCompletion() {
  // If there are no pending requests then do nothing, because there is no
  // pending request that could have completed.
  const previousPendingPlagiarismCaseRequests = yield select(selectPendingPlagiarismCaseRequests);
  if (previousPendingPlagiarismCaseRequests.length <= 0) {
    return;
  }

  // Call the fetch saga directly, which blocks this saga so that the results
  // of the fetch can be compared to the pending requests before the fetch.
  yield call(fetchPlagiarismCaseRequests);

  // If an plagiarism case request completed then fetch assigned plagiarism cases, there should
  // be a new plagiarism case for the user.
  const pendingPlagiarismCaseRequests = yield select(selectPendingPlagiarismCaseRequests);
  if (havePlagiarismCaseRequestsCompleted(previousPendingPlagiarismCaseRequests, pendingPlagiarismCaseRequests)) {
    yield put(createAction(actionTypes.FETCH_ASSIGNED_PLAGIARISM_CASES_START));
  }
}

export function* fetchAssignedPlagiarismCases() {
  try {
    const assignedBeforeFetch = yield select(selectAssignedPlagiarismCases);
    const response = yield call(getAssignedPlagiarismCases);

    yield put(createAction(actionTypes.FETCH_ASSIGNED_PLAGIARISM_CASES_FULFILLED, response));

    const assignedAfterFetch = yield select(selectAssignedPlagiarismCases);
    if (hasNewAssignments(assignedBeforeFetch, assignedAfterFetch)) {
      yield put(uiActions.addToast(alertCreators.newPlagiarismCaseAssignedToast()));
      yield put(uiActions.addNotification(alertCreators.newPlagiarismCaseAssignedNotification()));
    }
  } catch (error) {
    yield put(createAction(actionTypes.FETCH_PLAGIARISM_CASE_REQUESTS_REJECTED, error));
    const errorToast = alertCreators.fetchPlagiarismCaseRequestsFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* pollAssignedPlagiarismCases() {
  while (true) {
    yield delay(POLLING_INTERVAL_MS);

    const assignedPlagiarismCases = yield select(selectAssignedPlagiarismCases);
    if (assignedPlagiarismCases.length > 0) {
      yield put(createAction(actionTypes.FETCH_ASSIGNED_PLAGIARISM_CASES_START));
    }
  }
}

export function* fetchCompletedPlagiarismCases(action) {
  try {
    const response = yield call(getCompletedPlagiarismCases, action.payload);
    const itemsBeforePage = (action.payload.page - 1) * action.payload.per_page;
    // The `x-total` header is misleadingly named, it does not contain the total
    // number of items but instead the total number of items after and including
    // the items in the fetched page (but not before).
    const totalCompletedPlagiarismCases = itemsBeforePage + parseInt(response.headers['x-total'], 10);
    const results = {
      apiCompletedPlagiarismCases: response.data,
      totalCompletedPlagiarismCases,
    };
    yield put(createAction(actionTypes.FETCH_COMPLETED_PLAGIARISM_CASES_FULFILLED, results));
  } catch (error) {
    yield put(createAction(actionTypes.FETCH_COMPLETED_PLAGIARISM_CASES_REJECTED, error));
    const errorToast = alertCreators.fetchCompletedPlagiarismCasesFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* watchBootstrap() {
  yield takeEvery(actionTypes.BOOTSTRAP_PLAGIARISM_CASE_QUEUE_START, bootstrap);
}

export function* watchFetchPlagiarismCaseRequests() {
  yield takeEvery(actionTypes.FETCH_PLAGIARISM_CASE_REQUESTS_START, fetchPlagiarismCaseRequests);
}

export function* watchRefreshPlagiarismCaseRequests() {
  yield takeEvery(actionTypes.REFRESH_PLAGIARISM_CASE_REQUESTS_START, refreshPlagiarismCaseRequests);
}

export function* watchUpdatePlagiarismCaseRequests() {
  yield takeEvery(actionTypes.UPDATE_PLAGIARISM_CASE_REQUESTS_START, updatePlagiarismCaseRequests);
}

export function* watchJoinQueue() {
  yield takeEvery(actionTypes.JOIN_PLAGIARISM_CASE_QUEUE_START, joinQueue);
}

export function* watchLeaveQueue() {
  yield takeEvery(actionTypes.LEAVE_PLAGIARISM_CASE_QUEUE_START, leaveQueue);
}

export function* watchFetchAssignedPlagiarismCases() {
  yield takeEvery(actionTypes.FETCH_ASSIGNED_PLAGIARISM_CASES_START, fetchAssignedPlagiarismCases);
}

export function* watchFetchCompletedPlagiarismCases() {
  yield takeEvery(actionTypes.FETCH_COMPLETED_PLAGIARISM_CASES_START, fetchCompletedPlagiarismCases);
}

export default function* rootSaga() {
  yield all([
    watchBootstrap(),
    watchFetchPlagiarismCaseRequests(),
    watchRefreshPlagiarismCaseRequests(),
    watchUpdatePlagiarismCaseRequests(),
    watchJoinQueue(),
    watchLeaveQueue(),
    watchFetchAssignedPlagiarismCases(),
    watchFetchCompletedPlagiarismCases(),
  ]);
}
