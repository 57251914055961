import React from 'react';
import { Trans } from 'react-i18next';
import { WarningIcon } from '@udacity/chakra-uds-icons';
import { Link } from '@chakra-ui/react';

import styles from '../payment-settings.module.scss';

export function PayoneerPending({ logIn }) {
  return (
    <p className={styles['payoneer-alert']}>
      <WarningIcon title="attention" w={6} h={6} />
      <Trans
        i18nKey="mentorProfile.payoneer.pendingApproval"
        components={{
          login_link: <Link href={logIn} isExternal fontSize="sm" />,
        }}
      />
    </p>
  );
}

export default PayoneerPending;
