import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Select, SingleValue } from 'chakra-react-select';
import {
  Alert,
  AlertDescription,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { InfoIcon } from '@udacity/chakra-uds-icons';
import { getChakraReactSelectStyles } from '@udacity/chakra-uds-theme';
import Analytics from '@udacity/ureact-analytics';

import { useMarkUngradeableMutation, useUnassignSubmissionMutation } from 'app/apis/reviews';
import { Modal } from 'app/common/components/modal';
import { selectUid } from 'app/common/domains/user/user-selectors';

import { FeedbackInput } from './feedback-input';

type Option = {
  value: string;
  label: string;
  helperText: string;
};

type UnableToReviewModalProps = {
  isOpen: boolean;
  onClose: () => void;
  submissionId: number;
  rubricLanguage: string;
};

export const UnableToReviewModal: React.FC<UnableToReviewModalProps> = ({
  isOpen,
  onClose,
  submissionId,
  rubricLanguage,
}) => {
  const [reason, setReason] = useState<SingleValue<Option>>(null);
  const [reasonDetails, setReasonDetails] = useState('');
  const [plagiarismSourceUrl, setPlagiarismSourceUrl] = useState('');
  const [hasError, setHasError] = useState(false);

  const currentUserUid = useSelector(selectUid);
  const { t } = useTranslation();
  const history = useHistory();

  const [unassignMyself, { isLoading: isUnassigning }] = useUnassignSubmissionMutation();
  const [markUngradeable, { isLoading: isMarkingUngradeable }] = useMarkUngradeableMutation();

  const reasonOptions = [
    {
      value: 'missing_requirements',
      label: t('projectReviews.unableToReview.missingRequirements'),
      helperText: t('projectReviews.unableToReview.missingRequirementsHint'),
    },
    {
      value: 'plagiarism',
      label: t('projectReviews.plagiarism.plagiarism'),
      helperText: t('projectReviews.unableToReview.plagiarismHint'),
    },
    {
      value: 'unassign',
      label: t('projectReviews.unableToReview.unassigningMyself'),
      helperText: t('projectReviews.unableToReview.unassigningMyselfHint'),
    },
    {
      value: 'abuse',
      label: t('projectReviews.unableToReview.abusiveConduct'),
      helperText: t('projectReviews.unableToReview.abusiveConductHint'),
    },
    {
      value: 'language',
      label: t('projectReviews.unableToReview.wrongLanguage'),
      helperText: t('projectReviews.unableToReview.wrongLanguageHint'),
    },
  ];
  const shownToStudent = ['missing_requirements', 'plagiarism', 'language'].includes(reason?.value ?? '');
  const shownToStaff = ['abuse', 'unassign'].includes(reason?.value ?? '');

  const isInvalid = useMemo(() => {
    return !reason || !reasonDetails || (reason.value === 'plagiarism' && !plagiarismSourceUrl);
  }, [reason, reasonDetails, plagiarismSourceUrl]);

  useEffect(() => {
    if (reason?.value === 'language') {
      setReasonDetails(
        t('projectReviews.unableToReview.wrongLanguageResponse', {
          lng: rubricLanguage.toLowerCase(),
        })
      );
    } else {
      setReasonDetails('');
    }
  }, [reason?.value]);

  const handleSubmit = async () => {
    setHasError(false);
    if (!reason?.value) return;

    if (reason.value === 'unassign') {
      Analytics.track('Project Review Unassigned', {
        timestamp: new Date().toISOString(),
        submissionId,
        reviewerId: currentUserUid,
      });
      await unassignMyself({ submissionId, reason: reasonDetails })
        .unwrap()
        .then(() => {
          onClose();
          history.push('/queue/overview');
        })
        .catch((e) => {
          console.error(e);
          setHasError(true);
        });

      return;
    }

    Analytics.track('Project Review Marked Ungradeable', {
      timestamp: new Date().toISOString(),
      submissionId,
      reviewerId: currentUserUid,
    });

    await markUngradeable({
      submissionId,
      notes: reasonDetails,
      tag: reason.value,
      plagiarismSourceUrl: plagiarismSourceUrl ?? null,
    })
      .then(() => {
        onClose();
        history.push('/queue/overview');
      })
      .catch((e) => {
        console.error(e);
        setHasError(true);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('projectReviews.unableToReview.whyCantBeReviewed')}
      footer={
        <>
          {hasError && (
            <Text color="feedback.negative" size="label">
              {t('error.anErrorOccurred')}
            </Text>
          )}

          <Flex gap={6} w="full">
            <Button onClick={handleSubmit} isDisabled={isInvalid} isLoading={isUnassigning || isMarkingUngradeable}>
              {t('common.submit')}
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                setReason(null);
                onClose();
              }}
            >
              {t('common.cancel')}
            </Button>
          </Flex>
        </>
      }
      footerStyles={{ gap: 6, flexDir: 'column' }}
    >
      <>
        <Text mb={6}>{t('projectReviews.unableToReview.afterSubmittingAReason')}</Text>

        <FormControl mb={8} maxW="25rem">
          <FormLabel mb={2}>{t('projectReviews.unableToReview.selectReason')}</FormLabel>
          <Select<Option>
            chakraStyles={getChakraReactSelectStyles()}
            value={reason}
            options={reasonOptions}
            onChange={(newValue) => setReason(newValue)}
            menuPosition="fixed"
          />
        </FormControl>

        {reason?.value === 'plagiarism' && (
          <FormControl mb={4}>
            <FormLabel mb={2}>{t('projectReviews.plagiarism.pleaseEnterUrl')}</FormLabel>
            <Input value={plagiarismSourceUrl} onChange={(e) => setPlagiarismSourceUrl(e.target.value)} />
          </FormControl>
        )}

        {reason && (
          <FormControl>
            <FormLabel mb={2}>{t('projectReviews.unableToReview.pleaseExplain')}</FormLabel>
            <FormHelperText mb={2}>{reason.helperText}</FormHelperText>

            {(shownToStudent || shownToStaff) && (
              <Alert
                status="info"
                mb={4}
                borderStart="8px solid"
                borderStartColor="blue.500"
                sx={{
                  '.chakra-alert__desc': { padding: '1rem 1rem 1rem 0.5rem' },
                }}
              >
                <InfoIcon w={8} h={8} color="blue.500" ms={2} />
                <AlertDescription>
                  {shownToStudent
                    ? t('projectReviews.unableToReview.shownToStudent')
                    : t('projectReviews.unableToReview.shownToStaff')}
                </AlertDescription>
              </Alert>
            )}

            <FeedbackInput id="ungradeable-reason" feedback={reasonDetails} setFeedback={setReasonDetails} isAssigned />
          </FormControl>
        )}
      </>
    </Modal>
  );
};
