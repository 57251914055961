import BrowserNotification from 'app/services/browser-notifications/notification';
import { NotificationContent } from 'app/services/browser-notifications/notification-types';

export enum NotificationsDeniedReason {
  WINDOW_HAS_NO_NOTIFICATIONS = 'WINDOW_HAS_NO_NOTIFICATIONS',
  PERMISSION_PREVIOUSLY_DENIED = 'PERMISSION_PREVIOUSLY_DENIED',
  PERMISSION_NEWLY_DENIED = 'PERMISSION_NEWLY_DENIED',
}

export async function createNotifyFunction(window): Promise<(content: NotificationContent) => void> {
  if (!window.Notification) {
    throw NotificationsDeniedReason.WINDOW_HAS_NO_NOTIFICATIONS;
  }

  return new Promise((resolve, reject) => {
    const browserNotifications = new BrowserNotification(window.Notification);
    const candidateNotifyFunction = (content: NotificationContent) => {
      browserNotifications.notify(content);
    };
    if (browserNotifications.isNotificationPermissionGranted()) {
      return resolve(candidateNotifyFunction);
    }
    if (browserNotifications.isNotificationPermissionDenied()) {
      throw NotificationsDeniedReason.PERMISSION_PREVIOUSLY_DENIED;
    }
    browserNotifications.requestNotificationPermission(
      () => {
        resolve(candidateNotifyFunction);
      },
      () => {
        reject(NotificationsDeniedReason.PERMISSION_NEWLY_DENIED);
      }
    );
  });
}
