import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _differenceBy from 'lodash/differenceBy';
import _unionBy from 'lodash/unionBy';
import dayjs from 'dayjs';

import storage from 'app/helpers/storage-helper';
import { pluralize } from 'app/helpers/format-helper';

export function dayDiff(timestamp) {
  // reformat timestamp to remove hours/minutes/seconds
  const target = new Date(timestamp);
  const targetString = dayjs(target).startOf('day');

  const today = new Date();
  const todayString = dayjs(today).startOf('day');

  return dayjs(targetString).diff(dayjs(todayString), 'days');
}

export function getExpiresText(expires) {
  const daysDifference = dayDiff(expires);

  if (daysDifference >= 1) {
    return `${daysDifference} ${pluralize('day', daysDifference)} left`;
  } else {
    return 'Last day!';
  }
}

export function omitExpired(opportunities) {
  return _filter(opportunities, (opp) => {
    return new Date(opp.expires_at) > dayjs().toDate();
  });
}

export function trackViewedOpportunities(opportunities) {
  const currentOpportunities = _map(opportunities, ({ id, expires_at }) => {
    return { id, expires_at };
  });
  const viewedOpportunities = omitExpired(storage.get('viewed-opportunities'));

  const mergedOpportunities = _unionBy(currentOpportunities, viewedOpportunities, (opp) => opp.id);

  storage.set('viewed-opportunities', mergedOpportunities);
}

export function hasNewOpportunities(activeOpportunities) {
  const viewedOpportunities = omitExpired(storage.get('viewed-opportunities'));
  const newOpportunities = _differenceBy(activeOpportunities, viewedOpportunities, (opp) => opp.id);

  return newOpportunities.length > 0;
}
