import _forEach from 'lodash/forEach';
import { sagaMiddleware } from './store';
import uiSagas from 'app/common/domains/ui/ui-sagas';
import submissionsSagas from 'app/queue/domains/submissions/submissions-sagas';
import statementsSagas from 'app/queue/domains/statements/statements-sagas';
import auditQueueSagas from 'app/queue/domains/audit-queue/audit-queue-sagas';
import plagiarismCaseQueueSagas from 'app/queue/domains/plagiarism-case-queue/plagiarism-case-queue-sagas';
import certificationsSagas from 'app/queue/domains/certifications/certifications-sagas';
import questionQueueSagas from 'app/queue/domains/question-queue/question-queue-sagas';
import reviewsQueueSagas from 'app/queue/domains/reviews-queue/reviews-queue-sagas';
import reviewerDataSagas from 'app/queue/domains/reviewer-data/reviewer-data-sagas';
import RecruitmentFamiliesSagas from 'app/queue/domains/recruitment-families/recruitment-families-sagas';

const sagas = {
  uiSagas,
  submissionsSagas,
  statementsSagas,
  auditQueueSagas,
  plagiarismCaseQueueSagas,
  certificationsSagas,
  questionQueueSagas,
  reviewsQueueSagas,
  reviewerDataSagas,
  RecruitmentFamiliesSagas,
};

export default function initalizeRootSaga() {
  _forEach(sagas, (moduleRoot) => {
    sagaMiddleware.run(moduleRoot);
  });
}
