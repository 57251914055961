import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _noop from 'lodash/noop';
import auth from '@udacity/ureact-hoth';
import { LogoutIcon, UdacityIcon } from '@udacity/chakra-uds-icons';
import GroupItems from './group-items';
import styles from './sidebar.module.scss';

export default class GroupingsSidebar extends React.PureComponent {
  static propTypes = {
    groupings: PropTypes.array,
    selectedGrouping: PropTypes.string,
    changeGrouping: PropTypes.func,
  };

  static defaultProps = {
    changeGrouping: _noop,
  };

  handleSignOut = () => {
    auth.signOut();
  };

  handleChangeGrouping = (key) => {
    const { changeGrouping } = this.props;
    return changeGrouping(key);
  };

  render() {
    const { groupings, selectedGrouping } = this.props;
    return (
      <div className={styles.coursebar}>
        <div className={styles['top']}>
          <Link to="/">
            <UdacityIcon w={8} h={8} color="white" />
          </Link>
        </div>

        <div className={styles['groupings']}>
          <GroupItems
            groupings={groupings}
            selectedGrouping={selectedGrouping}
            onChangeGrouping={this.handleChangeGrouping}
          />
        </div>

        <div className={styles['end']}>
          <div className={styles['item']} onClick={this.handleSignOut}>
            <LogoutIcon w={8} h={8} color="white" />
          </div>
        </div>
      </div>
    );
  }
}
