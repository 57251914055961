import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Box, ListItem, Text } from '@chakra-ui/react';

type ReviewsSideNavbarItemProps = {
  to: string;
  label: string;
  isActive: boolean;
};

export const ReviewsSideNavbarItem: React.FC<ReviewsSideNavbarItemProps> = ({ to, label, isActive }) => {
  return (
    <ListItem>
      <Box
        as={ReactRouterLink}
        to={to}
        p={4}
        gap={1}
        display="flex"
        color="black"
        w="full"
        textAlign="start"
        bgColor={isActive ? 'blue.100' : 'none'}
        boxShadow={isActive ? `inset 4px 0 0 0 var(--chakra-colors-blue-500)` : 'none'}
        _hover={{ bgColor: 'blue.100' }}
        _visited={{ color: 'black' }}
        _focus={{ outline: 'none' }}
        _focusVisible={{
          boxShadow: `inset 0 0 0 2px var(--chakra-colors-blue-500)`,
        }}
      >
        <Text size="label">{label}</Text>
      </Box>
    </ListItem>
  );
};
