import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { SidebarLayout } from 'app/common/components/sidebar-layout';
import { getSession } from 'app/common/domains/session/session-selectors';
import { Session } from 'app/common/domains/session/session-types';
import { actionCreators as ui } from 'app/common/domains/ui/ui-actions';
import { useAppToast } from 'app/common/hooks/use-app-toast';
import auditQueueActions from 'app/queue/domains/audit-queue/audit-queue-actions';
import { fetchAll as fetchAllCertifications } from 'app/queue/domains/certifications/certifications-actions';
import plagiarismCaseQueueActions from 'app/queue/domains/plagiarism-case-queue/plagiarism-case-queue-actions';
import questionQueueActions from 'app/queue/domains/question-queue/question-queue-actions';
import {
  startPollingAssignedSubmissions,
  cancelPollingAssignedSubmissions,
} from 'app/queue/domains/submissions/submissions-actions';
import { getAssignedSubmissions, getSubmissionsAsync } from 'app/queue/domains/submissions/submissions-selectors';
import { Overview, History, Statements, CertificationsRoutes } from 'app/queue/views';
import { RootState, useAppDispatch } from 'app/store';

import CONFIG from 'configLoader';
import SidebarNavigation from './sidebar-navigation';

export const QueueApp: React.FC = () => {
  const assignedSubmissions = useSelector((state: RootState) => getAssignedSubmissions(state));
  const submissionsAsync = useSelector((state: RootState) => getSubmissionsAsync(state, 'fetchAllAssigned'));
  const session = useSelector((state) => getSession(state));

  const prevState = useRef<{
    assignedSubmissions;
    submissionsAsync;
    session: Session;
  }>({
    assignedSubmissions,
    submissionsAsync,
    session,
  });

  const { t } = useTranslation();
  const toast = useAppToast();
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useAppDispatch();

  const redirectIfMissingAgreement = () => {
    if (!session.agrees_terms_of_service) {
      history.push('/reviewer-agreement');
    }
  };

  useEffect(() => {
    redirectIfMissingAgreement();
    dispatch(startPollingAssignedSubmissions());
    dispatch(ui.bootstrapNotifications());
    dispatch(questionQueueActions.bootstrapQueue());
    dispatch(auditQueueActions.bootstrap());
    dispatch(plagiarismCaseQueueActions.bootstrap());
    dispatch(fetchAllCertifications());

    return () => {
      dispatch(cancelPollingAssignedSubmissions());
    };
  }, []);

  const displayNewSubmissionToast = () => {
    toast({
      variant: 'info',
      title: t('queue.newAssignment'),
      description: t('queue.assignedANewReview'),
    });

    dispatch(
      ui.addNotification({
        title: t('queue.newAssignment'),
        body: t('queue.assignedANewReview'),
        linkTo: `${CONFIG.dashboardUrl}/queue/overview`,
      })
    );
  };

  const checkForNewSubmission = () => {
    if (
      !prevState.current.submissionsAsync.firstLoad &&
      prevState.current.assignedSubmissions.length < assignedSubmissions.length
    ) {
      displayNewSubmissionToast();
    }
  };

  const checkToSAndRedirect = () => {
    const prevAgrees = _get(prevState.current, 'session.reviewsMe.accepted_terms', null);
    const currAgrees = _get(session, 'reviewsMe.accepted_terms', null);

    if (currAgrees === false && prevAgrees !== false) {
      history.push('/reviewer-agreement');
    }
  };

  useEffect(() => {
    checkToSAndRedirect();
    checkForNewSubmission();

    prevState.current = { assignedSubmissions, submissionsAsync, session };
  }, [session, submissionsAsync, assignedSubmissions]);

  return (
    <div>
      <SidebarLayout leftNav={<SidebarNavigation />}>
        <Switch>
          <Route exact path={`${match.path}`}>
            <Redirect to={`${match.path}/overview`} />
          </Route>
          <Route path={`${match.path}/overview`}>
            <Overview />
          </Route>
          <Route path={`${match.path}/history`}>
            <History />
          </Route>
          <Route path={`${match.path}/statements`}>
            <Statements />
          </Route>
          <Route path={`${match.path}/certifications`}>
            <CertificationsRoutes />
          </Route>
          <Route path={`${match.path}/*`}>
            <Redirect to={`${match.path}/overview`} />
          </Route>
        </Switch>
      </SidebarLayout>
    </div>
  );
};
