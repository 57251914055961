import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, FormControlProps, InputGroup } from '@chakra-ui/react';

type AttachImageButtonProps = FormControlProps & {
  onUpload: (files: FileList | null) => void;
  isLoading: boolean;
};

export const AttachImageButton: React.FC<AttachImageButtonProps> = ({ onUpload, isLoading, ...rest }) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <FormControl w="max-content" {...rest}>
      <InputGroup
        onClick={() => {
          fileInputRef.current?.click();
        }}
      >
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*,.pdf"
          onChange={(e) => onUpload(e.target.files)}
          multiple
          hidden
        />
        <Button variant="link" isDisabled={isLoading}>
          {t('projectReviews.attachImage')}
        </Button>
      </InputGroup>
    </FormControl>
  );
};
