import React, { ReactNode } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { Filters } from './filters';
import { SearchInput } from './search-input';
import { Filter } from '../types';

type Props = {
  placeholder?: string;
  filters?: Filter[];
  cta?: ReactNode;
};

export const TableFilters: React.FC<Props> = ({ placeholder, filters, cta }) => {
  return (
    <Box width="100%">
      <Flex justify="space-between">
        {placeholder && <SearchInput placeholder={placeholder} />}
        {cta && <Box>{cta}</Box>}
      </Flex>
      {filters && <Filters filters={filters} />}
    </Box>
  );
};
