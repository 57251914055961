import dayjs from 'dayjs';

import { createAction } from 'app/helpers/action-helper';

export const actionTypes = {
  FETCH_EARNINGS: 'reviewer-data:FETCH_EARNINGS',
  FETCH_EARNINGS_FULFILLED: 'reviewer-data:FETCH_EARNINGS_FULFILLED',
  FETCH_RATINGS_MATRIX: 'reviewer-data:FETCH_RATINGS_MATRIX',
  FETCH_RATINGS_MATRIX_FULFILLED: 'reviewer-data:FETCH_RATINGS_MATRIX_FULFILLED',
};

export function fetchEarnings(startDate, endDate) {
  return createAction(actionTypes.FETCH_EARNINGS, {
    start_date: startDate || dayjs().utc().startOf('month'),
    end_date: endDate || null,
  });
}

export function fetchRatingsMatrix(startDate, endDate) {
  return createAction(actionTypes.FETCH_RATINGS_MATRIX, {
    start_date: startDate || dayjs().subtract(60, 'days'),
    end_date: endDate || null,
  });
}
