import { Box, Flex, ListItem, OrderedList, Text } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { dateFormatter } from 'app/helpers/format-helper';
import { AccordionList, AccordionOption } from './accordion-list';
import { MeetingCard } from './meeting-card';
import max from 'lodash/max';
import { FetchedMeeting } from 'app/uconnect/helpers/types';
import orderBy from 'lodash/orderBy';
import dayjs from 'dayjs';
import sum from 'lodash/sum';
import { getExcuseRequestCount } from 'app/uconnect/helpers/utils';
import { useTranslation } from 'react-i18next';

type MeetingsListProps = {
  meetingsByDay: Record<string, FetchedMeeting[]>;
};

export const MeetingsList: React.FC<MeetingsListProps> = ({ meetingsByDay }) => {
  const { t } = useTranslation();
  const meetingDays = useMemo(
    () => orderBy(Object.keys(meetingsByDay), (meetingDay) => Date.parse(meetingDay)),
    [meetingsByDay]
  );

  const defaultIndex =
    max<number>([
      meetingDays.findIndex(
        (dateStr) =>
          dayjs(dateStr).startOf('day').isSame(dayjs().startOf('day')) || dayjs(dateStr).startOf('day').isAfter(dayjs())
      ),
      0,
    ]) || 0;

  const [openIndexes, setOpenIndexes] = useState<number[]>([defaultIndex]);

  const handleClickMeetingDay = (meetingDay: string) => {
    // we have to tightly control the open panels to do sane things when we filter them
    const meetingDayIndex = meetingDays.indexOf(meetingDay);

    const indexSet = new Set(openIndexes);

    if (indexSet.has(meetingDayIndex)) {
      // toggle shut if open
      indexSet.delete(meetingDayIndex);
    } else {
      // expand if not open
      indexSet.add(meetingDayIndex);
    }

    setOpenIndexes(Array.from(indexSet));
  };

  useEffect(() => {
    // if the default index changes, then a filter was applied, reset the open indexes
    setOpenIndexes([defaultIndex]);
  }, [defaultIndex, meetingsByDay]);

  const meetingList = meetingDays.map<AccordionOption>((dateStr) => {
    const meetings = meetingsByDay[dateStr];

    const unservedAttendanceRequestCounts = meetings.map(getExcuseRequestCount);

    const requestCount = sum(unservedAttendanceRequestCounts);

    return {
      id: dateStr,
      title: (
        <Box>
          {dateFormatter.long(dateStr)}
          {requestCount > 0 && (
            <Text as="span" color="red.700">
              {' '}
              {t('connect.excuseRequestCount', {
                count: requestCount,
              })}
            </Text>
          )}
        </Box>
      ),
      component: (
        <Flex as={OrderedList} direction="column" gap={4} listStyleType="none" px={4} m={0}>
          {meetings.map((meeting) => (
            <ListItem key={meeting.id}>
              <MeetingCard meeting={meeting} />
            </ListItem>
          ))}
        </Flex>
      ),
    };
  });

  return <AccordionList allowMultiple options={meetingList} index={openIndexes} onChange={handleClickMeetingDay} />;
};
