import React from 'react';
import { Link } from 'react-router-dom';
import styles from './sidebar-row.module.scss';

type SidebarRowProps = {
  to: string;
  label: string;
  isSelected: boolean;
  isSpecialized?: boolean;
};

export const SidebarRow: React.FC<SidebarRowProps> = ({ to, label, isSelected, isSpecialized }) => {
  const url = isSpecialized ? '/specialized-mentor/guidelines' : '/guidelines';

  return (
    <Link to={`${url}/${to}`}>
      <li className={`${styles['list-item']} ${isSelected ? styles.active : ''}`}>{label}</li>
    </Link>
  );
};
