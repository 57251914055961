import React from 'react';
import { withTranslation } from 'react-i18next';
import { CancelIcon, CheckedIcon } from '@udacity/chakra-uds-icons';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';
import PropTypes from 'prop-types';

import i18n from 'app/i18n';
import FeedbackComment from 'app/queue/views/history/task-history/feedback-comment';

import styles from './peer-feedback.module.scss';

export function displayVote(vote) {
  return vote === 1 ? (
    <CheckedIcon title={i18n.t('queue.history.peerFeedback')} color="feedback.positive" />
  ) : (
    <CancelIcon title={i18n.t('queue.history.peerFeedback')} color="feedback.negative" />
  );
}

export function generateCommentId(feedback, index) {
  if (!feedback) {
    feedback = 'no_comment';
  }
  const id = _size(feedback) < 18 ? feedback : feedback.slice(0, 18);
  return id.replace(/\s/g, '_').toLowerCase() + index;
}

class PeerFeedback extends React.Component {
  static propTypes = {
    peerFeedbacks: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        feedback: PropTypes.string,
      })
    ),
  };

  render() {
    const { peerFeedbacks, t } = this.props;
    const showPeerFeedbacks = !_isEmpty(peerFeedbacks);

    return (
      <div>
        {showPeerFeedbacks ? (
          peerFeedbacks.map(({ feedback, value }, i) => (
            <FeedbackComment key={generateCommentId(feedback, i)} icon={displayVote(value)}>
              <p>{feedback ? feedback : t('queue.history.noComment')}</p>
            </FeedbackComment>
          ))
        ) : (
          <span className={styles['default-label']} data-tst="non-evaluated">
            {t('queue.history.hasNotBeenPeerReviewed')}
          </span>
        )}
      </div>
    );
  }
}

export default withTranslation()(PeerFeedback);
