import { createSelector } from 'reselect';

export const getStatements = (state) => state.reviews.statements;

export const getStatementsAsync = createSelector(
  (state, action) => {
    if (action) {
      return state.reviews.statementsAsync[action];
    }
    return state.reviews.statementsAsync;
  },
  (state) => state
);
