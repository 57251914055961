import Papa from 'papaparse';
import { Badge, Box, Button, Flex, GridItem, Heading, Link, Text } from '@chakra-ui/react';
import { ArrowLeftIcon, CalendarIcon, ChatIcon, TimeIcon } from '@udacity/chakra-uds-icons';
import { Link as RouterLink } from 'react-router-dom';
import { DataTable } from 'app/common/components/uds-table';
import { CheckboxConfig, DataTableColumn } from 'app/common/components/uds-table/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useParams } from 'react-router';
import { useGetMeetingUsersQuery } from '../queries/enhanced';
import { commonLanguages } from 'app/helpers/form-helper';
import { MeetingUser } from 'app/types/uconnect';
import { dateFormatter } from 'app/helpers/format-helper';
import { AttendanceCell } from './attendance-cell';
import { BulkAttendanceButtons } from './bulk-attendance-buttons';
import { downloadCsvString } from '../helpers/utils';

export const MeetingOverviewPage: React.FC = () => {
  const { t } = useTranslation();
  const [selectedUserKeys, setSelectedUserKeys] = useState<string[]>([]);

  const params = useParams<{ meetingId: string }>();
  const { meetingId } = params;

  const { data, isLoading, isFetching } = useGetMeetingUsersQuery({ meetingId }, { skip: !meetingId });

  const meeting = data?.meetings?.meetings[0];

  const date = dateFormatter.short(meeting?.startAt);
  const startTime = dateFormatter.localTime(meeting?.startAt);
  const endTime = dateFormatter.localTime(meeting?.endAt);

  const languages =
    meeting?.locales.map(
      (locale) =>
        commonLanguages.find((lang) => locale === lang.code) || {
          name: 'English',
        }
    ) || [];

  const onSelectRow = (userKey: string, shouldSelect: boolean) => {
    const selectedSet = new Set(selectedUserKeys);
    if (shouldSelect) {
      selectedSet.add(userKey);
    } else {
      selectedSet.delete(userKey);
    }
    setSelectedUserKeys(Array.from(selectedSet));
  };

  const onSelectAll = (shouldSelect: boolean) => {
    if (shouldSelect) {
      setSelectedUserKeys(meeting?.meetingUsers?.meetingUsers?.map((meetingUser) => meetingUser.userKey) || []);
    } else {
      setSelectedUserKeys([]);
    }
  };

  const checkboxConfig: CheckboxConfig = {
    checkboxId: 'userKey',
    onSelectRow,
    onSelectAll,
    selectedIds: selectedUserKeys,
  };

  const students = meeting?.meetingUsers?.meetingUsers || [];

  const handleCsvDownload = () => {
    const rows = students.map((student) => ({
      studentID: student.userKey,
      email: student.user?.email,
      firstName: student.user?.firstName,
      lastName: student.user?.lastName,
      seriesName: meeting?.series?.name,
      seriesId: meeting?.series?.id,
      attendance: student.attendance?.status,
    }));
    downloadCsvString(Papa.unparse(rows), `${meeting?.name || 'meeting'}-students.csv`);
  };

  return (
    <GridItem gridColumn="1 / -1">
      <Button as={RouterLink} to="/connect" variant="outline" mb={12} leftIcon={<ArrowLeftIcon h={6} w={6} />}>
        {t('common.back')}
      </Button>
      <Box borderRadius="base" border="1px solid" borderColor="gray.300" bg="white">
        <Flex flexDir="column" gap={4} borderTopRadius="base" bg="blue.800" color="white" p={10}>
          <Heading size="h2" as="h1">
            {meeting?.name || `${date} ${startTime} - ${endTime}`}
          </Heading>
          <Text fontWeight={600}>{meeting?.series?.name}</Text>
          <Flex gap={2}>
            <Badge backgroundColor="blue.700" color="white">
              <CalendarIcon h={4} mb={0.5} w={4} /> {date}
            </Badge>
            <Badge backgroundColor="blue.700" color="white">
              <TimeIcon h={4} mb={0.5} w={4} /> {startTime} - {endTime}
            </Badge>
            {languages.map((language) => (
              <Badge backgroundColor="blue.700" color="white" key={language.name}>
                <ChatIcon h={4} mb={0.5} w={4} /> {language.name}
              </Badge>
            ))}
          </Flex>
          {meeting?.conferenceLink && (
            <Link
              color="accent.sea-foam.500"
              _hover={{ color: 'accent.sea-foam.100' }}
              _visited={{ color: 'accent.sea-foam.200' }}
              href={meeting.conferenceLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {meeting.conferenceLink}
            </Link>
          )}
          {meeting?.instructions && <Text whiteSpace="pre-line">{meeting.instructions}</Text>}
        </Flex>
        <Box p={10}>
          <Flex justifyContent="space-between">
            <Heading size="h3" as="h2" mb={8}>
              {t('connect.attendance')}
            </Heading>
            <BulkAttendanceButtons meetingId={meetingId} selectedUserKeys={selectedUserKeys} />
          </Flex>
          <DataTable
            id="meeting-learners"
            columns={getMeetingLearnersTableColumns(t)}
            data={students}
            paginationData={meetingUserPaginationData}
            checkboxConfig={checkboxConfig}
            isLoading={isLoading || isFetching}
          />
          <Button variant="outline" onClick={handleCsvDownload}>
            Download Student CSV
          </Button>
        </Box>
      </Box>
    </GridItem>
  );
};

const meetingUserPaginationData = {
  defaultPageSize: 100,
};

function getMeetingLearnersTableColumns(t: TFunction): DataTableColumn<MeetingUser>[] {
  return [
    {
      id: 'name',
      title: t('connect.meetingUserTableNameHeader'),
      getRow: (learner) => {
        return {
          data: `${learner.user?.firstName} ${learner.user?.lastName} | ${learner.user?.email}`,
        };
      },
    },
    {
      id: 'attendance',
      title: t('connect.attendance'),
      getRow: (learner) => {
        return {
          data: learner.attendance?.status || t('connect.notRecorded'),
          component: (
            <AttendanceCell
              attendance={learner.attendance}
              attendanceRequest={learner.attendanceRequest}
              meetingId={learner.meetingId}
              learner={learner}
            />
          ),
        };
      },
    },
  ];
}
