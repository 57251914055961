import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue, Select } from 'chakra-react-select';
import { getChakraReactSelectStyles } from '@udacity/chakra-uds-theme';
import { Checkbox, FormControl, FormHelperText, FormLabel } from '@chakra-ui/react';

import { getSelectLanguages, getAllSelectLanguageOptions, Option } from 'app/helpers/form-helper';

import styles from './locale.module.scss';
import { LanguageChangeEvent } from 'app/common/views/profile-editor/profile-editor';

type SpecializedMentorLocaleProps = {
  languages: string[]; // language codes for all previously submitted languages
  onChangeLanguages: (event: React.ChangeEvent<HTMLInputElement>) => void;
  languageOptions: { code: string; name: string; value?: string }[];
  onChangeMultiSelectLanguages: (e: LanguageChangeEvent) => void;
};

export const SpecializedMentorLocale: React.FC<SpecializedMentorLocaleProps> = ({
  languages,
  onChangeLanguages,
  languageOptions,
  onChangeMultiSelectLanguages,
}) => {
  const [otherLanguages, setOtherLanguages] = useState<string[]>(
    getSelectLanguages(languages).map(({ label }) => label)
  );

  function isLanguageChecked(locale: string) {
    return languages && languages.includes(locale);
  }

  const { t } = useTranslation();
  const selectOptions = getAllSelectLanguageOptions();

  const handleMultiSelectChange = (options: MultiValue<Option>) => {
    setOtherLanguages(options.map((option) => option.label));
    onChangeMultiSelectLanguages({
      id: 'languages',
      name: 'languages',
      value: options.map((opt) => opt.value),
    });
  };

  return (
    <section>
      <FormControl my={5}>
        <FormLabel mb={2}>{t('mentorProfile.spokenLanguages')}</FormLabel>
        <FormHelperText mb={2}>{t('mentorProfile.theFollowingLanguages')}</FormHelperText>

        <ul className={styles['list']}>
          {languageOptions.map(({ name, code }) => {
            return (
              <li className={styles['language-checklist']} key={code}>
                <Checkbox
                  id={`${name}-checkbox`}
                  checked={isLanguageChecked(code)}
                  value={code}
                  onChange={onChangeLanguages}
                >
                  {name}
                </Checkbox>
              </li>
            );
          })}

          <li data-tst="select-language">
            <Select<Option, true>
              id="other"
              aria-label={t('mentorProfile.otherSelectAll')}
              chakraStyles={getChakraReactSelectStyles()}
              options={selectOptions.filter((option) => !otherLanguages.includes(option.label))}
              onChange={handleMultiSelectChange}
              isMulti
            />
          </li>
        </ul>
      </FormControl>
    </section>
  );
};
