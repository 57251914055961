import React from 'react';
import styles from './sidebar-list.module.scss';

type SidebarListProps = {
  label: string;
  title: string;
};

export const SidebarList: React.FC<SidebarListProps> = ({ label, title, children }) => {
  return (
    <section className={styles.container}>
      <div className={styles['list-header']}>
        <p className={styles['label']}>{label}</p>
        <p className={styles.title}>{title}</p>
      </div>
      {children}
    </section>
  );
};
