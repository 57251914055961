import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatAlternatePrice, formatPrice } from 'app/helpers/format-helper';

import styles from './earnings-card.module.scss';

type EarningsCardProps = {
  earned?: string;
  monthText: string;
  currency?: string;
};

export const EarningsCard: React.FC<EarningsCardProps> = ({ earned, monthText, currency }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['earnings-card']}>
      <h4 className={`${styles['label']} label`}>{t('queue.overview.monthTotalEarnings', { month: monthText })}</h4>
      <p data-tst="earnings-total">
        {currency && currency !== 'USD'
          ? formatAlternatePrice(currency ?? '', earned ?? '0')
          : formatPrice(earned ?? '0')}
      </p>
      <p className={styles['subtext']}>{t('queue.overview.paidMidNextMonth')}</p>
    </div>
  );
};
