import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Spinner } from '@chakra-ui/react';
import DataTable from '@udacity/ureact-data-table';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import i18n from 'app/i18n';
import { fetchAll } from 'app/queue/domains/statements/statements-actions';
import { getStatements, getStatementsAsync } from 'app/queue/domains/statements/statements-selectors';

import styles from './statements.module.scss';

const mapStateToProps = (state) => ({
  statements: getStatements(state),
  statementsAsync: getStatementsAsync(state, 'fetchAll'),
});

const mapDispatchToProps = {
  fetchAll,
};

const getStatementsTableData = (statements) => {
  return statements.map((statement) => {
    const { id, archive_url, start_date, end_date } = statement;

    return {
      'Start Date': {
        data: dayjs.utc(start_date).format('DD MMMM YYYY'),
        statement_id: id,
      },
      'End Date': {
        data: dayjs.utc(end_date).subtract(1, 'day').format('DD MMMM YYYY'),
        statement_id: id,
      },
      Archive: {
        data: (
          <a href={archive_url} download>
            {i18n.t('common.download')}
          </a>
        ),
        statement_id: id,
      },
    };
  });
};

export const sortComparator = (sortColumn, row) => {
  if (sortColumn === 'Archive') {
    return _get(row, [sortColumn, 'id']);
  } else {
    return dayjs(_get(row, [sortColumn, 'data']));
  }
};

export class Statements extends React.Component {
  static propTypes = {
    // state
    statements: PropTypes.array.isRequired,
    statmentsAsync: PropTypes.shape({
      loading: PropTypes.bool,
      firstLoad: PropTypes.bool,
      status: PropTypes.string,
    }),
    // dispatch
    fetchAll: PropTypes.func.isRequired,
  };

  static defaultProps = {
    statements: [],
  };

  componentDidMount() {
    this.props.fetchAll();
  }

  render() {
    const { statements, statementsAsync, t } = this.props;

    const isAllLoading = statementsAsync.firstLoad;

    const statementsTableData = getStatementsTableData(statements);

    return (
      <div>
        <div className={styles['title-bar']}>
          <h1>{t('queue.monthlyStatements')}</h1>
        </div>

        {isAllLoading ? (
          <div className={styles['loader']}>
            <div>
              <Spinner />
            </div>
          </div>
        ) : (
          <div className={styles.content}>
            <DataTable
              rows={statementsTableData}
              data-tst="statements-table"
              className={styles['statements-table']}
              sortComparator={sortComparator}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Statements));
