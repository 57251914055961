import { CountryLocale, MentorRole } from '../types';
import { careerCoachLocaleData } from './career-coach';
import { communityModeratorLocaleData } from './community-moderator';
import { guruLocaleData } from './guru';
import { sessionLeadLocaleData } from './session-lead';
import { tutorLocaleData } from './tutor';

export const getCountryLocaleDataForRole = (
  role: MentorRole,
  countryCode: string,
  currency_override: string
): CountryLocale | undefined => {
  countryCode = currency_override === 'EGP' ? 'EG_EGP' : countryCode;

  switch (role) {
    case MentorRole.Guru:
      return guruLocaleData[countryCode];
    case MentorRole.CommunityModerator:
      return communityModeratorLocaleData[countryCode];
    case MentorRole.CareerCoach:
      return careerCoachLocaleData[countryCode];
    case MentorRole.Tutor:
      return tutorLocaleData[countryCode];
    case MentorRole.SessionLead:
      return sessionLeadLocaleData[countryCode];
  }
};
