import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox, Link, Text } from '@chakra-ui/react';

import styles from 'app/common/views/profile-editor/profile-editor.module.scss';

import CONFIG from 'configLoader';

type TermsOfServiceProps = {
  id?: string;
  handleTOS: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
  link?: string;
};

export const TermsOfService: React.FC<TermsOfServiceProps> = ({
  id,
  handleTOS,
  isChecked = false,
  link = CONFIG.mentorAgreementUrl,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.flex}>
      <Checkbox id={id ? id : 'mentor-services-agreement'} isChecked={isChecked} onChange={handleTOS}>
        {link ? (
          <Text>
            <Trans
              i18nKey="termsOfService.iAgreeToLink"
              components={{ agreement_link: <Link href={link} isExternal /> }}
            />
          </Text>
        ) : (
          <Text>{t('termsOfService.iAgreeTo')}</Text>
        )}
      </Checkbox>
    </div>
  );
};
