import React, { useEffect, useState } from 'react';
import { Accordion, Flex, Heading } from '@chakra-ui/react';

import { useUpdateCritiqueMutation } from 'app/apis/reviews';
import { Critique, EditedCritique, RubricSection as RubricSectionType } from 'app/types/reviews';

import { FeedbackRubricItem } from './feedback-rubric-item';

type RubricSectionProps = {
  section: RubricSectionType;
  isAllExpanded: boolean | null;
  handleExpandAll: (shouldExpandAll: boolean | null) => void;
  critiques: Critique[];
  previousCritiques: Critique[] | undefined;
  submissionId: number;
  isAssigned: boolean;
};

export const RubricSection: React.FC<RubricSectionProps> = ({
  section,
  isAllExpanded,
  handleExpandAll,
  critiques,
  previousCritiques,
  submissionId,
  isAssigned,
}) => {
  const [expandedIndex, setExpandedIndex] = useState<number[]>([]);
  const sectionCritiques = critiques.filter((c) => c.sectionId === section.id);
  const previousSectionCritiques = previousCritiques?.filter((c) => c.sectionId === section.id);

  const [updateCritique] = useUpdateCritiqueMutation();

  const handleSubmitCritique = async (payload: EditedCritique) => {
    await updateCritique({ ...payload, submissionOrCaseId: submissionId });
  };

  const handleAccordionToggle = (newIndex: number) => {
    handleExpandAll(null);
    setExpandedIndex((currentIndices) => {
      if (currentIndices.includes(newIndex)) {
        return currentIndices.filter((i) => i !== newIndex);
      }

      return currentIndices.concat(newIndex);
    });
  };

  useEffect(() => {
    if (isAllExpanded) {
      setExpandedIndex(Array.from(Array(section.rubricItems.length).keys()));
    } else if (isAllExpanded === false) {
      setExpandedIndex([]);
    }
  }, [isAllExpanded]);

  return (
    <Flex direction="column" gap={4}>
      <Heading size="h4" as="h4">
        {section.name}
      </Heading>

      <Accordion index={expandedIndex} allowToggle allowMultiple>
        {section.rubricItems.map((item, i) => {
          const rubricItemCritique = sectionCritiques.find((sectionCrit) => sectionCrit.rubricItemId === item.id);
          const previousRubricItemCritique = previousSectionCritiques?.find(
            (sectionCrit) => sectionCrit.rubricItemId === item.id
          );

          return (
            <FeedbackRubricItem
              key={i}
              item={item}
              handleAccordionToggle={() => handleAccordionToggle(i)}
              isAssigned={isAssigned}
              critique={rubricItemCritique}
              previousCritique={previousRubricItemCritique}
              onSubmitCritique={handleSubmitCritique}
            />
          );
        })}
      </Accordion>
    </Flex>
  );
};
