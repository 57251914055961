import React from 'react';
import { Text } from '@chakra-ui/react';

export const CurrentItemsDisplay: React.FC<{
  totalRows: number;
  pageEnd: number;
  pageStart: number;
}> = ({ pageEnd, pageStart, totalRows }) => {
  return pageEnd ? (
    <Text fontSize="sm" color="blackAlpha.700">
      {pageStart} - {pageEnd} of {totalRows}
    </Text>
  ) : (
    <Text color="blackAlpha.700">No Results</Text>
  );
};
