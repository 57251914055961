export default {
  POLLING_INTERVAL: 60 * 1000,
  MAX_QUEUE_VOLUME: 1,
  SUBMISSION_REQUEST_STATUS: {
    available: 'available',
    fulfilled: 'fulfilled',
    canceled: 'canceled',
  },
};

export const QUEUE_VOLUME = {
  single: 'single',
  max: 'max',
} as const;
