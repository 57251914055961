import React from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react';

import { getNumberOfPages, getTruncatedRange, RANGE_ELLIPSIS } from '../utils';

export const PageLinks: React.FC<{
  pageSize: number;
  totalRows: number;
  currentPage: number;
  onGoToPage: (newPageIndex: number) => void;
}> = ({ pageSize, totalRows, currentPage, onGoToPage }) => {
  const numberOfPages = getNumberOfPages(totalRows, pageSize);
  const rangeToShow = getTruncatedRange(numberOfPages, currentPage);
  const goToPage = (pageNumber: number) => {
    return () => onGoToPage(pageNumber);
  };

  return (
    <ButtonGroup spacing={0}>
      {currentPage > 1 && <PageLink goTo={currentPage - 1} label="prev" onGoToPage={goToPage} />}
      {rangeToShow.map((num, idx) => (
        <PageLink key={idx} goTo={num} currentPage={currentPage} onGoToPage={goToPage} />
      ))}
      {currentPage < numberOfPages && <PageLink goTo={currentPage + 1} label="next" onGoToPage={goToPage} />}
    </ButtonGroup>
  );
};

const PageLink: React.FC<{
  goTo: number;
  label?: string;
  currentPage?: number;
  onGoToPage(page: number): () => void;
}> = ({ goTo, label, currentPage, onGoToPage }) => {
  if (goTo === RANGE_ELLIPSIS) {
    return (
      <Button size="sm" variant="ghost" disabled>
        ...
      </Button>
    );
  }

  const isActive = currentPage === goTo;
  return (
    <Button as="a" size="sm" variant="ghost" backgroundColor={isActive ? 'gray.100' : ''} onClick={onGoToPage(goTo)}>
      {label || goTo}
    </Button>
  );
};
