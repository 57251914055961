import _ from 'lodash';
import axios from 'axios';

const IGNORED_STATUSES = [403];

export const retryUntilMax = (config = {}, error) => {
  const { retry, maxRetries, retryDelay } = config;

  if (_.isNumber(retry) && _.isNumber(maxRetries) && retry <= maxRetries) {
    return new Promise((resolve) =>
      setTimeout(() => {
        const axiosConfig = {
          ...config,
          retry: retry + 1,
          retryDelay: retryDelay * 2 || 1000,
        };

        return resolve(axios(axiosConfig));
      }, retryDelay || 0)
    );
    // end retry loop after max fails
  } else if (retry === maxRetries) {
    return Promise.reject(error);
  }
};

const errorInterceptor = (error = {}) => {
  const status = _.get(error, 'response.status');

  if (IGNORED_STATUSES.includes(status)) {
    return Promise.reject(error);
  }

  const config = _.get(error, 'config', {});
  if (_.isEmpty(config)) {
    return Promise.reject(error);
  }

  const isServerError = status >= 500 && status <= 599;
  const code = _.get(error, 'code') || _.get(error, 'response.code');
  const isConnectionTimeout = code === 'ECONNABORTED';

  // if connection timeouts or server errors and request
  // has retry rules set then, enter retry loop
  if ((isConnectionTimeout || isServerError) && !_.isUndefined(config.maxRetries)) {
    return retryUntilMax(config, error);
  }

  return Promise.reject(error);
};

axios.interceptors.response.use(errorInterceptor);
