import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text, VStack } from '@chakra-ui/react';
import { CheckedIcon } from '@udacity/chakra-uds-icons';

type GuideStepProps = {
  stepNumber: number;
  description: ReactNode;
};

export const GuideStep: React.FC<GuideStepProps> = ({ stepNumber, description }) => {
  const { t } = useTranslation();

  return (
    <Flex gap={2}>
      <CheckedIcon w={8} h={8} color="blue.500" />
      <VStack spacing={0} align="start">
        <Text size="label">{t('projectReviews.annotations.step', { count: stepNumber })}</Text>
        <Text>{description}</Text>
      </VStack>
    </Flex>
  );
};
