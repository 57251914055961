import { actionTypes } from 'app/common/domains/ui/ui-actions';

export const initialState = {
  showPanel: '',
  toasts: [],
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_PANEL:
      state = {
        ...state,
        showPanel: payload,
      };
      break;

    case actionTypes.ADD_TOAST:
      state = {
        ...state,
        toasts: [...state.toasts, payload],
      };
      break;

    case actionTypes.REMOVE_TOAST:
      state = {
        ...state,
        toasts: state.toasts.filter((tst) => tst.id !== payload.id),
      };
      break;

    default:
      break;
  }
  return state;
};
