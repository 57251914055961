import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SingleValue } from 'chakra-react-select';
import { Badge, Button, FormControl, FormLabel, Heading, HStack } from '@chakra-ui/react';
import { getChakraReactSelectStyles } from '@udacity/chakra-uds-theme';

import { useCreateCommentMutation, useDeleteCommentMutation, useUpdateCommentMutation } from 'app/apis/reviews';
import { AsymmetricCard } from 'app/common/components/asymmetric-card';
import { getBadgeInfo } from 'app/project-reviews/helpers';
import { Comment } from 'app/types/reviews';

import { FeedbackInput, MarkdownPreview } from './feedback-input';

type Option = { value: Comment['category']; label: string };

type EditCodeCommentProps = {
  lineNumber?: number;
  contentId: number;
  submissionId: number;
  comment?: Comment;
  onCancel: () => void;
};

export const EditCodeComment: React.FC<EditCodeCommentProps> = ({
  lineNumber,
  contentId,
  submissionId,
  comment,
  onCancel,
}) => {
  const { t } = useTranslation();

  const reviewCategories: Option[] = useMemo(() => {
    return [
      {
        value: 'awesome',
        label: t('projectReviews.codeReview.great'),
      },
      {
        label: t('projectReviews.codeReview.couldImprove'),
        value: 'nitpick',
      },
      {
        label: t('projectReviews.codeReview.requiresUpdate'),
        value: 'critical',
      },
    ];
  }, [t]);

  const [isEditing, setIsEditing] = useState(!comment);
  const [category, setCategory] = useState<SingleValue<Option>>(
    reviewCategories.find((c) => c.value === comment?.category) || null
  );
  const [newComment, setNewComment] = useState(comment?.body || '');

  const badgeInfo = getBadgeInfo(category?.value, t);

  const [createComment] = useCreateCommentMutation();
  const [updateComment] = useUpdateCommentMutation();
  const [deleteComment, { isLoading: isLoadingDeleteComment }] = useDeleteCommentMutation();

  const onCategoryChange = (option: SingleValue<Option>) => {
    const newCategory = reviewCategories.find((c) => c.value === option?.value);

    if (newCategory) {
      setCategory(newCategory);
    }
  };

  const onSave = async () => {
    if (newComment && category?.value && lineNumber) {
      const commentPayload = {
        body: newComment,
        category: category.value,
        position: lineNumber - 1,
      };

      if (comment?.id) {
        await updateComment({
          submissionId,
          commentId: comment.id,
          contentId,
          ...commentPayload,
        });
      } else {
        await createComment({
          submissionId,
          contentId,
          ...commentPayload,
        });
      }

      setIsEditing(false);
    }
  };

  return (
    <AsymmetricCard
      direction="column"
      mt={2}
      p={6}
      maxW={{ base: '20rem', lg: '42rem' }}
      bgColor="white"
      fontFamily="body"
      color="black"
    >
      <Heading size="h5" as="h5" mb={4}>
        {t('projectReviews.codeReview.lineNumber', { number: lineNumber })}
      </Heading>

      {isEditing && (
        <FormControl mb={4} maxW={{ base: 'full', lg: '45%' }}>
          <FormLabel mb={2}>{t('projectReviews.codeReview.reviewCategory')}</FormLabel>
          <Select<Option>
            aria-label={t('projectReviews.codeReview.reviewCategory')}
            chakraStyles={getChakraReactSelectStyles()}
            options={reviewCategories}
            value={category}
            onChange={onCategoryChange}
            isClearable={false}
          />
        </FormControl>
      )}

      {!isEditing && badgeInfo && (
        <Badge bgColor={badgeInfo.bgColor} color="white" fontFamily="body" width="max-content" size="lg" mb={4}>
          {badgeInfo.label}
        </Badge>
      )}

      {isEditing ? (
        <FormControl mb={2}>
          <FormLabel mb={2}>{t('projectReviews.feedback.feedback')}</FormLabel>

          <FeedbackInput id={`${comment?.id}`} feedback={newComment} setFeedback={setNewComment} isAssigned mb={6} />
        </FormControl>
      ) : (
        <MarkdownPreview text={newComment} bgColor="gray.200" mb={6} />
      )}

      {isEditing && (
        <HStack spacing={4}>
          <Button onClick={onSave} isDisabled={!category?.value || !newComment}>
            {t('projectReviews.codeReview.saveFeedback')}
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              setIsEditing(false);
              onCancel();
            }}
          >
            {t('common.cancel')}
          </Button>
        </HStack>
      )}

      {!isEditing && comment?.id && (
        <HStack spacing={4}>
          <Button variant="outline" onClick={() => setIsEditing(true)}>
            {t('projectReviews.codeReview.editFeedback')}
          </Button>
          <Button
            variant="ghost"
            isLoading={isLoadingDeleteComment}
            onClick={() => {
              onCancel();
              deleteComment({
                submissionId,
                commentId: comment.id,
                contentId,
              });
            }}
          >
            {t('projectReviews.codeReview.deleteFeedback')}
          </Button>
        </HStack>
      )}
    </AsymmetricCard>
  );
};
