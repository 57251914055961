import _ from 'lodash';
import { actionTypes } from './recruitment-families-actions';

export const initialState = {
  families: [],
  reviewerInterests: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  if (error || !payload) {
    return state;
  }

  switch (type) {
    case actionTypes.FETCH_ALL_FULFILLED: {
      return {
        ...state,
        families: payload,
      };
    }
    case actionTypes.FETCH_MY_INTERESTS_FULFILLED: {
      return {
        ...state,
        reviewerInterests: payload,
      };
    }
    case actionTypes.UPDATE_REVIEWER_INTEREST: {
      const { family } = payload;
      return {
        ...state,
        reviewerInterests: [
          ..._.filter(state.reviewerInterests, (f) => f.recruitment_family_id !== family.recruitment_family_id),
          family,
        ],
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
