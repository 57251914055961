import { Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { CancelIcon, CheckedIcon, CircleEmptyIcon } from '@udacity/chakra-uds-icons';
import { useAppToast } from 'app/common/hooks/use-app-toast';
import { MeetingAttendance, MeetingAttendanceStatus } from 'app/types/uconnect';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateAttendancesMutation } from '../queries/enhanced';

type BulkAttendanceButtonsProps = {
  meetingId: string;
  selectedUserKeys: string[];
};

export const BulkAttendanceButtons: React.FC<BulkAttendanceButtonsProps> = ({ meetingId, selectedUserKeys }) => {
  const { t } = useTranslation();
  const toast = useAppToast();
  const [updateAttendances, { isLoading }] = useUpdateAttendancesMutation();

  async function handleUpdateAttendances(status: MeetingAttendanceStatus) {
    const attendances: MeetingAttendance[] = selectedUserKeys.map((userKey) => ({
      userKey,
      meetingId,
      status,
    }));
    const res = await updateAttendances({ attendances });
    if ('error' in res) {
      toast({
        variant: 'error',
        description: (res.error as { data: string }).data,
      });
    }
  }

  return (
    <Flex gap={6}>
      <Tooltip label={t('connect.attended')}>
        <IconButton
          icon={<CheckedIcon h={8} w={8} />}
          aria-label={t('connect.setAttended')}
          variant="outline"
          isRound
          width={12}
          isDisabled={!selectedUserKeys.length}
          isLoading={isLoading}
          onClick={() => handleUpdateAttendances(MeetingAttendanceStatus.Attended)}
        />
      </Tooltip>
      <Tooltip label={t('connect.excused')}>
        <IconButton
          icon={<CircleEmptyIcon h={8} w={8} />}
          aria-label={t('connect.setExcused')}
          variant="outline"
          isRound
          width={12}
          isDisabled={!selectedUserKeys.length}
          isLoading={isLoading}
          onClick={() => handleUpdateAttendances(MeetingAttendanceStatus.Excused)}
        />
      </Tooltip>
      <Tooltip label={t('connect.absent')}>
        <IconButton
          icon={<CancelIcon h={8} w={8} />}
          aria-label={t('connect.setAbsent')}
          variant="outline"
          isRound
          width={12}
          isDisabled={!selectedUserKeys.length}
          isLoading={isLoading}
          onClick={() => handleUpdateAttendances(MeetingAttendanceStatus.Absent)}
        />
      </Tooltip>
    </Flex>
  );
};
