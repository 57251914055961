import { actionTypes } from './reviewer-data-actions';

export const initialState = {
  earnings: null,
  statements: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  if (error || !payload) {
    return state;
  }

  switch (type) {
    case actionTypes.FETCH_EARNINGS_FULFILLED: {
      return {
        ...state,
        earnings: payload,
      };
    }

    case actionTypes.FETCH_RATINGS_MATRIX_FULFILLED: {
      return {
        ...state,
        ratingsMatrix: payload.ratings_matrix,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
