import i18n from 'app/i18n';
import dayjs from 'dayjs';

import 'dayjs/locale/en';
import 'dayjs/locale/pt-br';

import avancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(avancedFormat);
dayjs.extend(localizedFormat);

dayjs.locale(i18n.language === 'en-US' ? 'en' : i18n.language.toLowerCase());

export default dayjs;
