import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UnorderedList } from '@chakra-ui/react';

import { getIsSelected } from 'app/project-reviews/helpers';

import { ReviewsSideNavbarItem } from './reviews-side-navbar-item';

type ReviewsSideNavbarProps = {
  hasHistory: boolean;
  showPlagiarismAudit: boolean;
};

export const ReviewsSideNavbar: React.FC<ReviewsSideNavbarProps> = ({ hasHistory, showPlagiarismAudit }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const match = useRouteMatch<{ submissionId: string }>();

  const isSelected = (tabName: string) => getIsSelected(tabName, pathname);

  return (
    <UnorderedList m={0} p={0} listStyleType="none" overflow="auto" bgColor="white" w="full">
      {showPlagiarismAudit && (
        <ReviewsSideNavbarItem
          to={`/project-reviews/${match.params.submissionId}/plagiarism-audit`}
          label={t('projectReviews.plagiarismAudit.title')}
          isActive={isSelected('plagiarism-audit')}
        />
      )}
      <ReviewsSideNavbarItem
        to={`/project-reviews/${match.params.submissionId}/feedback`}
        label={t('projectReviews.feedback.feedback')}
        isActive={isSelected('feedback')}
      />
      <ReviewsSideNavbarItem
        to={`/project-reviews/${match.params.submissionId}/rubric`}
        label={t('projectReviews.rubric.rubric')}
        isActive={isSelected('rubric')}
      />
      <ReviewsSideNavbarItem
        to={`/project-reviews/${match.params.submissionId}/resources`}
        label={t('projectReviews.resources.resources')}
        isActive={isSelected('resources')}
      />
      {hasHistory && (
        <ReviewsSideNavbarItem
          to={`/project-reviews/${match.params.submissionId}/history`}
          label={t('projectReviews.history.history')}
          isActive={isSelected('history')}
        />
      )}
    </UnorderedList>
  );
};
