/* eslint-disable */ /**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 * instead, edit its related `.gql` file and recreate it with
 *
 * npm run codegen:connect
 */

import * as Types from '../../types/uconnect';

import { api } from 'app/apis/connect-gql-api';
export type GetAvailableSeriesQueryVariables = Types.Exact<{
  facilitatorUserKey?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetAvailableSeriesQuery = {
  __typename?: 'Query';
  series: {
    __typename?: 'PaginatedSeries';
    totalCount: number;
    series: Array<{
      __typename?: 'Series';
      id: any;
      name: string;
      description?: string | null;
      firstMeeting: {
        __typename?: 'PaginatedMeetings';
        totalCount: number;
        meetings: Array<{
          __typename?: 'Meeting';
          id: any;
          startAt: any;
          endAt: any;
          locales: Array<string>;
          meetingUsers: { __typename?: 'PaginatedMeetingUsers'; totalCount: number };
        }>;
      };
      lastMeeting: {
        __typename?: 'PaginatedMeetings';
        meetings: Array<{ __typename?: 'Meeting'; id: any; startAt: any; endAt: any }>;
      };
    }>;
  };
};

export const GetAvailableSeriesDocument = `
    query GetAvailableSeries($facilitatorUserKey: String) {
  series(input: {availability: {hasMeetingsAvailableForFacilitatorUserKey: $facilitatorUserKey}}) {
    totalCount
    series {
      id
      name
      description
      firstMeeting: meetings(input: {paginationParams: {limit: 1, offset: 0}, availability: {availableToFacilitators: true, availableToFacilitatorUserKey: $facilitatorUserKey}, sortBy: START_AT}) {
        totalCount
        meetings {
          id
          startAt
          endAt
          meetingUsers(input: {paginationParams: {limit: 0, offset: 0}}) {
            totalCount
          }
          locales
        }
      }
      lastMeeting: meetings(input: {paginationParams: {limit: 1, offset: 0}, availability: {availableToFacilitators: true, availableToFacilitatorUserKey: $facilitatorUserKey}, sortBy: START_AT, sortOrder: DESC}) {
        meetings {
          id
          startAt
          endAt
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetAvailableSeries: build.query<GetAvailableSeriesQuery, GetAvailableSeriesQueryVariables | void>({
      query: (variables) => ({ document: GetAvailableSeriesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
