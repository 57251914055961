import { reviewsApi } from 'app/apis/reviews';
import { SubmissionStatus } from 'app/queue/domains/submissions/submissions-helpers';
import {
  ApiComment,
  ApiContent,
  ApiCritique,
  ApiPlagiarismCase,
  ApiRating,
  ApiReviewerToolkit,
  ApiRubric,
  ApiSubmission,
  Critique,
  Submission,
} from 'app/types/reviews';

export const sanitizeApiSubmission = (apiSubmission: ApiSubmission) => {
  return {
    id: apiSubmission.id,
    rubricId: apiSubmission.rubric_id,
    language: apiSubmission.language,
    projectId: apiSubmission.project_id,
    expiresAt: apiSubmission.expires_at,
    projectTitle: apiSubmission.project.name,
    archiveUrl: apiSubmission.archive_url,
    result: apiSubmission.result,
    studentNote: apiSubmission.notes,
    reviewerComment: apiSubmission.general_comment,
    status: apiSubmission.status as SubmissionStatus,
    enrollmentId: apiSubmission.enrollment_id,
    userId: apiSubmission.user_id,
    completedAt: apiSubmission.completed_at,
    ungradeableTag: apiSubmission.ungradeable_tag,
    resultReason: apiSubmission.result_reason,
    isDemerit: !!apiSubmission.is_demerit,
    plagiarismConfirmed: apiSubmission.plagiarism_judgement === 'confirmed',
    plagiarismSourceUrl: apiSubmission.plagiarism_source_url || undefined,
    url: apiSubmission.url,
    annotationsUrl: apiSubmission.annotation_urls.length ? apiSubmission.annotation_urls[0] : null,
    graderId: apiSubmission.grader_id,
  };
};

export const sanitizeApiReviewerToolkit = (apiReviewerToolkit: ApiReviewerToolkit) => {
  return {
    id: apiReviewerToolkit.id,
    language: apiReviewerToolkit.language,
    projectId: apiReviewerToolkit.project_id,
    archiveUrl: apiReviewerToolkit.archive_url,
    updatedAt: apiReviewerToolkit.updated_at,
  };
};

export const sanitizeApiRubric = (apiRubric: ApiRubric) => {
  return {
    id: apiRubric.id,
    description: apiRubric.description,
    language: apiRubric.language,
    sections: apiRubric.sections.map(sanitizeApiRubricSection),
  };
};

export const sanitizeApiRubricSection = (apiRubricSection: ApiRubric['sections'][number]) => {
  return {
    id: apiRubricSection.id,
    name: apiRubricSection.name,
    rubricItems: apiRubricSection.rubric_items.map((r) => ({
      id: r.id,
      criteria: r.criteria,
      requirements: r.passed_description,
      reviewerTip: r.reviewer_tips,
    })),
  };
};

export const sanitizeApiCritique = (apiCritique: ApiCritique) => {
  return {
    id: apiCritique.id,
    observation: apiCritique.observation,
    suggestion: apiCritique.suggestion,
    result: apiCritique.result === 'exceeded' ? 'passed' : apiCritique.result,
    rubricItemId: apiCritique.rubric_item_id,
    sectionId: apiCritique.rubric_item.section.id,
    rubricItemDeletedAt: apiCritique.rubric_item.deleted_at,
  };
};

export const sanitizeApiContent = (apiContents: ApiContent) => {
  return {
    id: apiContents.id,
    blob: apiContents.blob,
    commentsCount: apiContents.comments_count,
    path: apiContents.path,
    submissionId: apiContents.submission_id,
  };
};

export const sanitizeApiComment = (apiComment: ApiComment) => {
  return {
    id: apiComment.id,
    body: apiComment.body,
    category: apiComment.category,
    lineNumber: apiComment.position + 1,
  };
};

export const sanitizeApiRating = (apiRating: ApiRating) => {
  return {
    value: apiRating.value,
    submissionId: apiRating.submission_id,
  };
};

export const sanitizeApiPlagiarismCase = (apiPlagiarismCase: ApiPlagiarismCase) => {
  return {
    id: apiPlagiarismCase.id,
    submissionId: apiPlagiarismCase.submission_id,
    graderId: apiPlagiarismCase.grader_id,
    result: apiPlagiarismCase.result,
    status: apiPlagiarismCase.status,
    assignedAt: apiPlagiarismCase.assigned_at,
    expiresAt: apiPlagiarismCase.expires_at,
    completedAt: apiPlagiarismCase.completed_at,
    price: apiPlagiarismCase.price,
    project: apiPlagiarismCase.submission_project
      ? {
          id: apiPlagiarismCase.submission_project.id,
          name: apiPlagiarismCase.submission_project.name,
        }
      : null,
  };
};

// Cache helpers
export const updateCritique = (
  submissionOrCaseId: number,
  updatedCritique?: Critique | null,
  isPlagiarismAudit?: boolean
) => {
  if (isPlagiarismAudit) {
    return reviewsApi.util.updateQueryData('fetchPlagiarismCritiques', submissionOrCaseId, (draftCritiques) => {
      const critiqueIndex = draftCritiques.findIndex((c) => c.id === updatedCritique?.id);

      if (critiqueIndex > -1 && updatedCritique) {
        draftCritiques[critiqueIndex] = updatedCritique;
      }
    });
  }
  return reviewsApi.util.updateQueryData('fetchCritiques', submissionOrCaseId, (draftCritiques) => {
    const critiqueIndex = draftCritiques.findIndex((c) => c.id === updatedCritique?.id);

    if (critiqueIndex > -1 && updatedCritique) {
      draftCritiques[critiqueIndex] = updatedCritique;
    }
  });
};

export const updateSubmission = (submissionId: number, updatedSubmission?: Submission | null) => {
  return reviewsApi.util.updateQueryData('fetchSubmission', submissionId, (draftSubmission) => {
    if (updatedSubmission) Object.assign(draftSubmission, updatedSubmission);
  });
};
