import React from 'react';
import { Tbody, Td, Tr } from '@chakra-ui/react';

import { DataTableColumn, DataTableRow } from './types';

type Props<T extends DataTableRow> = {
  columns: DataTableColumn<T>[];
  data: T[];
};

export function TableBody<T extends DataTableRow>({ columns, data }: Props<T>): JSX.Element {
  return (
    <Tbody>
      {data.map((row, idx) => {
        let key: string | number = idx;
        if (row && row.id) {
          key = row.id as string | number;
        }

        return (
          <Tr key={key}>
            {columns.map((col) => {
              const rowData = col.getRow(row, idx);
              return <Td key={col.id}>{rowData.component || rowData.data}</Td>;
            })}
          </Tr>
        );
      })}
    </Tbody>
  );
}
