import { marked } from 'marked';

// Instantiates renderer instance in closure so we have access to contextual information
export const createMarkdownHTML = (content: string) => {
  const renderer = new marked.Renderer();

  // Modify how we render links:
  //  - add target=_blank to all links
  //  - don't allow for links with titles to obscure the real destination; always show the full
  //    link (security concern)
  renderer.link = function (href, title, text) {
    const rendererLink = marked.Renderer.prototype.link.call(this, href, href, text);
    if (href && href.charAt(0) === '#') {
      return rendererLink;
    }
    return rendererLink.replace(/^<a /, '<a target="_blank" rel="noopener" ');
  };

  // Modify to allow newlines/breaks in tablecells
  // https://github.com/markedjs/marked/blob/master/src/Renderer.js#L107
  renderer.tablecell = function (content, flags) {
    const selfClosingBreak = '↳';
    const contentByLine = content.split(selfClosingBreak);
    const type = flags.header ? 'th' : 'td';
    const tag = flags.align ? '<' + type + ' align="' + flags.align + '">' : '<' + type + '>';
    return tag + contentByLine.join(`<br />`) + '</' + type + '>\n';
  };

  const options = {
    ...marked.defaults,
    renderer,
    sanitize: true,
  };

  const lexer = new marked.Lexer(options);
  const parser = new marked.Parser(options);

  const tokens = lexer.lex(content);
  return parser.parse(tokens);
};
