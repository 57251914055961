import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _reduce from 'lodash/reduce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
  fetchAllRecruitmentFamilies,
  fetchMyInterests,
  updateReviewerInterest,
} from 'app/queue/domains/recruitment-families/recruitment-families-actions';
import {
  selectedComposedFamilies,
  selectReviewerInterestAsync,
} from 'app/queue/domains/recruitment-families/recruitment-families-selectors';
import { selectUid } from 'app/common/domains/user/user-selectors';
import {
  REVIEWER_STATUSES,
  INTEREST_LIMIT,
} from 'app/queue/domains/recruitment-families/recruitment-families-constants';
import { STATUSES as ASYNC_STATUSES } from 'app/common/domains/async';

import RecruitmentFamilyItem from './recruitment-family-item';
import styles from './recruitment-families.module.scss';

const mapDispatchToProps = {
  fetchAllRecruitmentFamilies,
  fetchMyInterests,
  updateReviewerInterest,
};

const mapStateToProps = (state) => ({
  recruitmentFamilies: selectedComposedFamilies(state),
  uid: selectUid(state),
  reviewerInterestAsyncStatus: selectReviewerInterestAsync(state),
});

export class RecruitmentFamilies extends Component {
  static propTypes = {
    recruitmentFamilies: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchAllRecruitmentFamilies: PropTypes.func.isRequired,
    fetchMyInterests: PropTypes.func.isRequired,
    updateReviewerInterest: PropTypes.func.isRequired,
    reviewerInterestAsyncStatus: PropTypes.object.isRequired,
    uid: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    recruitmentFamilies: [],
  };

  state = {
    isAnyApiFulfilled: false,
    totalSelected: 0,
  };

  componentDidMount() {
    const { fetchAllRecruitmentFamilies, fetchMyInterests, uid } = this.props;
    fetchAllRecruitmentFamilies();
    fetchMyInterests(uid);
    this.countSelected();
  }

  componentDidUpdate = (prevProps) => {
    const { recruitmentFamilies, reviewerInterestAsyncStatus } = this.props;
    const { recruitmentFamilies: prevRecruitmentFamilies, reviewerInterestAsyncStatus: prevAsyncStatus } = prevProps;

    if (!_isEqual(recruitmentFamilies, prevRecruitmentFamilies)) {
      this.countSelected();
    }

    if (!_isEqual(reviewerInterestAsyncStatus, prevAsyncStatus)) {
      this.checkFulfilledStatus();
    }
  };

  countSelected() {
    const { recruitmentFamilies } = this.props;

    const totalSelected = _reduce(
      recruitmentFamilies,
      (count, val) => {
        if (val.status === REVIEWER_STATUSES.ACTIVE) {
          return count + 1;
        }
        return count;
      },
      0
    );

    this.setState({ totalSelected });
  }

  checkFulfilledStatus() {
    const { reviewerInterestAsyncStatus } = this.props;

    const isAnyApiFulfilled = _reduce(
      reviewerInterestAsyncStatus,
      (bool, val) => {
        if (bool) return bool;
        return val.status === ASYNC_STATUSES.FULFILLED;
      },
      false
    );

    this.setState({ isAnyApiFulfilled });
  }

  handleChange = (e) => {
    const { updateReviewerInterest } = this.props;
    updateReviewerInterest({
      recruitment_family_id: parseInt(e.target.value),
      status: e.target.checked ? REVIEWER_STATUSES.ACTIVE : REVIEWER_STATUSES.INACTIVE,
    });
  };

  render() {
    const { recruitmentFamilies, reviewerInterestAsyncStatus, t } = this.props;

    const { totalSelected, isAnyApiFulfilled } = this.state;

    const confirmationClasses = classNames(styles.confirmation, {
      [styles['confirmation-visible']]: isAnyApiFulfilled,
    });

    return (
      <div className={styles['form-container']}>
        <section className={styles['section']}>
          <div className={styles['header']}>
            <h2 className={styles['section-title']}>{t('mentorProfile.topicsOfInterest')}</h2>
            <p className={styles['prompt-text']}>{t('mentorProfile.expertise')}</p>
          </div>
          <p className={styles['counter']}>
            {t('mentorProfile.interestsRemaining', {
              count: INTEREST_LIMIT - totalSelected,
            })}
          </p>
          <div className={confirmationClasses}>{t('mentorProfile.interestsUpdated')}</div>
          <ul>
            {recruitmentFamilies.map((family) => {
              const asyncStatus = _get(reviewerInterestAsyncStatus, `${family.id}.status`);

              const isDisabled = totalSelected >= INTEREST_LIMIT && family.status !== REVIEWER_STATUSES.ACTIVE;

              return (
                <RecruitmentFamilyItem
                  key={family.id}
                  family={family}
                  asyncStatus={asyncStatus}
                  handleChange={this.handleChange}
                  isDisabled={isDisabled}
                />
              );
            })}
          </ul>
        </section>
      </div>
    );
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RecruitmentFamilies));
