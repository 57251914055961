import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { ReviewsIcon } from '@udacity/chakra-uds-icons';
import dayjs from 'dayjs';

import { formatPrice, formatAlternatePrice } from 'app/helpers/format-helper';
import ListItem from 'app/queue/components/list-item';
import RaisedGlyph from 'app/queue/components/raised-glyph';
import { ApiSubmission } from 'app/types/reviews';

import styles from './assigned-submission-list-item.module.scss';

const expirations = {
  WARNING: 60, // Warn when an assignment has <= 60 minutes left.
};

type AssignedSubmissionListItemProps = { submission: ApiSubmission };

export const AssignedSubmissionListItem: React.FC<AssignedSubmissionListItemProps> = ({ submission }) => {
  const { t } = useTranslation();

  const isExpiring = (expiresAt: string | null) => {
    return dayjs(expiresAt).diff(dayjs(), 'minutes') <= expirations.WARNING;
  };

  const isExpired = useMemo(() => {
    return dayjs().isAfter(dayjs(submission.expires_at));
  }, [submission.expires_at]);

  const price = formatPrice(submission.price ?? '0');
  const alternatePrice = formatAlternatePrice(
    submission.exchange_currency ?? '',
    submission.exchange_currency_price ?? '0'
  );
  const expiredStyles = isExpired ? styles['expired-submission'] : '';
  const completeIn = isExpired ? t('common.expired') : dayjs(submission.expires_at).fromNow(true);

  return (
    <ListItem className={`${styles['assigned-submission-list-item']} ${expiredStyles}`}>
      <div className={styles.glyph}>
        <RaisedGlyph icon={<ReviewsIcon w={8} h={8} />} />
      </div>

      <div data-tst="title" className={styles.title}>
        <p>{submission.project.name}</p>
      </div>

      <div className={styles['cell-group']}>
        <div data-tst="price" className="price">
          <label>{t('common.earn')}</label>
          <p>{alternatePrice ? alternatePrice : price}</p>
        </div>

        <div data-tst="complete-in" className={isExpiring(submission.expires_at) ? styles['complete-in-expiring'] : ''}>
          <label>{t('queue.reassignedIn')}</label>
          <p>{completeIn}</p>
        </div>
      </div>

      <div className={styles['actions']}>
        {!isExpired && (
          <Button as={ReactRouterLink} to={`/project-reviews/${submission.id}/feedback`} variant="outline" size="sm">
            {t('common.review')}
          </Button>
        )}
      </div>
    </ListItem>
  );
};
