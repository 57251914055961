import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Box, Heading } from '@chakra-ui/react';
import { Markdown } from '@udacity/ureact-markdown';

import { AsymmetricCard } from 'app/common/components/asymmetric-card';
import { getBadgeInfo } from 'app/project-reviews/helpers';

type CodeCommentProps = {
  category: string;
  lineNumber: number;
  comment: string;
};

export const CodeComment: React.FC<CodeCommentProps> = ({ category, lineNumber, comment }) => {
  const { t } = useTranslation();
  const badgeInfo = getBadgeInfo(category, t);

  return (
    <AsymmetricCard
      direction="column"
      mt={2}
      p={6}
      maxW={{ base: '20rem', lg: '42rem' }}
      bgColor="white"
      fontFamily="body"
      color="black"
    >
      <Heading size="h5" as="h5" mb={4}>
        {t('projectReviews.codeReview.lineNumber', { number: lineNumber })}
      </Heading>

      {badgeInfo && (
        <Badge bgColor={badgeInfo.bgColor} color="white" fontFamily="body" width="max-content" size="lg" mb={4}>
          {badgeInfo.label}
        </Badge>
      )}

      <Box maxW="full" whiteSpace="normal" wordBreak="break-word">
        <Markdown markdownText={comment} />
      </Box>
    </AsymmetricCard>
  );
};
