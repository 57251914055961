import React, { useState, useEffect } from 'react';
import { MarkdownRenderer } from 'app/common/components/markdown-renderer/markdown-renderer';
import styles from './guideline-page.module.scss';

export const GuidelinePage: React.FC<{ contentUrl: string }> = ({ contentUrl }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      return fetch(contentUrl)
        .then((response) => {
          return response.blob();
        })
        .then(async (blob) => {
          const text = await new Response(blob).text();
          setContent(text);
        });
    };
    fetchContent();
  }, [contentUrl]);

  return (
    <div className={styles.main}>
      <MarkdownRenderer content={content} />
    </div>
  );
};
