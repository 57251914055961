import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Session } from 'app/common/domains/session/session-types';
import _get from 'lodash/get';

const useApplicationRedirect = (pathname: string, session: Session) => {
  const history = useHistory();

  useEffect(() => {
    const redirect = () => {
      if (
        pathname === '/specialized-mentor/apply' &&
        _get(session, ['application', 'services'], []).includes('government_mentorship')
      ) {
        history.push('/specialized-mentor/update');
      } else if (pathname === '/apply' && session.has_mentor_account) {
        history.push('/update');
      } else if (pathname === '/update' && !session.has_mentor_account) {
        history.push('/apply');
      }
    };
    redirect();
  }, []);
};

export default useApplicationRedirect;
