import auth from '@udacity/ureact-hoth';

export function handleAuthorization() {
  if (!auth.isAuthenticated()) {
    auth.signUp({
      env: 'production',
      returnUrl: window.location.href,
    });
  }
}

export function isStaff() {
  return !!auth.getCurrentUser()?.roles?.includes('staff');
}
