import React from 'react';
import { useTranslation } from 'react-i18next';

import _throttle from 'lodash/throttle';
import { Button, Heading, Tooltip } from '@chakra-ui/react';

import Countdown from 'app/queue/components/countdown';

import styles from './queue-control-row.module.scss';

type QueueControlRowProps = {
  name: string;
  isQueueEnabled: boolean;
  onQueueClick: () => void;
  onOptionsClick: () => void;
  onRefreshClick: () => void;
  expiration: number | null;
  disableQueueButton?: boolean;
  disableQueueButtonTooltip?: string;
};

export const QueueControlRow: React.FC<QueueControlRowProps> = ({
  name,
  isQueueEnabled,
  onQueueClick,
  onOptionsClick,
  onRefreshClick,
  expiration = new Date().getTime(),
  disableQueueButton,
  disableQueueButtonTooltip,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['control-row']}>
      <div className={styles['section']}>
        <Heading size="h4" as="h4" fontSize="1.125rem">
          {name}
        </Heading>
        {isQueueEnabled && (
          <Button variant="ghost" onClick={onOptionsClick} size="sm">
            {t('queue.overview.updateOptions')}
          </Button>
        )}
      </div>

      <div className={styles['section']}>
        {isQueueEnabled && (
          <>
            <Countdown className={styles['countdown']} theme="dark" endDateEpoch={expiration} useShortUnits />

            <Button onClick={_throttle(onRefreshClick, 1000)} size="sm">
              {t('common.refresh')}
            </Button>
          </>
        )}

        {!isQueueEnabled && (
          <Heading size="h4" as="h4" fontSize="1.125rem">
            {t('common.off')}
          </Heading>
        )}
      </div>

      <div className={styles['section']}>
        {disableQueueButton && !!disableQueueButtonTooltip ? (
          <Tooltip label={disableQueueButtonTooltip} placement="end" hasArrow shouldWrapChildren>
            <Button
              variant={isQueueEnabled ? 'outline' : 'solid'}
              onClick={_throttle(onQueueClick, 1000)}
              isDisabled
              size="sm"
              data-tst="queue-button"
            >
              {isQueueEnabled ? t('queue.overview.exitQueue') : t('queue.overview.enterQueue')}
            </Button>
          </Tooltip>
        ) : (
          <Button
            variant={isQueueEnabled ? 'outline' : 'solid'}
            onClick={_throttle(onQueueClick, 1000)}
            isDisabled={disableQueueButton}
            size="sm"
            data-tst="queue-button"
          >
            {isQueueEnabled ? t('queue.overview.exitQueue') : t('queue.overview.enterQueue')}
          </Button>
        )}
      </div>
    </div>
  );
};
