import auth from '@udacity/ureact-hoth';
import { ApolloClient, ApolloLink, HttpLink, from } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import CONFIG from 'configLoader';
import cache from './apollo-cache';

// specifies link to hub-bff
// change this to hub-bff-staging for local dev if you dont have it running locally
export const bffLink = new HttpLink({
  uri: CONFIG.hubBffEndpoint,
});

// - add Authorization header to each request
export const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const jwt = auth.getJWT();
  operation.setContext({
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

  return forward(operation);
});

export const communityApiLink = new RestLink({
  uri: CONFIG.communityApiLink,
});

// the link field works as a stream
// - each request will flow through each link that is specified for the client
// - using different link streams for a specific request would be a case for specifying a different client
// see: https://www.apollographql.com/docs/link/
export const bffClient = new ApolloClient({
  link: from([authMiddleware, communityApiLink, bffLink]),
  cache,
});
