import { actionTypes } from './plagiarism-case-queue-actions';
import {
  plagiarismCaseRequestFromApiRequest,
  projectsEnabledForPlagiarismCaseQueueFromApiRequests,
  assignedPlagiarismCaseFromApi,
  completedPlagiarismCaseFromApi,
  mergeCompletedPlagiarismCasesIntoHistory,
} from './plagiarism-case-queue-reducer-helpers';
import { PlagiarismCaseQueue, PlagiarismCaseRequestStatus } from './plagiarism-case-queue-types';

export const initialState: PlagiarismCaseQueue = {
  pendingPlagiarismCaseRequests: [],
  projectsEnabledForPlagiarismCaseQueue: {},
  assignedPlagiarismCases: [],
  completedPlagiarismCases: [],
};

export default function reducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_PLAGIARISM_CASE_REQUESTS_FULFILLED:
    case actionTypes.REFRESH_PLAGIARISM_CASE_REQUESTS_FULFILLED:
    case actionTypes.UPDATE_PLAGIARISM_CASE_REQUESTS_FULFILLED: {
      const pendingPlagiarismCaseRequests = payload
        .map((r) => plagiarismCaseRequestFromApiRequest(r))
        .filter((r) => r.status === PlagiarismCaseRequestStatus.AVAILABLE);
      return {
        ...state,
        pendingPlagiarismCaseRequests,
        projectsEnabledForPlagiarismCaseQueue: projectsEnabledForPlagiarismCaseQueueFromApiRequests(payload),
      };
    }

    case actionTypes.JOIN_PLAGIARISM_CASE_QUEUE_FULFILLED: {
      const newRequest = plagiarismCaseRequestFromApiRequest(payload);
      const pendingPlagiarismCaseRequests =
        newRequest.status === PlagiarismCaseRequestStatus.AVAILABLE
          ? [...state.pendingPlagiarismCaseRequests, newRequest]
          : state.pendingPlagiarismCaseRequests;
      return {
        ...state,
        pendingPlagiarismCaseRequests,
        projectsEnabledForPlagiarismCaseQueue: projectsEnabledForPlagiarismCaseQueueFromApiRequests([payload]),
      };
    }

    case actionTypes.LEAVE_PLAGIARISM_CASE_QUEUE_FULFILLED: {
      return { ...state, pendingPlagiarismCaseRequests: [] };
    }

    case actionTypes.FETCH_ASSIGNED_PLAGIARISM_CASES_FULFILLED: {
      return {
        ...state,
        assignedPlagiarismCases: payload.map((apiPlagiarismCase) => assignedPlagiarismCaseFromApi(apiPlagiarismCase)),
      };
    }

    case actionTypes.FETCH_COMPLETED_PLAGIARISM_CASES_FULFILLED: {
      const newCompletedPlagiarismCases = payload.apiCompletedPlagiarismCases.map((apiPlagiarismCase) =>
        completedPlagiarismCaseFromApi(apiPlagiarismCase)
      );
      return {
        ...state,
        completedPlagiarismCases: mergeCompletedPlagiarismCasesIntoHistory(
          state.completedPlagiarismCases,
          newCompletedPlagiarismCases
        ),
        totalCompletedPlagiarismCases: payload.totalCompletedPlagiarismCases,
      };
    }

    case actionTypes.CLEAR_COMPLETED_PLAGIARISM_CASES: {
      return { ...state, completedPlagiarismCases: [] };
    }
  }

  return state;
}
