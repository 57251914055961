import { RootState } from 'app/store';
import { createSelector } from 'reselect';
import { Certification } from 'app/types/reviews';

export const getCertifications = (state: RootState) => state.reviews.certifications.entities;

export const getCertificationsAsync = createSelector(
  (state: RootState, action: string) => {
    if (action) {
      return state.reviews.certificationsAsync[action];
    }
    return state.reviews.certificationsAsync;
  },
  (state) => state
);

export const getCompletedCertificationsSelector = createSelector(
  [getCertifications],
  (certifications: { [id: number]: Certification }) => {
    return Object.values(certifications).filter((cert) => cert.status === 'certified');
  }
);

export const getCompletedAuditCertifications = createSelector([getCompletedCertificationsSelector], (certifications) =>
  certifications.filter((c) => c.allowed_to_audit)
);

export const getCompletedPlagiarismCaseCertifications = createSelector(
  [getCompletedCertificationsSelector],
  (certifications) => certifications
);
