import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@chakra-ui/react';
import { AddIcon } from '@udacity/chakra-uds-icons';
import DataTable from '@udacity/ureact-data-table';
import Analytics from '@udacity/ureact-analytics';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';

import i18n from 'app/i18n';
import { eventTypes } from 'app/queue/views/overview/queue/queue-constants';
import constants from 'app/queue/domains/certifications/certifications-constants';
import { formatPrice, formatAlternatePrice } from 'app/helpers/format-helper';
import {
  dayDiff,
  getExpiresText,
  trackViewedOpportunities,
} from 'app/queue/domains/opportunities/opportunities-helpers';
import DataTableCell from 'app/queue/components/data-table-cell';
import DataTablePlaceholder from 'app/queue/components/data-table-placeholder';
import { Opportunity } from 'app/types/reviews';

import styles from './active-opportunities.module.scss';
import { Link } from 'react-router-dom';

const { DATE_FORMAT } = constants;

export const getOpportunitiesDataTable = (
  opportunities: Opportunity[],
  reviewerId: number | null,
  mentorCountryCode: string,
  trackFn = Analytics.track
) => {
  const sortedOpportunities = _sortBy(opportunities, (opp) => {
    return _get(opp, 'project.name', '');
  });

  return sortedOpportunities.map((opp) => {
    const {
      expires_at,
      created_at,
      project: { name, price, exchange_currency, exchange_currency_price, exchange_currency_country },
      id,
    } = opp;

    const justAdded = dayDiff(created_at) > -2;
    const expiringSoon = dayDiff(expires_at) <= 3;
    const expiresText = getExpiresText(expires_at);
    const onboardingLink = `/queue/certifications/opportunities/${id}`;
    const trackAccept = () =>
      trackFn(eventTypes.REVIEW_ACCEPTED, {
        reviewerId,
        submissionId: id,
        timestamp: Date.now(),
      });

    const acceptLink = (
      <Link to={onboardingLink} className={styles['action-link']} onClick={trackAccept}>
        {opp.accepted ? (
          i18n.t('queue.accepted')
        ) : (
          <Flex align="center" gap={1} padding={0}>
            <AddIcon w={6} h={6} />
            {i18n.t('queue.accept')}
          </Flex>
        )}
      </Link>
    );

    return {
      'Project Name': {
        data: (
          <DataTableCell id={opp.id} isFirstChild>
            <span>{name}</span>
            {justAdded && <span className={styles['just-added']}>{i18n.t('queue.justAdded')}</span>}
          </DataTableCell>
        ),
        sortValue: name,
      },
      Earn: {
        data: (
          <DataTableCell id={opp.id}>
            {exchange_currency_price &&
            exchange_currency_price !== '0' &&
            exchange_currency_country &&
            mentorCountryCode &&
            mentorCountryCode.toLowerCase() === exchange_currency_country.toLowerCase()
              ? formatPrice(price) + ' ' + formatAlternatePrice(exchange_currency ?? '', exchange_currency_price ?? '0')
              : formatPrice(price)}
          </DataTableCell>
        ),
        sortValue: price,
      },
      Expires: {
        data: (
          <DataTableCell id={opp.id}>
            <span>{dayjs(expires_at).format(DATE_FORMAT)}</span>
            {expiringSoon && <span className={styles['expiring-soon']}>{expiresText}</span>}
          </DataTableCell>
        ),
        sortValue: expires_at,
      },
      Action: {
        data: <DataTableCell id={opp.id}>{acceptLink}</DataTableCell>,
        sortValue: name,
      },
    };
  });
};

type ActiveOpportunitiesProps = {
  opportunities: Opportunity[];
  reviewerId: number | null;
  mentorCountryCode: string;
};

export const ActiveOpportunities: React.FC<ActiveOpportunitiesProps> = ({
  opportunities,
  reviewerId,
  mentorCountryCode,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (opportunities.length > 0) {
      trackViewedOpportunities(opportunities);
    }
  }, []);

  const activeOpportunities = getOpportunitiesDataTable(opportunities, reviewerId, mentorCountryCode);

  return (
    <section className={styles['opportunities']}>
      <h3>{t('queue.opportunities.newCertificationOpportunities')}</h3>
      {(opportunities.length > 0 && <DataTable rows={activeOpportunities} data-tst="certifications-table" />) || (
        <DataTablePlaceholder>{t('queue.opportunities.noOpportunities')}</DataTablePlaceholder>
      )}
    </section>
  );
};
