import React from 'react';
import PropTypes from 'prop-types';
import { calculateTimeDistance } from './helpers';
import styles from './countdown.module.scss';

function formatNumber(number) {
  return number < 10 ? `0${number}` : number;
}

const SHORT_UNITS = {
  days: 'day',
  hours: 'hr',
  minutes: 'min',
  seconds: 'sec',
};

const FULL_UNITS = {
  days: 'days',
  hours: 'hours',
  minutes: 'minutes',
  seconds: 'seconds',
};

export default class Countdown extends React.Component {
  static propTypes = {
    endDateEpoch: PropTypes.number.isRequired /* milliseconds */,
    theme: PropTypes.oneOf(['light', 'dark']),
    style: PropTypes.object,
    className: PropTypes.string,
    onTimerExpire: PropTypes.func,
    useShortUnits: PropTypes.bool,
  };

  static defaultProps = {
    theme: 'light',
    style: null,
    className: '',
  };

  state = {
    now: Date.now(),
  };

  componentDidMount() {
    this.timer = setInterval(() => {
      const now = Date.now();
      this.setState({
        now: Date.now(),
      });

      const { endDateEpoch, onTimerExpire } = this.props;
      if (now > endDateEpoch) {
        clearInterval(this.timer);
        onTimerExpire && onTimerExpire();
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    let { endDateEpoch, theme, style, className, useShortUnits } = this.props;
    var { now } = this.state;

    var distance = calculateTimeDistance(endDateEpoch - now);
    const containerTheme = `container-${theme}`;
    const unitLabels = useShortUnits ? SHORT_UNITS : FULL_UNITS;

    return (
      <div style={style} className={`${styles[containerTheme]} ${className}`}>
        <div className={styles.digit}>
          {formatNumber(distance.d)}
          <span className={styles.label}>{unitLabels.days}</span>
        </div>

        <div className={styles.digit}>
          {formatNumber(distance.h)}
          <span className={styles.label}>{unitLabels.hours}</span>
        </div>

        <div className={styles.digit}>
          {formatNumber(distance.m)}
          <span className={styles.label}>{unitLabels.minutes}</span>
        </div>

        <div className={styles.digit}>
          {formatNumber(distance.s)}
          <span className={styles.label}>{unitLabels.seconds}</span>
        </div>
      </div>
    );
  }
}
