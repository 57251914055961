import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormLabel, Heading, Switch, Text } from '@chakra-ui/react';

import { formatPrice } from 'app/helpers/format-helper';
import { Certification } from 'app/types/reviews';

import styles from './plagiarism-case-queue-settings.module.scss';

export type PlagiarismCaseQueueSettingsProps = {
  isOptionsUpdate: boolean;
  projectsEnabledInQueue: { [projectId: string]: boolean };
  plagiarismCaseCertifications: Certification[];
  onSubmit: (projectIds: number[]) => void;
};

export const PlagiarismCaseQueueSettings: React.FC<PlagiarismCaseQueueSettingsProps> = ({
  isOptionsUpdate,
  projectsEnabledInQueue,
  plagiarismCaseCertifications,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const [enabledProjectIdMap, setEnabledProjectIdMap] = useState(projectsEnabledInQueue);

  const allProjectIds = plagiarismCaseCertifications.map((c) => c.project_id);

  const areAllProjectsDisabled = useMemo(
    () => allProjectIds.every((id) => !enabledProjectIdMap[id]),
    [allProjectIds, enabledProjectIdMap]
  );

  const onSubmitClick = useCallback(() => {
    const enabledProjectIds = allProjectIds.filter((id) => enabledProjectIdMap[id]);
    onSubmit(enabledProjectIds);
  }, [allProjectIds, enabledProjectIdMap, onSubmit]);

  return (
    <>
      <section className={styles['settings-section']}>
        <Heading size="h4" as="h4">
          {t('queue.overview.queueForPlagiarismCases')}
        </Heading>
      </section>

      <section className={styles['settings-section']}>
        <ul>
          {plagiarismCaseCertifications.map((certification) => {
            return (
              <li className={styles['project-row']} key={certification.project_id}>
                <FormLabel htmlFor={`project-${certification.project_id}-switch`}>
                  {certification.project.name}
                </FormLabel>
                <Text>{formatPrice(certification.project.price)}</Text>

                <Switch
                  id={`project-${certification.project_id}-switch`}
                  isChecked={!!enabledProjectIdMap[certification.project_id]}
                  onChange={({ target: { checked } }) => {
                    setEnabledProjectIdMap({
                      ...enabledProjectIdMap,
                      [certification.project_id]: checked,
                    });
                  }}
                />
              </li>
            );
          })}
        </ul>
      </section>

      <section className={styles['settings-section']}>
        {areAllProjectsDisabled && (
          <Text me={8} color="feedback.negative" data-testid="disabled-reason">
            {t('queue.overview.youMustQueue')}
          </Text>
        )}
        <Button isDisabled={areAllProjectsDisabled} onClick={onSubmitClick}>
          {isOptionsUpdate ? t('queue.overview.updateQueue') : t('queue.overview.enterQueue')}
        </Button>
      </section>
    </>
  );
};
