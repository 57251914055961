import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import { Comment } from 'app/types/reviews';

import { CodeComment } from './code-comment';
import { EditCodeComment } from './edit-code-comment';

type CommentPortalsProps = {
  comments?: Comment[];
  currentLine: number;
  contentId: number;
  submissionId: number;
  visibleRangeStart: number;
  visibleRangeEnd: number;
  isAssigned: boolean;
  onCancel: () => void;
};

export const CommentPortals: React.FC<CommentPortalsProps> = ({
  comments,
  currentLine,
  contentId,
  submissionId,
  visibleRangeStart,
  visibleRangeEnd,
  isAssigned,
  onCancel,
}) => {
  const [hasNewLineComment, setHasNewLineComment] = useState(false);
  const newLineCommentWrapperId = `comment-wrapper-${currentLine}-${currentLine}`;
  const [wrapperElement, setWrapperElement] = useState(document?.getElementById(newLineCommentWrapperId));

  useEffect(() => {
    if (document?.getElementById(newLineCommentWrapperId) && currentLine) {
      setHasNewLineComment(true);
      setWrapperElement(document?.getElementById(newLineCommentWrapperId));
    } else {
      setHasNewLineComment(false);
      setWrapperElement(null);
    }
  }, [document, currentLine, visibleRangeStart, visibleRangeEnd]);

  const editableComment = useMemo(() => comments?.find((c) => c.lineNumber === currentLine), [comments, currentLine]);
  const editableCommentWrapperDiv = document?.getElementById(
    `comment-wrapper-${editableComment?.id}-${editableComment?.lineNumber}`
  );

  return (
    <>
      {!!comments &&
        comments.map((c) => {
          const commentWrapperId = `comment-wrapper-${c.id}-${c.lineNumber}`;
          if (
            !document?.getElementById(commentWrapperId) ||
            c.lineNumber < visibleRangeStart ||
            c.lineNumber > visibleRangeEnd
          ) {
            return null;
          }

          return (
            <WidgetPortal
              key={c.id}
              wrapperElement={document?.getElementById(commentWrapperId)}
              comment={c}
              contentId={contentId}
              submissionId={submissionId}
              isAssigned={isAssigned}
              onCancel={onCancel}
            />
          );
        })}

      {hasNewLineComment && !editableCommentWrapperDiv && (
        <WidgetPortal
          key={currentLine}
          wrapperElement={wrapperElement}
          comment={editableComment}
          contentId={contentId}
          submissionId={submissionId}
          currentLine={currentLine}
          isAssigned
          onCancel={onCancel}
        />
      )}
    </>
  );
};

type WidgetPortalProps = {
  wrapperElement: HTMLElement | null;
  comment?: Comment;
  currentLine?: number;
  contentId: number;
  submissionId: number;
  isAssigned?: boolean;
  onCancel: () => void;
};

const WidgetPortal: React.FC<WidgetPortalProps> = ({
  wrapperElement,
  comment,
  currentLine,
  contentId,
  submissionId,
  isAssigned,
  onCancel,
}) => {
  if (!wrapperElement) return null;

  if (!comment) {
    return ReactDOM.createPortal(
      <EditCodeComment
        lineNumber={currentLine}
        contentId={contentId}
        submissionId={submissionId}
        comment={comment}
        onCancel={onCancel}
      />,
      wrapperElement
    );
  }

  return (
    <>
      {!isAssigned
        ? ReactDOM.createPortal(
            <CodeComment category={comment.category} lineNumber={comment.lineNumber} comment={comment.body} />,
            wrapperElement
          )
        : ReactDOM.createPortal(
            <EditCodeComment
              lineNumber={comment.lineNumber}
              contentId={contentId}
              submissionId={submissionId}
              comment={comment}
              onCancel={onCancel}
            />,
            wrapperElement
          )}
    </>
  );
};
