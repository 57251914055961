import { createAction } from 'app/helpers/action-helper';

export const actionTypes = {
  FETCH_ALL_ASSIGNED: 'submissions:FETCH_ALL_ASSIGNED',
  FETCH_ALL_ASSIGNED_FULFILLED: 'submissions:FETCH_ALL_ASSIGNED_FULFILLED',
  FETCH_ALL_ASSIGNED_RESET: 'submissions:FETCH_ALL_ASSIGNED_RESET',
  FETCH_COMPLETED: 'submissions:FETCH_COMPLETED',
  FETCH_COMPLETED_FULFILLED: 'submissions:FETCH_COMPLETED_FULFILLED',
  FETCH_COMPLETED_RESET: 'submissions:FETCH_COMPLETED_RESET',
  CLEAR_ALL_COMPLETED: 'submissions:CLEAR_ALL_COMPLETED',
  UNASSIGN: 'submissions:UNASSIGN',
  UNASSIGN_FULFILLED: 'submissions:UNASSIGN_FULFILLED',
  UNASSIGN_RESET: 'submissions:UNASSIGN_RESET',
  POLL_ALL_ASSIGNED: 'submissions:POLL_ALL_ASSIGNED',
  CANCEL_POLL_ALL_ASSIGNED: 'submissions:CANCEL_POLL_ALL_ASSIGNED',
};

export const fetchAllAssigned = () => {
  return createAction(actionTypes.FETCH_ALL_ASSIGNED);
};

export const fetchCompleted = (params) => {
  return createAction(actionTypes.FETCH_COMPLETED, params);
};

export const clearAllCompleted = () => {
  return createAction(actionTypes.CLEAR_ALL_COMPLETED);
};

export const unassign = (submissionId) => {
  return createAction(actionTypes.UNASSIGN, submissionId);
};

export const startPollingAssignedSubmissions = () => {
  return createAction(actionTypes.POLL_ALL_ASSIGNED);
};

export const cancelPollingAssignedSubmissions = () => {
  return createAction(actionTypes.CANCEL_POLL_ALL_ASSIGNED);
};
