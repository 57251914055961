export interface PlagiarismCaseQueue {
  readonly pendingPlagiarismCaseRequests: PlagiarismCaseRequest[];
  readonly projectsEnabledForPlagiarismCaseQueue: { [id: number]: boolean };
  readonly assignedPlagiarismCases: AssignedPlagiarismCase[];
  readonly completedPlagiarismCases: CompletedPlagiarismCase[];
  readonly totalCompletedPlagiarismCases?: number;
}

export interface PlagiarismCaseRequest {
  readonly id: number;
  readonly plagiarismCaseId?: number;
  readonly status: PlagiarismCaseRequestStatus;
  readonly closedAt: number;
}

export enum PlagiarismCaseRequestStatus {
  AVAILABLE = 'available',
  FULFILLED = 'fulfilled',
  CANCELLED = 'canceled',
}

export interface ApiPlagiarismCaseRequest {
  readonly id: number;
  readonly plagiarism_case_id: number | null;
  readonly status: PlagiarismCaseRequestStatus;
  readonly closed_at: string;
  readonly plagiarism_case_request_projects: Array<{
    project_id: number;
    language: string;
  }>;
}

export interface AssignedPlagiarismCase {
  readonly id: number;
  readonly url: string;
  readonly price: string;
  readonly assignedAt: number;
  readonly expiresAt: number;
  readonly project: {
    id: number;
    name: string;
  };
}

export interface ApiPlagiarismCase {
  readonly id: number;
  readonly submission_id: number;
  readonly assigned_at: string;
  readonly expires_at: string;
  readonly completed_at: string | null;
  readonly result: string | null;
  readonly price: string;
  readonly submission_project: {
    id: number;
    name: string;
  };
}

export interface CompletedPlagiarismCase {
  readonly id: number;
  readonly completedAt: number;
  readonly result: string;
  readonly price: string;
  readonly project: {
    id: number;
    name: string;
  };
}

export interface CompletedPlagiarismCasePageParams {
  readonly page: number;
  readonly per_page: number;
}
