import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Text } from '@chakra-ui/react';
import { Markdown } from '@udacity/ureact-markdown';

type ReviewerTipProps = {
  tip: string;
};

export const ReviewerTip: React.FC<ReviewerTipProps> = ({ tip }) => {
  const { t } = useTranslation();

  return (
    <Accordion allowToggle border="1px solid" borderColor="accent.sky.500">
      <AccordionItem>
        <h3>
          <AccordionButton bgColor="accent.sky.100" _hover={{ bgColor: 'accent.sky.100' }}>
            <Flex grow={1} gap={4} align="center" as="span">
              <Text size="label">{t('projectReviews.rubric.reviewerTip')}</Text>
            </Flex>
            <AccordionIcon ms={4} />
          </AccordionButton>
        </h3>

        <AccordionPanel p={8}>
          <Markdown markdownText={tip} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
