import apiService from '@udacity/ureact-api-service';
import axios from 'axios';
import CONFIG from 'configLoader';

const configOpts = {
  retry: 0,
  maxRetries: 3,
  timeout: 15000,
};

function get(path, axiosOpts = {}) {
  return apiService.makeGetRequest(CONFIG.reviewsApiUrl, path, axiosOpts);
}

function post(path, payload = {}, axiosOpts = {}) {
  return apiService.makePostRequest(CONFIG.reviewsApiUrl, path, payload, axiosOpts);
}

function destroy(path, axiosOpts = {}) {
  return apiService.makeDeleteRequest(CONFIG.reviewsApiUrl, path, axiosOpts);
}

function put(path, payload = {}, axiosOpts = {}) {
  return apiService.makePutRequest(CONFIG.reviewsApiUrl, path, payload, axiosOpts);
}

export const getProjectJson = async (id) => {
  const { data } = await axios.get(`${CONFIG.reviewsApiUrl}/projects/${id}.json`);
  return data;
};

export const getMe = async () => {
  return await get('/me');
};

export const getAllRecruitmentFamilies = async () => {
  return get('/recruitment_families.json');
};

export const getReviewerRecruitmentFamilyInterests = async (uid) => {
  return get(`/reviewer_interests.json?udacity_key=${uid}`);
};

export const submitReviewerInterests = async (payload) => {
  return post('/reviewer_interests.json', payload, configOpts);
};

export const getReviewerEarnings = async (start_date, end_date) => {
  return get('/me/earnings', {
    params: {
      start_date,
      end_date,
    },
  });
};

export const getReviewerRatingsMatrix = async (start_date, end_date) => {
  return get('/me/ratings_matrix', {
    params: {
      start_date,
      end_date,
    },
  });
};

export const getCertifications = async () => {
  return get('/me/certifications.json?status=certified');
};

export const getReviewerStatements = async (start_date, end_date) => {
  return get('/me/reviewer_statements', {
    params: {
      start_date,
      end_date,
    },
  });
};

export const getAssignedSubmissions = async () => {
  const opts = {
    timeout: 30 * 1000,
  };
  return get('/me/submissions/assigned.json', opts);
};

export const getCompletedSubmissions = async (params = {}) => {
  const opts = {
    timeout: 60 * 1000,
    params,
  };
  return get('/me/submissions/completed.json', opts);
};

export const getSubmissionFeedback = async (id) => {
  return get(`/submissions/${id}/feedback`);
};

export const getSubmissionsPeerVotes = async (submissionIds = []) => {
  return get('/me/votes', {
    params: {
      submission_ids: submissionIds,
    },
  });
};

export const getSubmissionsStudentFeedbacks = async (submissionIds = []) => {
  return get('/me/student_feedbacks', {
    params: {
      submission_ids: submissionIds,
    },
  });
};

export const unassignSubmission = async (submissionId) => {
  return put(`/submissions/${submissionId}/unassign.json`);
};

export const getSubmissionRequests = async () => {
  const opts = {
    timeout: 30 * 1000,
  };
  return get('/me/submission_requests.json', opts);
};

export const createSubmissionRequest = async (payload = {}) => {
  return post('/submission_requests.json', payload, configOpts);
};

export const deleteSubmissionRequest = async (requestId) => {
  return destroy(`/submission_requests/${requestId}.json`, configOpts);
};

export const refreshSubmissionRequest = async (requestId) => {
  return put(`/submission_requests/${requestId}/refresh.json`, {}, configOpts);
};

export const updateSubmissionRequest = async (requestId, payload = {}) => {
  return put(`/submission_requests/${requestId}.json`, payload, configOpts);
};

export const getAuditRequests = async () => {
  return get('/me/audit_requests.json');
};

export const createAuditRequest = async (payload = {}) => {
  return post('/audit_requests.json', payload, configOpts);
};

export const updateAuditRequest = async (id, payload = {}) => {
  return put(`/audit_requests/${id}.json`, payload, configOpts);
};

export const refreshAuditRequest = async (id) => {
  return put(`/audit_requests/${id}/refresh.json`, undefined, configOpts);
};

export const deleteAuditRequest = async (requestId) => {
  return destroy(`/audit_requests/${requestId}.json`, configOpts);
};

export const getAssignedAudits = async () => {
  return get('/me/audits/assigned.json');
};

export const getCompletedAudits = async (params = {}) => {
  const opts = {
    timeout: 60 * 1000,
    params,
    returnFullResponse: true,
  };
  return get('/me/audits/completed.json', opts);
};

export const getPlagiarismCaseRequests = async () => {
  return get('/me/plagiarism_case_requests.json');
};

export const createPlagiarismCaseRequest = async (payload = {}) => {
  return post('/plagiarism_case_requests.json', payload, configOpts);
};

export const updatePlagiarismCaseRequest = async (id, payload = {}) => {
  return put(`/plagiarism_case_requests/${id}.json`, payload, configOpts);
};

export const refreshPlagiarismCaseRequest = async (id) => {
  return put(`/plagiarism_case_requests/${id}/refresh.json`, undefined, configOpts);
};

export const deletePlagiarismCaseRequest = async (requestId) => {
  return destroy(`/plagiarism_case_requests/${requestId}.json`, configOpts);
};

export const getAssignedPlagiarismCases = async () => {
  return get('/me/plagiarism_cases/assigned.json');
};

export const getCompletedPlagiarismCases = async (params = {}) => {
  const opts = {
    timeout: 60 * 1000,
    params,
    returnFullResponse: true,
  };
  return get('/me/plagiarism_cases/completed.json', opts);
};
