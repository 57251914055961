import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import _orderBy from 'lodash/orderBy';
import {
  Box,
  Button,
  Flex,
  GridItem,
  Heading,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Markdown } from '@udacity/ureact-markdown';

import { useFetchContentsQuery, useFetchCritiquesQuery } from 'app/apis/reviews';
import { UDS_GLYPH_URL } from 'app/apis/service-paths';
import { AsymmetricCard } from 'app/common/components/asymmetric-card';
import { getTimeSince } from 'app/project-reviews/helpers';
import { Rubric, Submission } from 'app/types/reviews';

import { AnnotationsPanel } from './annotations-panel';
import { CodeReviewPanel } from './code-review-panel';
import { ConfirmSubmissionModal } from './confirm-submission-modal';
import { ProjectReviewPanel } from './project-review-panel';
import { UnableToReviewModal } from './unable-to-review-modal';

type FeedbackProps = {
  submission: Submission;
  allSubmissions?: Submission[];
  rubric?: Rubric;
  isAssigned: boolean;
};

export const Feedback: React.FC<FeedbackProps> = ({ submission, allSubmissions, rubric, isAssigned }) => {
  const [file, setFile] = useState<File | null | undefined>();
  const { archiveUrl, url, id, studentNote, annotationsUrl } = submission;

  const { data: critiques } = useFetchCritiquesQuery(id);
  const { data: contents } = useFetchContentsQuery(id);

  const { t } = useTranslation();
  const {
    isOpen: isUnableToReviewOpen,
    onClose: onUnableToReviewClose,
    onOpen: onUnableToReviewOpen,
  } = useDisclosure();
  const {
    isOpen: isConfirmSubmissionOpen,
    onClose: onConfirmSubmissionClose,
    onOpen: onConfirmSubmissionOpen,
  } = useDisclosure();

  const sortedSubmissions = _orderBy(allSubmissions, 'id', 'desc');
  const currentSubmissionIndex = sortedSubmissions.findIndex((s) => s.id === id);
  const pastSubmissions = sortedSubmissions.slice(currentSubmissionIndex + 1);

  const mostRecentFailedSubmission = useMemo(() => {
    if (sortedSubmissions.length === 1) {
      return null;
    }

    return pastSubmissions.find((s) => s.result === 'failed') || null;
  }, [pastSubmissions, sortedSubmissions]);

  const previouslyFlaggedForPlagiarism = pastSubmissions.some(
    (pastSub) => (pastSub.ungradeableTag === 'plagiarism' && pastSub.isDemerit) || !!pastSub.plagiarismConfirmed
  );

  const { data: previousCritiques } = useFetchCritiquesQuery(mostRecentFailedSubmission?.id ?? skipToken);

  const passedRubricItemIds =
    previousCritiques
      ?.filter((pc) => pc.result === 'passed')
      .map((pc) => pc.rubricItemId)
      .filter((pc) => pc) ?? [];

  // If a rubric item is deleted while a submission is still in review we no longer need
  // to submit a critique for that rubric item
  const currentCritiques = critiques?.filter((c) => c.rubricItemDeletedAt === null);
  const critiqueRequiresResult = !!currentCritiques?.some(
    (c) => c.result === null && !passedRubricItemIds.includes(c.rubricItemId)
  );
  const critiqueRequiresObservation = !!currentCritiques?.some((c) => c.result === 'failed' && c.observation === null);

  const isSubmitFeedbackDisabled = critiqueRequiresResult || critiqueRequiresObservation;

  const didPass = !!currentCritiques?.every(
    (c) => c.result === 'passed' || passedRubricItemIds.includes(c.rubricItemId)
  );

  const timeSinceLastReviewed = useMemo(() => {
    if (mostRecentFailedSubmission) {
      const submission = allSubmissions?.find((s) => s.id === mostRecentFailedSubmission.id);

      return submission?.completedAt ? getTimeSince(submission.completedAt) : null;
    }

    return null;
  }, [allSubmissions, mostRecentFailedSubmission]);

  const hasAnnotations = useMemo(() => {
    if (submission.url) return true;

    if (contents)
      return contents.some((c) => {
        const ext = c.path.toLowerCase().split('.').pop();
        return !!ext && ['pdf', 'docx'].includes(ext);
      });

    return false;
  }, [submission.url, contents]);

  const nonAnnotationContents = contents
    ? contents.filter((c) => {
        const ext = c.path.toLowerCase().split('.').pop();
        return !!ext && !['pdf', 'docx'].includes(ext);
      })
    : contents;

  const handleFile = (newFile: File | null | undefined) => setFile(newFile);

  return (
    <>
      <GridItem gridColumn={{ base: '3 / -1', lg: '4 / -1' }}>
        <Heading size="h1" as="h1" mb={6}>
          {t('projectReviews.feedback.feedback')}
        </Heading>

        <Box w={{ base: 'full', lg: '78%' }}>
          <Heading size="h3" as="h3" mb={2}>
            {t('projectReviews.feedback.instructions')}
          </Heading>
          <Text mb={4}>{t('projectReviews.feedback.downloadStudentProject')}</Text>
          <Text mb={4}>
            <Trans i18nKey="projectReviews.feedback.rememberTo" components={{ bold: <strong /> }} />
          </Text>
        </Box>
      </GridItem>

      <GridItem gridColumn={{ base: '3 / -1', lg: '4 / -1' }}>
        <Flex gap={4} align="center" mb={10} wrap="wrap">
          <Button
            as="a"
            variant="outline"
            href={archiveUrl || url || undefined}
            target="_blank"
            rel="noopener noreferrer"
          >
            {url ? t('projectReviews.feedback.projectLink') : t('projectReviews.feedback.downloadProject')}
          </Button>
          {isAssigned && (
            <>
              <Button isDisabled={isSubmitFeedbackDisabled} onClick={onConfirmSubmissionOpen}>
                {t('projectReviews.feedback.submitFeedback')}
              </Button>
              <Button variant="ghost" onClick={onUnableToReviewOpen}>
                {t('projectReviews.feedback.cantReview')}
              </Button>
            </>
          )}
        </Flex>
      </GridItem>

      <GridItem gridColumn={{ base: '3 / -1', lg: '4 / -4' }}>
        {studentNote && (
          <AsymmetricCard bgColor="white" gap={2} mb={10}>
            <Image src={`${UDS_GLYPH_URL}/chat-bubbles-chat-bubbles-one.svg`} alt="" w={10} h={10} />
            <Flex direction="column" maxW="full">
              <Text size="label" mb={4}>
                {t('projectReviews.feedback.studentNote')}
              </Text>
              <Markdown markdownText={studentNote} />
            </Flex>
          </AsymmetricCard>
        )}

        {mostRecentFailedSubmission && timeSinceLastReviewed && isAssigned && (
          <Flex bgColor="white" gap={4} p={6} mb={10} border="1px solid" borderColor="gray.300" borderRadius="base">
            <Image src={`${UDS_GLYPH_URL}/magnifying-glass-check-mark-two.svg`} alt="" w={16} h={16} />
            <Text>
              {t('projectReviews.feedback.projectWasLastReviewed', {
                timeSince: timeSinceLastReviewed,
              })}
            </Text>
          </Flex>
        )}
      </GridItem>

      <GridItem gridColumn={{ base: '3 / -1', lg: '4 / -1' }}>
        <Heading size="h3" as="h3">
          {t('projectReviews.review')}
        </Heading>

        <Tabs mb={10}>
          <TabList>
            <Tab>{t('projectReviews.projectReview.projectReview')}</Tab>
            {!!nonAnnotationContents?.length && <Tab>{t('projectReviews.codeReview.codeReview')}</Tab>}
            {hasAnnotations && <Tab>{t('projectReviews.annotations.fileAnnotations')}</Tab>}
          </TabList>

          <TabPanels>
            <TabPanel p={0} pt={10}>
              <ProjectReviewPanel
                rubric={rubric}
                critiques={currentCritiques}
                submission={submission}
                isAssigned={isAssigned}
                previouslyFlaggedForPlagiarism={previouslyFlaggedForPlagiarism}
                previousCritiques={previousCritiques}
              />
            </TabPanel>

            {!!nonAnnotationContents?.length && (
              <TabPanel p={0} pt={10}>
                <CodeReviewPanel
                  contents={nonAnnotationContents}
                  submissionId={submission.id}
                  isAssigned={isAssigned}
                />
              </TabPanel>
            )}

            {hasAnnotations && (
              <TabPanel p={0} py={10}>
                <AnnotationsPanel
                  annotationsUrl={annotationsUrl}
                  file={file}
                  handleFile={handleFile}
                  isAssigned={isAssigned}
                />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>

        <Flex gap={4} align="center" wrap="wrap">
          <Button
            as="a"
            variant="outline"
            href={archiveUrl || url || undefined}
            target="_blank"
            rel="noopener noreferrer"
          >
            {url ? t('projectReviews.feedback.projectLink') : t('projectReviews.feedback.downloadProject')}
          </Button>
          {isAssigned && (
            <>
              <Button isDisabled={isSubmitFeedbackDisabled} onClick={onConfirmSubmissionOpen}>
                {t('projectReviews.feedback.submitFeedback')}
              </Button>
              <Button variant="ghost" onClick={onUnableToReviewOpen}>
                {t('projectReviews.feedback.cantReview')}
              </Button>
            </>
          )}
        </Flex>
      </GridItem>

      <UnableToReviewModal
        isOpen={isUnableToReviewOpen}
        onClose={onUnableToReviewClose}
        submissionId={id}
        rubricLanguage={rubric?.language ?? 'en-us'}
      />
      {isConfirmSubmissionOpen && (
        <ConfirmSubmissionModal
          isOpen={isConfirmSubmissionOpen}
          onClose={onConfirmSubmissionClose}
          didPass={didPass}
          submissionId={id}
        />
      )}
    </>
  );
};
