import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

export const AsymmetricCard: React.FC<FlexProps> = ({ children, ...rest }) => {
  return (
    <Flex
      p={8}
      bgColor="gray.100"
      border="1px solid"
      borderColor="gray.300"
      borderRadius="base"
      borderBottomEndRadius="xl"
      {...rest}
    >
      {children}
    </Flex>
  );
};
