import { createSelector } from 'reselect';

import {
  QuestionQueue,
  QuestionQueueAssignment,
  QuestionQueueHistory,
  QuestionQueueOpportunity,
  QuestionQueueStatus,
} from './question-queue-types';

export const selectQuestionQueue = (state: any): QuestionQueue => state.questions.queue;

export const selectQuestionQueueStatus = createSelector(
  selectQuestionQueue,
  (queue: QuestionQueue): QuestionQueueStatus => queue.status
);

export const selectQuestionQueueEnabled = createSelector(
  selectQuestionQueueStatus,
  (status: QuestionQueueStatus): boolean => status.enabled
);

export const selectQuestionQueueExpiration = createSelector(
  selectQuestionQueueStatus,
  (status: QuestionQueueStatus): number | null => status.expiresAt
);

export const selectProjectsEnabledInQuestionQueue = createSelector(
  selectQuestionQueueStatus,
  (status: QuestionQueueStatus): { [projectId: string]: boolean } => status.projectEnabledInQueue
);

export const selectQuestionQueueOpportunities = createSelector(
  selectQuestionQueue,
  (queue: QuestionQueue): { [projectId: string]: QuestionQueueOpportunity } => queue.projectOpportunities
);

export const selectQuestionQueueAssignments = createSelector(
  selectQuestionQueue,
  (queue: QuestionQueue): QuestionQueueAssignment[] => queue.assignments
);

export const selectQuestionQueueHistory = createSelector(
  selectQuestionQueue,
  (queue: QuestionQueue): QuestionQueueHistory => queue.history
);

export const selectQuestionQueueMonthlyEarningsSummary = createSelector(
  selectQuestionQueue,
  (queue: QuestionQueue): string | undefined => queue.pastMonthEarnings
);

export const selectQuestionQueueAsync = (state: any): QuestionQueue => state.questions.queueAsync;

export const selectQuestionQueueHistoryAsync = createSelector(
  selectQuestionQueueAsync,
  (queueAsync) => queueAsync.history
);
