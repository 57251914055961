/* eslint-disable */ /**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 * instead, edit its related `.gql` file and recreate it with
 *
 * npm run codegen:connect
 */

import * as Types from '../../types/uconnect';

import { api } from 'app/apis/connect-gql-api';
export type GetSeriesDetailsQueryVariables = Types.Exact<{
  facilitatorUserKey?: Types.InputMaybe<Types.Scalars['String']['input']>;
  seriesId?: Types.InputMaybe<Types.Scalars['UUID']['input']>;
}>;

export type GetSeriesDetailsQuery = {
  __typename?: 'Query';
  series: {
    __typename?: 'PaginatedSeries';
    series: Array<{
      __typename?: 'Series';
      id: any;
      name: string;
      description?: string | null;
      meetings: {
        __typename?: 'PaginatedMeetings';
        totalCount: number;
        meetings: Array<{
          __typename?: 'Meeting';
          id: any;
          name: string;
          startAt: any;
          endAt: any;
          locales: Array<string>;
        }>;
      };
    }>;
  };
};

export const GetSeriesDetailsDocument = `
    query GetSeriesDetails($facilitatorUserKey: String, $seriesId: UUID) {
  series(input: {availability: {hasMeetingsAvailableForFacilitatorUserKey: $facilitatorUserKey}, seriesId: $seriesId}) {
    series {
      id
      name
      description
      meetings(input: {availability: {availableToFacilitators: true, availableToFacilitatorUserKey: $facilitatorUserKey}, sortBy: START_AT}) {
        totalCount
        meetings {
          id
          name
          startAt
          endAt
          locales
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSeriesDetails: build.query<GetSeriesDetailsQuery, GetSeriesDetailsQueryVariables | void>({
      query: (variables) => ({ document: GetSeriesDetailsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
