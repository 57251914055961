import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import _map from 'lodash/map';
import styles from './sidebar.module.scss';

export class GroupItems extends React.PureComponent {
  static propTypes = {
    groupings: PropTypes.array,
    selectedGrouping: PropTypes.string,
    onChangeGrouping: PropTypes.func,
  };

  render() {
    const { groupings, selectedGrouping, onChangeGrouping } = this.props;

    return (
      <ul>
        {_map(groupings, (grouping) => {
          const { link, key, title, subtitle, notification } = grouping;
          const isGroupingActive = selectedGrouping === key;

          return (
            <li key={key}>
              <NavLink
                to={link}
                className={`${styles.grouping} ${isGroupingActive ? styles.active : ''}`}
                onClick={() => onChangeGrouping(key)}
              >
                {title}
                {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
                {notification && <div className={styles['badge']} />}
              </NavLink>
            </li>
          );
        })}
      </ul>
    );
  }
}

export default GroupItems;
