import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getAssignedSubmissions } from 'app/queue/domains/submissions/submissions-selectors';
import { fetchAllAssigned } from 'app/queue/domains/submissions/submissions-actions';
import { selectQuestionQueueAssignments } from 'app/queue/domains/question-queue/question-queue-selectors';
import { selectAssignedAudits } from 'app/queue/domains/audit-queue/audit-queue-selectors';
import { selectAssignedPlagiarismCases } from 'app/queue/domains/plagiarism-case-queue/plagiarism-case-queue-selectors';
import List from 'app/queue/components/list';
import { AssignedAuditListItem } from 'app/queue/components/assigned-audit-list-item';
import { AssignedPlagiarismCaseListItem } from 'app/queue/components/assigned-plagiarism-case-list-item';
import { AssignedSubmissionListItem } from 'app/queue/components/assigned-submission-list-item';
import { AssignedQuestionListItem } from 'app/queue/components/assigned-question-list-item';
import { RootState, useAppDispatch } from 'app/store';

import styles from './active-tasks.module.scss';

export const ActiveTasks: React.FC = () => {
  const assignedSubmissions = useSelector((state: RootState) => getAssignedSubmissions(state));
  const assignedQuestions = useSelector((state) => selectQuestionQueueAssignments(state));
  const assignedAudits = useSelector((state) => selectAssignedAudits(state));
  const assignedPlagiarismCases = useSelector((state) => selectAssignedPlagiarismCases(state));

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllAssigned());
  }, []);

  const hasItems =
    assignedSubmissions.length || assignedQuestions.length || assignedPlagiarismCases.length || assignedAudits.length;

  if (!hasItems) {
    return null;
  }

  return (
    <div className={styles['active-tasks']}>
      <h2>{t('queue.overview.toDo')}</h2>
      <List>
        {assignedSubmissions.map((sub) => {
          return <AssignedSubmissionListItem key={sub.id} submission={sub} />;
        })}
        {assignedAudits.map((audit) => {
          return <AssignedAuditListItem key={audit.id} audit={audit} />;
        })}
        {assignedPlagiarismCases.map((plagiarismCase) => {
          return <AssignedPlagiarismCaseListItem key={plagiarismCase.id} plagiarismCase={plagiarismCase} />;
        })}
        {assignedQuestions.map((assignment) => {
          return <AssignedQuestionListItem key={`question-${assignment.questionID}`} assignment={assignment} />;
        })}
      </List>
    </div>
  );
};
