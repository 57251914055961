import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { LanguageName } from '@uiw/codemirror-extensions-langs';

// Markdown helpers
const isImage = (file: File) => {
  return file.type.match(/image\/.*/);
};

export const getFileMarkdown = (file: File, url: string) => {
  return (isImage(file) ? '!' : '') + '[' + file.name + '](' + url + ')';
};

export const markdownHasMultipleImages = (md: string) => {
  const imageMatches = md.match(/!\[[^\]]+\]\([^)]+\)/g);
  return imageMatches ? imageMatches.length > 1 : false;
};

// Nav helpers
export const getIsSelected = (tabName: string, pathname: string) => {
  const pathSegments = pathname.split('/');

  return pathname.split('/').pop() === 'quality-guidelines'
    ? pathSegments[pathSegments.length - 2] === tabName
    : pathSegments[pathSegments.length - 1] === tabName;
};

// Time helpers
export const getTimeSince = (dateTime: string) => {
  return dayjs(dateTime).fromNow();
};

// Code comment helpers
export const getBadgeInfo = (category: string | null | undefined, t: TFunction) => {
  if (!category) return;

  if (category === 'awesome') {
    return {
      label: t('projectReviews.codeReview.great'),
      bgColor: 'feedback.positive',
    };
  }

  if (category === 'nitpick') {
    return {
      label: t('projectReviews.codeReview.couldImprove'),
      bgColor: 'accent.sky.500',
    };
  }

  if (category === 'critical') {
    return {
      label: t('projectReviews.codeReview.requiresUpdate'),
      bgColor: 'feedback.negative',
    };
  }

  console.error(`The category value ${category} is invalid`);
  return;
};

// Code mirror helpers
export const getFileExtension = (filePath: string) => {
  const index = filePath.lastIndexOf('.');
  if (index !== -1) {
    const ext = filePath.slice(index + 1);
    return !['pdf', 'docx'].includes(ext) ? ext : '';
  }

  return '';
};

export const getLang = (fileExt: string) => {
  /* Mapping from extension to CodeMirror mode */
  const codeModes: { [key: string]: LanguageName } = {
    js: 'javascript',
    jsx: 'jsx',
    ts: 'typescript',
    tsx: 'tsx',
    css: 'css',
    html: 'html',
    htm: 'html',
    py: 'python',
    txt: 'shell',
    md: 'markdown',
    rmd: 'markdown',
    markdown: 'markdown',
    go: 'go',
    swift: 'swift',
    sql: 'sql',
    java: 'java',
    xml: 'xml',
    gradle: 'shell',
    rst: 'rust',
    yml: 'yaml',
    yaml: 'yaml',
  };

  const codeMode = codeModes[fileExt] ?? 'shell';

  return codeMode;
};

// File helpers
/*
      Escapes all regexp except '*', which we replace as '.*'.
      This is to support wildcards in the filter pattern.
    */
export const escapeRegExp = (str: string) => {
  // http://stackoverflow.com/a/6969486
  str = str.replace(/[-[\]/{}()+?.\\^$|]/g, '\\$&');

  str = str.replace(/[*]/g, '.*');
  return new RegExp(str, 'i');
};
