import {
  useCreateFeedbackTemplateMutation,
  useDeleteFeedbackTemplateMutation,
  useFetchFeedbackTemplatesQuery,
  useFetchSubmissionQuery,
  useUpdateFeedbackTemplateMutation,
} from 'app/apis/reviews';
import { CreateFeedbackTemplatePayload, FeedbackTemplate, UpdateFeedbackTemplatePayload } from 'app/types/reviews';
import _sortBy from 'lodash/sortBy';
import { useRouteMatch } from 'react-router';

export function useFeedbackTemplates(filters?: { meetsRequirements?: boolean; rubricItemId?: number }) {
  const match = useRouteMatch<{ submissionId: string }>('/project-reviews/:submissionId/feedback');
  const submissionId = Number(match?.params.submissionId);
  const { data: submission } = useFetchSubmissionQuery(submissionId);
  const projectId = submission?.projectId;
  const {
    data: templates,
    isLoading,
    isUninitialized,
  } = useFetchFeedbackTemplatesQuery(
    { projectId: projectId as number, submissionId: submissionId },
    { skip: !projectId }
  );

  const [create, { isLoading: isCreating }] = useCreateFeedbackTemplateMutation();
  const [update, { isLoading: isUpdating }] = useUpdateFeedbackTemplateMutation();
  const [deleteFeedbackTemplate, { isLoading: isDeleting }] = useDeleteFeedbackTemplateMutation();

  const meetsRequirements = filters?.meetsRequirements;
  const rubricItemId = filters?.rubricItemId;

  const filteredTemplates = templates?.filter((t) => {
    const matchesRequirements = meetsRequirements === undefined ? true : t.meets_requirements === meetsRequirements;
    const matchesRubricItem = rubricItemId ? t.rubric_item_id === rubricItemId : !t.rubric_item_id;

    return matchesRequirements && matchesRubricItem;
  });

  return {
    feedbackTemplates: _sortBy(filteredTemplates || [], [(item) => item.is_shared === false, 'title']),
    isLoading: isLoading || isUninitialized,
    createTemplate(form: Omit<CreateFeedbackTemplatePayload, 'project_id'>) {
      const body = { ...form, project_id: projectId as number };

      return create({ body, submissionId });
    },
    isCreating,
    updateTemplate(body: UpdateFeedbackTemplatePayload) {
      return update({ body, submissionId });
    },
    isUpdating,
    deleteTemplate(templateId: number) {
      return deleteFeedbackTemplate({ templateId, submissionId });
    },
    isDeleting,
  };
}
