import {
  sanitizeApiComment,
  sanitizeApiContent,
  sanitizeApiCritique,
  sanitizeApiPlagiarismCase,
  sanitizeApiRating,
  sanitizeApiReviewerToolkit,
  sanitizeApiRubric,
  sanitizeApiSubmission,
} from 'app/apis/helpers/reviews-helpers';

export type User = {
  accepted_terms: boolean;
  agreement_version: string | null;
  can_join_plagiarism_queue: boolean;
  email: string;
  gig_approved: boolean;
  id: number;
  mentor_country_code: string | null;
  mentor_languages: string[];
  mentor_level: number;
  name: string;
  payoneer_status: string | null;
  role: string;
  udacity_key: string;
};

export type Certification = {
  active: boolean;
  allowed_to_audit: boolean;
  certified_at: string;
  created_at: string;
  grader_id: number;
  id: number;
  project: Project;
  project_id: number;
  status: string;
  updated_at: string;
};

type Project = {
  audit_price: string;
  audit_project_id: number | null;
  audit_rubric_id: number;
  created_at: string;
  entitlement_required: boolean;
  hashtag: string | null;
  id: number;
  is_career: boolean;
  is_cert_project: boolean;
  name: string;
  nanodegree_key: string;
  price: string;
  recruitment_family_id: number;
  ungradeable_price: string;
  updated_at: string;
  exchange_currency: string;
  exchange_currency_price: string;
  exchange_currency_country: string;
};

export enum AuditRequestStatus {
  AVAILABLE = 'available',
  FULFILLED = 'fulfilled',
  CANCELLED = 'canceled',
}

export type AuditRequest = {
  id: number;
  audit_id: number | null;
  status: AuditRequestStatus;
  closed_at: string;
  created_at: string;
  updated_at: string | null;
  user_id: number;
  audit_request_projects: {
    project_id: number;
    language: string;
    created_at: string;
    updated_at: string | null;
  }[];
};

export type Audit = {
  id: number;
  user_id: number;
  grader_id: number;
  price: string;
  read_at: string | null;
  result: string | null;
  rubric_id: number;
  status: string;
  submission_id: number;
  submission_project: {
    id: number;
    is_career: boolean;
    is_cert_project: boolean;
    name: string;
  };
  created_at: string;
  updated_at: string | null;
  completed_at: string | null;
  assigned_at: string;
};

export type SubmissionRequest = {
  closed_at: string;
  created_at: string;
  id: number;
  status: string;
  submission_id: number;
  submission_request_projects: {
    created_at: string;
    language: string;
    project_id: number;
    updated_at: string;
  }[];
  updated_at: string;
  user_id: number;
};

export type FetchSubmissionsPayload = {
  userId: number;
  rubricId: number;
  enrollmentId: string | null;
};

export type ApiSubmission = {
  annotation_urls: string[];
  archive_url: string | null;
  assigned_at: string | null;
  commit_sha: string | null;
  completed_at: string | null;
  created_at: string;
  enrollment_id: string | null;
  enrollment_node_key: string | null;
  escalated_at: string | null;
  expires_at: string | null;
  files: string[];
  general_comment: string | null;
  grader_id: number | null;
  hidden: boolean;
  id: number;
  is_demerit: boolean | null;
  language: string;
  nomination: string | null;
  notes: string | null;
  plagiarism_judgement: string | null;
  plagiarism_source_url: string | null;
  previous_submission_id: number | null;
  price: string | null;
  project: {
    id: number;
    name: string | null;
    is_cert_project: boolean | null;
    is_career: boolean | null;
  };
  project_id: number;
  repo_url: string | null;
  result: string | null;
  result_reason: string | null;
  rubric_id: number | null;
  status: string;
  status_reason: string | null;
  type: string | null;
  ungradeable_tag: string | null;
  updated_at: string;
  url: string | null;
  user_id: number | null;
  exchange_currency: string | null;
  exchange_currency_price: string | null;
};

export type Submission = ReturnType<typeof sanitizeApiSubmission>;

export type PlagiarismCaseRequest = {
  id: number;
  plagiarism_case_id: number | null;
  status: string;
  closed_at: string;
  plagiarism_case_request_projects: {
    project_id: number;
    language: string;
  }[];
};

export type Opportunity = {
  id: number;
  user_id: number;
  submission_required: boolean;
  expires_at: string | null;
  created_at: string;
  updated_at: string;
  accepted: boolean;
  creator_id: number;
  rubric_id: number;
  classroom_project_url: string;
  rescinded_reason: string;
  project: {
    name: string;
    price: string;
    exchange_currency: string;
    exchange_currency_price: string;
    exchange_currency_country: string;
  };
  project_id: number;
  submission_attempts: number;
  max_submission_attempts: number;
};

export type RecruitmentFamily = {
  id: number;
  created_at: string;
  updated_at: string;
  description: string;
  name: string;
};

export type ReviewerInterest = {
  id: number;
  created_at: string;
  updated_at: string;
  status: string;
  user_id: number;
  last_expressed_at: string;
  last_screened_at: string | null;
  reapply_at: string | null;
  recruitment_family_id: number;
};

export type ApiReviewerToolkit = {
  id: number;
  language: string;
  project_id: number;
  archive_url: string;
  updated_at: string;
};

export type ReviewerToolkit = ReturnType<typeof sanitizeApiReviewerToolkit>;

export type UploadType = 'zip' | 'repo' | 'link' | 'file';

export type ApiRubric = {
  id: number;
  project_id: number | null;
  description: string | null;
  upload_types: UploadType[];
  file_filter_regex: string | null;
  nomination_eligible: boolean;
  stand_out: string | null;
  hide_criteria: boolean;
  created_at: string;
  updated_at: string;
  hashtag: string | null;
  max_upload_size_mb: number;
  estimated_sla: string | null;
  project_assistant_enabled: boolean;
  available_for_cert_project: boolean;
  language: string;
  classroom_node_id?: number | null;
  classroom_project_key?: string | null;
  ndkeys: string[];
  coursekeys: string[];
  is_career: boolean | null;
  project: {
    audit_price: string;
    audit_project_id: number | null;
    audit_rubric_id: number;
    created_at: string;
    entitlement_required: boolean | null;
    hashtag: string | null;
    id: number;
    is_career: boolean | null;
    is_cert_project: boolean;
    name: string;
    nanodegree_key: string;
    price: string;
    recruitment_family_id: number;
    ungradeable_price: string;
    updated_at: string;
  };
  sections: {
    created_at: string;
    deleted_at: string | null;
    id: number;
    name: string;
    position: number;
    rubric_id: number;
    rubric_items: {
      created_at: string;
      criteria: string;
      deleted_at: string | null;
      exceedable: boolean;
      exceeded_description: string | null;
      id: number;
      optional: boolean;
      passed_description: string;
      position: number;
      reviewer_tips: string;
      section_id: number;
      updated_at: string;
    }[];
    updated_at: string;
  }[];
};

export type Rubric = ReturnType<typeof sanitizeApiRubric>;

export type RubricSection = Rubric['sections'][number];
export type RubricItem = Rubric['sections'][number]['rubricItems'][number];

export type UnassignPayload = {
  submissionId: number;
  reason: string;
};

export type UngradeablePayload = {
  submissionId: number;
  notes: string;
  tag: string;
  plagiarismSourceUrl: string | null;
};

export type ApiCritique = {
  id: number;
  observation: string | null;
  suggestion: string | null;
  result: 'passed' | 'failed' | 'exceeded' | null;
  exceedable?: boolean | null;
  created_at: string;
  updated_at: string;
  subject_id: number | null;
  subject_type: string | null;
  autograded: boolean | null;
  rubric_item_id: number | null;
  rubric_item: {
    created_at: string;
    criteria: string;
    deleted_at: string | null;
    exceedable: boolean;
    exceeded_description: string | null;
    id: number;
    optional: boolean;
    passed_description: string;
    position: number;
    reviewer_tips?: string;
    section: {
      created_at: string;
      deleted_at: string | null;
      id: number;
      name: string;
      position: number;
      rubric_id: number;
      updated_at: string | null;
    };
    section_id: number;
    updated_at: string;
  };
};

export type Critique = ReturnType<typeof sanitizeApiCritique>;

export type ApiContent = {
  id: number;
  blob: string;
  comments_count: number;
  path: string;
  sha: string;
  size: number;
  submission_id: number;
};

export type Content = ReturnType<typeof sanitizeApiContent>;

export type ApiComment = {
  body: string;
  category: 'awesome' | 'nitpick' | 'critical';
  content_id: number;
  created_at: string;
  id: number;
  position: number;
  updated_at: string;
  user_id: number;
};

export type Comment = ReturnType<typeof sanitizeApiComment>;

export type CreateCommentPayload = {
  submissionId: number;
  contentId: number;
  body: string;
  category: Comment['category'];
  position: number;
};

export type UpdateCommentPayload = CreateCommentPayload & {
  commentId: number;
};

export type DeleteCommentPayload = {
  submissionId: number;
  commentId: number;
  contentId: number;
};

export type Result = 'passed' | 'failed' | null;

export type UpdateCritiquePayload = {
  id: number;
  result: Result;
  observation: string | null;
  submissionOrCaseId: number;
  isPlagiarismAudit?: boolean;
};

export type EditedCritique = Omit<UpdateCritiquePayload, 'submissionOrCaseId'>;

export type SubmitGeneralCommentPayload = {
  submissionId: number;
  generalComment: string | null;
};

export type ApiRating = {
  created_at: string | null;
  feedback: string | null;
  id: number | null;
  submission_id: number;
  updated_at: string | null;
  value: number | null;
  voter_id: number;
};

export type Rating = ReturnType<typeof sanitizeApiRating>;

export type SubmitRatingPayload = {
  submissionId: number;
  value: number;
  feedback: string;
};

export type FeedbackTemplate = {
  title: string;
  text: string;
  is_shared: boolean;
  meets_requirements: boolean;
  id: number;
  rubric_item_id: number;
  project_id: number;
};

export type CreateFeedbackTemplatePayload = {
  title: string;
  text: string;
  meets_requirements: boolean;
  rubric_item_id?: number;
  project_id: number;
};

export type UpdateFeedbackTemplatePayload = {
  id: number;
  title: string;
  text: string;
  meets_requirements: boolean;
};
export type ApiPlagiarismCase = {
  id: number;
  submission_id: number;
  grader_id: number;
  result: string | null;
  status: string;
  assigned_at: string;
  expires_at: string;
  completed_at: string | null;
  price: string;
  submission_project?: {
    id: number;
    name: string;
  };
};

export type PlagiarismCase = ReturnType<typeof sanitizeApiPlagiarismCase>;
