import React from 'react';
import styles from './list-item.module.scss';

export class ListItem extends React.PureComponent {
  render() {
    const { className } = this.props;

    return <li className={`${styles['list-item']} ${className}`}>{this.props.children}</li>;
  }
}

export default ListItem;
