import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppIntro } from 'app/common/components/onboarding/application-intro';
import { EditorType } from 'app/common/views/profile-editor/form-types';

export const AppIntroManager: React.FC<{ editorType: EditorType }> = ({ editorType }) => {
  const { t } = useTranslation();

  switch (editorType) {
    case EditorType.Specialized:
    case EditorType.AbbreviatedSpecialized: {
      return (
        <AppIntro
          title={t('mentorProfile.specializedMentorSetUp')}
          subHeader={t('mentorProfile.thanksForYourInterestSpecialized')}
        />
      );
    }
    case EditorType.Update: {
      return <AppIntro title={t('mentorProfile.mentorAccountUpdate')} subHeader={t('mentorProfile.wantToApply')} />;
    }
    default: {
      return (
        <AppIntro title={t('mentorProfile.mentorAccountSetUp')} subHeader={t('mentorProfile.thanksForYourInterest')} />
      );
    }
  }
};

export default AppIntroManager;
