import React from 'react';
import styles from './application-intro.module.scss';

interface Props {
  title: string;
  subHeader: string;
}

export const AppIntro: React.FC<Props> = ({ title, subHeader }) => {
  return (
    <div>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.acknowledgement}>{subHeader}</p>
    </div>
  );
};

export default AppIntro;
