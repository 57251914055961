import { Option } from 'app/helpers/form-helper';
import { ReactNode } from 'react';

export type DataTableRow = Record<string, unknown> | null;
export type DataTableCell = {
  data: string | number;
  component?: ReactNode | null;
  sortValue?: string | number;
};

export type DataTableColumn<T> = {
  getRow(data: T, idx?: number): DataTableCell;
  id: string;
  disableSorting?: boolean;
  title?: ReactNode;
  width?: string;
  paginationParam?: string;
};

export type PaginationData = {
  defaultPageSize?: number;
  defaultOrder?: Orders;
  defaultOrderBy?: string;
  suffix?: string;
  total?: number;
  isApiPaginated?: boolean;
};

export enum PaginationQueryParams {
  Order = 'order',
  OrderBy = 'orderBy',
  Page = 'page',
  PageSize = 'pageSize',
  Search = 'search',
}

export type Orders = 'ASC' | 'DESC';

export const ASC: Orders = 'ASC';
export const DESC: Orders = 'DESC';

export type OnSelectRow = (rowId: string, shouldSelect: boolean) => void;

export type CheckboxConfig = {
  checkboxId: string;
  checkboxTitleId?: string;
  onSelectRow: OnSelectRow;
  onSelectAll?(shouldSelect: boolean): void;
  selectedIds: (string | number)[];
};

export type Filter = {
  id: string;
  label: string;
  description?: string;
  options?: Option[];
  minWidth?: string;
} & (SingleFilter | MultiFilter);

type SingleFilter = {
  multi?: false;
  defaultValue?: string | number;
  onSelect?(value?: string | number | boolean): void;
};

type MultiFilter = {
  multi: true;
  defaultValue?: (string | number)[];
  onSelect?(value?: (string | number | boolean)[]): void;
};
