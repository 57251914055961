import { createAction } from 'app/helpers/action-helper';

export const actionTypes = {
  SET_PANEL: 'SET_PANEL',
  ADD_TOAST: 'ADD_TOAST',
  REMOVE_TOAST: 'REMOVE_TOAST',
  BOOTSTRAP_NOTIFICATIONS: 'BOOTSTRAP_NOTIFICATIONS',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
};

export const actionCreators = {
  setPanel(panelType) {
    return createAction(actionTypes.SET_PANEL, panelType);
  },
  addToast(toast) {
    return createAction(actionTypes.ADD_TOAST, toast);
  },
  removeToast(toast) {
    return createAction(actionTypes.REMOVE_TOAST, toast);
  },
  bootstrapNotifications() {
    return createAction(actionTypes.BOOTSTRAP_NOTIFICATIONS);
  },
  addNotification(notification) {
    return createAction(actionTypes.ADD_NOTIFICATION, notification);
  },
};
