import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client';

export default new InMemoryCache({
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case 'UserNanodegree': {
        if (object._id) {
          return `UserNanodegree:${object._id}`;
        }
        return `UserNanodegree:${object.key}`;
      }
      case 'StudentProjectProgress': {
        if (object._id) {
          return `StudentProjectProgress:${object._id}`;
        }
        return defaultDataIdFromObject(object);
      }
      default: {
        return defaultDataIdFromObject(object);
      }
    }
  },
});
