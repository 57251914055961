import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import { getActiveSubmissionRequests, getQueueAsync } from 'app/queue/domains/reviews-queue/reviews-queue-selectors';
import { useAppDispatch } from 'app/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as reviewsQueueActions from 'app/queue/domains/reviews-queue/reviews-queue-actions';
import { useTranslation } from 'react-i18next';
import { useAppToast } from 'app/common/hooks/use-app-toast';

export function useQueueEnabledStateWithErrors() {
  const [queueEnabled, setQueueEnabled] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useAppToast();
  const activeSubmissionRequests = useSelector(getActiveSubmissionRequests);
  const queueAsync = useSelector(getQueueAsync);
  const { create, destroy, update, refresh } = queueAsync;

  useEffect(() => {
    if (activeSubmissionRequests.length && !queueEnabled) {
      dispatch(reviewsQueueActions.startPollingSubmissionRequests());
      setQueueEnabled(true);
    }
    if (!activeSubmissionRequests.length && queueEnabled) {
      dispatch(reviewsQueueActions.stopPollingSubmissionRequests());
      dispatch(reviewsQueueActions.destroyAll());
      setQueueEnabled(false);
    }
  }, [activeSubmissionRequests.length, queueEnabled]);

  useEffect(() => {
    const defaultMessage = t('error.pleaseReachOutToSupport');

    if (create.error) {
      const createErrorMessage = _get(create, 'error.response.data.error', defaultMessage);
      toast({
        variant: 'error',
        title: t('error.errorCreatingSubmissionRequest', {
          message: _capitalize(createErrorMessage),
        }),
      });
      dispatch(reviewsQueueActions.stopPollingSubmissionRequests());
      dispatch(reviewsQueueActions.resetCreate());
      return;
    }
  }, [create.error]);

  useEffect(() => {
    const defaultMessage = t('error.pleaseReachOutToSupport');

    if (update.error) {
      const updateErrorMessage = _get(update, 'error.response.data.error', defaultMessage);
      toast({
        variant: 'error',
        title: t('error.errorUpdatingSubmissionRequest', {
          message: _capitalize(updateErrorMessage),
        }),
      });
      dispatch(reviewsQueueActions.resetUpdate());
      return;
    }
  }, [update.error]);

  useEffect(() => {
    const defaultMessage = t('error.pleaseReachOutToSupport');

    if (destroy.error) {
      const destroyErrorMessage = _get(destroy, 'error.response.data.error', defaultMessage);
      toast({
        variant: 'error',
        title: t('error.errorCancelingSubmissionRequest', {
          message: _capitalize(destroyErrorMessage),
        }),
      });
      dispatch(reviewsQueueActions.resetDestroy());
      return;
    }
  }, [destroy.error]);

  useEffect(() => {
    const defaultMessage = t('error.pleaseReachOutToSupport');

    if (refresh.error) {
      const refreshErrorMessage = _get(refresh, 'error.response.data.error', defaultMessage);
      toast({
        variant: 'error',
        title: t('error.errorRefreshingSubmissionRequest', {
          message: _capitalize(refreshErrorMessage),
        }),
      });
      dispatch(reviewsQueueActions.resetRefresh());
      return;
    }
  }, [refresh.error]);

  return { queueEnabled, setQueueEnabled };
}
