import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Flex } from '@chakra-ui/react';

import sessionActions from 'app/common/domains/session/session-actions';
import { getSession } from 'app/common/domains/session/session-selectors';
import TermsOfServiceCopy from 'app/common/components/terms-of-service/terms-of-service-copy';
import { useAppToast } from 'app/common/hooks/use-app-toast';
import { useAppDispatch } from 'app/store';

import CONFIG from 'configLoader';
import styles from './terms-of-service.module.scss';

export const TermsOfService: React.FC = () => {
  const [uid, setUid] = useState(null);
  const [agreementVersion, setAgreementVersion] = useState(null);
  const session = useSelector((state) => getSession(state));

  const { t } = useTranslation();
  const toast = useAppToast();
  const history = useHistory();
  const dispatch = useAppDispatch();
  let redirectTimer: NodeJS.Timer;

  useEffect(() => {
    const { uid } = session;
    setUid(uid);

    return () => clearTimeout(redirectTimer);
  }, []);

  /* onChange Input Handlers */
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setAgreementVersion(CONFIG.latestAgreementVersion);
    } else {
      setAgreementVersion(null);
    }
  };

  /* Submit Handlers */
  const isFormComplete = useMemo(() => {
    const requiredFields = [uid, agreementVersion];

    return requiredFields.every((field) => {
      return !!field;
    });
  }, [uid, agreementVersion]);

  const handleSuccess = () => {
    redirectTimer = setTimeout(() => {
      history.push('/');
    }, 3000);

    toast({
      variant: 'success',
      description: t('termsOfService.thankYouForAgreeing'),
    });
  };

  const handleDoneClick = async () => {
    const submitForm = { uid, agreement_version: agreementVersion };

    try {
      const { data } = await dispatch(sessionActions.postUpdate(submitForm));

      dispatch(
        sessionActions.updateUserAgreement({
          agreement_version: data.updateMentor.agreement_version,
          agrees_terms_of_service: true,
        })
      );
      handleSuccess();
    } catch (e) {
      console.error(e);
      toast({
        variant: 'error',
        description: t('error.somethingWentWrong'),
      });
    }
  };

  return (
    <div className={styles.gradient}>
      <section className={styles.card}>
        <div>
          <TermsOfServiceCopy />
        </div>
        <Flex justify="space-between" align="center" py={6} px="5rem" borderTop="1px solid" borderTopColor="gray.200">
          <Checkbox id="tos-checkbox" onChange={handleCheck} isChecked={!!agreementVersion} alignItems="start">
            {t('termsOfService.iAgreeTo')}
          </Checkbox>
          <Button type="submit" onClick={handleDoneClick} isDisabled={!isFormComplete}>
            {t('common.submit')}
          </Button>
        </Flex>
      </section>
    </div>
  );
};
