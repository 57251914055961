import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { useFetchRubricQuery } from 'app/apis/reviews';
import { LoadingPage } from 'app/common/views/loading-page';

const QUALITY_GUIDELINES_RUBRIC_ID = 1144;

export const QualtiyGuidelines: React.FC = () => {
  const { t } = useTranslation();
  const { data: qualityGuidelines } = useFetchRubricQuery(QUALITY_GUIDELINES_RUBRIC_ID);

  if (!qualityGuidelines) {
    return <LoadingPage />;
  }

  return (
    <Flex direction="column" gap={4} w={{ base: 'full', lg: '66%' }}>
      <Heading size="h3" as="h3">
        {t('projectReviews.resources.qualityGuidelines')}
      </Heading>

      {qualityGuidelines.sections.map((s, i) => (
        <Flex
          key={i}
          direction="column"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="base"
          bgColor="white"
          p={10}
          gap={6}
        >
          <Heading size="h4" as="h4">
            {s.name}
          </Heading>
          <UnorderedList spacing={6}>
            {s.rubricItems.map((r, ind) => (
              <ListItem key={ind}>
                <Text size="label">{r.criteria}</Text>
                {'\n'}
                <Text>{r.requirements}</Text>
              </ListItem>
            ))}
          </UnorderedList>
        </Flex>
      ))}
    </Flex>
  );
};
