import React from 'react';
import { Box, CloseButton, Flex, Text } from '@chakra-ui/react';
import { CancelIcon, CheckedIcon, InfoIcon, LightningIcon, WarningIcon } from '@udacity/chakra-uds-icons';

type ToastProps = {
  variant: 'success' | 'error' | 'warning' | 'info' | 'achievement';
  title?: string;
  description?: string;
  onClose: () => void;
};

export const Toast: React.FC<ToastProps> = ({ variant, title, description, onClose }) => {
  return (
    <Flex
      bg="white"
      border="solid 1px"
      borderColor="gray.500"
      borderRadius="base"
      shadow="md"
      align="stretch"
      justify="space-between"
    >
      <Flex align="stretch">
        <Box
          flexShrink={0}
          borderStartRadius="base"
          w={2}
          me={2}
          sx={variant === 'achievement' ? { bgGradient: colorMap.get(variant) } : { bg: colorMap.get(variant) }}
        />
        {iconMap.get(variant)}
        <Flex direction="column" py={5} mx={4}>
          <Text size="label">{title}</Text>
          <Text>{description}</Text>
        </Flex>
      </Flex>
      <CloseButton onClick={onClose} me={3} mt={4} />
    </Flex>
  );
};

const colorMap = new Map();
colorMap.set('success', 'feedback.positive');
colorMap.set('error', 'feedback.negative');
colorMap.set('warning', 'feedback.warning');
colorMap.set('info', 'blue.500');
colorMap.set('achievement', 'linear(to-b, blue.500, accent.purple)');

const iconMap = new Map();
iconMap.set('success', <CheckedIcon w={8} h={8} alignSelf="center" color={colorMap.get('success')} />);
iconMap.set('error', <CancelIcon w={8} h={8} alignSelf="center" color={colorMap.get('error')} />);
iconMap.set('warning', <WarningIcon w={8} h={8} alignSelf="center" color={colorMap.get('warning')} />);
iconMap.set('info', <InfoIcon w={8} h={8} alignSelf="center" color={colorMap.get('info')} />);
iconMap.set('achievement', <LightningIcon w={8} h={8} alignSelf="center" color="blue.500" />);
