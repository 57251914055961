import React, { useEffect } from 'react';
import { formatPrice } from 'app/helpers/format-helper';
import { Opportunity, Submission } from 'app/types/reviews';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Flex, Heading, Link, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useAcceptOpportunityMutation, useFetchCertificationsQuery, useFetchSubmissionsQuery } from 'app/apis/reviews';
import { useHistory } from 'react-router';
import { SubmissionPassedStatuses, SubmissionPendingStatuses } from 'app/queue/domains/submissions/submissions-helpers';

type OpportunityCardProps = {
  opportunity: Opportunity;
};

export const OpportunityCard: React.FC<OpportunityCardProps> = ({ opportunity }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { data: submissions } = useFetchSubmissionsQuery({
    userId: opportunity.user_id,
    rubricId: opportunity.rubric_id,
    enrollmentId: null,
  });
  const { data: certifications } = useFetchCertificationsQuery();
  const [acceptOpportunity, { isLoading }] = useAcceptOpportunityMutation();

  const pendingSubmission = submissions?.find(
    (submission) =>
      submission.projectId === opportunity.project_id && SubmissionPendingStatuses.includes(submission.status)
  );

  const passedSubmission = submissions?.find(
    (submission) =>
      submission.projectId === opportunity.project_id && SubmissionPassedStatuses.includes(submission.status)
  );

  const isAlreadyCertified = certifications?.find(
    (cert) => cert.project_id === opportunity.project_id && cert.status === 'certified'
  );

  useEffect(() => {
    if (isAlreadyCertified) {
      history.push('/queue/overview');
    }
  }, [isAlreadyCertified]);

  const isReadyForOnboarding = !!(passedSubmission || !opportunity.submission_required);

  /**
   * Lets a user accept the opportunity. Will redirect to either the onboarding tab
   * if they have a passed submission, or the submission page otherwise.
   */
  async function handleAccept() {
    if (!opportunity.accepted) {
      await acceptOpportunity(opportunity.id);
    }
    if (isReadyForOnboarding) {
      history.push('/queue/overview');
    } else {
      window.open(opportunity.classroom_project_url, '_blank');
    }
  }

  const project = opportunity.project;

  const formattedPrice = formatPrice(project.price);

  return (
    <Flex
      direction="column"
      gap={5}
      maxW="40rem"
      marginX="auto"
      px={6}
      py={4}
      boxShadow="lg"
      borderRadius="base"
      bg="white"
    >
      <Heading size="h2" as="h2">
        {project.name}
      </Heading>

      {project.price && (
        <Text>
          <Trans
            i18nKey="queue.opportunities.currentProjectPrice"
            components={{
              bold: <Text as="span" fontWeight="semibold" />,
            }}
            values={{ price: formattedPrice }}
          />
        </Text>
      )}
      {isReadyForOnboarding ? (
        <>
          <Text>{t('queue.opportunities.allSet')}</Text>
          <Button isLoading={isLoading} onClick={handleAccept} variant="solid">
            {t('queue.opportunities.continueApplication')}
          </Button>
        </>
      ) : (
        <InProgressApplication
          handleAccept={handleAccept}
          isLoading={isLoading}
          pendingSubmission={pendingSubmission}
          opportunity={opportunity}
        />
      )}
      <Text fontStyle="italic">
        {t('queue.opportunities.opportunityExpiresIn', {
          time: dayjs(opportunity.expires_at).fromNow(true),
        })}
      </Text>
    </Flex>
  );
};

const InProgressApplication: React.FC<{
  isLoading: boolean;
  handleAccept: () => void;
  pendingSubmission: Submission | undefined;
  opportunity: Opportunity;
}> = ({ isLoading, handleAccept, pendingSubmission, opportunity }) => {
  const { t } = useTranslation();
  const attemptsRemaining = Math.max(opportunity.max_submission_attempts - opportunity.submission_attempts, 0);

  return (
    <>
      {pendingSubmission ? (
        <Text>{t('queue.opportunities.submissionPending')}</Text>
      ) : (
        <SubmitProjectSection
          classroomProjectUrl={opportunity.classroom_project_url}
          handleAccept={handleAccept}
          isLoading={isLoading}
        />
      )}
      <Text>
        {t('queue.opportunities.attemptsMadeAttemptsRemaining', {
          count: attemptsRemaining,
        })}
      </Text>
    </>
  );
};

const SubmitProjectSection: React.FC<{
  classroomProjectUrl: string;
  isLoading: boolean;
  handleAccept: () => void;
}> = ({ classroomProjectUrl, isLoading, handleAccept }) => {
  const { t } = useTranslation();

  return (
    <>
      <Text>{t('queue.opportunities.beforeApplyingMustPass')}</Text>
      <Text>
        <Link target="_blank" rel="noopener noreferrer" href={classroomProjectUrl}>
          {t('queue.opportunities.clickToViewInstructions')}
        </Link>
      </Text>
      <Button isLoading={isLoading} onClick={handleAccept} variant="solid">
        {t('queue.opportunities.submitProject')}
      </Button>
    </>
  );
};
