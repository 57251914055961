import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { Modal } from 'app/common/components/modal';
import { FeedbackTemplate } from 'app/types/reviews';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeedbackTemplates } from './use-feedback-templates';

type FeedbackTemplateFormModalProps = {
  isOpen: boolean;
  rubricItemId?: number;
  meetsRequirements?: boolean;
  template?: FeedbackTemplate;
  onClose: () => void;
};

interface FormElements extends HTMLFormControlsCollection {
  text: HTMLTextAreaElement;
  title: HTMLInputElement;
  meetsRequirements: HTMLInputElement;
}

export const FeedbackTemplateFormModal: React.FC<FeedbackTemplateFormModalProps> = ({
  isOpen,
  rubricItemId,
  meetsRequirements,
  template,
  onClose,
}) => {
  const { t } = useTranslation();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const title = template
    ? confirmDelete
      ? t('projectReviews.template.delete')
      : t('projectReviews.template.edit')
    : t('projectReviews.template.feedbackTemplates');

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      {confirmDelete && template ? (
        <DeleteConfirmation template={template} setConfirmDelete={setConfirmDelete} />
      ) : (
        <TemplateForm
          template={template}
          setConfirmDelete={setConfirmDelete}
          rubricItemId={rubricItemId}
          meetsRequirements={meetsRequirements}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};

const TemplateForm: React.FC<{
  template?: FeedbackTemplate;
  setConfirmDelete: (confirmDelete: boolean) => void;
  rubricItemId?: number;
  meetsRequirements?: boolean;
  onClose: () => void;
}> = ({ template, setConfirmDelete, rubricItemId, onClose, meetsRequirements }) => {
  const { t } = useTranslation();
  const { createTemplate, isCreating, updateTemplate, isUpdating } = useFeedbackTemplates();
  const formRef = useRef<HTMLFormElement>(null);

  async function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();

    const { meetsRequirements, title, text } = evt.currentTarget.elements as FormElements;

    const body = {
      meets_requirements: meetsRequirements.value === 'true',
      title: title.value,
      text: text.value,
    };

    if (template) {
      await updateTemplate({ id: template.id, ...body });
    } else {
      await createTemplate({
        rubric_item_id: rubricItemId,
        ...body,
      });
    }

    formRef.current?.reset();

    onClose();
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <Flex direction="column" gap={4}>
        <Text>
          {rubricItemId
            ? t('projectReviews.template.newItemTemplateExplanation')
            : t('projectReviews.template.newTemplateExplanation')}
        </Text>

        <FormControl isRequired mb={6}>
          <FormLabel>{t('projectReviews.template.titleLabel')}</FormLabel>
          <Text mb={2} color="gray.700">
            {t('projectReviews.template.titleExplanation')}
          </Text>
          <Input name="title" type="text" defaultValue={template?.title} />
        </FormControl>

        <FormControl isRequired mb={6}>
          <FormLabel mb={2}>{t('projectReviews.template.typeLabel')}</FormLabel>
          <RadioGroup
            name="meetsRequirements"
            defaultValue={String(
              template?.meets_requirements !== undefined ? template?.meets_requirements : meetsRequirements
            )}
            display="flex"
            gap={6}
          >
            <Radio value="true">{t('projectReviews.passes')}</Radio>
            <Radio value="false">{t('projectReviews.doesNotPass')}</Radio>
          </RadioGroup>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>{t('projectReviews.template.textLabel')}</FormLabel>
          <Text mb={2} color="gray.700">
            {t('projectReviews.template.textExplanation')}
          </Text>
          <Textarea name="text" defaultValue={template?.text} />
        </FormControl>

        <Divider mt={4} mb={6} />

        <Flex gap={6}>
          <Button type="submit" w="12rem" isLoading={isCreating || isUpdating}>
            {t('common.submit')}
          </Button>
          <Button variant="outline" onClick={onClose}>
            {t('common.close')}
          </Button>
          {template && (
            <Button variant="ghost" colorScheme="red" onClick={() => setConfirmDelete(true)}>
              {t('projectReviews.template.delete')}
            </Button>
          )}
        </Flex>
      </Flex>
    </form>
  );
};

const DeleteConfirmation: React.FC<{
  template: FeedbackTemplate;
  setConfirmDelete: (confirmDelete: boolean) => void;
}> = ({ template, setConfirmDelete }) => {
  const { t } = useTranslation();
  const { deleteTemplate, isDeleting } = useFeedbackTemplates();

  function handleCancel() {
    setConfirmDelete(false);
  }

  function handleDelete() {
    deleteTemplate(template.id);
  }

  return (
    <>
      <Text>
        {t('projectReviews.template.deleteConfirmation', {
          templateName: template.title,
        })}
      </Text>
      <Divider mt={4} mb={6} />
      <Flex gap={6} justifyContent="end">
        <Button variant="outline" onClick={handleCancel}>
          {t('common.cancel')}
        </Button>
        <Button variant="solid" isLoading={isDeleting} onClick={handleDelete}>
          {t('common.delete')}
        </Button>
      </Flex>
    </>
  );
};
