import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControl, FormLabel } from '@chakra-ui/react';

export interface Props {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  id?: string;
  value: string | number;
  isChecked: boolean;
}

export const MentorOpportunity: React.FC<Props> = ({
  handleChange,
  label,
  id = 'available_hours',
  value,
  isChecked,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <FormLabel mb={2}>{t('mentorProfile.expandedMentorOpportunity')}</FormLabel>
      <Checkbox id={id} value={value} onChange={handleChange} checked={isChecked}>
        {label}
      </Checkbox>
    </FormControl>
  );
};

export default MentorOpportunity;
