import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as ReactRouterLink, useHistory } from 'react-router-dom';
import { Badge, Button, Flex, Link, Text } from '@chakra-ui/react';
import Analytics from '@udacity/ureact-analytics';

import { useSubmitFeedbackMutation } from 'app/apis/reviews';
import { Modal } from 'app/common/components/modal';
import { selectUid } from 'app/common/domains/user/user-selectors';

type ConfirmSubmissionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  didPass: boolean;
  submissionId: number;
};

export const ConfirmSubmissionModal: React.FC<ConfirmSubmissionModalProps> = ({
  isOpen,
  onClose,
  didPass,
  submissionId,
}) => {
  const currentUserUid = useSelector(selectUid);
  const history = useHistory();
  const { t } = useTranslation();
  const [submitFeedback, { isLoading, isError }] = useSubmitFeedbackMutation();

  const handleSubmit = async () => {
    Analytics.track('Project Review Submitted', {
      timestamp: new Date().toISOString(),
      submissionId,
      reviewerId: currentUserUid,
    });
    await submitFeedback(submissionId)
      .unwrap()
      .then(() => {
        history.push('/queue/overview');
      })
      .catch((e) => console.error(e));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isError ? t('error.submissionError') : t('projectReviews.confirmSubmission.confirmSubmission')}
      footer={
        <>
          <Button onClick={handleSubmit} isLoading={isLoading}>
            {t('projectReviews.feedback.submitFeedback')}
          </Button>
          <Button variant="outline" onClick={onClose}>
            {t('common.cancel')}
          </Button>
        </>
      }
      footerStyles={{ gap: 6 }}
    >
      <Flex direction="column" gap={4}>
        {isError ? (
          <Text>{t('error.anErrorOccurred')}</Text>
        ) : (
          <>
            <Badge
              bgColor={didPass ? 'feedback.positive' : 'feedback.negative'}
              color="white"
              size="lg"
              w="max-content"
            >
              {didPass ? t('projectReviews.passes') : t('projectReviews.doesNotPass')}
            </Badge>

            <Text>
              {didPass
                ? t('projectReviews.confirmSubmission.studentMeetsAllSpecs')
                : t('projectReviews.confirmSubmission.studentDoesNotMeetAllSpecs')}
            </Text>
            <Text>
              <Trans
                i18nKey="projectReviews.confirmSubmission.bySubmitting"
                components={{
                  quality_link: (
                    <Link
                      as={ReactRouterLink}
                      to={`/project-reviews/${submissionId}/resources/quality-guidelines`}
                      isExternal
                    />
                  ),
                }}
              />
            </Text>
          </>
        )}
      </Flex>
    </Modal>
  );
};
