import { Flex, Skeleton } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { MeetingCalendar } from './meeting-calendar';
import groupBy from 'lodash/groupBy';
import { dateFormatter } from 'app/helpers/format-helper';
import { MeetingsList } from './meetings-list';
import { useGetMeetingsQuery } from 'app/uconnect/queries/enhanced';
import auth from '@udacity/ureact-hoth';
import dayjs, { Dayjs } from 'dayjs';

export const Meetings: React.FC = () => {
  const [focusedDay, setFocusedDay] = useState<string | undefined>();
  const [focusedMonth, setFocusedMonth] = useState<Dayjs>(dayjs());

  const { data, isLoading } = useGetMeetingsQuery({
    facilitatorUserKey: auth.getCurrentUserId(),
    startsBefore: focusedMonth.endOf('month').toISOString(),
    startsAfter: focusedMonth.startOf('month').toISOString(),
  });

  const meetings = data?.meetings?.meetings || [];

  const handleSelectDate = (date: Date) => {
    const selectedDay = dateFormatter.numeric(date);

    if (focusedDay === selectedDay || meetingDays.indexOf(selectedDay) < 0) {
      // if the user clicks the same day twice - remove the filter
      // OR if the user clicks a random day without meetings - remove the filter
      setFocusedDay(undefined);
    } else {
      // filter the meetings list to the selected day
      setFocusedDay(selectedDay);
    }
  };

  const handleSelectMonth = (date: Dayjs) => {
    setFocusedMonth(date);
    setFocusedDay(undefined);
  };

  const allMeetingsByDay = useMemo(
    () => groupBy(meetings, (meeting) => dateFormatter.numeric(meeting.startAt)),
    [meetings]
  );

  const meetingDays = useMemo(() => Object.keys(allMeetingsByDay), [allMeetingsByDay]);

  const meetingsByDay = useMemo(() => {
    return focusedDay ? { [focusedDay]: allMeetingsByDay[focusedDay] } : allMeetingsByDay;
  }, [allMeetingsByDay, focusedDay]);

  return (
    <Skeleton isLoaded={!isLoading}>
      <Flex gap={8}>
        <MeetingCalendar
          datesToHighlight={meetingDays}
          onSelectDate={handleSelectDate}
          onSelectMonth={handleSelectMonth}
        />
        <MeetingsList meetingsByDay={meetingsByDay} />
      </Flex>
    </Skeleton>
  );
};
