import i18n from 'app/i18n';
import dayjs, { Dayjs } from 'dayjs';

const locale = i18n.language ? i18n.language.toLowerCase() : 'en-us';

export const formatPrice = (price: string) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
  }).format(Number(price));
};

export const formatAlternatePrice = (exchangeCurrency: string, exchangeCurrencyPrice: string) => {
  return exchangeCurrency === '' || exchangeCurrencyPrice === '0'
    ? ''
    : new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: exchangeCurrency,
      }).format(Number(exchangeCurrencyPrice));
};

export const agreementDate = (dateStr: Date) => {
  return dayjs(dateStr).format('LL');
};

export const pluralize = (word = '', size = 0, plural = 's', singular = '') => {
  if (size === 1 || size === -1) {
    return `${word}${singular}`;
  }
  return `${word}${plural}`;
};

export const removeDanglingUrlSlash = (url: string) => {
  if (url.charAt(url.length - 1) === '/') {
    return url.slice(0, url.length - 1);
  }
  return url;
};

export const SHORT_DATE_FORMAT = 'll';

class DateFormatter {
  constructor(locale: string) {
    this.resetLocale(locale);
  }

  resetLocale(locale: string) {
    dayjs.locale(locale);
  }

  dayOfTheWeek(dayIndex: number) {
    return dayjs().day(dayIndex).format('dddd');
  }

  long = (dateString: string | Dayjs | undefined | null, defaultReturn = '-') => {
    return dateString ? dayjs(dateString).format('LL') : defaultReturn;
  };

  monthAndYear = (dateString: string | Dayjs | undefined, defaultReturn = '-') => {
    return dateString ? dayjs(dateString).format('MMMM YYYY') : defaultReturn;
  };

  monthDayUTC = (dateString: string | Date | undefined, defaultReturn = '-') => {
    return dateString ? dayjs.utc(dateString).format('MMM D') : defaultReturn;
  };

  monthShort = (dateString: string | Date | undefined, defaultReturn = '-') => {
    return dateString ? dayjs(dateString).format('MMM') : defaultReturn;
  };

  numeric = (dateString: string | Date | Dayjs | undefined | null, defaultReturn = '-') => {
    return dateString ? dayjs(dateString).format('l') : defaultReturn;
  };

  numericUTC = (dateString: string | Dayjs | undefined, defaultReturn = '-') => {
    return dateString ? dayjs.utc(dateString).format('l') : defaultReturn;
  };

  short = (dateString: string | Date | Dayjs | undefined, defaultReturn = '-') => {
    return dateString ? dayjs(dateString).format(SHORT_DATE_FORMAT) : defaultReturn;
  };

  shortUTC = (dateString: string | Dayjs | Date | undefined | null, defaultReturn = '-') => {
    return dateString ? dayjs.utc(dateString).format(SHORT_DATE_FORMAT) : defaultReturn;
  };

  date = (dateString: string | Date | Dayjs | undefined, defaultReturn = '-') => {
    return dateString ? dayjs.utc(dateString).format('YYYY-MM-DD') : defaultReturn;
  };

  datetime = (dateString: string | Dayjs | undefined, defaultReturn = '-') => {
    return dateString ? dayjs(dateString).format('lll') : defaultReturn;
  };

  localTime = (dateString: string | undefined, defaultReturn = '-') => {
    if (dateString) {
      const date = new Date(dateString);
      if (date.toLocaleTimeString) {
        return date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
      }
    }
    return defaultReturn;
  };

  relativeTime = (dateString: string | Dayjs | Date | undefined, defaultReturn = '-') => {
    return dateString ? dayjs(dateString).fromNow() : defaultReturn;
  };
  longMonthDateAndYear = (dateString: string | Dayjs | Date | undefined, defaultReturn = '-') => {
    return dateString ? dayjs(dateString).format('MMMM D, YYYY') : defaultReturn;
  };
}

export const dateFormatter = new DateFormatter(locale);
