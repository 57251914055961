import { retry } from '@reduxjs/toolkit/query/react';
import { ClientError, GraphQLClient, Variables } from 'graphql-request';
import auth from '@udacity/ureact-hoth';

export type GraphqlQuery = {
  document: string;
  variables: Variables | void;
};

export function getGqlBaseQuery(baseUrl: string) {
  const client = new GraphQLClient(baseUrl);

  return async ({ document, variables }: GraphqlQuery) => {
    const jwt = auth.getJWT();
    client.setHeader('Authorization', `Bearer ${jwt}`);
    try {
      const result = await client.request(document, variables || undefined);
      return { data: result };
    } catch (error) {
      if (error instanceof ClientError) {
        const errorMessage = ((error.message as string) || '').split(': {')[0];
        const returnedError = {
          status: error.response.status,
          data: errorMessage,
          metadata: {
            response: error.response.data,
            errors: error.response.errors,
          },
        };
        if (error?.response?.status === 200) {
          return retry.fail(returnedError);
        }
        return { error: returnedError };
      }
      return { error: { status: 500, data: error } };
    }
  };
}
