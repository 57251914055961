import { actionTypes } from './session-actions';
import _ from 'lodash';

export const initialState = {
  loading: true,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_ME: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.FETCH_ME_FULFILLED: {
      return {
        ...state,
        loading: false,
        ...payload,
      };
    }
    case actionTypes.POST_CREATE_FULFILLED: {
      const mentorData = _.get(payload.data, 'createMentor', {});

      return {
        ...state,
        ...mentorData,
        loading: false,
      };
    }
    case actionTypes.POST_UPDATE_FULFILLED: {
      const mentorData = _.get(payload.data, 'updateMentor', {});

      return {
        ...state,
        ...mentorData,
        loading: false,
      };
    }
    case actionTypes.UPDATE_USER_AGREEMENT: {
      return {
        ...state,
        ...payload,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};
