/* eslint-disable */ /**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  UUID: { input: any; output: any };
  Time: { input: any; output: any };
};

export type Query = {
  __typename?: 'Query';
  cohorts: PaginatedCohorts;
  cohortUsers: PaginatedCohortUsers;
  /**
   * Returns true if the user is a facilitator in any cohort.  If cohortId is provided,
   * it will check if the user is a facilitator for that cohort.
   */
  isFacilitator: Scalars['Boolean']['output'];
  meetings: PaginatedMeetings;
  myConnectData: MyConnectData;
  series: PaginatedSeries;
  seriesById?: Maybe<Series>;
  seriesByUserKey: Array<Series>;
};

export type QueryCohortsArgs = {
  input: CohortSearchParams;
};

export type QueryCohortUsersArgs = {
  input: CohortUserSearchParams;
};

export type QueryIsFacilitatorArgs = {
  cohortId?: InputMaybe<Scalars['UUID']['input']>;
  userKey: Scalars['String']['input'];
};

export type QueryMeetingsArgs = {
  input: MeetingSearchParams;
};

export type QueryMyConnectDataArgs = {
  input: MyConnectDataParams;
};

export type QuerySeriesArgs = {
  input: SeriesSearchParams;
};

export type QuerySeriesByIdArgs = {
  seriesId: Scalars['UUID']['input'];
};

export type QuerySeriesByUserKeyArgs = {
  programKey?: InputMaybe<Scalars['String']['input']>;
  userKey: Scalars['String']['input'];
};

export type CohortSearchParams = {
  goToMarket?: InputMaybe<GoToMarket>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  learnerUserKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<PaginationParams>;
  sortBy?: InputMaybe<CohortSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<CohortStatus>;
  type?: InputMaybe<CohortType>;
};

export enum GoToMarket {
  B2B = 'B2B',
  B2C = 'B2C',
  B2G = 'B2G',
}

export type PaginationParams = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export enum CohortSortBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  StartDate = 'START_DATE',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum CohortStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  PreLaunch = 'PRE_LAUNCH',
}

export enum CohortType {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Test = 'TEST',
}

export type PaginatedCohorts = {
  __typename?: 'PaginatedCohorts';
  cohorts: Array<Cohort>;
  hasMore: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Cohort = {
  __typename?: 'Cohort';
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  endDate?: Maybe<Scalars['Time']['output']>;
  goToMarket: GoToMarket;
  id: Scalars['UUID']['output'];
  maxSeriesSwitches: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  relevantEnrollments?: Maybe<Array<RelevantEnrollment>>;
  showLeaderboard: Scalars['Boolean']['output'];
  startDate?: Maybe<Scalars['Time']['output']>;
  status: CohortStatus;
  type: CohortType;
  updatedAt: Scalars['Time']['output'];
};

export type RelevantEnrollment = {
  __typename?: 'RelevantEnrollment';
  cohortId: Scalars['UUID']['output'];
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  id: Scalars['UUID']['output'];
  programKey: Scalars['String']['output'];
  unenrollOnWithdrawal: Scalars['Boolean']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type CohortUserSearchParams = {
  cohortId: Scalars['UUID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  hasSeries?: InputMaybe<Scalars['Boolean']['input']>;
  meetingIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  paginationParams?: InputMaybe<PaginationParams>;
  role?: InputMaybe<CohortUserRole>;
  seriesIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  sortBy?: InputMaybe<CohortUserSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  userKey?: InputMaybe<Scalars['String']['input']>;
  userKeys?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum CohortUserRole {
  Facilitator = 'FACILITATOR',
  Learner = 'LEARNER',
}

export enum CohortUserSortBy {
  CreatedAt = 'CREATED_AT',
  Email = 'EMAIL',
}

export type PaginatedCohortUsers = {
  __typename?: 'PaginatedCohortUsers';
  cohortUsers: Array<CohortUser>;
  hasMore: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CohortUser = {
  __typename?: 'CohortUser';
  bio?: Maybe<Scalars['String']['output']>;
  cohortId: Scalars['UUID']['output'];
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  maxWeeklyMeetings: Scalars['Int']['output'];
  mentorStatus?: Maybe<MentorStatus>;
  roles: Array<CohortUserRole>;
  series: Array<Series>;
  updatedAt: Scalars['Time']['output'];
  user?: Maybe<UserDetails>;
  userKey: Scalars['String']['output'];
};

export type MentorStatus = {
  __typename?: 'MentorStatus';
  isBlocked: Scalars['Boolean']['output'];
  isMentor: Scalars['Boolean']['output'];
};

export type Series = {
  __typename?: 'Series';
  assignmentType: SeriesAssignmentType;
  cohortId: Scalars['UUID']['output'];
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  firstMeetingStartAt?: Maybe<Scalars['Time']['output']>;
  id: Scalars['UUID']['output'];
  lastMeetingStartAt?: Maybe<Scalars['Time']['output']>;
  maxSize: Scalars['Int']['output'];
  meetings: PaginatedMeetings;
  name: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
  userCount: Scalars['Int']['output'];
};

export type SeriesMeetingsArgs = {
  input?: InputMaybe<SeriesMeetingSearchParams>;
};

export enum SeriesAssignmentType {
  Manual = 'MANUAL',
  Self = 'SELF',
}

/** Search params for Meetings resolved under a Series. */
export type SeriesMeetingSearchParams = {
  availability?: InputMaybe<MeetingAvailability>;
  facilitatorUserKey?: InputMaybe<Scalars['String']['input']>;
  learnerUserKey?: InputMaybe<Scalars['String']['input']>;
  locales?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<PaginationParams>;
  sortBy?: InputMaybe<MeetingSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  startsAfter?: InputMaybe<Scalars['Time']['input']>;
  startsBefore?: InputMaybe<Scalars['Time']['input']>;
};

export type MeetingAvailability = {
  availableToFacilitators?: InputMaybe<Scalars['Boolean']['input']>;
  availableToFacilitatorUserKey?: InputMaybe<Scalars['String']['input']>;
};

export enum MeetingSortBy {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  StartAt = 'START_AT',
}

export type PaginatedMeetings = {
  __typename?: 'PaginatedMeetings';
  hasMore: Scalars['Boolean']['output'];
  meetings: Array<Meeting>;
  totalCount: Scalars['Int']['output'];
};

export type Meeting = {
  __typename?: 'Meeting';
  cohortId: Scalars['UUID']['output'];
  conferenceLink?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  endAt: Scalars['Time']['output'];
  facilitators: Array<CohortUser>;
  facilitatorUserKeys?: Maybe<Array<Scalars['String']['output']>>;
  facilitatorUsers: PaginatedCohortUsers;
  id: Scalars['UUID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  locales: Array<Scalars['String']['output']>;
  maxSize: Scalars['Int']['output'];
  meetingUsers: PaginatedMeetingUsers;
  name: Scalars['String']['output'];
  series?: Maybe<Series>;
  seriesId?: Maybe<Scalars['UUID']['output']>;
  startAt: Scalars['Time']['output'];
  timezone: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type MeetingFacilitatorUsersArgs = {
  input?: InputMaybe<MeetingFacilitatorSearchParams>;
};

export type MeetingMeetingUsersArgs = {
  input?: InputMaybe<MeetingMeetingUserSearchParams>;
};

/** Search params for Facilitator CohortUsers resolved under a Meeting. */
export type MeetingFacilitatorSearchParams = {
  paginationParams?: InputMaybe<PaginationParams>;
  sortBy?: InputMaybe<CohortUserSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  userKey?: InputMaybe<Scalars['String']['input']>;
};

/** Search params for MeetingUsers resolved under a Meeting. */
export type MeetingMeetingUserSearchParams = {
  paginationParams?: InputMaybe<PaginationParams>;
  sortBy?: InputMaybe<MeetingUserSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  userKey?: InputMaybe<Scalars['String']['input']>;
};

export enum MeetingUserSortBy {
  CreatedAt = 'CREATED_AT',
}

export type PaginatedMeetingUsers = {
  __typename?: 'PaginatedMeetingUsers';
  hasMore: Scalars['Boolean']['output'];
  meetingUsers: Array<MeetingUser>;
  totalCount: Scalars['Int']['output'];
};

export type MeetingUser = {
  __typename?: 'MeetingUser';
  attendance?: Maybe<MeetingAttendance>;
  attendanceRequest?: Maybe<MeetingAttendanceRequest>;
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  meetingId: Scalars['UUID']['output'];
  updatedAt: Scalars['Time']['output'];
  user?: Maybe<UserDetails>;
  userKey: Scalars['String']['output'];
};

export type MeetingAttendance = {
  __typename?: 'MeetingAttendance';
  meetingId: Scalars['UUID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  status: MeetingAttendanceStatus;
  userKey: Scalars['String']['output'];
};

export enum MeetingAttendanceStatus {
  Absent = 'ABSENT',
  Attended = 'ATTENDED',
  Excused = 'EXCUSED',
}

export type MeetingAttendanceRequest = {
  __typename?: 'MeetingAttendanceRequest';
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  id: Scalars['UUID']['output'];
  judgeUserKey?: Maybe<Scalars['String']['output']>;
  meetingId: Scalars['UUID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  requestApproved?: Maybe<Scalars['Boolean']['output']>;
  requestedAttendanceStatus: MeetingAttendanceStatus;
  updatedAt: Scalars['Time']['output'];
  userKey: Scalars['String']['output'];
};

export type UserDetails = {
  __typename?: 'UserDetails';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  userKey: Scalars['String']['output'];
};

export type MeetingSearchParams = {
  availability?: InputMaybe<MeetingAvailability>;
  cohortId?: InputMaybe<Scalars['UUID']['input']>;
  facilitatorUserKey?: InputMaybe<Scalars['String']['input']>;
  learnerUserKey?: InputMaybe<Scalars['String']['input']>;
  locales?: InputMaybe<Array<Scalars['String']['input']>>;
  meetingId?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<PaginationParams>;
  seriesId?: InputMaybe<Scalars['UUID']['input']>;
  sortBy?: InputMaybe<MeetingSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  startsAfter?: InputMaybe<Scalars['Time']['input']>;
  startsBefore?: InputMaybe<Scalars['Time']['input']>;
};

export type MyConnectDataParams = {
  userKey: Scalars['String']['input'];
};

export type MyConnectData = {
  __typename?: 'MyConnectData';
  myCohorts: MyPaginatedCohorts;
  myMeetings: MyPaginatedMeetings;
};

export type MyConnectDataMyCohortsArgs = {
  input: CohortSearchParams;
};

export type MyConnectDataMyMeetingsArgs = {
  input: MeetingSearchParams;
};

export type MyPaginatedCohorts = {
  __typename?: 'MyPaginatedCohorts';
  cohorts: Array<MyCohort>;
  hasMore: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type MyCohort = {
  __typename?: 'MyCohort';
  availableSeries: PaginatedSeries;
  cohort: Cohort;
  mySeries: PaginatedSeries;
  seriesSwitchesLeft: Scalars['Int']['output'];
};

export type MyCohortAvailableSeriesArgs = {
  input?: InputMaybe<CohortSeriesSearchParams>;
};

export type MyCohortMySeriesArgs = {
  input?: InputMaybe<MySeriesSearchParams>;
};

/** Search params for Series resolved under a Cohort. */
export type CohortSeriesSearchParams = {
  assignmentType?: InputMaybe<SeriesAssignmentType>;
  availability?: InputMaybe<SeriesAvailability>;
  containsUserKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<PaginationParams>;
  seriesId?: InputMaybe<Scalars['UUID']['input']>;
  sortBy?: InputMaybe<SeriesSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type SeriesAvailability = {
  availableToAny?: InputMaybe<Scalars['Boolean']['input']>;
  availableToNone?: InputMaybe<Scalars['Boolean']['input']>;
  availableToUserKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  availableToUsers?: InputMaybe<Scalars['Boolean']['input']>;
  hasMeetingsAvailableForFacilitatorUserKey?: InputMaybe<Scalars['String']['input']>;
};

export enum SeriesSortBy {
  CreatedAt = 'CREATED_AT',
  MaxSize = 'MAX_SIZE',
  Name = 'NAME',
}

export type PaginatedSeries = {
  __typename?: 'PaginatedSeries';
  hasMore: Scalars['Boolean']['output'];
  series: Array<Series>;
  totalCount: Scalars['Int']['output'];
};

export type MySeriesSearchParams = {
  assignmentType?: InputMaybe<SeriesAssignmentType>;
  availability?: InputMaybe<SeriesAvailability>;
  name?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<PaginationParams>;
  seriesId?: InputMaybe<Scalars['UUID']['input']>;
  sortBy?: InputMaybe<SeriesSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type MyPaginatedMeetings = {
  __typename?: 'MyPaginatedMeetings';
  hasMore: Scalars['Boolean']['output'];
  meetings: Array<MyMeeting>;
  totalCount: Scalars['Int']['output'];
};

export type MyMeeting = {
  __typename?: 'MyMeeting';
  attendance?: Maybe<MeetingAttendance>;
  attendanceRequest?: Maybe<MeetingAttendanceRequest>;
  meeting: Meeting;
};

export type SeriesSearchParams = {
  assignmentType?: InputMaybe<SeriesAssignmentType>;
  availability?: InputMaybe<SeriesAvailability>;
  cohortId?: InputMaybe<Scalars['UUID']['input']>;
  containsUserKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<PaginationParams>;
  seriesId?: InputMaybe<Scalars['UUID']['input']>;
  seriesIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  sortBy?: InputMaybe<SeriesSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type Mutation = {
  __typename?: 'Mutation';
  claimMeetings: Scalars['Boolean']['output'];
  createCohorts: Array<Cohort>;
  createCohortUsers: Array<CohortUser>;
  createMeetingAttendanceRequests: Array<MeetingAttendanceRequest>;
  createMeetings: Array<Meeting>;
  createMeetingUsers: Array<MeetingUser>;
  createSeries: Array<Series>;
  createSeriesUsers: Array<SeriesUser>;
  deleteCohort: Scalars['Boolean']['output'];
  deleteCohortUsers: Scalars['Boolean']['output'];
  deleteMeetings: Scalars['Boolean']['output'];
  deleteSeries: Scalars['Boolean']['output'];
  /** @deprecated Use deleteSeriesUsers instead */
  deleteSeriesUser: Scalars['Boolean']['output'];
  deleteSeriesUsers: Scalars['Boolean']['output'];
  /**
   * Distribute users randomly into the provided series as fairly as possible
   * while respecting series maxSize.  If dryRun is true, then no changes will
   * occur, but a preview of the results can be viewed.
   *
   * If it is not possible to distribute the learners into the given space, an
   * error will be returned.
   */
  distributeUsersInSeries: Array<DistributeUsersSeriesSummary>;
  /**
   * Resize series allows you to change the maxSize of a series.  If the
   * new maxSize is smaller than the current number of users in the series,
   * then additional series will be created to accommodate the users and
   * the users will be distributed evenly among the newly added series.
   */
  resizeSeries: Array<ResizedSeriesResult>;
  /**
   * Ensures that all cohort facilitators have valid Mentor entries.  Returns
   * a list of faciliators that were "out of sync", or missing Mentor entries.
   * If dryRun is false, then the Mentor entries will be created.
   */
  resyncCohortFacilitators: Array<CohortUser>;
  resyncCohortUserEmails: Scalars['Boolean']['output'];
  switchSeries?: Maybe<SeriesSwitch>;
  /** @deprecated Use transferSeriesUsers instead */
  transferSeriesUser: Scalars['Boolean']['output'];
  transferSeriesUsers: Scalars['Boolean']['output'];
  updateCohorts: Array<Cohort>;
  updateCohortUsers: Array<CohortUser>;
  updateMeetingAttendance: Scalars['Boolean']['output'];
  updateMeetingAttendanceRequests: Array<MeetingAttendanceRequest>;
  updateMeetings: Array<Meeting>;
  updateSeries: Array<Series>;
};

export type MutationClaimMeetingsArgs = {
  meetingIDs: Array<Scalars['UUID']['input']>;
};

export type MutationCreateCohortsArgs = {
  input: Array<NewCohort>;
};

export type MutationCreateCohortUsersArgs = {
  input: Array<NewCohortUser>;
};

export type MutationCreateMeetingAttendanceRequestsArgs = {
  input: Array<NewMeetingAttendanceRequest>;
};

export type MutationCreateMeetingsArgs = {
  input: Array<NewMeeting>;
};

export type MutationCreateMeetingUsersArgs = {
  input: Array<NewMeetingUser>;
};

export type MutationCreateSeriesArgs = {
  input: Array<NewSeries>;
};

export type MutationCreateSeriesUsersArgs = {
  input: Array<NewSeriesUser>;
};

export type MutationDeleteCohortArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationDeleteCohortUsersArgs = {
  input: Array<DeleteCohortUser>;
};

export type MutationDeleteMeetingsArgs = {
  meetingIDs: Array<Scalars['UUID']['input']>;
};

export type MutationDeleteSeriesArgs = {
  input: Array<DeleteSeries>;
};

export type MutationDeleteSeriesUserArgs = {
  input: DeleteSeriesUser;
};

export type MutationDeleteSeriesUsersArgs = {
  input: Array<DeleteSeriesUser>;
};

export type MutationDistributeUsersInSeriesArgs = {
  input: DistributeUsersInSeriesInput;
};

export type MutationResizeSeriesArgs = {
  input: ResizeSeriesInput;
};

export type MutationResyncCohortFacilitatorsArgs = {
  cohortId?: InputMaybe<Scalars['UUID']['input']>;
  dryRun?: Scalars['Boolean']['input'];
};

export type MutationResyncCohortUserEmailsArgs = {
  cohortId: Scalars['UUID']['input'];
};

export type MutationSwitchSeriesArgs = {
  input: NewSeriesSwitch;
};

export type MutationTransferSeriesUserArgs = {
  input: TransferSeriesUserDetails;
};

export type MutationTransferSeriesUsersArgs = {
  input: Array<TransferSeriesUserDetails>;
};

export type MutationUpdateCohortsArgs = {
  input: Array<UpdateCohort>;
};

export type MutationUpdateCohortUsersArgs = {
  input: Array<UpdateCohortUser>;
};

export type MutationUpdateMeetingAttendanceArgs = {
  input: Array<UpdateMeetingAttendance>;
};

export type MutationUpdateMeetingAttendanceRequestsArgs = {
  input: Array<UpdateMeetingAttendanceRequest>;
};

export type MutationUpdateMeetingsArgs = {
  input: Array<UpdateMeeting>;
};

export type MutationUpdateSeriesArgs = {
  input: Array<UpdateSeries>;
};

export type NewCohort = {
  endDate?: InputMaybe<Scalars['Time']['input']>;
  goToMarket: GoToMarket;
  maxSeriesSwitches?: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  relevantEnrollments?: InputMaybe<Array<NewRelevantEnrollment>>;
  showLeaderboard?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  type?: InputMaybe<CohortType>;
};

export type NewRelevantEnrollment = {
  programKey: Scalars['String']['input'];
  unenrollOnWithdrawal: Scalars['Boolean']['input'];
};

export type NewCohortUser = {
  bio?: InputMaybe<Scalars['String']['input']>;
  cohortId: Scalars['UUID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  maxWeeklyMeetings?: Scalars['Int']['input'];
  roles: Array<CohortUserRole>;
  userKey?: InputMaybe<Scalars['String']['input']>;
};

export type NewMeetingAttendanceRequest = {
  meetingId: Scalars['UUID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  requestedAttendanceStatus: MeetingAttendanceStatus;
  userKey: Scalars['String']['input'];
};

export type NewMeeting = {
  cohortId: Scalars['UUID']['input'];
  conferenceLink?: InputMaybe<Scalars['String']['input']>;
  endAt: Scalars['Time']['input'];
  facilitatorUserKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  locales?: InputMaybe<Array<Scalars['String']['input']>>;
  maxSize?: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  seriesId?: InputMaybe<Scalars['UUID']['input']>;
  startAt: Scalars['Time']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type NewMeetingUser = {
  meetingId: Scalars['UUID']['input'];
  userKey: Scalars['String']['input'];
};

export type NewSeries = {
  assignmentType?: InputMaybe<SeriesAssignmentType>;
  cohortId: Scalars['UUID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  maxSize?: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type NewSeriesUser = {
  seriesId: Scalars['UUID']['input'];
  userKey: Scalars['String']['input'];
};

export type SeriesUser = {
  __typename?: 'SeriesUser';
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
  id: Scalars['UUID']['output'];
  seriesId: Scalars['UUID']['output'];
  updatedAt: Scalars['Time']['output'];
  userKey: Scalars['String']['output'];
};

export type DeleteCohortUser = {
  cohortId: Scalars['UUID']['input'];
  removalReason?: InputMaybe<CohortUserRemovalReason>;
  removalType: CohortUserRemovalType;
  userKey: Scalars['String']['input'];
};

export enum CohortUserRemovalReason {
  Dissatisfaction = 'DISSATISFACTION',
  Interest = 'INTEREST',
  Other = 'OTHER',
  Personal = 'PERSONAL',
  Time = 'TIME',
}

export enum CohortUserRemovalType {
  Blocked = 'BLOCKED',
  CohortEnded = 'COHORT_ENDED',
  Plagiarism = 'PLAGIARISM',
  Revoked = 'REVOKED',
  Transferred = 'TRANSFERRED',
  Withdrawal = 'WITHDRAWAL',
}

export type DeleteSeries = {
  seriesId: Scalars['UUID']['input'];
};

export type DeleteSeriesUser = {
  seriesId: Scalars['UUID']['input'];
  userKey: Scalars['String']['input'];
};

export type DistributeUsersInSeriesInput = {
  dryRun?: Scalars['Boolean']['input'];
  seriesIds: Array<Scalars['UUID']['input']>;
  userKeys: Array<Scalars['String']['input']>;
};

export type DistributeUsersSeriesSummary = {
  __typename?: 'DistributeUsersSeriesSummary';
  /** The total number of users in the series before distribution. */
  existingUserCount: Scalars['Int']['output'];
  maxSize: Scalars['Int']['output'];
  /** The users that were added to the series. */
  newSeriesUsers: Array<SeriesUser>;
  /** The total number of users in the series after distribution. */
  newUserCount: Scalars['Int']['output'];
  seriesId: Scalars['UUID']['output'];
};

export type ResizeSeriesInput = {
  dryRun?: Scalars['Boolean']['input'];
  resizeSeries: Array<ResizeSeries>;
};

export type ResizeSeries = {
  maxSize?: Scalars['Int']['input'];
  seriesId: Scalars['UUID']['input'];
};

export type ResizedSeriesResult = {
  __typename?: 'ResizedSeriesResult';
  dryRun: Scalars['Boolean']['output'];
  existingSeries: ResizedSeriesSummary;
  newSeries: Array<ResizedSeriesSummary>;
  seriesId: Scalars['UUID']['output'];
};

export type ResizedSeriesSummary = {
  __typename?: 'ResizedSeriesSummary';
  maxSize: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  numMeetings: Scalars['Int']['output'];
  numUsers: Scalars['Int']['output'];
  seriesId: Scalars['UUID']['output'];
};

export type NewSeriesSwitch = {
  cohortId: Scalars['UUID']['input'];
  destinationSeriesId: Scalars['UUID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  originSeriesId: Scalars['UUID']['input'];
  userKey: Scalars['String']['input'];
};

export type SeriesSwitch = {
  __typename?: 'SeriesSwitch';
  cohortId: Scalars['UUID']['output'];
  createdAt: Scalars['Time']['output'];
  destinationSeriesId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  originSeriesId: Scalars['UUID']['output'];
  userKey: Scalars['String']['output'];
};

export type TransferSeriesUserDetails = {
  cohortId: Scalars['UUID']['input'];
  destinationSeriesId: Scalars['UUID']['input'];
  originSeriesId: Scalars['UUID']['input'];
  userKey: Scalars['String']['input'];
};

export type UpdateCohort = {
  endDate?: InputMaybe<Scalars['Time']['input']>;
  goToMarket?: InputMaybe<GoToMarket>;
  id: Scalars['UUID']['input'];
  maxSeriesSwitches?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relevantEnrollmentsToAdd?: InputMaybe<Array<NewRelevantEnrollment>>;
  relevantEnrollmentsToRemove?: InputMaybe<Array<Scalars['UUID']['input']>>;
  showLeaderboard?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  status?: InputMaybe<CohortStatus>;
  type?: InputMaybe<CohortType>;
};

export type UpdateCohortUser = {
  bio?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  maxWeeklyMeetings?: InputMaybe<Scalars['Int']['input']>;
  rolesToAdd?: InputMaybe<Array<CohortUserRole>>;
  rolesToRemove?: InputMaybe<Array<CohortUserRole>>;
};

export type UpdateMeetingAttendance = {
  meetingId: Scalars['UUID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  status: MeetingAttendanceStatus;
  userKey: Scalars['String']['input'];
};

export type UpdateMeetingAttendanceRequest = {
  meetingId: Scalars['UUID']['input'];
  requestApproved: Scalars['Boolean']['input'];
  userKey: Scalars['String']['input'];
};

export type UpdateMeeting = {
  conferenceLink?: InputMaybe<Scalars['String']['input']>;
  endAt?: InputMaybe<Scalars['Time']['input']>;
  facilitatorUserKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['UUID']['input'];
  instructions?: InputMaybe<Scalars['String']['input']>;
  locales?: InputMaybe<Array<Scalars['String']['input']>>;
  maxSize?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['Time']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSeries = {
  assignmentType?: InputMaybe<SeriesAssignmentType>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  maxSize?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MeetingUserSearchParams = {
  meetingId?: InputMaybe<Scalars['UUID']['input']>;
  paginationParams?: InputMaybe<PaginationParams>;
  sortBy?: InputMaybe<MeetingUserSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  userKey?: InputMaybe<Scalars['String']['input']>;
};
