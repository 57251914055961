import { combineReducers } from 'redux';
import { actionTypes } from './audit-queue-actions';
import { makeAsyncReducerWithFulfilledAndRejected } from 'app/common/domains/async/async-reducer';

const reducer = combineReducers({
  fetchCompletedAudits: makeAsyncReducerWithFulfilledAndRejected(
    actionTypes.FETCH_COMPLETED_AUDITS_START,
    actionTypes.FETCH_COMPLETED_AUDITS_FULFILLED,
    actionTypes.FETCH_COMPLETED_AUDITS_REJECTED
  ),
});

export default reducer;
