import _ from 'lodash';
import { createSelector } from 'reselect';

const languageMap = {
  en: 'en-us',
  pt: 'pt-br',
  zh: 'zh-cn',
  de: 'de',
  ar: 'ar',
  es: 'es',
  ko: 'ko',
};

export const getSession = createSelector(
  (state) => state.session,
  (session) => session
);

export const getUserLanguages = createSelector([getSession], (session) => {
  return session.languages;
});

export const getUserApprovedLanguages = createSelector([getSession], (session) => {
  return session.approved_languages;
});

export const getUserAgreementVersion = createSelector([getSession], (session) => {
  return session.agreement_version;
});

export const selectReviewerId = createSelector(getSession, (session) => _.get(session, 'reviewsMe.id', null));

export const selectReviewerName = createSelector(getSession, (session) => _.get(session, 'reviewsMe.name', ''));

export const getPayoneerIntegration = createSelector([getSession], (session) => {
  const { payoneer_status } = session;
  return {
    status: payoneer_status,
  };
});

export const getMentorAccountStatus = createSelector([getSession], (session) => {
  return session.has_mentor_account;
});

export const getReviewerCertifiedStatus = createSelector([getSession], (session) =>
  _.get(session, 'reviewsMe.isCertified', false)
);

export const getApprovedServices = createSelector([getSession], (session) => {
  return session.services || {};
});

export const getCanJoinPlagiarismQueue = createSelector([getSession], (session) => {
  return _.get(session, 'reviewsMe.can_join_plagiarism_queue', false);
});

export const getEligibleProjectLanguages = createSelector([getUserApprovedLanguages], (userApprovedLanguages) => {
  let languages = userApprovedLanguages;

  if (languages) {
    languages = languages.reduce((accum, lang) => {
      const firstTwoLangChars = lang.substring(0, 2);
      if (languageMap.hasOwnProperty(firstTwoLangChars)) {
        accum.push(languageMap[firstTwoLangChars]);
      }
      return accum;
    }, []);
  }

  return _.uniq(languages);
});

export const selectCountryCode = (state) => _.get(state, 'session.country');

export const getIsBlocked = createSelector([getSession], (session) => session.reviewsMe && session.reviewsMe.blocked);

export const getIsGigApproved = createSelector(
  [getSession],
  (session) => session.reviewsMe && session.reviewsMe.gig_approved !== false
);

export const getHasAccess = createSelector(
  [getIsBlocked, getIsGigApproved],
  (isBlocked, isGigApproved) => !isBlocked && isGigApproved
);

export const getIsSessionLoading = (state) => state.session.loading;
