import React from 'react';
import { Select, SingleValue } from 'chakra-react-select';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { getChakraReactSelectStyles } from '@udacity/chakra-uds-theme';
import dayjs from 'dayjs';

import { Option } from 'app/helpers/form-helper';
import { useTranslation } from 'react-i18next';

const BLACKLISTED_TZ = ['UTC', 'Etc/UTC', 'Etc/Universal', 'Universal'];

function formatTimezone(tz: string, now: number) {
  return `${tz.replace(/_/g, ' ')} (${dayjs(now).tz(tz).format('h:mm A')})`;
}

export function getTimezones(now: number) {
  const timezoneNames = Intl.supportedValuesOf('timeZone');
  return timezoneNames
    .filter((tz) => !BLACKLISTED_TZ.includes(tz))
    .map((tz) => ({
      value: tz,
      label: formatTimezone(tz, now),
    }));
}

type TimezoneSelectorProps = {
  id?: string;
  value: string;
  onChange: ({ id, value }: { id: string; value: string | number }) => void;
};

export const TimezoneSelector: React.FC<TimezoneSelectorProps> = ({ id, value, onChange }) => {
  const { t } = useTranslation();
  const now = Date.now();
  const options = getTimezones(now);
  const currentValue = options.find((opt) => opt.value === value);

  const onTimezoneChange = (option: SingleValue<Option>) => {
    if (option) onChange({ id: id ? id : 'timezone', value: option.value });
  };

  return (
    <FormControl>
      <FormLabel mb={2}>{t('mentorProfile.timezone')}</FormLabel>
      <Select<Option>
        chakraStyles={getChakraReactSelectStyles()}
        id={id ? id : 'timezone'}
        options={options}
        value={currentValue}
        onChange={onTimezoneChange}
        isClearable={false}
      />
    </FormControl>
  );
};
