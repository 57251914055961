import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { Modal } from 'app/common/components/modal';
import { dateFormatter } from 'app/helpers/format-helper';
import { MeetingAttendanceRequest } from 'app/types/uconnect';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useJudgeAttendanceRequestMutation } from '../queries/enhanced';

type ExcusedAbsenceButtonProps = {
  attendanceRequest: MeetingAttendanceRequest | null | undefined;
  user: { firstName: string; lastName: string } | null | undefined;
};

export const ExcusedAbsenceButton: React.FC<ExcusedAbsenceButtonProps> = ({ attendanceRequest, user }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [judgeAttendanceRequest] = useJudgeAttendanceRequestMutation();

  if (!attendanceRequest) return null;

  async function handleApprove() {
    if (!attendanceRequest) return;

    const res = await judgeAttendanceRequest({
      judgement: {
        meetingId: attendanceRequest.meetingId,
        userKey: attendanceRequest.userKey,
        requestApproved: true,
      },
    });
  }

  async function handleDeny() {
    if (!attendanceRequest) return;

    const res = await judgeAttendanceRequest({
      judgement: {
        meetingId: attendanceRequest.meetingId,
        userKey: attendanceRequest.userKey,
        requestApproved: false,
      },
    });
  }

  return (
    <div>
      <Button onClick={onOpen}>
        {attendanceRequest?.judgeUserKey
          ? attendanceRequest.requestApproved
            ? t('connect.excusedAbsenceApproved')
            : t('connect.excusedAbsenceDenied')
          : t('connect.excusedAbsenceRequested')}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} title={t('connect.excusedAbsenceRequested')}>
        <Text mb={4}>
          {attendanceRequest.note
            ? t('connect.excuseRequestExplanationWithReason', {
                date: dateFormatter.short(attendanceRequest.createdAt),
                firstName: user?.firstName,
                lastName: user?.lastName,
              })
            : t('connect.excuseRequestExplanation', {
                date: dateFormatter.short(attendanceRequest.createdAt),
                firstName: user?.firstName,
                lastName: user?.lastName,
              })}
        </Text>
        <Box bgColor="gray.100" p={4} mb={4}>
          {attendanceRequest.note}
        </Box>
        {!attendanceRequest?.judgeUserKey && <Text mb={4}>{t('connect.excuseRequestConsequences')}</Text>}
        {attendanceRequest?.requestApproved && (
          <Text mb={4}>
            {t('connect.requestApprovedOn', {
              date: dateFormatter.short(attendanceRequest.updatedAt),
            })}
          </Text>
        )}
        {attendanceRequest?.judgeUserKey && !attendanceRequest.requestApproved && (
          <Text mb={4}>
            {t('connect.requestDeniedOn', {
              date: dateFormatter.short(attendanceRequest.updatedAt),
            })}
          </Text>
        )}
        {!attendanceRequest?.judgeUserKey ? (
          <Flex gap={4}>
            <Button onClick={handleApprove}>{t('connect.approveButton')}</Button>
            <Button onClick={handleDeny} colorScheme="red">
              {t('connect.denyButton')}
            </Button>
            <Button onClick={onClose} variant="ghost">
              {t('connect.cancelExcuseButton')}
            </Button>
          </Flex>
        ) : (
          <Button onClick={onClose}>{t('common.ok')}</Button>
        )}
      </Modal>
    </div>
  );
};
