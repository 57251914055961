import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import GroupingsSidebar from 'app/common/components/groupings-sidebar/sidebar';
import { OverviewBanner } from 'app/common/components/styled-components';
import styles from './guidelines-layout.module.scss';

interface Props {
  sidebar: React.ReactNode;
  header: string;
}

export const GuidelinesLayout: React.FC<Props> = ({ children, sidebar, header }) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles.container}>
      <GroupingsSidebar />
      {sidebar}
      <div ref={scrollRef} className={styles.content}>
        <div className={styles.sticky}>
          <OverviewBanner>
            <div className={styles.bannerContent}>
              <h1 className={styles.title}>{header}</h1>
            </div>
          </OverviewBanner>
        </div>
        {children}
      </div>
    </div>
  );
};

export default GuidelinesLayout;
