import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { Box, Text } from '@chakra-ui/react';
import { useDebouncedCallback, useResizeObserver } from '@react-hookz/web';

export function ClampedText({
  children,
  buttonSlot,
  numberOfLines = 2,
  color = 'black',
}: {
  children: ReactNode;
  buttonSlot?: ReactNode;
  numberOfLines?: number;
  color?: string;
}) {
  const textRef = useRef<HTMLDivElement>(null);
  const [showFullText, setShowFullText] = useState(false);
  const [isClamped, setIsClamped] = useState(false);

  function calculateClamped() {
    const scrollHeight = textRef.current?.scrollHeight;
    const clientHeight = textRef.current?.clientHeight;

    if (!scrollHeight || !clientHeight) return;

    setIsClamped(scrollHeight > clientHeight);
  }

  const cb = useDebouncedCallback(calculateClamped, [], 500);
  useResizeObserver(textRef, cb);

  useEffect(() => {
    calculateClamped();
  }, []);

  return (
    <>
      <Text ref={textRef} color={color} noOfLines={showFullText ? undefined : numberOfLines}>
        {children}
      </Text>

      {isClamped && !showFullText && (
        <Box as="button" onClick={() => setShowFullText(true)}>
          {buttonSlot}
        </Box>
      )}
    </>
  );
}
