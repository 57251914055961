import React from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Flex, GridItem, Heading, Text } from '@chakra-ui/react';

import { useFetchRubricQuery } from 'app/apis/reviews';

import { RubricItem } from './rubric-item';

type RubricProps = {
  rubricId: number | null;
};

export const Rubric: React.FC<RubricProps> = ({ rubricId }) => {
  const { data: rubric } = useFetchRubricQuery(rubricId ? rubricId : skipToken);

  const { t } = useTranslation();

  if (!rubric) return null;

  return (
    <GridItem gridColumn={{ base: '3 / -1', lg: '4 / -2' }}>
      <Heading size="h1" as="h1" mb={6}>
        {t('projectReviews.rubric.rubric')}
      </Heading>
      <Text mb={10}>{t('projectReviews.rubric.useAsAGuide')}</Text>

      {rubric.sections.map((s, ind) => (
        <Flex key={ind} direction="column" gap={4} mb={10}>
          <Heading size="h4" as="h4">
            {s.name}
          </Heading>

          {s.rubricItems.map((r, i) => (
            <RubricItem key={i} item={r} />
          ))}
        </Flex>
      ))}
    </GridItem>
  );
};
