import React, { ReactNode } from 'react';
import { Flex, Skeleton, Table } from '@chakra-ui/react';

import { TableBody } from './table-body';
import { TableFilters } from './table-filters';
import { TableHead } from './table-head';
import { TablePagination } from './table-pagination';
import { CheckboxConfig, DataTableColumn, DataTableRow, Filter, PaginationData } from './types';
import { getTableState, getVisibleColumns, getVisibleData } from './utils';
import { useHistory } from 'react-router';

type Props<T extends DataTableRow> = {
  id: string;
  columns: DataTableColumn<T>[];
  data: T[];
  isLoading?: boolean;
  paginationData?: PaginationData;
  checkboxConfig?: CheckboxConfig;
  searchPlaceholder?: string;
  filters?: Filter[];
  cta?: ReactNode;
  usesInternalState?: boolean;
  disablePagination?: boolean;
  allowColumnCustomization?: boolean;
};

const DEFAULT_PAGE_SIZE = 10;

const sxProp = { tableLayout: 'fixed' };

export function DataTable<T extends DataTableRow>(props: Props<T>): JSX.Element {
  const {
    id,
    data,
    columns,
    paginationData,
    checkboxConfig,
    isLoading,
    searchPlaceholder,
    filters,
    disablePagination,
    cta,
  } = props;
  const defaultPageSize = disablePagination ? 100000 : paginationData?.defaultPageSize || DEFAULT_PAGE_SIZE;

  const { tableColumns } = getVisibleColumns(columns, data, id, checkboxConfig);

  const history = useHistory();
  const { pageSize, pageIndex, order, orderBy, handleChangePageSize, handleGoToPage, handleSort } = getTableState({
    defaultPageSize,
    history,
    paginationSuffix: paginationData?.suffix || '',
  });

  const visibleData = getVisibleData({
    data,
    isApiPaginated: Boolean(paginationData?.isApiPaginated),
    pageSize,
    pageIndex,
    orderBy,
    order,
    columns,
  });

  return (
    <>
      <Flex justifyContent="space-between">
        <TableFilters placeholder={searchPlaceholder} filters={filters} cta={cta} />
      </Flex>
      <Skeleton isLoaded={!isLoading}>
        <Table size="sm" sx={sxProp} backgroundColor="white">
          <TableHead columns={tableColumns} currentOrder={order} currentOrderBy={orderBy} onSort={handleSort} />
          <TableBody columns={tableColumns} data={visibleData} />
        </Table>
      </Skeleton>
      {!disablePagination && (
        <TablePagination
          pageSize={pageSize}
          currentPage={pageIndex + 1}
          totalRows={paginationData?.total || data.length}
          onChangePageSize={handleChangePageSize}
          onGoToPage={handleGoToPage}
        />
      )}
    </>
  );
}
