import _isEmpty from 'lodash/isEmpty';
import _trim from 'lodash/trim';
import { FormFields } from './form-types';

export const HOURS = {
  specialized: 20,
  default: 15,
};

export const MAX_CHARS = {
  textarea: 350,
  input: 150,
};

export const REQUIRED_FIELDS = {
  standard: [
    'agreement_version',
    'avatar_url',
    'bio',
    'available_hours',
    'languages',
    'linkedin_url',
    'phone_number',
    'timezone',
  ],
  specialized: [
    'gov_mentor_agreement_version',
    'avatar_url',
    'bio',
    'available_hours',
    'languages',
    'linkedin_url',
    'phone_number',
    'timezone',
    'roles',
  ],
  update: ['avatar_url', 'bio', 'linkedin_url', 'phone_number', 'timezone'],
  abbreviatedSpecialized: ['gov_mentor_agreement_version', 'languages', 'available_hours', 'roles'],
};

export const isValid = (fields: FormFields, requiredFields?: string[]): boolean => {
  if (!requiredFields) {
    return Object.keys(fields).every((f) => !_isEmpty(_trim(fields[f])));
  }
  return requiredFields.every((f) => !_isEmpty(_trim(fields[f])));
};

export const getInvalidFields = (fields: FormFields, requiredFields?: string[]): string[] => {
  if (!requiredFields) {
    return Object.keys(fields).filter((f) => _isEmpty(_trim(fields[f])));
  }
  return requiredFields.filter((f) => _isEmpty(_trim(fields[f])));
};
