import React from 'react';
import { Button, Flex, Th, Thead, Tr } from '@chakra-ui/react';
import { CaretDownIcon, CaretUpIcon } from '@udacity/chakra-uds-icons';

import { ASC, DataTableColumn, DESC, Orders } from './types';

type Props<T> = {
  columns: DataTableColumn<T>[];
  currentOrder: Orders;
  currentOrderBy: string | undefined;
  onSort: (newOrderBy: string, newOrder: Orders) => void;
};

export function TableHead<T>({ columns, currentOrder, currentOrderBy, onSort }: Props<T>) {
  const handleSort = (orderBy: string) => () => {
    const newOrder = currentOrder === ASC ? DESC : ASC;

    onSort(orderBy, newOrder);
  };

  return (
    <Thead>
      <Tr>
        {columns.map((col) => {
          const isActivelySorting = currentOrderBy === col.id;
          const Caret = currentOrder === DESC && isActivelySorting ? CaretUpIcon : CaretDownIcon;

          return (
            <Th key={col.id} w={col.width} bg="accent.sky.100">
              <Flex alignItems="center">
                {col.title}
                {!col.disableSorting && (
                  <Button
                    variant="ghost"
                    size="xs"
                    ml={2}
                    onClick={handleSort(col.paginationParam || col.id)}
                    aria-label={`Sort by ${col.title}`}
                  >
                    <Caret color={isActivelySorting ? 'blue.600' : 'gray.300'} h={6} w={6} />
                  </Button>
                )}
              </Flex>
            </Th>
          );
        })}
      </Tr>
    </Thead>
  );
}
