import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Spinner } from '@chakra-ui/react';
import _toPairs from 'lodash/toPairs';

import {
  getMentorAccountStatus,
  getApprovedServices,
  selectReviewerId,
  selectCountryCode,
} from 'app/common/domains/session/session-selectors';
import { WelcomeNewUser } from 'app/queue/components/welcome-new-user';

import { ActiveCertifications } from './active-certifications';
import { ActiveOpportunities } from './active-opportunities';
import styles from './certifications.module.scss';
import { useFetchCertificationsQuery, useFetchOpportunitiesQuery } from 'app/apis/reviews';
import { getCompletedCertifications } from 'app/queue/domains/certifications/certifications-helpers';

export const Certifications: React.FC = () => {
  const { data: certifications, isLoading: isLoadingCertifications } = useFetchCertificationsQuery();
  const completedCertifications = getCompletedCertifications(certifications);

  const hasMentorAccount = useSelector((state) => getMentorAccountStatus(state));

  const mentorCountryCode = useSelector<any, string>(selectCountryCode);
  const approvedServices = useSelector((state) => getApprovedServices(state));
  const reviewerId = useSelector((state) => selectReviewerId(state));

  const { t } = useTranslation();

  const { data: opportunities, isLoading: isLoadingOpportunities } = useFetchOpportunitiesQuery();

  const history = useHistory();

  const navigateToSettings = () => {
    history.push('/update');
  };

  const opportunitiesFiltered = () => {
    const compCertProjectIDs = completedCertifications?.map((cert) => cert.project_id);

    // Remove opportunities for which the user is already certified.
    return (opportunities || []).filter((opp) => {
      return !compCertProjectIDs?.includes(opp.project_id);
    });
  };

  const accountInReview = _toPairs(approvedServices).every((pair) => pair[1] === false) && hasMentorAccount;

  return (
    <main>
      <header className={styles['title-bar']}>
        <h1>{t('queue.certifications')}</h1>

        <div className={styles['title-bar-actions']}>
          <Button onClick={navigateToSettings} variant="ghost">
            {t('queue.programPreferences')}
          </Button>
        </div>
      </header>

      {isLoadingCertifications || isLoadingOpportunities ? (
        <div className={styles['loading']}>
          <div>
            <Spinner />
          </div>
        </div>
      ) : (
        <div className={styles.content}>
          {accountInReview && <WelcomeNewUser />}
          <ActiveOpportunities
            opportunities={opportunitiesFiltered()}
            reviewerId={reviewerId}
            mentorCountryCode={mentorCountryCode}
          />

          {!!completedCertifications?.length && (
            <ActiveCertifications
              completedCertifications={completedCertifications}
              mentorCountryCode={mentorCountryCode}
            />
          )}
        </div>
      )}
    </main>
  );
};
