export enum EditorType {
  Standard,
  Update,
  Specialized,
  AbbreviatedSpecialized,
}

// TODO: Fix FormFields
// Make BaseFormFields and <EditorType>FormFields (which should extend BaseFormFields)
export interface FormFields {
  uid: string;
  bio: string;
  phone_number: string;
  timezone: string;
  languages?: string[];
  avatar_url?: string;
  educational_background?: string;
  linkedin_url: string;
  github_url?: string;
  agreement_version?: string;
  available_hours?: number;
  roles?: string[];
  gov_mentor_agreement_version?: string;
}

export interface FormEvent {
  id: string;
  value: string | any[];
}

export type Event =
  | FormEvent
  | React.ChangeEvent<HTMLFormElement>
  | React.ChangeEvent<HTMLInputElement>
  | React.ChangeEvent<HTMLTextAreaElement>;
