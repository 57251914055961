import React from 'react';
import { MultiValue, Select, SingleValue } from 'chakra-react-select';
import { useHistory } from 'react-router';
import { Option } from 'app/helpers/form-helper';
import { Filter, PaginationQueryParams } from '../types';
import { getFilterParam } from './utils';
import { getQueryParams, updateQueryParams } from '../utils';
import { getChakraReactSelectStyles } from '@udacity/chakra-uds-theme';
import { Box } from '@chakra-ui/react';

type Props = { filter: Filter };

export const FilterDropdown: React.FC<Props> = ({ filter }) => {
  const history = useHistory();
  const params = getQueryParams();

  const updateFilter =
    (filterId: string, multi?: boolean) => (option: SingleValue<Option> | MultiValue<Option> | null) => {
      const params: Record<string, string | false> = {
        [PaginationQueryParams.Page]: false,
      };

      params[filterId] = getFilterParam(option, multi);

      updateQueryParams(history, params);
    };

  const dropdownProps = {
    id: `dropdown-${filter.id}`,
    label: filter.label,
    hiddenLabel: true,
    options: filter.options || [],
    onChange: updateFilter(filter.id, filter.multi),
    placeholder: filter.label,
  };

  const value = filter.multi
    ? dropdownProps.options.filter((opt) =>
        ((params[filter.id] as string)?.split(',') || filter.defaultValue).includes(opt.value)
      )
    : dropdownProps.options.find((opt) => ((params[filter.id] as string) || filter.defaultValue) === opt.value);
  return (
    <Box w={filter.minWidth}>
      {filter.multi ? (
        <Select<Option> chakraStyles={getChakraReactSelectStyles()} {...dropdownProps} value={value as Option[]} />
      ) : (
        <Select<Option> chakraStyles={getChakraReactSelectStyles()} {...dropdownProps} value={value} />
      )}
    </Box>
  );
};
