import React from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import GuidelinesLayout from 'app/common/components/guidelines-layout';
import { GuidelinePage } from 'app/common/components/guideline-page/index';

import careerUrl from './content/career.md';
import generalUrl from './content/general.md';
import guruUrl from './content/guru.md';
import moderatorUrl from './content/moderator.md';
import sessionLeadUrl from './content/session-lead.md';
import tutorUrl from './content/tutor.md';
import guidelinesHelper from './guidelines-helper';
import { Sidebar } from './sidebar';

export const GuidelinesContainer: React.FC = () => {
  const { pathname } = useLocation();
  const match = useRouteMatch();

  const parsedPathname = guidelinesHelper.parsePathname(pathname);
  const header = guidelinesHelper.getHeader(parsedPathname);

  return (
    <GuidelinesLayout header={header} sidebar={<Sidebar pathname={pathname} />}>
      <Switch>
        <Route exact path={`${match.path}`}>
          <Redirect to={`${match.path}/general`} />
        </Route>
        <Route path={`${match.path}/general`}>
          <GuidelinePage contentUrl={generalUrl} />
        </Route>
        <Route path={`${match.path}/career`}>
          <GuidelinePage contentUrl={careerUrl} />
        </Route>
        <Route path={`${match.path}/guru`}>
          <GuidelinePage contentUrl={guruUrl} />
        </Route>
        <Route path={`${match.path}/moderator`}>
          <GuidelinePage contentUrl={moderatorUrl} />
        </Route>
        <Route path={`${match.path}/session-lead`}>
          <GuidelinePage contentUrl={sessionLeadUrl} />
        </Route>
        <Route path={`${match.path}/tutor`}>
          <GuidelinePage contentUrl={tutorUrl} />
        </Route>
        <Route path={`${match.path}/*`}>
          <Redirect to={`${match.path}/general`} />
        </Route>
      </Switch>
    </GuidelinesLayout>
  );
};
