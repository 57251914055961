import auth from '@udacity/ureact-hoth';
import { datadogRum } from '@datadog/browser-rum';

import CONFIG from 'configLoader';

export const initDatadog = () => {
  if (CONFIG.datadogEnabled === 'true') {
    datadogRum.init({
      applicationId: '02c19e11-2f52-4f55-bd4f-673c017835f0',
      clientToken: 'pub3b0d1467eda016e5eb1013ff325981f1',
      site: 'datadoghq.com',
      service: 'mentor-dashboard',
      env: process.env.REACT_APP_ENV,
      version: '1.0.0',
      sessionSampleRate: 20,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.setUser({ id: auth.getCurrentUserId() });

    datadogRum.startSessionReplayRecording();
  }
};
