import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from '@udacity/ureact-data-table';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';

import { formatAlternatePrice, formatPrice } from 'app/helpers/format-helper';
import constants from 'app/queue/domains/certifications/certifications-constants';
import { Certification } from 'app/types/reviews';

import styles from './active-certifications.module.scss';
import { Link as ChakraLink, Text } from '@chakra-ui/react';
import { DownloadIcon } from '@udacity/chakra-uds-icons';
import { useFetchReviewerToolkitsQuery } from 'app/apis/reviews';

const { DATE_FORMAT } = constants;

export const getCompletedCertificationsTableData = (certifications, mentorCountryCode) => {
  const sortedCertifications = _sortBy(certifications, (cert) => {
    return _get(cert, 'project.name', '');
  });

  return sortedCertifications.map((cert) => {
    const {
      certified_at,
      project: { name, price, exchange_currency, exchange_currency_price, exchange_currency_country },
    } = cert;
    return {
      'Project Name': {
        data: name,
      },
      Earn: {
        data:
          exchange_currency_price &&
          exchange_currency_price !== '0' &&
          exchange_currency_country &&
          mentorCountryCode &&
          mentorCountryCode.toLowerCase() === exchange_currency_country.toLowerCase()
            ? formatPrice(price) + ' ' + formatAlternatePrice(exchange_currency ?? '', exchange_currency_price ?? '0')
            : formatPrice(price),
      },
      Certified: {
        data: dayjs(certified_at).format(DATE_FORMAT),
      },
      'Toolkit*': {
        data: <ToolkitDownload projectId={cert.project_id} />,
      },
    };
  });
};

export const sortComparator = (sortColumn, row) => {
  const data = _get(row, [sortColumn, 'data']);
  if (sortColumn === 'Earn') {
    const price = parseFloat(data.replace(/\D/g, ''));
    return price;
  } else if (sortColumn === 'Certified') {
    const date = dayjs(data, DATE_FORMAT);
    return date.format();
  }
  return data;
};

export const ActiveCertifications: React.FC<{
  completedCertifications: Certification[];
  mentorCountryCode: string;
}> = ({ completedCertifications, mentorCountryCode }) => {
  const { t } = useTranslation();
  const completedCertificationsTableData = getCompletedCertificationsTableData(
    completedCertifications,
    mentorCountryCode
  );

  return (
    <section className={styles['active-certifications']}>
      <h3>{t('queue.certificationsColon')}</h3>
      <DataTable
        rows={completedCertificationsTableData}
        data-tst="certifications-table"
        sortComparator={sortComparator}
      />
      <Text my={4} color="gray.700">
        * {t('projectReviews.resources.mentorToolkitExplained')}
      </Text>
    </section>
  );
};

export const ToolkitDownload: React.FC<{
  projectId: number;
}> = ({ projectId }) => {
  const { t, i18n } = useTranslation();
  const { data } = useFetchReviewerToolkitsQuery({
    projectId,
    language: i18n.language.toLowerCase(),
  });
  const archiveUrl = data?.[0]?.archiveUrl;
  if (!archiveUrl) return null;

  return (
    <ChakraLink download href={archiveUrl} title={t('projectReviews.resources.downloadToolkit')}>
      <DownloadIcon h={6} w={6} />{' '}
    </ChakraLink>
  );
};
