import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import { SheetCheckedIcon } from '@udacity/chakra-uds-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { formatPrice } from 'app/helpers/format-helper';
import RaisedGlyph from 'app/queue/components/raised-glyph';
import ListItem from 'app/queue/components/list-item';
import { AssignedAudit } from 'app/queue/domains/audit-queue/audit-queue-types';

import styles from './assigned-audit-list-item.module.scss';

type AssignedAuditListItemProps = {
  audit: AssignedAudit;
};

export const AssignedAuditListItem: React.FC<AssignedAuditListItemProps> = ({ audit }) => {
  const { t } = useTranslation();

  const expiresAt = useMemo(() => dayjs(audit.assignedAt).add(8, 'h').valueOf(), [audit]);
  const isExpired = dayjs().isAfter(expiresAt);
  const isCloseToExpiring = dayjs().add(3, 'h').isAfter(expiresAt);
  const timeRemaining = isExpired ? t('common.expired') : dayjs(expiresAt).fromNow(true);

  const listItemClass = classNames(styles['assigned-audit-list-item'], {
    [styles['expired-audit']]: isExpired,
  });

  const completeInClass = classNames({
    [styles['complete-in-expiring']]: isCloseToExpiring,
  });

  return (
    <ListItem className={listItemClass}>
      <div className={styles['glyph']}>
        <RaisedGlyph icon={<SheetCheckedIcon w={8} h={8} />} />
      </div>

      <div data-tst="title" className={styles.title}>
        <p>{audit.project.name}</p>
      </div>

      <div className={styles['cell-group']}>
        <div data-tst="audit-price" className={styles['price']}>
          <label>{t('common.earn')}</label>
          <p>{formatPrice(audit.price)}</p>
        </div>

        <div data-tst="complete-in" className={completeInClass}>
          <label>{t('queue.reassignedIn')}</label>
          <p>{timeRemaining}</p>
        </div>
      </div>

      <div className={styles['actions']}>
        {!isExpired && (
          <Button as="a" href={audit.url} variant="outline" size="sm" target="_blank" rel="noopener noreferrer">
            {t('common.audit')}
          </Button>
        )}
      </div>
    </ListItem>
  );
};
