import { createSelector } from 'reselect';

import { AuditQueue, AuditRequest, AssignedAudit, CompletedAudit } from './audit-queue-types';

const selectAuditQueue = (state: any): AuditQueue => state.audits.queue;

export const selectPendingAuditRequests = createSelector(
  selectAuditQueue,
  (queue: AuditQueue): AuditRequest[] => queue.pendingAuditRequests
);

export const selectAuditQueueEnabled = createSelector(
  selectPendingAuditRequests,
  (requests: AuditRequest[]): boolean => {
    return requests.length > 0;
  }
);

export const selectAuditQueueExpiration = createSelector(
  selectPendingAuditRequests,
  (requests: AuditRequest[]): number => {
    return requests.reduce((maxClosedAt, r) => Math.max(maxClosedAt, r.closedAt), 0);
  }
);

export const selectProjectsToAudit = createSelector(
  selectAuditQueue,
  (queue: AuditQueue): { [id: number]: boolean } => queue.projectsToAudit
);

export const selectAssignedAudits = createSelector(
  selectAuditQueue,
  (queue: AuditQueue): AssignedAudit[] => queue.assignedAudits
);

export const selectCompletedAudits = createSelector(
  selectAuditQueue,
  (queue: AuditQueue): CompletedAudit[] => queue.completedAudits
);

export const selectTotalCompletedAudits = createSelector(
  selectAuditQueue,
  (queue: AuditQueue): number | undefined => queue.totalCompletedAudits
);

export const selectAuditQueueAsync = (state: any) => state.audits.queueAsync;

export const selectFetchCompletedAuditsAsync = createSelector(
  selectAuditQueueAsync,
  (queueAsync) => queueAsync.fetchCompletedAudits
);
