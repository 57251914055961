/**
 * Properly comma-ize a list of things. Includes Oxford comma.
 * eg. listize(['foo', 'bar', 'baz']) === 'foo, bar, and baz'
 *
 * @param {Array<String>} words
 * @returns {String}
 */
export function listize(words: string[]): string {
  const length = words.length;
  if (!length) {
    return '';
  }
  if (length === 1) {
    return words[0];
  }

  // there are 2+ words, so an 'and' is necessary before the last one
  words[length - 1] = `and ${words[length - 1]}`;

  if (length < 3) {
    return words.join(' ');
  }

  // commas before everything!
  return words.join(', ');
}

/**
 * Make a space separated first name and last name.
 *
 * @param {studentDetailPropType} student
 * @returns {String}
 */
export function makeUserName(student: { first_name: string; last_name: string } | undefined | null): string {
  if (!student) {
    return '';
  }

  const { first_name = '', last_name = '' } = student;

  return `${first_name} ${last_name}`.trim();
}

/**
 * Copy text to clipboard
 * Note: only works on https.
 *
 * @param {String} value
 * @returns {Promise<void>}
 */
export function copyToClipboard(value: string): Promise<void> {
  return navigator.clipboard.writeText(value);
}
