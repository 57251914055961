import { Box, Button, Heading, Text, UnorderedList, useDisclosure } from '@chakra-ui/react';
import { AddIcon } from '@udacity/chakra-uds-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FeedbackTemplateFormModal } from './feedback-template-form-modal';
import { TemplateListItem } from './template-list-item';
import { useFeedbackTemplates } from './use-feedback-templates';

type FeedbackTemplateContainerProps = {
  currentValue: string;
  meetsRequirements?: boolean;
  rubricItemId?: number;
  showAll?: boolean;
  onApplyTemplate: (value: string) => void;
};

export const FeedbackTemplateContainer: React.FC<FeedbackTemplateContainerProps> = ({
  rubricItemId,
  meetsRequirements,
  currentValue,
  showAll,
  onApplyTemplate,
}) => {
  const { feedbackTemplates } = useFeedbackTemplates({
    rubricItemId,
    meetsRequirements,
  });
  const { t } = useTranslation();
  const { onClose, onOpen, isOpen } = useDisclosure();

  function handleApplyTemplate(text: string) {
    const resultingText = [currentValue, text].join('\n\n');

    onApplyTemplate(resultingText);
  }

  if (!showAll && meetsRequirements === undefined) return null;
  const shouldScroll = feedbackTemplates.length > 3;

  return (
    <Box mb={6} bg="gray.50" p={4}>
      <Heading as="h5" size="h5">
        {t('projectReviews.template.feedbackTemplates')}
      </Heading>
      <Text mb={4}>{t('projectReviews.template.explanation')}</Text>
      {feedbackTemplates.length > 0 && (
        <UnorderedList
          margin="1rem 0"
          listStyleType="none"
          padding={0}
          height={shouldScroll ? '15rem' : undefined}
          overflowY={shouldScroll ? 'scroll' : undefined}
        >
          {feedbackTemplates.map((template) => (
            <TemplateListItem key={template.id} template={template} onApply={handleApplyTemplate} />
          ))}
        </UnorderedList>
      )}
      <Button variant="outline" onClick={onOpen} leftIcon={<AddIcon h={6} w={6} />} bg="white">
        {t('projectReviews.template.create')}
      </Button>
      <FeedbackTemplateFormModal
        isOpen={isOpen}
        rubricItemId={rubricItemId}
        onClose={onClose}
        meetsRequirements={meetsRequirements}
      />
    </Box>
  );
};
