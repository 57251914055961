import { takeEvery, all, call, put } from 'redux-saga/effects';
import { actionTypes } from './reviewer-data-actions';
import { getReviewerEarnings, getReviewerRatingsMatrix } from 'app/services/reviews-api-service';

function* fetchEarnings(action) {
  const { end_date, start_date } = action.payload;
  try {
    const payload = yield call(getReviewerEarnings, start_date, end_date);
    yield put({ type: actionTypes.FETCH_EARNINGS_FULFILLED, payload });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_EARNINGS_FULFILLED, error });
  }
}

export function* watchFetchEarnings() {
  yield takeEvery(actionTypes.FETCH_EARNINGS, fetchEarnings);
}

function* fetchRatingsMatrix(action) {
  const { end_date, start_date } = action.payload;
  try {
    const payload = yield call(getReviewerRatingsMatrix, start_date, end_date);
    yield put({ type: actionTypes.FETCH_RATINGS_MATRIX_FULFILLED, payload });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_RATINGS_MATRIX_FULFILLED, error });
  }
}

export function* watchFetchRatingsMatrix() {
  yield takeEvery(actionTypes.FETCH_RATINGS_MATRIX, fetchRatingsMatrix);
}

export default function* reviewerDataSaga() {
  yield all([watchFetchEarnings(), watchFetchRatingsMatrix()]);
}
