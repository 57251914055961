import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Flex, Heading, HStack, Image, InputGroup, Link, Text, VStack } from '@chakra-ui/react';
import { CheckedIcon } from '@udacity/chakra-uds-icons';

import { UDS_GLYPH_URL } from 'app/apis/service-paths';
import { AsymmetricCard } from 'app/common/components/asymmetric-card';

import { GuideStep } from './guide-step';

type AnnotationsPanelProps = {
  annotationsUrl: string | null;
  file?: File | null;
  handleFile?: (file: File | null | undefined) => void;
  isAssigned: boolean;
};

export const AnnotationsPanel: React.FC<AnnotationsPanelProps> = ({ annotationsUrl, file, handleFile, isAssigned }) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const guideSteps: { stepNumber: number; description: string }[] = [
    {
      stepNumber: 1,
      description: t('projectReviews.annotations.downloadStudentSubmission'),
    },
    {
      stepNumber: 2,
      description: (
        <Trans
          i18nKey="projectReviews.annotations.importStudentSubmission"
          components={{
            kami_link: <Link href="https://web.kamihq.com/" isExternal />,
            bold: <strong />,
          }}
        />
      ),
    },
    {
      stepNumber: 3,
      description: t('projectReviews.annotations.exportAnnotatedFile'),
    },
    {
      stepNumber: 4,
      description: t('projectReviews.annotations.uploadAnnotatedPDF'),
    },
  ];

  return (
    <Flex direction="column">
      {annotationsUrl && isAssigned && (
        <VStack spacing={2} align="start" mb={10}>
          <Text>{t('projectReviews.annotations.annotationUploaded')}</Text>
          <Button variant="outline" as="a" href={annotationsUrl}>
            {t('projectReviews.annotations.downloadPDF')}
          </Button>
        </VStack>
      )}

      <Flex gap={2} mb={6}>
        <Image src={`${UDS_GLYPH_URL}/paper-curled-one.svg`} alt="" w={10} h={10} />
        <Heading size="h4" as="h4">
          {t('projectReviews.annotations.submitAnnotatedPDF')}
        </Heading>
      </Flex>

      {!isAssigned && annotationsUrl && (
        <AsymmetricCard direction="column" bgColor="white" w={{ base: 'full', lg: '78%' }} mb={10}>
          <HStack spacing={2} mb={2}>
            <CheckedIcon color="feedback.positive" w={8} h={8} />
            <Text>{annotationsUrl?.split('/').pop()}</Text>
          </HStack>

          <Button variant="outline" w="max-content" as="a" href={annotationsUrl}>
            {t('common.download')}
          </Button>
        </AsymmetricCard>
      )}

      {!file && handleFile && isAssigned && (
        <Flex
          direction="column"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="base"
          bgColor="white"
          p={6}
          mb={10}
          w={{ base: 'full', lg: '78%' }}
        >
          <Text size="label">{t('projectReviews.annotations.uploadPDF')}</Text>
          <Text mb={4}>{t('projectReviews.annotations.ifMultipleAnnotated')}</Text>

          <InputGroup
            onClick={() => {
              fileInputRef.current?.click();
            }}
          >
            <input
              id="file"
              data-testid="file-input"
              ref={fileInputRef}
              type="file"
              accept="application/pdf"
              onChange={(e) => handleFile(e.target.files?.[0])}
              hidden
            />
            <Button w="max-content">{t('projectReviews.annotations.chooseFile')}</Button>
          </InputGroup>
        </Flex>
      )}

      {file && handleFile && (
        <AsymmetricCard direction="column" bgColor="white" w={{ base: 'full', lg: '78%' }} mb={10}>
          <HStack spacing={2}>
            <CheckedIcon color="feedback.positive" w={8} h={8} />
            <Text>{file.name}</Text>
          </HStack>

          <Button variant="link" w="max-content" textDecor="underline" onClick={() => handleFile(null)}>
            {t('common.delete')}
          </Button>
        </AsymmetricCard>
      )}

      <Flex
        direction="column"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="base"
        bgColor="white"
        p={10}
        gap={6}
      >
        <Heading size="h4" as="h4">
          {t('projectReviews.annotations.annotationGuide')}
        </Heading>

        {guideSteps.map((gs) => (
          <GuideStep key={gs.stepNumber} stepNumber={gs.stepNumber} description={gs.description} />
        ))}
      </Flex>
    </Flex>
  );
};
