import React, { useState } from 'react';
import _get from 'lodash/get';

import { Modal } from 'app/common/components/modal';
import plagiarismCaseQueueActions from 'app/queue/domains/plagiarism-case-queue/plagiarism-case-queue-actions';
import { getCompletedPlagiarismCaseCertifications } from 'app/queue/domains/certifications/certifications-selectors';
import {
  selectPlagiarismCaseQueueEnabled,
  selectPlagiarismCaseQueueExpiration,
  selectProjectsEnabledInPlagiarismCaseQueue,
  selectAssignedPlagiarismCases,
} from 'app/queue/domains/plagiarism-case-queue/plagiarism-case-queue-selectors';

import { PlagiarismCaseQueueSettings } from './plagiarism-case-queue-settings';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { QueueControlRow } from '../queue-control-row';
import { useAppDispatch } from 'app/store';

export const PlagiarismQueue: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isPlagiarismCaseOptionsModalOpen, setPlagiarismCaseOptionsModalOpen] = useState(false);

  const plagiarismQueueEnabled = useSelector(selectPlagiarismCaseQueueEnabled);
  const plagiarismQueueExpiresAt = useSelector(selectPlagiarismCaseQueueExpiration);
  const projectsEnabledInQueue = useSelector(selectProjectsEnabledInPlagiarismCaseQueue);
  const assignedPlagiarismCases = useSelector(selectAssignedPlagiarismCases);
  const plagiarismCaseCertifications = useSelector(getCompletedPlagiarismCaseCertifications);

  const togglePlagiarismCaseQueue = () => {
    if (plagiarismQueueEnabled) {
      dispatch(plagiarismCaseQueueActions.leaveQueue());
    } else {
      setPlagiarismCaseOptionsModalOpen(true);
    }
  };

  const submitPlagiarismCaseQueueSettings = (projectIds: number[]) => {
    if (plagiarismQueueEnabled) {
      dispatch(plagiarismCaseQueueActions.updateRequests(projectIds));
    } else {
      dispatch(plagiarismCaseQueueActions.joinQueue(projectIds));
    }
    setPlagiarismCaseOptionsModalOpen(false);
  };

  const refreshPlagiarismCaseQueue = () => {
    dispatch(plagiarismCaseQueueActions.refreshRequests);
  };

  const disablePlagiarismCaseQueueButton = !plagiarismQueueEnabled && assignedPlagiarismCases.length > 0;

  return (
    <>
      <QueueControlRow
        name={t('common.plagiarismCases')}
        onOptionsClick={() => setPlagiarismCaseOptionsModalOpen(true)}
        onQueueClick={togglePlagiarismCaseQueue}
        onRefreshClick={refreshPlagiarismCaseQueue}
        isQueueEnabled={plagiarismQueueEnabled}
        expiration={plagiarismQueueExpiresAt}
        disableQueueButton={disablePlagiarismCaseQueueButton}
        disableQueueButtonTooltip={t('queue.overview.completeAssignedPlagiarismCase')}
      />

      <Modal
        ariaLabel={t('queue.overview.plagiarismCaseQueueSettings')}
        onClose={() => setPlagiarismCaseOptionsModalOpen(false)}
        isOpen={isPlagiarismCaseOptionsModalOpen}
      >
        <PlagiarismCaseQueueSettings
          isOptionsUpdate={plagiarismQueueEnabled}
          projectsEnabledInQueue={projectsEnabledInQueue}
          plagiarismCaseCertifications={plagiarismCaseCertifications}
          onSubmit={submitPlagiarismCaseQueueSettings}
        />
      </Modal>
    </>
  );
};
