import React, { PropsWithChildren } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

const baseCardStyles = {
  bg: 'white',
  border: 'solid 1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
};

export const UdsCard: React.FC<PropsWithChildren<BoxProps>> = (props) => {
  const { children, ...boxProps } = props;

  return (
    <Box {...baseCardStyles} {...boxProps}>
      {children}
    </Box>
  );
};
