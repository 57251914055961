import React from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import _isNull from 'lodash/isNull';
import { Link } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { RequiredActionBanner } from 'app/common/components/required-action-banner';
import { getUserAgreementVersion, getPayoneerIntegration } from 'app/common/domains/session/session-selectors';
import { dayDiff } from 'app/queue/domains/opportunities/opportunities-helpers';

import CONFIG from 'configLoader';

const mapStateToProps = (state) => ({
  currentSignedAgreementVersion: getUserAgreementVersion(state),
  payoneer: getPayoneerIntegration(state),
});

export class RequiredActions extends React.Component {
  static propTypes = {
    // state
    currentSignedAgreementVersion: PropTypes.string.isRequired,
    payoneer: PropTypes.object.isRequired,
  };

  showTOSAlert() {
    const { currentSignedAgreementVersion } = this.props;
    const { latestAgreementVersion } = CONFIG;

    const tosRequirement = dayjs(latestAgreementVersion).startOf('day');
    const tosSigned = dayjs(currentSignedAgreementVersion).startOf('day');

    return tosRequirement.diff(tosSigned, 'days') > 0;
  }

  render() {
    const { payoneer, t } = this.props;

    const { agreementDeadline, payoneerSignupDeadline, payoneerLoginUrl } = CONFIG;

    const payoneerMessage =
      dayDiff(payoneerSignupDeadline) > 0 ? (
        <span>
          {t('mentorProfile.payoneer.signUpForPayoneerOrLink', {
            deadline: dayjs(payoneerSignupDeadline).format('MMMM Do'),
          })}
        </span>
      ) : (
        <span>
          <Trans i18nKey="mentorProfile.payoneer.payoneerIsOurThirdParty" components={{ bold: <strong /> }} />
        </span>
      );

    return (
      <div>
        {this.showTOSAlert() && (
          <RequiredActionBanner
            data-ref="reviewer-agreement"
            message={t('termsOfService.pleaseAgree', {
              deadline: dayjs(agreementDeadline).format('MMMM D'),
            })}
            link={
              <Link as={ReactRouterLink} to="/reviewer-agreement" fontSize="sm">
                {t('termsOfService.termsOfServiceAgreement')}
              </Link>
            }
          />
        )}
        {_isNull(payoneer.status) && (
          <RequiredActionBanner
            data-ref="payoneer-sign-up"
            message={payoneerMessage}
            link={
              <Link as={ReactRouterLink} to="/update" fontSize="sm">
                {t('mentorProfile.payoneer.updateAccountSettings')}
              </Link>
            }
          />
        )}
        {payoneer.status === 'INACTIVE' && (
          <RequiredActionBanner
            data-ref="payoneer-log-in"
            message={<span>{t('mentorProfile.payoneer.applicationPending')}</span>}
            link={
              <Link href={payoneerLoginUrl} isExternal fontSize="sm">
                {t('mentorProfile.payoneer.logIntoPayoneer')}
              </Link>
            }
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(connect(mapStateToProps, {})(RequiredActions));
