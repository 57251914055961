import { Box } from '@chakra-ui/react';
import { getChakraReactSelectStyles } from '@udacity/chakra-uds-theme';
import { Option } from 'app/helpers/form-helper';
import { Select } from 'chakra-react-select';
import React from 'react';

const PAGE_SIZE_OPTIONS = [
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
];
export const PageSizeSelector: React.FC<{
  pageSize: number;
  onChangePageSize: (newPageSize: number) => void;
}> = ({ pageSize, onChangePageSize }) => {
  const onChange = (option: Option | null) => {
    return onChangePageSize(Number(option?.value || pageSize));
  };

  const currentPageSize = PAGE_SIZE_OPTIONS.find((opt) => opt.value === String(pageSize));

  return (
    <Box width="6.15rem">
      <Select<Option>
        id="pagination-dropdown"
        chakraStyles={getChakraReactSelectStyles()}
        value={currentPageSize}
        onChange={onChange}
        isClearable={false}
        options={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
};
