import dayjs from 'dayjs';

import { createAction } from 'app/helpers/action-helper';

export const actionTypes = {
  BOOTSTRAP_QUESTION_QUEUE: 'question-queue:BOOTSTRAP_QUESTION_QUEUE',
  FETCH_QUESTION_QUEUE_START: 'question-queue:FETCH_QUESTION_QUEUE_START',
  FETCH_QUESTION_QUEUE_FULFILLED: 'question-queue:FETCH_QUESTION_QUEUE_FULFILLED',
  FETCH_QUESTION_QUEUE_REJECTED: 'question-queue:FETCH_QUESTION_QUEUE_REJECTED',
  FETCH_QUESTION_QUEUE_ASSIGNMENTS_START: 'question-queue:FETCH_QUESTION_QUEUE_ASSIGNMENTS_START',
  FETCH_QUESTION_QUEUE_ASSIGNMENTS_FULFILLED: 'question-queue:FETCH_QUESTION_QUEUE_ASSIGNMENTS_FULFILLED',
  FETCH_QUESTION_QUEUE_ASSIGNMENTS_REJECTED: 'question-queue:FETCH_QUESTION_QUEUE_ASSIGNMENTS_REJECTED',
  FETCH_QUESTION_QUEUE_HISTORY_START: 'question-queue:FETCH_QUESTION_QUEUE_HISTORY_START',
  FETCH_QUESTION_QUEUE_HISTORY_FULFILLED: 'question-queue:FETCH_QUESTION_QUEUE_HISTORY_FULFILLED',
  FETCH_QUESTION_QUEUE_HISTORY_REJECTED: 'question-queue:FETCH_QUESTION_QUEUE_HISTORY_REJECTED',
  CLEAR_QUESTION_QUEUE_HISTORY: 'question-queue:CLEAR_QUESTION_QUEUE_HISTORY',
  FETCH_MONTHLY_EARNINGS_SUMMARY_START: 'question-queue:FETCH_MONTHLY_EARNINGS_SUMMARY_START',
  FETCH_MONTHLY_EARNINGS_SUMMARY_FULFILLED: 'question-queue:FETCH_MONTHLY_EARNINGS_SUMMARY_FULFILLED',
  FETCH_MONTHLY_EARNINGS_SUMMARY_REJECTED: 'question-queue:FETCH_MONTHLY_EARNINGS_SUMMARY_REJECTED',
  JOIN_QUESTION_QUEUE_START: 'question-queue:JOIN_QUESTION_QUEUE_START',
  JOIN_QUESTION_QUEUE_FULFILLED: 'question-queue:JOIN_QUESTION_QUEUE_FULFILLED',
  JOIN_QUESTION_QUEUE_REJECTED: 'question-queue:JOIN_QUESTION_QUEUE_REJECTED',
  LEAVE_QUESTION_QUEUE_START: 'question-queue:LEAVE_QUESTION_QUEUE_START',
  LEAVE_QUESTION_QUEUE_FULFILLED: 'question-queue:LEAVE_QUESTION_QUEUE_FULFILLED',
  LEAVE_QUESTION_QUEUE_REJECTED: 'question-queue:LEAVE_QUESTION_QUEUE_REJECTED',
  QUESTION_QUEUE_EXPIRED: 'question-queue:QUESTION_QUEUE_EXPIRED',
};

export default {
  bootstrapQueue() {
    return createAction(actionTypes.BOOTSTRAP_QUESTION_QUEUE);
  },
  fetchQueue() {
    return createAction(actionTypes.FETCH_QUESTION_QUEUE_START);
  },
  fetchQueueAssignments() {
    return createAction(actionTypes.FETCH_QUESTION_QUEUE_ASSIGNMENTS_START);
  },
  fetchQueueHistory(count: number, afterCursor?: string) {
    return createAction(actionTypes.FETCH_QUESTION_QUEUE_HISTORY_START, {
      count,
      afterCursor,
    });
  },
  clearQueueHistory() {
    return createAction(actionTypes.CLEAR_QUESTION_QUEUE_HISTORY);
  },
  fetchMonthlyEarningsSummary() {
    const start = dayjs().utc().startOf('month').toISOString();
    return createAction(actionTypes.FETCH_MONTHLY_EARNINGS_SUMMARY_START, {
      start,
    });
  },
  joinQueue(projectIds: string[]) {
    return createAction(actionTypes.JOIN_QUESTION_QUEUE_START, projectIds);
  },
  leaveQueue() {
    return createAction(actionTypes.LEAVE_QUESTION_QUEUE_START);
  },
};
