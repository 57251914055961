import React from 'react';
import { useTranslation } from 'react-i18next';

import { SidebarList } from 'app/common/components/sidebar-list/sidebar-list';
import { SidebarRow } from 'app/common/components/sidebar-list/sidebar-row';
import { removeDanglingUrlSlash } from 'app/helpers/format-helper';

type SidebarProps = {
  pathname: string;
};

export const Sidebar: React.FC<SidebarProps> = ({ pathname }) => {
  const { t } = useTranslation();

  const isSelected = (componentName: string): boolean => {
    const parsedPathname = removeDanglingUrlSlash(pathname);
    if (componentName === 'general' && parsedPathname === '/guidelines') {
      return true;
    }
    return parsedPathname === `/guidelines/${componentName}`;
  };

  return (
    <SidebarList label={t('handbook.serviceGuidelines')} title={t('common.udacityMentorship')}>
      <SidebarRow to="" label={t('handbook.general')} isSelected={isSelected('general')} />
      <SidebarRow
        to="project-reviews"
        label={t('handbook.projectReviews')}
        isSelected={isSelected('project-reviews')}
      />
      <SidebarRow to="knowledge" label={t('common.knowledge')} isSelected={isSelected('knowledge')} />
      <SidebarRow to="community" label={t('common.community')} isSelected={isSelected('community')} />
      <SidebarRow to="career" label={t('handbook.careerSupport')} isSelected={isSelected('career')} />
      <SidebarRow to="contact" label={t('common.contactUs')} isSelected={isSelected('contact')} />
    </SidebarList>
  );
};
