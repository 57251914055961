import { Box, Flex } from '@chakra-ui/react';
import { CancelIcon, CheckedIcon, CircleEmptyIcon } from '@udacity/chakra-uds-icons';
import { getChakraReactSelectStyles } from '@udacity/chakra-uds-theme';
import { useAppToast } from 'app/common/hooks/use-app-toast';
import { Option } from 'app/helpers/form-helper';
import {
  MeetingAttendance,
  MeetingAttendanceRequest,
  MeetingAttendanceStatus,
  MeetingUser,
  UpdateMeetingAttendance,
} from 'app/types/uconnect';
import { Select } from 'chakra-react-select';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateAttendancesMutation } from '../queries/enhanced';
import { ExcusedAbsenceButton } from './excused-absence-button';

type AttendanceCellProps = {
  attendance: MeetingAttendance | undefined | null;
  attendanceRequest: MeetingAttendanceRequest | undefined | null;
  learner: MeetingUser;
  meetingId: string;
};

export const AttendanceCell: React.FC<AttendanceCellProps> = ({
  attendance,
  attendanceRequest,
  learner,
  meetingId,
}) => {
  const { t } = useTranslation();

  const toast = useAppToast();

  const [updateAttendances, { isLoading }] = useUpdateAttendancesMutation();

  const attendanceOptions = [
    {
      label: (
        <>
          <CheckedIcon color="feedback.positive" h={6} w={6} /> {t('connect.attended')}
        </>
      ),
      value: MeetingAttendanceStatus.Attended,
    },
    {
      label: (
        <>
          <CancelIcon color="feedback.negative" h={6} w={6} /> {t('connect.absent')}
        </>
      ),
      value: MeetingAttendanceStatus.Absent,
    },
    {
      label: (
        <>
          <CircleEmptyIcon color="black" h={6} w={6} /> {t('connect.excused')}
        </>
      ),
      value: MeetingAttendanceStatus.Excused,
    },
  ];

  const handleUpdateAttendance = async (option: Option<ReactNode> | null) => {
    if (!option) return;

    const newAttendance: UpdateMeetingAttendance = {
      userKey: learner.userKey,
      meetingId,
      status: option.value as MeetingAttendanceStatus,
    };
    const res = await updateAttendances({ attendances: [newAttendance] });
    if ('error' in res) {
      toast({
        variant: 'error',
        description: (res.error as { data: string }).data,
      });
    }
  };

  return (
    <Flex gap={4}>
      {attendanceRequest && <ExcusedAbsenceButton user={learner.user} attendanceRequest={attendanceRequest} />}
      <Box flex={1}>
        <Select<Option<ReactNode>>
          chakraStyles={getChakraReactSelectStyles()}
          options={attendanceOptions}
          onChange={handleUpdateAttendance}
          placeholder={t('connect.notRecorded')}
          isLoading={isLoading}
          value={attendanceOptions.find((option) => attendance?.status === option.value)}
        />
      </Box>
    </Flex>
  );
};
