import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Text } from '@chakra-ui/react';
import { ArrowLeftSmallIcon } from '@udacity/chakra-uds-icons';

import { calculateTimeDistance } from 'app/queue/components/countdown/helpers';

type ProjectReviewsHeaderProps = {
  projectTitle: string;
  timeRemaining: ReturnType<typeof calculateTimeDistance>;
  isPlagiarismAudit?: boolean;
};

export const ProjectReviewsHeader: React.FC<ProjectReviewsHeaderProps> = ({
  projectTitle,
  timeRemaining,
  isPlagiarismAudit,
}) => {
  const { t } = useTranslation();

  return (
    <Flex bgColor="white" justify="space-between" align="center" pos="relative" boxShadow="md" px={6} py={2} h={16}>
      <Button as={ReactRouterLink} variant="ghost" to="/" leftIcon={<ArrowLeftSmallIcon w={8} h={8} />}>
        {t('common.backToDashboard')}
      </Button>

      <Text size="label">
        {isPlagiarismAudit
          ? t('projectReviews.plagiarismAudit.headerTitle', { projectTitle })
          : t('projectReviews.reviewProjectTitle', { projectTitle })}
      </Text>

      <Text size="label" color="blue.700" visibility={!timeRemaining.h && !timeRemaining.m ? 'hidden' : 'visible'}>
        {t('projectReviews.timeLeft', {
          hour: timeRemaining.h,
          min: timeRemaining.m,
        })}
      </Text>
    </Flex>
  );
};
