import _ from 'lodash';
import { createSelector } from 'reselect';

export const selectRecruitmentFamiliesAsync = (state) => state.reviews.recruitmentFamiliesAsync;

export const selectReviewerInterestAsync = createSelector(
  selectRecruitmentFamiliesAsync,
  (asyncStatus) => asyncStatus.updateReviewerInterestsById
);

export const selectAllRecruitmentFamilies = (state) => state.reviews.recruitmentFamilies.families || [];

export const selectReviewerInterests = (state) => state.reviews.recruitmentFamilies.reviewerInterests || [];

export const selectedComposedFamilies = createSelector(
  selectAllRecruitmentFamilies,
  selectReviewerInterests,
  (families, interests) => {
    return families.map((family) => {
      const interest = _.find(interests, { recruitment_family_id: family.id });
      return {
        ...family,
        ..._.pick(interest, ['status', 'reapply_at', 'last_screened_at', 'last_expressed_at', 'updated_at']),
      };
    });
  }
);
