import React, { useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

import { useSubmitPlagiarismAuditMutation } from 'app/apis/reviews';

type ConfirmPlagiarismAuditAlertProps = {
  isOpen: boolean;
  onClose: () => void;
  plagiarismCaseId: number;
  isPlagiarized: boolean;
  isPlagiarizedFromSource: boolean;
  isPlagiarizedFromAlternative: boolean;
};

export const ConfirmPlagiarismAuditAlert = ({
  isOpen,
  onClose,
  plagiarismCaseId,
  isPlagiarized,
  isPlagiarizedFromSource,
  isPlagiarizedFromAlternative,
}: ConfirmPlagiarismAuditAlertProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  const [submitPlagiarismAudit, { isLoading: isSubmitting }] = useSubmitPlagiarismAuditMutation();

  const handleSubmit = async () => {
    await submitPlagiarismAudit(plagiarismCaseId)
      .unwrap()
      .then(() => {
        history.push('/queue/overview');
      })
      .catch((e) => console.error(e));
  };

  const translationKey = useMemo(() => {
    if (isPlagiarizedFromSource && isPlagiarizedFromAlternative) {
      return 'projectReviews.plagiarismAudit.confirmSubmission.plagiarizedFromBoth';
    }

    if (isPlagiarizedFromSource) {
      return 'projectReviews.plagiarismAudit.confirmSubmission.plagiarizedFromSource';
    }

    if (isPlagiarizedFromAlternative) {
      return 'projectReviews.plagiarismAudit.confirmSubmission.plagiarizedFromAlternative';
    }

    if (!isPlagiarized) {
      return 'projectReviews.plagiarismAudit.confirmSubmission.notPlagiarized';
    }

    return '';
  }, [isPlagiarized, isPlagiarizedFromSource, isPlagiarizedFromAlternative]);

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef} isCentered>
      <AlertDialogOverlay />
      <AlertDialogContent
        aria-label={t('projectReviews.plagiarismAudit.confirmSubmission.title')}
        maxW="35rem"
        maxH="90vh"
      >
        <AlertDialogHeader>{t('projectReviews.plagiarismAudit.confirmSubmission.title')}</AlertDialogHeader>
        <AlertDialogCloseButton />

        <AlertDialogBody>
          <Trans i18nKey={translationKey} />
        </AlertDialogBody>

        <AlertDialogFooter gap={6} justifyContent="end">
          <Button variant="ghost" onClick={onClose} ref={cancelRef}>
            {t('common.cancel')}
          </Button>
          <Button onClick={handleSubmit} isLoading={isSubmitting}>
            {t('common.confirm')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
