import { ConnectGqlApiTagTypes } from 'app/apis/connect-gql-api';
import { api as claimMeetingsApi } from './claim-meetings.generated';
import { api as getMeetingsApi } from './get-meetings.generated';
import { api as getMeetingUsersApi } from './get-meeting-users.generated';
import { api as getAvailableSeriesApi } from './get-available-series.generated';
import { api as getSeriesDetailsApi } from './get-series-details.generated';
import { api as judgeAttendancesApi } from './judge-attendance-request.generated';
import { api as updateAttendancesApi } from './update-attendances.generated';

getAvailableSeriesApi.enhanceEndpoints({
  endpoints: {
    GetAvailableSeries: {
      providesTags: [ConnectGqlApiTagTypes.AvailableSeries],
    },
  },
});

getSeriesDetailsApi.enhanceEndpoints({
  endpoints: {
    GetSeriesDetails: {
      providesTags: [ConnectGqlApiTagTypes.Series],
    },
  },
});

getMeetingsApi.enhanceEndpoints({
  endpoints: {
    GetMeetings: {
      providesTags: [ConnectGqlApiTagTypes.Meetings],
    },
  },
});

getMeetingUsersApi.enhanceEndpoints({
  endpoints: {
    GetMeetingUsers: {
      providesTags: [ConnectGqlApiTagTypes.MeetingUsers],
    },
  },
});

claimMeetingsApi.enhanceEndpoints({
  endpoints: {
    ClaimMeetings: {
      invalidatesTags: [ConnectGqlApiTagTypes.Meetings, ConnectGqlApiTagTypes.AvailableSeries],
    },
  },
});

judgeAttendancesApi.enhanceEndpoints({
  endpoints: {
    JudgeAttendanceRequest: {
      invalidatesTags: [ConnectGqlApiTagTypes.MeetingUsers],
    },
  },
});

updateAttendancesApi.enhanceEndpoints({
  endpoints: {
    UpdateAttendances: {
      invalidatesTags: [ConnectGqlApiTagTypes.MeetingUsers, ConnectGqlApiTagTypes.Meetings],
    },
  },
});

export const { useGetMeetingsQuery } = getMeetingsApi;
export const { useGetMeetingUsersQuery } = getMeetingUsersApi;
export const { useGetAvailableSeriesQuery } = getAvailableSeriesApi;
export const { useGetSeriesDetailsQuery } = getSeriesDetailsApi;
export const { useClaimMeetingsMutation } = claimMeetingsApi;
export const { useJudgeAttendanceRequestMutation } = judgeAttendancesApi;
export const { useUpdateAttendancesMutation } = updateAttendancesApi;
