import { Box } from '@chakra-ui/react';
import { useFetchOpportunitiesQuery } from 'app/apis/reviews';
import React from 'react';
import { useParams } from 'react-router';
import { OpportunityCard } from './opportunity-card';

export const Opportunities: React.FC = () => {
  const params = useParams<{ opportunityId: string }>();
  const { data: opportunities } = useFetchOpportunitiesQuery();
  const opportunity = opportunities?.find((opp) => String(opp.id) === params.opportunityId);

  return (
    <Box bg="gray.100" h="100vh" py={12}>
      {opportunity && <OpportunityCard opportunity={opportunity} />}
    </Box>
  );
};
