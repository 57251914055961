import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, CheckboxGroup, FormControl, FormLabel, Stack } from '@chakra-ui/react';

type SpecializedMentorRolesProps = {
  handleMultiCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  label?: string;
  roles: string[];
};

export const SpecializedMentorRoles: React.FC<SpecializedMentorRolesProps> = ({
  roles,
  handleMultiCheckboxChange,
  label,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl mt={5}>
      <FormLabel mb={2}>{label ?? t('mentorProfile.imInterested')}</FormLabel>
      <CheckboxGroup>
        <Stack spacing={4}>
          <Checkbox
            id="guru"
            value="guru"
            isChecked={roles.includes('guru')}
            onChange={(e) => handleMultiCheckboxChange(e, 'roles')}
            alignItems="start"
          >
            {t('common.guru')}
          </Checkbox>
          <Checkbox
            id="session_lead"
            value="session_lead"
            isChecked={roles.includes('session_lead')}
            onChange={(e) => handleMultiCheckboxChange(e, 'roles')}
            alignItems="start"
          >
            {t('common.sessionLead')}
          </Checkbox>
          <Checkbox
            id="community_moderator"
            value="community_moderator"
            isChecked={roles.includes('community_moderator')}
            onChange={(e) => handleMultiCheckboxChange(e, 'roles')}
            alignItems="start"
          >
            {t('common.communityModerator')}
          </Checkbox>
          <Checkbox
            id="tutor"
            value="tutor"
            isChecked={roles.includes('tutor')}
            onChange={(e) => handleMultiCheckboxChange(e, 'roles')}
            alignItems="start"
          >
            {t('common.tutor')}
          </Checkbox>
          <Checkbox
            id="career_coach"
            value="career_coach"
            isChecked={roles.includes('career_coach')}
            onChange={(e) => handleMultiCheckboxChange(e, 'roles')}
            alignItems="start"
          >
            {t('common.careerCoach')}
          </Checkbox>
          <Checkbox
            id="site_supervisor"
            value="site_supervisor"
            isChecked={roles.includes('site_supervisor')}
            onChange={(e) => handleMultiCheckboxChange(e, 'roles')}
            alignItems="start"
          >
            {t('common.siteSupervisor')}
          </Checkbox>
        </Stack>
      </CheckboxGroup>
    </FormControl>
  );
};
