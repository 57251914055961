import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Heading, Link } from '@chakra-ui/react';
import { GeolocateIcon, StudentHubIcon, ReviewsIcon, UdacityIcon } from '@udacity/chakra-uds-icons';

import styles from './mentor-type-card.module.scss';

type MentorTypeCardProps = {
  type: string;
};

export const MentorTypeCard: React.FC<MentorTypeCardProps> = ({ type }) => {
  const { t } = useTranslation();

  const getTypeConfig = () => {
    switch (type) {
      case 'community':
        return {
          glyph: <StudentHubIcon w={10} h={10} color="white" />,
          title: t('common.mentorship'),
          color: 'sea-foam',
          path: 'mentorship',
        };
      case 'connect':
        return {
          glyph: <GeolocateIcon w={10} h={10} color="white" />,
          title: t('common.connect'),
          color: 'sky',
          path: 'connect',
        };
      case 'reviews':
        return {
          glyph: <ReviewsIcon w={10} h={10} color="white" />,
          title: t('common.reviews'),
          color: 'purple',
          path: 'queue',
        };
      default:
        return {
          glyph: <UdacityIcon w={10} h={10} color="white" />,
          title: type.split('_').join(' '),
          color: 'blue',
          path: '#',
        };
    }
  };

  const { glyph, title, color, path } = getTypeConfig();

  return (
    <div className={styles[`switch-card-${color}`]}>
      {!!path && (
        <ReactRouterLink to={path}>
          {glyph}
          <Heading size="h2">{title}</Heading>
        </ReactRouterLink>
      )}
    </div>
  );
};
