import React from 'react';
import PropTypes from 'prop-types';
import styles from './raised-glyph.module.scss';

export class RaisedGlyph extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.element.isRequired,
  };

  render() {
    const { icon } = this.props;

    return <div className={styles['raised-glyph']}>{icon}</div>;
  }
}

export default RaisedGlyph;
