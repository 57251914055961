import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { SidebarLayout } from 'app/common/components/sidebar-layout';
import { DashboardOverviewScene } from 'app/mentorship/scenes/dashboard-overview-scene';

export const MentorshipApp: React.FC = () => {
  const match = useRouteMatch();

  return (
    <SidebarLayout>
      <Switch>
        <Route exact path={`${match.path}`}>
          <Redirect to={`${match.path}/overview`} />
        </Route>

        <Route path={`${match.path}/overview`}>
          <DashboardOverviewScene />
        </Route>
      </Switch>
    </SidebarLayout>
  );
};
