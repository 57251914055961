export interface QuestionQueueStatus {
  expiresAt: number | null;
  enabled: boolean;
  projectEnabledInQueue: { [projectID: string]: boolean };
}

export interface QuestionQueueAssignment {
  expiresAt: number | null;
  questionID: string;
  atRisk: boolean;
  opportunity: QuestionQueueOpportunity;
}

export interface Payment {
  amount: string;
  currencyCode: string;
}

export interface QuestionQueueOpportunity {
  project: { projectID: string; title: string };
  earnings: { answer: Payment; comment: Payment };
}

export enum QuestionQueueActionType {
  COMMENT = 'COMMENTED',
  ANSWER = 'ANSWERED',
}

export interface QuestionQueueAction {
  createdAt: number;
  cursor: string;
  payment: Payment;
  projectTitle: string;
  questionID: string;
  type: QuestionQueueActionType;
}

export interface QuestionQueueHistory {
  totalCount?: number;
  actions: QuestionQueueAction[];
}

export interface QuestionQueue {
  status: QuestionQueueStatus;
  assignments: QuestionQueueAssignment[];
  projectOpportunities: { [projectID: string]: QuestionQueueOpportunity };
  history: QuestionQueueHistory;
  pastMonthEarnings?: string;
}
