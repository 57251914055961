export enum MentorRole {
  SessionLead = 'session_lead',
  CommunityModerator = 'community_moderator',
  CareerCoach = 'career_coach',
  Guru = 'guru',
  Tutor = 'tutor',
}

export type CountryLocale = {
  countryCode: string;
  country: string;
  localeFactor: number;
  payRatePerHour: string;
  bonus?: string;
  upToHoursPerMonth: string;
  maxPaymentPerMonth: string;
  currency?: string;
};

export type Agreement = {
  id: string;
  mentor_id: string;
  role: MentorRole;
  status: 'pending_signature' | 'withdrawn' | 'signed';
  date_signed?: Date;
  pay_rate?: string;
  hours_per_month?: string;
  max_payments_per_month?: string;
  bonus?: string;
  created_at?: string;
  updated_at?: string;
  currency_override?: string;
};
