import { combineReducers } from 'redux';

import { reducer as ui } from 'app/common/domains/ui/ui-reducer';
import { reducer as session } from 'app/common/domains/session/session-reducer';
import { reducer as user } from 'app/common/domains/user/user-reducer';
import {
  auditsReducer as audits,
  questionsReducer as questions,
  reviewsReducer as reviews,
  plagiarismCasesReducer as plagiarismCases,
} from 'app/queue/domains';

import { connectGqlApi } from './apis/connect-gql-api';
import { reviewsApi } from './apis/reviews';

export default combineReducers({
  ui,
  session,
  audits,
  plagiarismCases,
  questions,
  reviews,
  user,
  [reviewsApi.reducerPath]: reviewsApi.reducer,
  [connectGqlApi.reducerPath]: connectGqlApi.reducer,
});
