import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckedIcon } from '@udacity/chakra-uds-icons';

import styles from './awaiting-approval.module.scss';

export const AwaitingApproval: React.FC<{ type: string }> = ({ type }) => {
  const { t } = useTranslation();

  return (
    <section className={styles['completed-container']}>
      <div className={styles['text-container']}>
        <h1 className={styles.completed}>
          <CheckedIcon w={10} h={10} />
          {type === 'apply' ? t('mentorProfile.profileCompleted') : t('mentorProfile.profileUpdated')}
        </h1>
        {type === 'apply' ? (
          <div>
            <p className={styles.copy}>{t('mentorProfile.thanksForApplying')}</p>
            <NavLink className={styles['back-link']} to="/specialized-mentor/update">
              {t('mentorProfile.updateApplication')}
            </NavLink>
          </div>
        ) : (
          <ul className={styles.links}>
            <li>
              <NavLink className={styles['back-link']} to="/">
                {t('common.backToDashboard')}
              </NavLink>
            </li>
          </ul>
        )}
      </div>
    </section>
  );
};
