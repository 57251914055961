import React from 'react';
import { useLocation } from 'react-router-dom';

import GuidelinesLayout from 'app/common/components/guidelines-layout';
import { Sidebar } from './sidebar';
import handbookHelper from './handbook-helper';

export const HandbookContainer: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const parsedPathname = handbookHelper.parsePathname(pathname);
  const header = handbookHelper.getHeader(parsedPathname);

  return (
    <GuidelinesLayout header={header} sidebar={<Sidebar pathname={pathname} />}>
      {children}
    </GuidelinesLayout>
  );
};

export default HandbookContainer;
