/* eslint-disable */ /**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 * instead, edit its related `.gql` file and recreate it with
 *
 * npm run codegen:connect
 */

import * as Types from '../../types/uconnect';

import { api } from 'app/apis/connect-gql-api';
export type JudgeAttendanceRequestMutationVariables = Types.Exact<{
  judgement: Types.UpdateMeetingAttendanceRequest;
}>;

export type JudgeAttendanceRequestMutation = {
  __typename?: 'Mutation';
  updateMeetingAttendanceRequests: Array<{ __typename?: 'MeetingAttendanceRequest'; id: any }>;
};

export const JudgeAttendanceRequestDocument = `
    mutation JudgeAttendanceRequest($judgement: UpdateMeetingAttendanceRequest!) {
  updateMeetingAttendanceRequests(input: [$judgement]) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    JudgeAttendanceRequest: build.mutation<JudgeAttendanceRequestMutation, JudgeAttendanceRequestMutationVariables>({
      query: (variables) => ({ document: JudgeAttendanceRequestDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
