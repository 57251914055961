import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import dayjs from 'dayjs';

export const getRatingsMatrixTableData = (rawData) => {
  const sortedRows = _sortBy(rawData, ['project_name', 'submission_language']);

  return sortedRows.map((group) => {
    return {
      'Project Name': { data: _get(group, 'project_name', 'Unknown') },
      Language: { data: _get(group, 'submission_language', 'Unknown') },
      '# Rated': { data: _get(group, 'num_ratings', 0) },
      'Avg Rating': { data: _get(group, 'avg_rating', 'N/A') },
    };
  });
};

export const sortComparator = (sortColumn, row) => {
  const data = _get(row, [sortColumn, 'data']);
  if (sortColumn === 'Total Earned') {
    const price = parseFloat(data.replace('$', ''));
    return price;
  }
  return data;
};

export const roundToDecimal = (num) => {
  if (!num || isNaN(num)) {
    return 0;
  }
  const decimal = Math.round(num * 100) / 100;
  return decimal.toFixed(2);
};

export const getReadablePSTDate = (date) => {
  return dayjs(date).tz('America/Los_Angeles').format('MMM Do YY');
};
