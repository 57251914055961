import CONFIG from 'configLoader';

import {
  AssignedPlagiarismCase,
  CompletedPlagiarismCase,
  ApiPlagiarismCase,
  PlagiarismCaseRequest,
  ApiPlagiarismCaseRequest,
} from './plagiarism-case-queue-types';

export function plagiarismCaseRequestFromApiRequest(apiRequest: ApiPlagiarismCaseRequest): PlagiarismCaseRequest {
  return {
    id: apiRequest.id,
    plagiarismCaseId: apiRequest.plagiarism_case_id || undefined,
    status: apiRequest.status,
    closedAt: Date.parse(apiRequest.closed_at),
  };
}

export function projectsEnabledForPlagiarismCaseQueueFromApiRequests(apiRequests: ApiPlagiarismCaseRequest[]): {
  [id: number]: boolean;
} {
  return apiRequests.reduce((projectsEnabled, r) => {
    r.plagiarism_case_request_projects.forEach((p) => {
      projectsEnabled[p.project_id] = true;
    });
    return projectsEnabled;
  }, {});
}

export function assignedPlagiarismCaseFromApi(apiPlagiarismCase: ApiPlagiarismCase): AssignedPlagiarismCase {
  return {
    id: apiPlagiarismCase.id,
    url: `/project-reviews/${apiPlagiarismCase.submission_id}/plagiarism-audit`,
    price: apiPlagiarismCase.price,
    assignedAt: Date.parse(apiPlagiarismCase.assigned_at),
    expiresAt: Date.parse(apiPlagiarismCase.expires_at),
    project: {
      id: apiPlagiarismCase.submission_project.id,
      name: apiPlagiarismCase.submission_project.name,
    },
  };
}

export function completedPlagiarismCaseFromApi(apiPlagiarismCase: ApiPlagiarismCase): CompletedPlagiarismCase {
  if (!apiPlagiarismCase.completed_at || !apiPlagiarismCase.result) {
    throw new Error('Cannot generate completed plagiarism case from incomplete API object');
  }

  return {
    id: apiPlagiarismCase.id,
    completedAt: Date.parse(apiPlagiarismCase.completed_at),
    result: apiPlagiarismCase.result,
    price: apiPlagiarismCase.price,
    project: {
      id: apiPlagiarismCase.submission_project.id,
      name: apiPlagiarismCase.submission_project.name,
    },
  };
}

export function mergeCompletedPlagiarismCasesIntoHistory(
  history: CompletedPlagiarismCase[],
  newCompleted: CompletedPlagiarismCase[]
): CompletedPlagiarismCase[] {
  const historyIds = history.map((plagiarismCase) => plagiarismCase.id);
  const completedNotInHistory = newCompleted.filter((plagiarismCase) => !historyIds.includes(plagiarismCase.id));
  return history.concat(completedNotInHistory).sort((a, b) => {
    return a.completedAt > b.completedAt ? -1 : a.completedAt < b.completedAt ? 1 : 0;
  });
}
