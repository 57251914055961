import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import { LightbulbIcon } from '@udacity/chakra-uds-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { formatPrice } from 'app/helpers/format-helper';
import RaisedGlyph from 'app/queue/components/raised-glyph';
import ListItem from 'app/queue/components/list-item';
import { QuestionQueueAssignment } from 'app/queue/domains/question-queue/question-queue-types';

import CONFIG from 'configLoader';
import styles from './assigned-question-list-item.module.scss';

export type AssignedQuestionListItemProps = {
  assignment: QuestionQueueAssignment;
};

export const AssignedQuestionListItem: React.FC<AssignedQuestionListItemProps> = ({ assignment }) => {
  const { t } = useTranslation();

  const expiration = assignment.expiresAt || 0;
  const isExpired = dayjs().isAfter(expiration);
  const isCloseToExpiring = dayjs().add(15, 'm').isAfter(expiration);
  const timeRemaining = isExpired ? t('common.expired') : dayjs(expiration).fromNow(true);

  const questionUrl = `${CONFIG.knowledge_url}/questions/${assignment.questionID}`;

  const listItemClass = classNames(styles['assigned-question-list-item'], {
    [styles['expired-question']]: isExpired,
  });

  const completeInClass = classNames({
    [styles['complete-in-expiring']]: isCloseToExpiring,
  });

  return (
    <ListItem className={listItemClass}>
      <div className={styles['glyph']}>
        <RaisedGlyph icon={<LightbulbIcon w={8} h={8} />} />
      </div>

      <div data-tst="title" className={styles.title}>
        <p>{assignment.opportunity.project.title}</p>
        {assignment.atRisk && <p className={styles['at-risk']}>{t('queue.specialAttention')}</p>}
      </div>

      <div className={styles['cell-group']}>
        <div data-tst="answer-price" className={styles['price']}>
          <label>{t('common.answer')}</label>
          <p>{formatPrice(assignment.opportunity.earnings.answer.amount)}</p>
        </div>

        <div data-tst="complete-in" className={completeInClass}>
          <label>{t('queue.reassignedIn')}</label>
          <p>{timeRemaining}</p>
        </div>
      </div>

      <div className={styles['actions']}>
        {!isExpired && (
          <Button as="a" href={questionUrl} variant="outline" size="sm" target="_blank" rel="noopener noreferrer">
            {t('common.answer')}
          </Button>
        )}
      </div>
    </ListItem>
  );
};
