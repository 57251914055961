import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormHelperText, FormLabel, Text, Textarea, TextareaProps } from '@chakra-ui/react';

type MentorFormTextareaProps = {
  label: string;
  helperText?: string;
  maxChars?: number;
  isOptional?: boolean;
} & TextareaProps;

export const MentorFormTextarea: React.FC<MentorFormTextareaProps> = ({
  label,
  helperText,
  maxChars,
  isOptional,
  id,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const chars = typeof value === 'string' ? value.length : 0;
  const remainingChars = maxChars ? maxChars - chars : null;

  return (
    <FormControl mt={5} mb={1}>
      <FormLabel mb={2} htmlFor={id}>
        {label}
        {isOptional && t('common.optional')}
      </FormLabel>
      {helperText && <FormHelperText mb={2}>{helperText}</FormHelperText>}
      <Textarea id={id} value={value} onChange={onChange} rows={4} />
      {remainingChars && (
        <Text color="gray.700" fontSize="sm" mt={2} align="end">
          {t('mentorProfile.form.charactersRemaining', {
            count: remainingChars,
          })}
        </Text>
      )}
    </FormControl>
  );
};
