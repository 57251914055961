import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';

import styles from '../payment-settings.module.scss';

type PayoneerStageOneProps = {
  logIn: string;
  signUp: string;
};

export const PayoneerStageOne: React.FC<PayoneerStageOneProps> = ({ logIn, signUp }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['payoneer-actions']}>
      <div>
        <p>{t('mentorProfile.payoneer.dontHaveAPayoneerAccount')}</p>
        <Button as="a" href={signUp} target="_blank" rel="noopener noreferrer">
          {t('mentorProfile.payoneer.signupForPayoneer')}
        </Button>
      </div>
      <div>
        <p>{t('mentorProfile.payoneer.alreadySignedUp')}</p>
        <Button as="a" href={logIn} target="_blank" rel="noopener noreferrer">
          {t('mentorProfile.payoneer.linkPayoneerAccount')}
        </Button>
      </div>
    </div>
  );
};
