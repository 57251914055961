import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';

import styles from './pagination.module.scss';

type PaginationProps = {
  page: number;
  onNextClick: () => void;
  onPreviousClick: () => void;
  className?: string;
};

export const Pagination: React.FC<PaginationProps> = ({ page = 1, onNextClick, onPreviousClick, className }) => {
  const { t } = useTranslation();
  return (
    <nav className={`${styles['pagination']} ${className}`}>
      {page > 1 && (
        <Button variant="outline" data-tst="previous-button" onClick={onPreviousClick}>
          {t('common.newer')}
        </Button>
      )}
      <Button variant="outline" data-tst="next-button" onClick={onNextClick}>
        {t('common.older')}
      </Button>
    </nav>
  );
};
