import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';

import { Modal } from 'app/common/components/modal';
import questionQueueActions from 'app/queue/domains/question-queue/question-queue-actions';
import {
  selectQuestionQueueEnabled,
  selectQuestionQueueExpiration,
  selectProjectsEnabledInQuestionQueue,
  selectQuestionQueueOpportunities,
} from 'app/queue/domains/question-queue/question-queue-selectors';
import { useAppDispatch } from 'app/store';

import { QueueControlRow } from '../queue-control-row';

import { QuestionQueueSettings } from './question-queue-settings';

export const QuestionQueue: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isQuestionOptionsModalOpen, setQuestionOptionsModalOpen] = useState(false);

  const questionQueueEnabled = useSelector(selectQuestionQueueEnabled);
  const questionQueueExpiresAt = useSelector(selectQuestionQueueExpiration);
  const projectsEnabledInQueue = useSelector(selectProjectsEnabledInQuestionQueue);
  const questionQueueProjectOpportunities = useSelector(selectQuestionQueueOpportunities);

  const toggleQuestionQueue = () => {
    if (questionQueueEnabled) {
      dispatch(questionQueueActions.leaveQueue());
    } else {
      setQuestionOptionsModalOpen(true);
    }
  };

  const refreshQuestionQueue = () => {
    const enabledProjectIds = Object.keys(projectsEnabledInQueue).filter(
      (projectId) => projectsEnabledInQueue[projectId]
    );
    dispatch(questionQueueActions.joinQueue(enabledProjectIds));
  };

  const submitQuestionQueueSettings = (projectIds: string[]) => {
    dispatch(questionQueueActions.joinQueue(projectIds));
    setQuestionOptionsModalOpen(false);
  };

  return (
    <>
      <QueueControlRow
        name={t('common.questions')}
        onOptionsClick={() => setQuestionOptionsModalOpen(true)}
        onQueueClick={toggleQuestionQueue}
        onRefreshClick={refreshQuestionQueue}
        isQueueEnabled={questionQueueEnabled}
        expiration={questionQueueExpiresAt}
      />

      <Modal
        ariaLabel={t('queue.overview.questionQueueSettings')}
        onClose={() => setQuestionOptionsModalOpen(false)}
        isOpen={isQuestionOptionsModalOpen}
      >
        <QuestionQueueSettings
          isOptionsUpdate={questionQueueEnabled}
          projectsEnabledInQueue={projectsEnabledInQueue}
          projectOpportunities={questionQueueProjectOpportunities}
          onSubmit={submitQuestionQueueSettings}
        />
      </Modal>
    </>
  );
};
