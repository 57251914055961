import { takeEvery, takeLatest, take, fork, call, put, race } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { actionTypes } from './submissions-actions';
import { getAssignedSubmissions, getCompletedSubmissions, unassignSubmission } from 'app/services/reviews-api-service';
import reviewsQueueConstants from 'app/queue/domains/reviews-queue/reviews-queue-constants';

function* fetchAllAssigned() {
  try {
    const payload = yield call(getAssignedSubmissions);
    yield put({ type: actionTypes.FETCH_ALL_ASSIGNED_FULFILLED, payload });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_ALL_ASSIGNED_FULFILLED, error });
  }
}

function* fetchCompleted(action) {
  const params = action.payload;

  try {
    const response = yield call(getCompletedSubmissions, params);
    yield put({
      type: actionTypes.FETCH_COMPLETED_FULFILLED,
      payload: response,
    });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_COMPLETED_FULFILLED, error });
  }
}

function* unassign(action) {
  const submissionId = action.payload;

  try {
    const payload = yield call(unassignSubmission, submissionId);
    yield put({ type: actionTypes.UNASSIGN_FULFILLED, payload });
  } catch (error) {
    yield put({ type: actionTypes.UNASSIGN_FULFILLED, error });
  }
}

function* pollAllAssigned() {
  while (true) {
    try {
      const assignedSubmissions = yield call(getAssignedSubmissions);
      yield put({
        type: actionTypes.FETCH_ALL_ASSIGNED_FULFILLED,
        payload: assignedSubmissions,
      });
      yield delay(reviewsQueueConstants.POLLING_INTERVAL);
    } catch (error) {
      yield put({ type: actionTypes.FETCH_ALL_ASSIGNED_FULFILLED, error });
      return;
    }
  }
}

function* watchFetchAllAssigned() {
  yield takeEvery(actionTypes.FETCH_ALL_ASSIGNED, fetchAllAssigned);
}

function* watchFetchAllCompleted() {
  yield takeLatest(actionTypes.FETCH_COMPLETED, fetchCompleted);
}

function* watchUnassign() {
  yield takeEvery(actionTypes.UNASSIGN, unassign);
}

function* watchPollAssignedSubmissions() {
  while (true) {
    yield take(actionTypes.POLL_ALL_ASSIGNED);
    yield race([call(pollAllAssigned), take(actionTypes.CANCEL_POLL_ALL_ASSIGNED)]);
  }
}

export default function* submissionsSaga() {
  yield [
    fork(watchFetchAllAssigned),
    fork(watchFetchAllCompleted),
    fork(watchUnassign),
    fork(watchPollAssignedSubmissions),
  ];
}
