const SECOND_MS = 1000;
const MINUTE_MS = SECOND_MS * 60;
const HOUR_MS = MINUTE_MS * 60;
const DAY_MS = HOUR_MS * 24;

const ZERO_DISTANCE = {
  d: 0,
  h: 0,
  m: 0,
  s: 0,
};

/**
 * Calculates the duration in days, hours, minutes and seconds.
 * @param {number} duration - The duration in milliseconds.
 * @returns {{d: number, h: number, m: number, s: number}}
 */
export function calculateTimeDistance(duration) {
  if (duration <= 0) {
    return ZERO_DISTANCE;
  }

  const hourDuration = duration % DAY_MS;
  const minuteDuration = hourDuration % HOUR_MS;
  const secondDuration = minuteDuration % MINUTE_MS;

  const days = Math.trunc(duration / DAY_MS);
  const hours = Math.trunc(hourDuration / HOUR_MS);
  const minutes = Math.trunc(minuteDuration / MINUTE_MS);
  const seconds = Math.trunc(secondDuration / SECOND_MS);

  return {
    d: days,
    h: hours,
    m: minutes,
    s: seconds,
  };
}
