import { Badge, Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import { ChatIcon, CohortIcon, TimeIcon } from '@udacity/chakra-uds-icons';
import { commonLanguages } from 'app/helpers/form-helper';
import { dateFormatter } from 'app/helpers/format-helper';
import { FetchedMeeting } from 'app/uconnect/helpers/types';
import { getExcuseRequestCount } from 'app/uconnect/helpers/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  meeting: FetchedMeeting;
};

export const MeetingCard: React.FC<Props> = ({ meeting }) => {
  const { t } = useTranslation();
  const languages = meeting.locales.map(
    (locale) =>
      commonLanguages.find((lang) => locale === lang.code) || {
        name: 'English',
      }
  );

  const startTime = dateFormatter.localTime(meeting.startAt);
  const endTime = dateFormatter.localTime(meeting.endAt);
  const excuseRequestCount = getExcuseRequestCount(meeting);

  return (
    <Box border="1px solid" borderColor="gray.300" borderRadius="base">
      <Flex direction="column" gap={4} p={6}>
        <Text>{meeting.series?.name}</Text>
        <Text>{meeting.name}</Text>
        <Flex gap={2}>
          <Badge>
            <TimeIcon h={4} mb={0.5} w={4} /> {startTime} - {endTime}
          </Badge>
          {languages.map((language) => (
            <Badge key={language.name}>
              <ChatIcon h={4} mb={0.5} w={4} /> {language.name}
            </Badge>
          ))}
          {meeting.meetingUsers.totalCount > 0 && (
            <Badge>
              <CohortIcon h={4} mb={0.5} w={4} />{' '}
              {t('connect.meetingUserCount', {
                count: meeting.meetingUsers.totalCount,
              })}
            </Badge>
          )}
          {excuseRequestCount > 0 && (
            <Badge bgColor="red.700" color="white">
              <CohortIcon h={4} mb={0.5} w={4} />{' '}
              {t('connect.excuseRequestCount', {
                count: excuseRequestCount,
              })}
            </Badge>
          )}
        </Flex>
        {meeting.instructions && <Text whiteSpace="pre-line">{meeting.instructions}</Text>}
        {meeting.conferenceLink && <Link href={meeting.conferenceLink}>{meeting.conferenceLink}</Link>}
        <Box>
          <Button as={RouterLink} to={`/connect/meetings/${meeting.id}`} variant="outline">
            {t('connect.editAttendance')}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};
