import { FetchedMeeting } from './types';

export function downloadCsvUrl(url: string, filename?: string) {
  const link = document.createElement('a');
  link.href = url;
  filename && link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}

export function downloadCsvString(data: string, filename: string) {
  const url = window.URL.createObjectURL(new Blob([data]));
  downloadCsvUrl(url, filename);
}

export function getExcuseRequestCount(meeting: FetchedMeeting) {
  return meeting.meetingUsers.meetingUsers.filter((u) => {
    return u.attendanceRequest?.createdAt && !u.attendanceRequest.judgeUserKey;
  }).length;
}
