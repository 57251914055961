import { combineReducers } from 'redux';
import { actionTypes } from './reviews-queue-actions';
import { makeAsyncReducer } from 'app/common/domains/async';

const reducer = combineReducers({
  fetchAll: makeAsyncReducer(actionTypes.FETCH_ALL, actionTypes.FETCH_ALL_FULFILLED, actionTypes.FETCH_ALL_RESET),
  create: makeAsyncReducer(
    actionTypes.CREATE_REQUEST,
    actionTypes.CREATE_REQUEST_FULFILLED,
    actionTypes.CREATE_REQUEST_RESET
  ),
  destroy: makeAsyncReducer(
    actionTypes.DELETE_REQUEST,
    actionTypes.DELETE_REQUEST_FULFILLED,
    actionTypes.DELETE_REQUEST_RESET
  ),
  update: makeAsyncReducer(
    actionTypes.UPDATE_REQUEST,
    actionTypes.UPDATE_REQUEST_FULFILLED,
    actionTypes.UPDATE_REQUEST_RESET
  ),
  refresh: makeAsyncReducer(
    actionTypes.REFRESH_REQUEST,
    actionTypes.REFRESH_REQUEST_FULFILLED,
    actionTypes.REFRESH_REQUEST_RESET
  ),
});

export default reducer;
