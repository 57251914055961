import { all, call, put, takeEvery } from 'redux-saga/effects';
import { actionTypes, actionCreators } from './ui-actions';
import { NotificationsDeniedReason, createNotifyFunction } from './ui-sagas-helpers';

export const NOTIFICATION_DENIED_TOAST = {
  id: 'denied-notifications',
  type: 'notification',
  message: 'To enable notifications, please update your browser settings.',
};

export let notify = () => {};

export function* bootstrapNotifications() {
  try {
    notify = yield call(createNotifyFunction, window);
  } catch (reason) {
    if (reason === NotificationsDeniedReason.PERMISSION_NEWLY_DENIED) {
      yield put(actionCreators.addToast(NOTIFICATION_DENIED_TOAST));
    }
  }
}

export function* addNotification(action) {
  yield call(notify, action.payload);
}

export function* watchBootstrapNotifications() {
  yield takeEvery(actionTypes.BOOTSTRAP_NOTIFICATIONS, bootstrapNotifications);
}

export function* watchAddNotification() {
  yield takeEvery(actionTypes.ADD_NOTIFICATION, addNotification);
}

export default function* rootSaga() {
  yield all([watchBootstrapNotifications(), watchAddNotification()]);
}
