import React from 'react';
import propTypes from 'prop-types';

import styles from './feedback-comment.module.scss';

export default class FeedbackComment extends React.PureComponent {
  static propTypes = {
    icon: propTypes.element.isRequired,
    glyphStyle: propTypes.string,
  };

  static defaultProps = {
    glyphStyle: 'feedback-glyph',
  };

  render() {
    const { icon, glyphStyle, children } = this.props;

    return (
      <div className={styles['feedback-container']} data-tst="comment">
        <div className={styles[glyphStyle]}>{icon}</div>
        <div>{children}</div>
      </div>
    );
  }
}
