import { actionTypes } from './submissions-actions';
import { mergeCompletedSubmissions } from './submissions-reducer-helpers';

export const initialState = {
  assigned: [],
  completed: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  if (error) {
    return state;
  }

  switch (type) {
    case actionTypes.FETCH_COMPLETED_FULFILLED: {
      if (!payload) {
        return state;
      }

      return {
        ...state,
        completed: mergeCompletedSubmissions(state.completed, payload),
      };
    }

    case actionTypes.CLEAR_ALL_COMPLETED: {
      return {
        ...state,
        completed: [],
      };
    }

    case actionTypes.FETCH_ALL_ASSIGNED_FULFILLED: {
      if (!payload) {
        return state;
      }

      return {
        ...state,
        assigned: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
