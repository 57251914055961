import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import _get from 'lodash/get';

import PeerFeedback from 'app/queue/views/history/task-history/peer-feedback';

import styles from './feedback-modal.module.scss';

type FeedbackModalProps = {
  peerFeedbacks: {
    value: number;
    feedback: string;
  }[];
  studentFeedback: {
    rating: number;
    body: string;
    submissionId: number;
  };
};

export const FeedbackModal: React.FC<FeedbackModalProps> = ({ peerFeedbacks, studentFeedback }) => {
  const { t } = useTranslation();

  const submissionId = _get(studentFeedback, 'submission_id');
  const reviewUrl = `/project-reviews/${submissionId}`;

  return (
    <div className={styles['feedback-container']} data-tst="feedback-content">
      <h5>{t('queue.history.peerFeedback')}</h5>
      <PeerFeedback peerFeedbacks={peerFeedbacks} />
      {!!submissionId && (
        <Button as={ReactRouterLink} to={reviewUrl} variant="outline" title={t('queue.history.seeOriginalReview')}>
          {t('queue.history.seeReview')}
        </Button>
      )}
    </div>
  );
};
