import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormHelperText, FormLabel, Input, InputProps, Text } from '@chakra-ui/react';

type MentorFormInputProps = {
  id: string;
  label: string;
  helperText?: string;
  maxChars?: number;
  isOptional?: boolean;
  handleFieldChange?: ({ id, value }: { id: string; value: string | number }) => void;
} & InputProps;

export const MentorFormInput: React.FC<MentorFormInputProps> = ({
  id,
  label,
  helperText,
  maxChars,
  isOptional,
  handleFieldChange,
  onChange,
  value,
}) => {
  const { t } = useTranslation();

  const chars = typeof value === 'string' ? value.length : 0;
  const remainingChars = maxChars ? maxChars - chars : null;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (handleFieldChange) handleFieldChange({ id, value: e.target.value });
  };

  return (
    <FormControl mt={5} mb={1}>
      <FormLabel mb={2} htmlFor={id}>
        {label}
        {isOptional && t('common.optional')}
      </FormLabel>
      {helperText && <FormHelperText mb={2}>{helperText}</FormHelperText>}
      <Input id={id} value={value} onChange={handleFieldChange ? onInputChange : onChange} />
      {remainingChars && (
        <Text color="gray.700" fontSize="sm" mt={2} align="end">
          {t('mentorProfile.form.charactersRemaining', {
            count: remainingChars,
          })}
        </Text>
      )}
    </FormControl>
  );
};
