export const STATUSES = {
  READY: 'ready',
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

export const defaultInitialState = {
  status: STATUSES.READY,
  loading: false,
  firstLoad: true,
  error: null,
};

export function makeAsyncReducer(startAction, stopAction, resetAction, initialState = defaultInitialState) {
  return (state = initialState, { type, error }) => {
    switch (type) {
      case startAction: {
        return {
          ...state,
          error: null,
          loading: true,
          status: STATUSES.PENDING,
        };
      }

      case stopAction: {
        if (error) {
          return {
            ...state,
            loading: false,
            status: STATUSES.REJECTED,
            error,
          };
        }

        return {
          ...state,
          loading: false,
          status: STATUSES.FULFILLED,
          error: null,
          firstLoad: false,
        };
      }

      case resetAction: {
        return {
          ...state,
          loading: false,
          status: STATUSES.READY,
          error: null,
        };
      }

      default: {
        return state;
      }
    }
  };
}

export function makeAsyncReducerWithFulfilledAndRejected(
  startAction,
  fulfilledAction,
  rejectedAction,
  initialState = defaultInitialState
) {
  return (state = initialState, action) => {
    const { type, error } = action;
    switch (type) {
      case startAction: {
        return {
          ...state,
          error: null,
          loading: true,
          status: STATUSES.PENDING,
        };
      }

      case fulfilledAction: {
        return {
          ...state,
          loading: false,
          status: STATUSES.FULFILLED,
          error: null,
          firstLoad: false,
        };
      }

      case rejectedAction: {
        return {
          ...state,
          loading: false,
          status: STATUSES.REJECTED,
          error,
        };
      }

      default: {
        return state;
      }
    }
  };
}
