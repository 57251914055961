import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { GridItem, Stack } from '@chakra-ui/react';
import dayjs from 'dayjs';

import {
  useFetchAllPlagiarismCasesQuery,
  useFetchAssignedSubmissionsQuery,
  useFetchPlagiarismCaseQuery,
  useFetchRubricQuery,
  useFetchSubmissionQuery,
  useFetchSubmissionsQuery,
} from 'app/apis/reviews';
import { UdsGridContainer } from 'app/common/components/uds-grid-container';
import { selectReviewerId } from 'app/common/domains/session/session-selectors';
import { LoadingPage } from 'app/common/views/loading-page';
import { isStaff } from 'app/helpers/auth-helper';
import { calculateTimeDistance } from 'app/queue/components/countdown/helpers';
import { PlagiarismCase } from 'app/types/reviews';

import {
  Feedback,
  History,
  PlagiarismAuditRoutes,
  ProjectReviewsHeader,
  Resources,
  ReviewsSideNavbar,
  Rubric,
} from './components';
import { SubmissionStatus } from 'app/queue/domains/submissions/submissions-helpers';

export const ProjectReviews: React.FC = () => {
  const currentReviewerId = useSelector(selectReviewerId);
  const [timeRemaining, setTimeRemaining] = useState({
    d: 0,
    h: 0,
    m: 0,
    s: 0,
  });
  const [selectedPlagiarismAudit, setSelectedPlagiarismAudit] = useState<PlagiarismCase | null>(null);

  const { pathname } = useLocation();
  const history = useHistory();
  const match = useRouteMatch<{ submissionId: string }>();

  const { submissionId } = useParams<{ submissionId: string }>();
  const { data: submission, isLoading: isLoadingSubmission } = useFetchSubmissionQuery(Number(submissionId));
  const { data: assignedSubmissions } = useFetchAssignedSubmissionsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: rubric } = useFetchRubricQuery(submission?.rubricId ? submission.rubricId : skipToken);
  const { data: allSubmissions, isFetching: isFetchingAllSubmissions } = useFetchSubmissionsQuery(
    submission?.userId && submission?.rubricId
      ? {
          userId: submission.userId,
          rubricId: submission.rubricId,
          enrollmentId: submission.enrollmentId,
        }
      : skipToken
  );

  const { data: allPlagiarismCases, isLoading: isLoadingPlagiarismCases } = useFetchAllPlagiarismCasesQuery(
    submission?.id ? submission.id : skipToken
  );
  const { data: plagiarismCase, isLoading: isLoadingPlagiarismCase } = useFetchPlagiarismCaseQuery(
    selectedPlagiarismAudit?.id ? selectedPlagiarismAudit.id : skipToken
  );

  const plagiarismCasesToShow = useMemo(() => {
    if (!allPlagiarismCases) return [];
    return isStaff() ? allPlagiarismCases : allPlagiarismCases.filter((pc) => pc.graderId === currentReviewerId);
  }, [allPlagiarismCases]);

  const hasPlagiarismCases = !!plagiarismCasesToShow.length;

  const isAssignedPlagiarismCase = plagiarismCase?.graderId === currentReviewerId;

  const isPlagiarismTab = pathname.split('/').pop() === 'plagiarism-audit';

  const handleSelectPlagiarismCase = (newCase: PlagiarismCase) => {
    setSelectedPlagiarismAudit(newCase);
  };

  useEffect(() => {
    if (hasPlagiarismCases) {
      setSelectedPlagiarismAudit(plagiarismCasesToShow[0]);
    }
  }, [hasPlagiarismCases]);

  const isAssignedSubmission = useMemo(
    () => !!assignedSubmissions?.find((submission) => submission.id === Number(submissionId)),
    [assignedSubmissions]
  );
  const expirationTime = dayjs(isPlagiarismTab ? plagiarismCase?.expiresAt : submission?.expiresAt).valueOf();
  const hasHistory = !!allSubmissions && allSubmissions.length > 1;
  const isInReview =
    submission?.status === SubmissionStatus.InReview || plagiarismCase?.status === SubmissionStatus.InReview;

  useEffect(() => {
    if (expirationTime && isInReview) {
      setTimeRemaining(calculateTimeDistance(expirationTime - Date.now()));
    }
  }, [expirationTime]);

  let timer: NodeJS.Timer;

  useEffect(() => {
    if (!isAssignedSubmission || !isInReview) return;

    if (timer) clearInterval(timer);

    timer = setInterval(() => {
      const now = Date.now();
      setTimeRemaining(calculateTimeDistance(expirationTime - now));

      if (now > expirationTime) {
        clearInterval(timer);
        history.push('/');
      }
    }, 60000);

    return () => clearInterval(timer);
  }, [expirationTime]);

  if (isFetchingAllSubmissions || isLoadingSubmission || isLoadingPlagiarismCases || isLoadingPlagiarismCase)
    return <LoadingPage />;
  if (!submission || (hasPlagiarismCases && !plagiarismCase)) return null;

  return (
    <Stack spacing={0} flexGrow={1} bgColor="gray.100">
      <header>
        <ProjectReviewsHeader
          projectTitle={submission.projectTitle ?? ''}
          timeRemaining={timeRemaining}
          isPlagiarismAudit={hasPlagiarismCases && isPlagiarismTab}
        />
      </header>

      <UdsGridContainer flexGrow={1} pt="4rem" pb="7.5rem" overflowY="auto" overflowX="hidden" bgColor="inherit">
        <GridItem gridColumn={{ base: '1 / 3', lg: '1 / 4' }}>
          <ReviewsSideNavbar hasHistory={hasHistory} showPlagiarismAudit={hasPlagiarismCases} />
        </GridItem>

        <Switch>
          <Route exact path={`/project-reviews/${match.params.submissionId}`}>
            <Redirect to={`/project-reviews/${match.params.submissionId}/feedback`} />
          </Route>

          <Route path={`/project-reviews/${match.params.submissionId}/plagiarism-audit`}>
            <PlagiarismAuditRoutes
              plagiarismCases={plagiarismCasesToShow}
              plagiarismCase={plagiarismCase}
              selectPlagiarismAudit={handleSelectPlagiarismCase}
              submission={submission}
              isAssigned={isAssignedPlagiarismCase}
            />
          </Route>

          <Route path={`/project-reviews/${match.params.submissionId}/feedback`}>
            <Feedback
              submission={submission}
              allSubmissions={allSubmissions?.filter((s) => s.status !== 'canceled')}
              rubric={rubric}
              isAssigned={isAssignedSubmission}
            />
          </Route>

          <Route path={`/project-reviews/${match.params.submissionId}/rubric`}>
            <Rubric rubricId={submission.rubricId} />
          </Route>

          <Route path={`/project-reviews/${match.params.submissionId}/resources`}>
            <Resources submission={submission} />
          </Route>

          <Route path={`/project-reviews/${match.params.submissionId}/history`}>
            {isFetchingAllSubmissions || hasHistory ? (
              <History
                currentSubmissionId={submission.id}
                allSubmissions={allSubmissions?.filter((s) => s.status !== 'canceled')}
                rubric={rubric}
                isAssigned={isAssignedSubmission}
              />
            ) : (
              <Redirect to={`/project-reviews/${match.params.submissionId}/feedback`} />
            )}
          </Route>
        </Switch>
      </UdsGridContainer>
    </Stack>
  );
};
