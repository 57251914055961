import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, Text } from '@chakra-ui/react';

export const WelcomeNewUser: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box mt={4} mb={12} mx="auto" p={8} border="1px solid" borderColor="gray.200" borderRadius="md" maxW="34.375rem">
      <Heading size="h2" as="h2" mb={4}>
        {t('common.welcome!')}
      </Heading>
      <Text mb={4}>{t('thankYouForYourInterest')}</Text>
    </Box>
  );
};
