import CONFIG from 'configLoader';

import { AssignedAudit, AuditRequest } from './audit-queue-types';

export const alertCreators = {
  fetchAuditRequestsFailedToast: (reason) => ({
    id: 'fetch-audit-requests-failed',
    type: 'error',
    message: `Could not fetch audit requests: ${reason}`,
  }),
  refreshAuditRequestsFailedToast: (reason) => ({
    id: 'refresh-audit-requests-failed',
    type: 'error',
    message: `Could not refresh audit requests: ${reason}`,
  }),
  updateAuditRequestsFailedToast: (reason) => ({
    id: 'update-audit-requests-failed',
    type: 'error',
    message: `Could not update audit requests: ${reason}`,
  }),
  joinQueueFailedToast: (reason) => ({
    id: 'join-audit-queue-failed',
    type: 'error',
    message: `Could not join queue: ${reason}`,
  }),
  leaveQueueFailedToast: (reason) => ({
    id: 'leave-audit-queue-failed',
    type: 'error',
    message: `Could not leave queue: ${reason}`,
  }),
  fetchAssignedAuditsFailedToast: (reason) => ({
    id: 'fetch-assigned-audits-failed',
    type: 'error',
    message: `Could not fetch assigned audits: ${reason}`,
  }),
  newAuditAssignedToast: () => ({
    id: 'new-audit-assignments',
    type: 'notification',
    message: 'You have been assigned a new audit!',
  }),
  newAuditAssignedNotification: () => ({
    title: 'New assignment!',
    body: 'You have been assigned a new audit!',
    linkTo: `${CONFIG.dashboardUrl}/queue/overview`,
  }),
  fetchCompletedAuditsFailedToast: (reason) => ({
    id: 'fetch-completed-audits-failed',
    type: 'error',
    message: `Could not fetch completed audits: ${reason}`,
  }),
};

export function makeQueueRequestBody(projectIds: number[], languages: string[]) {
  const idLanguagePairs = projectIds.reduce((pairs, id) => {
    return pairs.concat(languages.map((l) => ({ project_id: id, language: l })));
  }, [] as Array<{ project_id: number; language: string }>);
  return { projects: idLanguagePairs };
}

export function haveAuditRequestsCompleted(previous: AuditRequest[], current: AuditRequest[]): boolean {
  const currentRequestIds = current.map((request) => request.id);
  return previous.some((request) => !currentRequestIds.includes(request.id));
}

export function hasNewAssignments(previous: AssignedAudit[], current: AssignedAudit[]): boolean {
  const previousAuditIds = previous.map((audit) => audit.id);
  return current.some((audit) => !previousAuditIds.includes(audit.id));
}
