import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Flex, GridItem, Heading, Link, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { DownloadIcon } from '@udacity/chakra-uds-icons';
import { Markdown } from '@udacity/ureact-markdown';
import dayjs from 'dayjs';

import { useFetchReviewerToolkitsQuery, useFetchRubricQuery } from 'app/apis/reviews';
import { AsymmetricCard } from 'app/common/components/asymmetric-card';
import { Submission } from 'app/types/reviews';

import { QualtiyGuidelines } from './quality-guidelines';

type ResourcesProps = {
  submission: Submission;
};

export const Resources: React.FC<ResourcesProps> = ({ submission }) => {
  const { pathname } = useLocation();
  const [tabIndex, setTabIndex] = useState(pathname.split('/').pop() === 'quality-guidelines' ? 1 : 0);

  const { data: rubric } = useFetchRubricQuery(submission.rubricId ? submission.rubricId : skipToken);
  const { data: reviewerToolkits } = useFetchReviewerToolkitsQuery({
    language: submission.language,
    projectId: submission.projectId,
  });

  const { t } = useTranslation();
  const reviewerToolkit = reviewerToolkits?.[0];

  const handleTabChange = (index: number) => setTabIndex(index);

  return (
    <>
      <GridItem gridColumn={{ base: '3 / -1', lg: '4 / -1' }}>
        <Heading size="h1" as="h1" mb={4}>
          {t('projectReviews.resources.resources')}
        </Heading>

        {reviewerToolkit && (
          <AsymmetricCard gap={4} mb={10} bgColor="white" w={{ base: 'full', lg: '66%' }}>
            <DownloadIcon w={8} h={8} />
            <Flex direction="column" gap={4}>
              <div>
                <Text size="label">{t('projectReviews.resources.reviewerToolkit')}</Text>
                <Text>
                  {t('projectReviews.resources.updated', {
                    date: dayjs(reviewerToolkit.updatedAt).format('MMMM D, YYYY'),
                  })}
                </Text>
              </div>

              <Link href={reviewerToolkit.archiveUrl}>{t('projectReviews.resources.downloadToolkit')}</Link>
            </Flex>
          </AsymmetricCard>
        )}

        {!rubric?.description && <QualtiyGuidelines />}

        {rubric?.description && (
          <Tabs index={tabIndex} onChange={handleTabChange}>
            <TabList>
              <Tab>{t('projectReviews.resources.projectDescription')}</Tab>
              <Tab>{t('projectReviews.resources.qualityGuidelines')}</Tab>
            </TabList>

            <TabPanels>
              <TabPanel pt={10}>
                <Flex direction="column" gap={4} w={{ base: 'full', lg: '66%' }}>
                  <Heading size="h2">{t('projectReviews.resources.projectDescription')}</Heading>
                  <Markdown markdownText={rubric.description} />
                </Flex>
              </TabPanel>

              <TabPanel pt={10}>
                <QualtiyGuidelines />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </GridItem>
    </>
  );
};
