import { Api, createApi, retry } from '@reduxjs/toolkit/query/react';
import { getGqlBaseQuery, GraphqlQuery } from './helpers/graphql';
import { CONNECT_API_PATH } from './service-paths';

const gqlBaseQuery = getGqlBaseQuery(CONNECT_API_PATH);

export enum ConnectGqlApiTagTypes {
  AvailableSeries = 'available-series',
  Cohort = 'cohort',
  Facilitators = 'facilitators',
  Meetings = 'meetings',
  MeetingUsers = 'meeting-users',
  Series = 'series',
}

export type ConnectGqlApi = Api<
  (
    { document, variables }: GraphqlQuery,
    { getState }: any
  ) => Promise<{ data: any; error?: any } | { error: { status: number; data: any }; data?: any }>,
  Record<string, any>,
  'connectGqlApi',
  ConnectGqlApiTagTypes
>;

export const connectGqlApi = createApi({
  baseQuery: retry(gqlBaseQuery, {
    maxRetries: 3,
  }) as typeof gqlBaseQuery,
  reducerPath: 'connectGqlApi',
  tagTypes: Object.values(ConnectGqlApiTagTypes),
  endpoints: () => ({}),
});

export const api = connectGqlApi;
