import { CountryLocale } from '../types';

export const careerCoachLocaleData: Record<string, CountryLocale> = {
  TZ: {
    country: 'Tanzania',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'TZ',
  },
  MW: {
    country: 'Malawi',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'MW',
  },
  BO: {
    country: 'Bolivia',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'BO',
  },
  BJ: {
    country: 'Benin',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'BJ',
  },
  MA: {
    country: 'Morocco',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'MA',
  },
  BY: {
    country: 'Belarus',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'BY',
  },
  AR: {
    country: 'Argentina',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'AR',
  },
  MX: {
    country: 'Mexico',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'MX',
  },
  AM: {
    country: 'Armenia',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'AM',
  },
  PY: {
    country: 'Paraguay',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'PY',
  },
  MD: {
    country: 'Moldova',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'MD',
  },
  BD: {
    country: 'Bangladesh',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'BD',
  },
  MK: {
    country: 'Macedonia',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'MK',
  },
  NG: {
    country: 'Nigeria',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'NG',
  },
  CD: {
    country: 'Congo, Democratic Republic of the',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'CD',
  },
  DZ: {
    country: 'Algeria',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'DZ',
  },
  UG: {
    country: 'Uganda',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'UG',
  },
  LK: {
    country: 'Sri Lanka',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'LK',
  },
  CO: {
    country: 'Colombia',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'CO',
  },
  KZ: {
    country: 'Kazakhstan',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'KZ',
  },
  NP: {
    country: 'Nepal',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'NP',
  },
  AZ: {
    country: 'Azerbaijan',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'AZ',
  },
  GE: {
    country: 'Georgia',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'GE',
  },
  UA: {
    country: 'Ukraine',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'UA',
  },
  UZ: {
    country: 'Uzbekistan',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'UZ',
  },
  EG: {
    country: 'Egypt',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'EG',
  },
  EG_EGP: {
    country: 'Egypt',
    localeFactor: 0.6,
    payRatePerHour: '£310.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '£15500.00',
    countryCode: 'EG',
    currency: 'EGP',
  },
  VE: {
    country: 'Venezuela',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'VE',
  },
  IN: {
    country: 'India',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'IN',
  },
  TN: {
    country: 'Tunisia',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'TN',
  },
  PK: {
    country: 'Pakistan',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'PK',
  },
  MZ: {
    country: 'Mozambique',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'MZ',
  },
  CM: {
    country: 'Cameroon',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'CM',
  },
  RW: {
    country: 'Rwanda',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'RW',
  },
  YE: {
    country: 'Yemen',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'YE',
  },
  ZM: {
    country: 'Zambia',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'ZM',
  },
  TG: {
    country: 'Togo',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'TG',
  },
  XK: {
    country: 'Kosovo',
    localeFactor: 0.6,
    payRatePerHour: '$15.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$750.00',
    countryCode: 'XK',
  },
  GH: {
    country: 'Ghana',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'GH',
  },
  CL: {
    country: 'Chile',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'CL',
  },
  SA: {
    country: 'Saudi Arabia',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'SA',
  },
  AE: {
    country: 'United Arab Emirates',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'AE',
  },
  TH: {
    country: 'Thailand',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'TH',
  },
  KH: {
    country: 'Cambodia',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'KH',
  },
  OM: {
    country: 'Oman',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'OM',
  },
  SV: {
    country: 'El Salvador',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'SV',
  },
  LT: {
    country: 'Lithuania',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'LT',
  },
  NI: {
    country: 'Nicaragua',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'NI',
  },
  CZ: {
    country: 'Czech Republic',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'CZ',
  },
  SK: {
    country: 'Slovakia',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'SK',
  },
  DO: {
    country: 'Dominican Republic',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'DO',
  },
  NA: {
    country: 'Namibia',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'NA',
  },
  BR: {
    country: 'Brazil',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'BR',
  },
  SL: {
    country: 'Sierra Leone',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'SL',
  },
  ZA: {
    country: 'South Africa',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'ZA',
  },
  HN: {
    country: 'Honduras',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'HN',
  },
  HU: {
    country: 'Hungary',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'HU',
  },
  EC: {
    country: 'Ecuador',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'EC',
  },
  GT: {
    country: 'Guatemala',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'GT',
  },
  CU: {
    country: 'Cuba',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'CU',
  },
  IQ: {
    country: 'Iraq',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'IQ',
  },
  ME: {
    country: 'Montenegro',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'ME',
  },
  MY: {
    country: 'Malaysia',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'MY',
  },
  CN: {
    country: 'China',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'CN',
  },
  PL: {
    country: 'Poland',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'PL',
  },
  KE: {
    country: 'Kenya',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'KE',
  },
  VN: {
    country: 'Vietnam',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'VN',
  },
  MN: {
    country: 'Mongolia',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'MN',
  },
  BG: {
    country: 'Bulgaria',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'BG',
  },
  PE: {
    country: 'Peru',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'PE',
  },
  RO: {
    country: 'Romania',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'RO',
  },
  ID: {
    country: 'Indonesia',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'ID',
  },
  AL: {
    country: 'Albania',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'AL',
  },
  IR: {
    country: 'Iran',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'IR',
  },
  BA: {
    country: 'Bosnia And Herzegovina',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'BA',
  },
  RU: {
    country: 'Russian Federation',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'RU',
  },
  RS: {
    country: 'Serbia',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'RS',
  },
  TR: {
    country: 'Turkey',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'TR',
  },
  PH: {
    country: 'Philippines',
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'PH',
  },
  CI: {
    country: "Cote d'Ivoire",
    localeFactor: 0.7,
    payRatePerHour: '$30.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$1,500.00',
    countryCode: 'CI',
  },
  TW: {
    country: 'Taiwan',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'TW',
  },
  QA: {
    country: 'Qatar',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'QA',
  },
  JM: {
    country: 'Jamaica',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'JM',
  },
  LB: {
    country: 'Lebanon',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'LB',
  },
  UY: {
    country: 'Uruguay',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'UY',
  },
  BH: {
    country: 'Bahrain',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'BH',
  },
  GR: {
    country: 'Greece',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'GR',
  },
  CY: {
    country: 'Cyprus',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'CY',
  },
  JO: {
    country: 'Jordan',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'JO',
  },
  ES: {
    country: 'Spain',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'ES',
  },
  SI: {
    country: 'Slovenia',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'SI',
  },
  MU: {
    country: 'Mauritius',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'MU',
  },
  MM: {
    country: 'Myanmar',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'MM',
  },
  TT: {
    country: 'Trinidad And Tobago',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'TT',
  },
  ZW: {
    country: 'Zimbabwe',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'ZW',
  },
  KW: {
    country: 'Kuwait',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'KW',
  },
  PA: {
    country: 'Panama',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'PA',
  },
  EE: {
    country: 'Estonia',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'EE',
  },
  FJ: {
    country: 'Fiji',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'FJ',
  },
  CR: {
    country: 'Costa Rica',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'CR',
  },
  PT: {
    country: 'Portugal',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'PT',
  },
  PS: {
    country: 'Palestinian Territory',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'PS',
  },
  BZ: {
    country: 'Belize',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'BZ',
  },
  LV: {
    country: 'Latvia',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'LV',
  },
  HR: {
    country: 'Croatia',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'HR',
  },
  SN: {
    country: 'Senegal',
    localeFactor: 0.8,
    payRatePerHour: '$40.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,000.00',
    countryCode: 'SN',
  },
  CH: {
    country: 'Switzerland',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'CH',
  },
  IS: {
    country: 'Iceland',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'IS',
  },
  NO: {
    country: 'Norway',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'NO',
  },
  BS: {
    country: 'Bahamas',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'BS',
  },
  LU: {
    country: 'Luxembourg',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'LU',
  },
  JP: {
    country: 'Japan',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'JP',
  },
  DK: {
    country: 'Denmark',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'DK',
  },
  HK: {
    country: 'Hong Kong',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'HK',
  },
  KR: {
    country: 'South Korea',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'KR',
  },
  IE: {
    country: 'Ireland',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'IE',
  },
  FR: {
    country: 'France',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'FR',
  },
  NL: {
    country: 'Netherlands',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'NL',
  },
  IL: {
    country: 'Israel',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'IL',
  },
  BE: {
    country: 'Belgium',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'BE',
  },
  FI: {
    country: 'Finland',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'FI',
  },
  NZ: {
    country: 'New Zealand',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'NZ',
  },
  AU: {
    country: 'Australia',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'AU',
  },
  AT: {
    country: 'Austria',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'AT',
  },
  SE: {
    country: 'Sweden',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'SE',
  },
  SG: {
    country: 'Singapore',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'SG',
  },
  IT: {
    country: 'Italy',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'IT',
  },
  DE: {
    country: 'Germany',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'DE',
  },
  GB: {
    country: 'United Kingdom',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'GB',
  },
  CA: {
    country: 'Canada',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'CA',
  },
  PR: {
    country: 'Puerto Rico',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'PR',
  },
  MT: {
    country: 'Malta',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'MT',
  },
  US: {
    country: 'United States of America',
    localeFactor: 1,
    payRatePerHour: '$50.00',
    upToHoursPerMonth: '50',
    maxPaymentPerMonth: '$2,500.00',
    countryCode: 'US',
  },
};
