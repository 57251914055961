import _ from 'lodash';
const get = (key) => {
  if (_.isUndefined(key)) {
    return new TypeError('Key must be defined');
  }

  return JSON.parse(window.localStorage.getItem(key));
};

const set = (key, value) => {
  if (_.isUndefined(key)) {
    return new TypeError('Key must be defined.');
  }
  if (_.isUndefined(value)) {
    return new TypeError('Value must be defined.');
  }

  window.localStorage.setItem(key, JSON.stringify(value));
  return;
};

export default { get, set };
