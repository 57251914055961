import { combineReducers } from 'redux';
import { actionTypes } from './reviewer-data-actions';
import { makeAsyncReducer } from 'app/common/domains/async';

const reducer = combineReducers({
  fetchEarnings: makeAsyncReducer(actionTypes.FETCH_EARNINGS, actionTypes.FETCH_EARNINGS_FULFILLED),
  fetchRatingsMatrix: makeAsyncReducer(actionTypes.FETCH_RATINGS_MATRIX, actionTypes.FETCH_RATINGS_MATRIX_FULFILLED),
});

export default reducer;
