import { combineReducers } from 'redux';
import { actionTypes } from './plagiarism-case-queue-actions';
import { makeAsyncReducerWithFulfilledAndRejected } from 'app/common/domains/async/async-reducer';

const reducer = combineReducers({
  fetchCompletedPlagiarismCases: makeAsyncReducerWithFulfilledAndRejected(
    actionTypes.FETCH_COMPLETED_PLAGIARISM_CASES_START,
    actionTypes.FETCH_COMPLETED_PLAGIARISM_CASES_FULFILLED,
    actionTypes.FETCH_COMPLETED_PLAGIARISM_CASES_REJECTED
  ),
});

export default reducer;
