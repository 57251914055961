import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';

import { isStaff } from 'app/helpers/auth-helper';
import type { PlagiarismCase, Submission } from 'app/types/reviews';

import { PlagiarismAudit } from './plagiarism-audit';
import { PlagiarismAuditReadOnly } from './plagiarism-audit-read-only';

type PlagiarismAuditRoutesProps = {
  plagiarismCases: PlagiarismCase[];
  plagiarismCase: PlagiarismCase | undefined;
  selectPlagiarismAudit: (newAudit: PlagiarismCase) => void;
  submission: Submission;
  isAssigned: boolean;
};

export const PlagiarismAuditRoutes = ({
  plagiarismCases,
  plagiarismCase,
  selectPlagiarismAudit,
  submission,
  isAssigned,
}: PlagiarismAuditRoutesProps) => {
  const match = useRouteMatch<{ submissionId: string }>();

  const hasPlagiarismCases = !!plagiarismCases.length;

  if (hasPlagiarismCases && isAssigned) {
    return (
      <PlagiarismAudit
        caseId={plagiarismCase?.id}
        sourceUrl={submission.plagiarismSourceUrl}
        reviewerNote={submission.resultReason || ''}
        submissionUrl={submission.archiveUrl || submission.url || undefined}
        isAssigned={isAssigned}
      />
    );
  }

  if (hasPlagiarismCases && isStaff()) {
    return (
      <PlagiarismAuditReadOnly
        plagiarismCases={plagiarismCases}
        selectedPlagiarismAudit={plagiarismCase}
        selectPlagiarismAudit={selectPlagiarismAudit}
        sourceUrl={submission.plagiarismSourceUrl}
        reviewerNote={submission.resultReason || ''}
        submissionUrl={submission.archiveUrl || submission.url || undefined}
        isAssigned={isAssigned}
      />
    );
  }

  return (
    <>
      {isStaff() ? (
        <Redirect to={`/project-reviews/${match.params.submissionId}/feedback`} />
      ) : (
        <Redirect to={`/queue/overview`} />
      )}
    </>
  );
};
