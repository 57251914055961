import apiService from '@udacity/ureact-api-service';
import CONFIG from 'configLoader';
import {
  FETCH_QUEUE_ASSIGNMENTS_OPERATION_NAME,
  FETCH_QUEUE_EARNINGS_OPERATION_NAME,
  FETCH_QUEUE_HISTORY_OPERATION_NAME,
  FETCH_QUEUE_OPERATION_NAME,
  JOIN_QUEUE_OPERATION_NAME,
  LEAVE_QUEUE_OPERATION_NAME,
} from './constants';
import {
  fetchQueueQuery,
  fetchQueueAssignmentsQuery,
  fetchQueueHistoryQuery,
  fetchQueueEarningsSummaryQuery,
  joinQueueQuery,
  leaveQueueQuery,
} from './pensieve-api-schema';

function gql(query: string, operationName: string, variables?: { [key: string]: any }) {
  return apiService.makeGqlRequest(CONFIG.pensieveApiUrl, {
    query,
    operationName,
    variables,
  });
}

export default {
  async fetchQueue() {
    const {
      data: { queue },
    } = await gql(fetchQueueQuery, FETCH_QUEUE_OPERATION_NAME);
    return queue;
  },
  async fetchQueueAssignments() {
    const {
      data: {
        queue: { assigned },
      },
    } = await gql(fetchQueueAssignmentsQuery, FETCH_QUEUE_ASSIGNMENTS_OPERATION_NAME);
    return assigned;
  },
  async fetchQueueHistory(count: number, afterCursor = '') {
    const {
      data: {
        queue: { history },
      },
    } = await gql(fetchQueueHistoryQuery, FETCH_QUEUE_HISTORY_OPERATION_NAME, {
      count,
      afterCursor,
    });
    return history;
  },
  async fetchQueueEarningsSummary(start: string) {
    const {
      data: {
        queue: { earnings },
      },
    } = await gql(fetchQueueEarningsSummaryQuery, FETCH_QUEUE_EARNINGS_OPERATION_NAME, { start });
    return earnings;
  },
  async joinQueue(projectIds: string[]) {
    const {
      data: {
        queue: { enqueue },
      },
    } = await gql(joinQueueQuery, JOIN_QUEUE_OPERATION_NAME, { projectIds });
    return enqueue;
  },
  async leaveQueue() {
    const {
      data: {
        queue: { dequeue },
      },
    } = await gql(leaveQueueQuery, LEAVE_QUEUE_OPERATION_NAME);
    return dequeue;
  },
};
