import i18n from 'app/i18n';

const parsePathname = (pathname) => {
  return pathname.match(/.*\/(.*)/)[1];
};

const getHeader = (header: string): string => {
  switch (header) {
    case 'career':
      return i18n.t('handbook.careerCoachGuidelines');
    case 'guru':
      return i18n.t('handbook.guruGuidelines');
    case 'tutor':
      return i18n.t('handbook.tutorGuidelines');
    case 'moderator':
      return i18n.t('handbook.communityModeratorGuidelines');
    case 'session-lead':
      return i18n.t('handbook.sessionLeadGuidelines');
    default:
      return i18n.t('handbook.generalGuidelines');
  }
};

export default {
  getHeader,
  parsePathname,
};
