import React from 'react';
import { useTranslation } from 'react-i18next';

import { AvatarImage } from 'app/common/components/onboarding/avatar-image';
import { TimezoneSelector } from 'app/common/components/onboarding/timezone-selector';
import PaymentSettings from 'app/common/views/profile-editor/payment-settings';
import { MAX_CHARS } from 'app/common/views/profile-editor/form-helper';
import { TermsOfService } from 'app/common/components/onboarding/terms-of-service';
import { FormFields } from 'app/common/views/profile-editor/form-types';

import CONFIG from 'configLoader';
import { MentorFormInput } from './mentor-form-input';
import { MentorFormTextarea } from './mentor-form-textarea';
import styles from '../profile-editor.module.scss';

type UpdateMentorFormProps = {
  fields: FormFields;
  handleFieldChange: ({ id, value }: { id: string; value: string | number }) => void;
  handleTextareaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasTOSUpdate: boolean;
};

export const UpdateMentorForm: React.FC<UpdateMentorFormProps> = ({
  fields,
  handleFieldChange,
  handleTextChange,
  handleTextareaChange,
  hasTOSUpdate,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <AvatarImage
        avatarUrl={fields.avatar_url}
        onAvatarChange={(url: string) => handleFieldChange({ id: 'avatar_url', value: url })}
      />

      <PaymentSettings />

      <MentorFormInput id="linkedin_url" label="LinkedIn" value={fields.linkedin_url} onChange={handleTextChange} />

      <MentorFormInput
        id="github_url"
        label={t('mentorProfile.form.github')}
        value={fields.github_url}
        onChange={handleTextChange}
        isOptional
      />

      <MentorFormTextarea
        id="bio"
        label={t('mentorProfile.form.biography')}
        helperText={t('mentorProfile.form.addAShortBio')}
        value={fields.bio}
        maxChars={MAX_CHARS.textarea}
        onChange={handleTextareaChange}
      />

      <TimezoneSelector onChange={handleFieldChange} value={fields.timezone} />

      <MentorFormInput
        id="phone_number"
        label={t('mentorProfile.form.phoneNumber')}
        helperText={t('mentorProfile.form.yourPhoneNumber')}
        value={fields.phone_number}
        handleFieldChange={handleFieldChange}
      />

      {hasTOSUpdate && (
        <div className={styles.tos}>
          <TermsOfService
            id="agreement_version"
            handleTOS={(e) => {
              handleFieldChange({
                id: 'agreement_version',
                value: CONFIG.latestAgreementVersion,
              });
            }}
            isChecked={!!fields.agreement_version}
          />
        </div>
      )}
    </>
  );
};
