import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

import { useUnassignPlagiarismAuditMutation } from 'app/apis/reviews';

type UnassignPlagiarismAuditAlertProps = {
  isOpen: boolean;
  onClose: () => void;
  plagiarismCaseId: number;
};

export const UnassignPlagiarismAuditAlert = ({
  isOpen,
  onClose,
  plagiarismCaseId,
}: UnassignPlagiarismAuditAlertProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  const [unassignPlagiarismAudit, { isLoading: isUnassigning }] = useUnassignPlagiarismAuditMutation();

  const handleSubmit = async () => {
    await unassignPlagiarismAudit(plagiarismCaseId)
      .unwrap()
      .then(() => {
        history.push('/queue/overview');
      })
      .catch((e) => console.error(e));
  };

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef} isCentered>
      <AlertDialogOverlay />
      <AlertDialogContent aria-label={t('projectReviews.plagiarismAudit.confirmUnassignment')} maxW="35rem" maxH="90vh">
        <AlertDialogHeader>{t('projectReviews.plagiarismAudit.confirmUnassignment')}</AlertDialogHeader>
        <AlertDialogCloseButton />

        <AlertDialogBody>{t('projectReviews.plagiarismAudit.confirmUnassignmentDescription')}</AlertDialogBody>

        <AlertDialogFooter gap={6} justifyContent="end">
          <Button variant="ghost" onClick={onClose} ref={cancelRef}>
            {t('common.cancel')}
          </Button>
          <Button onClick={handleSubmit} isLoading={isUnassigning}>
            {t('common.confirm')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
