import React, { ReactNode } from 'react';
import { InfoIcon } from '@udacity/chakra-uds-icons';
import styles from './required-action-banner.module.scss';

type RequiredActionBannerProps = {
  message: ReactNode;
  link: ReactNode;
};

export const RequiredActionBanner: React.FC<RequiredActionBannerProps> = ({ message, link }) => {
  return (
    <div className={styles['required-action']}>
      <span>
        <InfoIcon w={8} h={8} />
        {message}
      </span>
      <span className={styles['action-link']}>{link}</span>
    </div>
  );
};
