import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Image, Link as ChakraLink } from '@chakra-ui/react';
import { ConnectRoutes } from './routes';
import { UdsGridContainer } from 'app/common/components/uds-grid-container';
import UdacityLogo from 'assets/images/udacity-logo.svg';
import { useTranslation } from 'react-i18next';

export const ConnectLayout: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Flex as="header" align="center" justify="center" zIndex={10} p={4} boxShadow="md">
        <ChakraLink as={Link} to="/connect">
          <Image src={UdacityLogo} alt={t('common.udacityLogo')} w="12.5rem" h="2.25rem" />
        </ChakraLink>
      </Flex>
      <Box bgColor="gray.50">
        <UdsGridContainer>
          <ConnectRoutes />
        </UdsGridContainer>
      </Box>
    </>
  );
};
