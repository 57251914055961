import { GridItem, Heading } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Meetings } from './meetings';
import { ConnectNotifications } from './notifications';

export const ConnectLandingPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <GridItem gridColumn="1 / -1">
      <Heading size="h1" as="h1" mb={6}>
        {t('connect.sessionsHeader')}
      </Heading>
      <ConnectNotifications />
      <Meetings />
    </GridItem>
  );
};
