import _map from 'lodash/map';
import { takeEvery, take, fork, all, call, put, race, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { actionTypes } from './reviews-queue-actions';
import { actionTypes as submissionActionTypes } from 'app/queue/domains/submissions/submissions-actions';
import {
  getAssignedSubmissions,
  getSubmissionRequests,
  createSubmissionRequest,
  deleteSubmissionRequest,
  refreshSubmissionRequest,
  updateSubmissionRequest,
} from 'app/services/reviews-api-service';
import queueConstants, { QUEUE_VOLUME } from 'app/queue/domains/reviews-queue/reviews-queue-constants';
import {
  getQueuingAvailability,
  getQueueSettings,
  getSubmissionRequestBody,
  getActiveSubmissionRequests,
  getActiveSubmissionRequestIds,
} from 'app/queue/domains/reviews-queue/reviews-queue-selectors';

const { POLLING_INTERVAL, SUBMISSION_REQUEST_STATUS } = queueConstants;

export function* fetchAll() {
  try {
    const payload = yield call(getSubmissionRequests);
    yield put({ type: actionTypes.FETCH_ALL_REQUESTS_FULFILLED, payload });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_ALL_REQUESTS_FULFILLED, error });
  }
}

function* create(action) {
  const submissionRequestBody = action.payload;

  try {
    const payload = yield call(createSubmissionRequest, submissionRequestBody);
    yield put({ type: actionTypes.CREATE_REQUEST_FULFILLED, payload });

    // if brand new SubmissionRequest is already fulfilled
    if (payload.status !== SUBMISSION_REQUEST_STATUS.available) {
      yield put({ type: submissionActionTypes.FETCH_ALL_ASSIGNED });
      // cancel polling if this is a single SR that is fulfilled
      const { queueVolume } = yield select(getQueueSettings);
      if (queueVolume === QUEUE_VOLUME.single) {
        yield put({ type: actionTypes.CANCEL_POLL_ALL_REQUESTS });
      }
    }
  } catch (error) {
    yield put({ type: actionTypes.CREATE_REQUEST_FULFILLED, error });
  }
}

function* createAll(payload, count = 1) {
  const createRequests = _map(Array.from(new Array(count)), () => {
    return call(create, { payload });
  });
  yield all(createRequests);
}

function* destroy(action) {
  const submissionRequestId = action.payload;

  try {
    yield call(deleteSubmissionRequest, submissionRequestId);
    yield put({
      type: actionTypes.DELETE_REQUEST_FULFILLED,
      payload: submissionRequestId,
    });
  } catch (error) {
    yield put({ type: actionTypes.DELETE_REQUEST_FULFILLED, error });
  }
}

function* destroyAll({ payload }) {
  const startIndex = payload || 0;
  const activeSubmissionRequestIds = yield select(getActiveSubmissionRequestIds);
  const destroyRequests = _map(activeSubmissionRequestIds.slice(startIndex), (id) => {
    return call(destroy, { payload: id });
  });
  yield all(destroyRequests);
}

function* update(action) {
  const { submissionRequestId, submissionRequest } = action.payload;

  try {
    yield call(updateSubmissionRequest, submissionRequestId, submissionRequest);
    const response = yield call(refreshSubmissionRequest, submissionRequestId);
    yield put({
      type: actionTypes.UPDATE_REQUEST_FULFILLED,
      payload: {
        submissionRequest: response,
        requestId: submissionRequestId,
      },
    });
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_REQUEST_FULFILLED, error });
  }
}

function* updateAll(queueSettings = {}) {
  const submissionRequest = yield select(getSubmissionRequestBody);
  // get queue settings
  const selectedSettings = yield select(getQueueSettings);
  const settings = {
    ...selectedSettings,
    ...queueSettings,
  };
  let activeSubmissionRequests = yield select(getActiveSubmissionRequests);

  // remove all but the first active SubmissionRequest
  // if we are changing from multi to single queue
  if (settings.queueVolume === QUEUE_VOLUME.single && activeSubmissionRequests.length > 1) {
    yield call(destroyAll, { payload: 1 });
    activeSubmissionRequests = yield select(getActiveSubmissionRequests);

    // create new SubmissionRequests if
    // changing from single queue to multiqueue
  } else if (settings.queueVolume === QUEUE_VOLUME.max && activeSubmissionRequests.length === 1) {
    const { available } = yield select(getQueuingAvailability);
    if (available) {
      yield call(createAll, submissionRequest, available);
    }
  }

  try {
    const updateRequests = _map(activeSubmissionRequests, ({ id }) => {
      return call(update, {
        payload: { submissionRequest, submissionRequestId: id },
      });
    });
    yield all(updateRequests);
    yield put({ type: actionTypes.UPDATE_ALL_REQUESTS_FULFILLED });
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_ALL_REQUESTS_FULFILLED, error });
  }
}

function* refresh(action) {
  const submissionRequestId = action.payload;

  try {
    const submissionRequest = yield call(refreshSubmissionRequest, submissionRequestId);
    yield put({
      type: actionTypes.UPDATE_REQUEST_FULFILLED,
      payload: {
        requestId: submissionRequestId,
        submissionRequest,
      },
    });
  } catch (error) {
    yield put({ type: actionTypes.REFRESH_REQUEST_FULFILLED, error });
  }
}

function* refreshAll() {
  const activeSubmissionRequestIds = yield select(getActiveSubmissionRequestIds);
  const refreshRequests = _map(activeSubmissionRequestIds, (id) => {
    return call(refresh, { payload: id });
  });
  yield all(refreshRequests);
}

function* pollAllSubmissionRequests(queueSettings = {}) {
  while (true) {
    try {
      // fetch all active SubmissionRequests
      const activeSubmissionRequests = yield call(getSubmissionRequests);
      yield put({
        type: actionTypes.FETCH_ALL_REQUESTS_FULFILLED,
        payload: activeSubmissionRequests,
      });

      // fetch all assigned submissions - make sure we're always up to date here
      const assignedSubmissions = yield call(getAssignedSubmissions);
      yield put({
        type: submissionActionTypes.FETCH_ALL_ASSIGNED_FULFILLED,
        payload: assignedSubmissions,
      });

      // check queuing availability
      const { available } = yield select(getQueuingAvailability);
      // get queue settings
      const selectedSettings = yield select(getQueueSettings);
      const settings = {
        ...selectedSettings,
        ...queueSettings,
      };

      const isSingleAndAvailable = !activeSubmissionRequests.length && settings.queueVolume === QUEUE_VOLUME.single;

      const isMulti = settings.queueVolume === QUEUE_VOLUME.max;

      // create new SubmissionRequests if we have availability
      if (available && (isSingleAndAvailable || isMulti)) {
        const submissionRequestBody = yield select(getSubmissionRequestBody);
        const createCount = isMulti ? available : 1;
        yield fork(createAll, submissionRequestBody, createCount);
      }

      yield delay(POLLING_INTERVAL);
    } catch (error) {
      yield put({ type: actionTypes.FETCH_ALL_REQUESTS_FULFILLED, error });
      return;
    }
  }
}

function* watchFetchAll() {
  yield takeEvery(actionTypes.FETCH_ALL_REQUESTS, fetchAll);
}

function* watchCreate() {
  yield takeEvery(actionTypes.CREATE_REQUEST, create);
}

function* watchDestroy() {
  yield takeEvery(actionTypes.DELETE_REQUEST, destroy);
}

function* watchDestroyAll() {
  yield takeEvery(actionTypes.DELETE_ALL_REQUESTS, destroyAll);
}

function* watchUpdate() {
  yield takeEvery(actionTypes.UPDATE_REQUEST, update);
}

function* watchUpdateAll() {
  yield takeEvery(actionTypes.UPDATE_ALL_REQUESTS, updateAll);
}

function* watchRefresh() {
  yield takeEvery(actionTypes.REFRESH_REQUEST, refresh);
}

function* watchRefreshAll() {
  yield takeEvery(actionTypes.REFRESH_ALL_REQUESTS, refreshAll);
}

function* watchPollSubmissionRequests() {
  while (true) {
    yield take(actionTypes.POLL_ALL_REQUESTS);
    yield race([call(pollAllSubmissionRequests), take(actionTypes.CANCEL_POLL_ALL_REQUESTS)]);
  }
}

export default function* queueSaga() {
  yield [
    fork(watchFetchAll),
    fork(watchCreate),
    fork(watchDestroy),
    fork(watchDestroyAll),
    fork(watchUpdate),
    fork(watchUpdateAll),
    fork(watchRefresh),
    fork(watchRefreshAll),
    fork(watchPollSubmissionRequests),
  ];
}
