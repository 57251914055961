import React, { ReactNode } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Text } from '@chakra-ui/react';

export type AccordionOption = {
  id: string;
  title: ReactNode;
  component: ReactNode;
  shouldLazyLoad?: boolean;
};

export type AccordionListProps = {
  allowMultiple?: boolean;
  defaultIndex?: number | number[];
  index?: number | number[];
  options: AccordionOption[];
  onChange?: (id: string) => void;
};

export const AccordionList: React.FC<AccordionListProps> = ({
  options,
  allowMultiple,
  defaultIndex,
  index,
  onChange,
}) => (
  <Accordion flex="1" allowMultiple={allowMultiple} allowToggle defaultIndex={defaultIndex} index={index}>
    {options.map(({ title, component, shouldLazyLoad, id }, idx) => (
      <AccordionItem key={`accordion-item-${idx}`} mb={4} border="1px solid #C7C7C7" borderRadius="base">
        {({ isExpanded }) => {
          return (
            <>
              <AccordionButton
                as={Flex}
                justifyContent="space-between"
                border="none"
                borderRadius="base"
                fontSize="sm"
                py={4}
                ps={6}
                pe={0}
                onClick={onChange ? () => onChange(id) : undefined}
              >
                <Text as="h4">{title}</Text>
                <AccordionIcon me={2} />
              </AccordionButton>
              <AccordionPanel border="none" p="1rem 0">
                {(!shouldLazyLoad || isExpanded) && component}
              </AccordionPanel>
            </>
          );
        }}
      </AccordionItem>
    ))}
  </Accordion>
);
