import { Option } from 'app/helpers/form-helper';
import { MultiValue, SingleValue } from 'chakra-react-select';

export function getFilterParam(option: SingleValue<Option> | MultiValue<Option>, multi?: boolean) {
  if (option === null) return false;

  const isMultiValue = multi && Array.isArray(option);
  const isSingleValue = !multi && 'value' in option;

  if (isMultiValue) {
    return option.join(',') || false;
  }

  if (isSingleValue) {
    return String(option.value) || false;
  }

  return false;
}
