const SERVICE_NAME = 'MentorDash';

export const fetchQueueQuery = `
  query ${SERVICE_NAME}_FetchQueue {
    queue {
      assigned {
        atRisk
        expiresAt
        opportunity {
          project {
            projectID
            title
          }
          answerPayment {
            amount
            currencyCode
          }
          commentPayment {
            amount
            currencyCode
          }
        }
        question {
          id
        }
      }
      reviewer {
        status
        expiresAt
        options {
          enabled
          opportunity {
            answerPayment {
              amount
              currencyCode
            }
            commentPayment {
              amount
              currencyCode
            }
            project {
              projectID
              title
            }
          }
        }
      }
    }
  } 
`;

export const joinQueueQuery = `
  mutation ${SERVICE_NAME}_JoinQueue($projectIds: [String!]!) {
    queue {
      enqueue(projects: $projectIds) {
        status
        expiresAt
        options {
          enabled
          opportunity {
            project { projectID }
          }
        }
      }
    }
  }
`;

export const leaveQueueQuery = `
  mutation ${SERVICE_NAME}_LeaveQueue {
    queue {
      dequeue {
        status
        expiresAt
        options {
          enabled
          opportunity {
            project { projectID }
          }
        }
      }
    }
  }
`;

export const fetchQueueAssignmentsQuery = `
  query ${SERVICE_NAME}_FetchQueueAssignments {
    queue {
      assigned {
        atRisk
        expiresAt
        opportunity {
          project {
            projectID
            title
          }
          answerPayment {
            amount
            currencyCode
          }
          commentPayment {
            amount
            currencyCode
          }
        }
        question {
          id
          link
        }
      }
    }
  }
`;

export const fetchQueueHistoryQuery = `
  query ${SERVICE_NAME}_FetchHistory($count: Int, $afterCursor: String) {
    queue {
      history(first: $count, after: $afterCursor) {
        edges {
          cursor
          node {
            project {
              title
            }
            createdAt
            payment {
              amount
              currencyCode
            }
            type
            question {
              id
            }
          }
        }
        totalCount
      }
    }
  }
`;

export const fetchQueueEarningsSummaryQuery = `
  query ${SERVICE_NAME}_FetchEarningsSummary($start: DateTime!) {
    queue {
      earnings(start: $start)
    }
  }
`;
