import React from 'react';
import { Route, Switch } from 'react-router';
import { ConnectLandingPage } from './landing';
import { MeetingOverviewPage } from './meeting';

export const ConnectRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/connect">
        <ConnectLandingPage />
      </Route>
      <Route exact path="/connect/meetings/:meetingId">
        <MeetingOverviewPage />
      </Route>
    </Switch>
  );
};
