import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, NavLink } from 'react-router-dom';
import auth from '@udacity/ureact-hoth';
import { Flex, Image, Link, Tooltip } from '@chakra-ui/react';
import { ConceptIcon, LogoutIcon, LightbulbIcon, SettingsIcon, StudentHubIcon } from '@udacity/chakra-uds-icons';

import trademark from 'assets/images/udacity-logo-vertical.svg';

import CONFIG from 'configLoader';

import styles from './services-bar.module.scss';

export const ServicesBar: React.FC = () => {
  const { t } = useTranslation();

  const handleSignOut = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    auth.signOut();
  };

  return (
    <Flex direction="column" justify="space-between" align="center" h="full">
      <NavLink to="/" title={t('common.home')} className={styles['home-link']}>
        <Image alt={t('common.udacityLogo')} src={trademark} title={t('common.home')} p={1} w="full" />
      </NavLink>

      <ul className={styles['services-links-wrapper']}>
        <Tooltip placement="end" hasArrow label={t('common.knowledge')}>
          <Link
            data-ref="nav-link"
            bgColor="accent.sky.500"
            borderTopEndRadius="base"
            py={2}
            href={CONFIG.knowledge_url}
            title={t('common.knowledge')}
            isExternal
          >
            <LightbulbIcon w={10} h={10} color="white" />
          </Link>
        </Tooltip>
        <Tooltip placement="end" hasArrow label={t('common.udacityMentorCircle')}>
          <Link
            data-ref="nav-link"
            bgColor="accent.purple"
            borderBottomEndRadius="base"
            py={2}
            href={CONFIG.student_hub_url}
            title={t('common.udacityMentorCircle')}
            isExternal
          >
            <StudentHubIcon w={10} h={10} color="white" />
          </Link>
        </Tooltip>
      </ul>

      <div className={styles['end']}>
        <div className={styles['end-link']}>
          <ReactRouterLink to="/guidelines" title={t('common.guidelines')}>
            <ConceptIcon w={10} h={10} color="blue.500" />
          </ReactRouterLink>
        </div>
        <div className={styles['end-link']}>
          <ReactRouterLink to="/update" title={t('common.settings')}>
            <SettingsIcon w={10} h={10} color="blue.500" />
          </ReactRouterLink>
        </div>
        <div className={styles['end-link']}>
          <a href="/logout" onClick={handleSignOut} title={t('common.logout')}>
            <LogoutIcon w={10} h={10} color="blue.500" />
          </a>
        </div>
      </div>
    </Flex>
  );
};
