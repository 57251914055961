import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Select, type SingleValue } from 'chakra-react-select';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  Link,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { getChakraReactSelectStyles } from '@udacity/chakra-uds-theme';
import { Markdown } from '@udacity/ureact-markdown';

import { useFetchPlagiarismCritiquesQuery } from 'app/apis/reviews';
import { Alert } from 'app/common/components/alert';
import { AsymmetricCard } from 'app/common/components/asymmetric-card';
import { getTimeSince } from 'app/project-reviews/helpers';
import type { PlagiarismCase } from 'app/types/reviews';

import { PlagiarismAuditReason } from './plagiarism-audit-reason';

type Option = {
  value: number;
  label: string;
  index: number;
};

type PlagiarismAuditReadOnlyProps = {
  plagiarismCases: PlagiarismCase[];
  selectedPlagiarismAudit: PlagiarismCase | undefined;
  selectPlagiarismAudit: (newAudit: PlagiarismCase) => void;
  sourceUrl: string | undefined;
  reviewerNote: string;
  submissionUrl: string | undefined;
  isAssigned: boolean;
};

export const PlagiarismAuditReadOnly = ({
  plagiarismCases,
  selectedPlagiarismAudit,
  selectPlagiarismAudit,
  sourceUrl,
  reviewerNote,
  submissionUrl,
  isAssigned,
}: PlagiarismAuditReadOnlyProps) => {
  const { t } = useTranslation();

  const { data: critiques } = useFetchPlagiarismCritiquesQuery(
    selectedPlagiarismAudit?.id ? selectedPlagiarismAudit?.id : skipToken
  );

  const isPlagiarized = useMemo(() => critiques?.some((c) => c.result !== 'passed'), [critiques]);

  const timeSinceLastReviewed = useMemo(() => {
    return selectedPlagiarismAudit?.completedAt ? getTimeSince(selectedPlagiarismAudit?.completedAt) : null;
  }, [selectedPlagiarismAudit]);

  const options = useMemo(
    () =>
      plagiarismCases.map((p, index) => ({
        value: p.id,
        label: t('projectReviews.plagiarismAudit.auditNumber', { index: index + 1 }),
        index: index + 1,
      })),
    [plagiarismCases]
  );

  const selectedAudit = useMemo(
    () => options.find((opt) => !!selectedPlagiarismAudit && opt.value === selectedPlagiarismAudit.id),
    [options, selectedPlagiarismAudit]
  );

  const onOptionChange = (newValue: SingleValue<Option>) => {
    if (newValue) {
      const newPlagiarismAudit = plagiarismCases.find((p) => p.id === newValue.value);

      if (newPlagiarismAudit) {
        selectPlagiarismAudit(newPlagiarismAudit);
      }
    }
  };

  if (!critiques) return null;

  const fromSource = critiques.filter((c) => c.rubricItemId === 18999)[0];
  const fromAlternative = critiques.filter((c) => c.rubricItemId === 19000)[0];

  return (
    <>
      <GridItem gridColumn={{ base: '3 / -1', lg: '4 / -1' }}>
        <Heading size="h1" as="h1" mb={6}>
          {t('projectReviews.plagiarismAudit.title')}
        </Heading>

        <Box w={{ base: 'full', lg: '78%' }} mb={10}>
          <Heading size="h3" as="h3" mb={2}>
            {t('projectReviews.feedback.instructions')}
          </Heading>
          <Text mb={4}>
            <Trans i18nKey="projectReviews.plagiarismAudit.instructions" />
          </Text>

          <FormControl mb={4}>
            <FormLabel fontSize="xl" fontFamily="heading" fontWeight="normal" lineHeight="tallest" mb={2}>
              {t('projectReviews.plagiarismAudit.previousAudits')}
            </FormLabel>
            <Text mb={6}>
              {t('projectReviews.plagiarismAudit.currentlyViewing', { auditNumber: selectedAudit?.index })}
            </Text>

            <Box mb={timeSinceLastReviewed ? 2 : 6}>
              <Select<Option>
                chakraStyles={getChakraReactSelectStyles()}
                options={options}
                value={selectedAudit}
                onChange={onOptionChange}
                isClearable={false}
              />
            </Box>
            {timeSinceLastReviewed && (
              <Text size="caption" mb={4}>
                {t('projectReviews.history.reviewedTimeSince', {
                  timeSince: timeSinceLastReviewed,
                })}
              </Text>
            )}
          </FormControl>

          <Button
            as="a"
            href={submissionUrl}
            variant="outline"
            target="_blank"
            rel="noopener noreferrer"
            download
            mb={10}
          >
            {t('projectReviews.feedback.downloadProject')}
          </Button>

          {critiques?.every((c) => c.result !== null) && (
            <Alert status={isPlagiarized ? 'error' : 'success'} mb={10}>
              {isPlagiarized
                ? t('projectReviews.plagiarismAudit.confirmedPlagiarized')
                : t('projectReviews.plagiarismAudit.confirmedOriginal')}
            </Alert>
          )}

          <Heading size="h3" as="h3" mb={2}>
            {t('projectReviews.plagiarismAudit.priorReview')}
          </Heading>
          <AsymmetricCard bgColor="white" gap={2}>
            <Flex direction="column" maxW="full">
              <Text size="label">{t('projectReviews.plagiarismAudit.reviewerNote')}</Text>
              <Text mb={4}>{t('projectReviews.plagiarismAudit.keyParts')}</Text>
              <Text>
                <Trans
                  i18nKey="projectReviews.plagiarismAudit.potentialSourceUrl"
                  values={{ sourceUrl }}
                  components={{
                    source_link: <Link href={sourceUrl} isExternal />,
                  }}
                />
              </Text>
              <Markdown markdownText={reviewerNote} />
            </Flex>
          </AsymmetricCard>
        </Box>

        <Heading size="h3" as="h3" mb={2}>
          {t('projectReviews.plagiarismAudit.auditResults')}
        </Heading>
        <Flex
          direction="column"
          gap={6}
          bgColor="white"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="base"
          p={6}
          mb={10}
        >
          <FormControl>
            <FormLabel mb={2}>{t('projectReviews.plagiarismAudit.isSubmissionPlagiarized')}</FormLabel>

            <RadioGroup value={isPlagiarized ? 'yes' : 'no'}>
              <HStack spacing={8}>
                <Radio value="yes" isDisabled>
                  {t('projectReviews.plagiarismAudit.isPlagiarized')}
                </Radio>
                <Radio value="no" isDisabled>
                  {t('projectReviews.plagiarismAudit.originalSubmission')}
                </Radio>
              </HStack>
            </RadioGroup>
          </FormControl>

          {!isPlagiarized && (
            <PlagiarismAuditReason
              label={t('projectReviews.plagiarismAudit.explainWhyReviewIncorrect')}
              helperText={t('projectReviews.plagiarismAudit.explainWhyReviewIncorrectHelper')}
              critique={fromSource} // Both critiques should have the same observation value when not plagiarized
              isAssigned={isAssigned}
            />
          )}

          {isPlagiarized && (
            <>
              <FormControl>
                <FormLabel mb={2}>{t('projectReviews.plagiarismAudit.plagiarizedFromSubmitted')}</FormLabel>

                <RadioGroup value={fromSource.result === 'failed' ? 'yes' : 'no'}>
                  <HStack spacing={8}>
                    <Radio value="yes" isDisabled>
                      {t('common.yes')}
                    </Radio>
                    <Radio value="no" isDisabled>
                      {t('common.no')}
                    </Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>

              {fromSource.result === 'failed' && (
                <PlagiarismAuditReason
                  label={t('projectReviews.plagiarismAudit.explainHowPlagiarized')}
                  helperText={t('projectReviews.plagiarismAudit.explainHowPlagiarizedHelper')}
                  critique={fromSource}
                  isAssigned={isAssigned}
                />
              )}

              {fromSource.result === 'passed' && (
                <PlagiarismAuditReason
                  label={t('projectReviews.plagiarismAudit.explainWhyReviewIncorrect')}
                  helperText={t('projectReviews.plagiarismAudit.explainWhyReviewIncorrectHelper')}
                  critique={fromSource}
                  isAssigned={isAssigned}
                />
              )}

              <FormControl>
                <FormLabel mb={2}>{t('projectReviews.plagiarismAudit.plagiarizedFromAlternative')}</FormLabel>

                <RadioGroup value={fromAlternative.result === 'failed' ? 'yes' : 'no'}>
                  <HStack spacing={8}>
                    <Radio value="yes" isDisabled>
                      {t('common.yes')}
                    </Radio>
                    <Radio value="no" isDisabled>
                      {t('common.no')}
                    </Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>

              {fromAlternative.result === 'failed' && (
                <PlagiarismAuditReason
                  label={t('projectReviews.plagiarismAudit.explainHowPlagiarized')}
                  helperText={t('projectReviews.plagiarismAudit.explainHowPlagiarizedHelper')}
                  critique={fromAlternative}
                  isAssigned={isAssigned}
                />
              )}
            </>
          )}
        </Flex>

        <Button as="a" href={submissionUrl} variant="outline" target="_blank" rel="noopener noreferrer" download>
          {t('projectReviews.feedback.downloadProject')}
        </Button>
      </GridItem>
    </>
  );
};
