import CONFIG from 'configLoader';

import { AssignedPlagiarismCase, PlagiarismCaseRequest } from './plagiarism-case-queue-types';

export const alertCreators = {
  fetchPlagiarismCaseRequestsFailedToast: (reason) => ({
    id: 'fetch-plagiarism-case-requests-failed',
    type: 'error',
    message: `Could not fetch plagiarism case requests: ${reason}`,
  }),
  refreshPlagiarismCaseRequestsFailedToast: (reason) => ({
    id: 'refresh-plagiarism-case-requests-failed',
    type: 'error',
    message: `Could not refresh plagiarism case requests: ${reason}`,
  }),
  updatePlagiarismCaseRequestsFailedToast: (reason) => ({
    id: 'update-plagiarism-case-requests-failed',
    type: 'error',
    message: `Could not update plagiarism case requests: ${reason}`,
  }),
  joinQueueFailedToast: (reason) => ({
    id: 'join-plagiarism-case-queue-failed',
    type: 'error',
    message: `Could not join queue: ${reason}`,
  }),
  leaveQueueFailedToast: (reason) => ({
    id: 'leave-plagiarism-case-queue-failed',
    type: 'error',
    message: `Could not leave queue: ${reason}`,
  }),
  fetchAssignedPlagiarismCasesFailedToast: (reason) => ({
    id: 'fetch-assigned-plagiarism-cases-failed',
    type: 'error',
    message: `Could not fetch assigned plagiarism cases: ${reason}`,
  }),
  newPlagiarismCaseAssignedToast: () => ({
    id: 'new-plagiarism-case-assignments',
    type: 'notification',
    message: 'You have been assigned a new plagiarism case!',
  }),
  newPlagiarismCaseAssignedNotification: () => ({
    title: 'New assignment!',
    body: 'You have been assigned a new plagiarism case!',
    linkTo: `${CONFIG.dashboardUrl}/queue/overview`,
  }),
  fetchCompletedPlagiarismCasesFailedToast: (reason) => ({
    id: 'fetch-completed-plagiarism-cases-failed',
    type: 'error',
    message: `Could not fetch completed plagiarism cases: ${reason}`,
  }),
};

export function makeQueueRequestBody(projectIds: number[], languages: string[]) {
  const idLanguagePairs = projectIds.reduce((pairs, id) => {
    return pairs.concat(languages.map((l) => ({ project_id: id, language: l })));
  }, [] as Array<{ project_id: number; language: string }>);
  return { projects: idLanguagePairs };
}

export function havePlagiarismCaseRequestsCompleted(
  previous: PlagiarismCaseRequest[],
  current: PlagiarismCaseRequest[]
): boolean {
  const currentRequestIds = current.map((request) => request.id);
  return previous.some((request) => !currentRequestIds.includes(request.id));
}

export function hasNewAssignments(previous: AssignedPlagiarismCase[], current: AssignedPlagiarismCase[]): boolean {
  const previousPlagiarismCaseIds = previous.map((plagiarismCase) => plagiarismCase.id);
  return current.some((plagiarismCase) => !previousPlagiarismCaseIds.includes(plagiarismCase.id));
}
