import React, { ReactNode } from 'react';

import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  ariaLabel?: string;
  title?: string;
  headerHidden?: boolean;
  footer?: ReactNode;
  footerStyles?: ModalFooterProps;
  contentStyles?: ModalContentProps;
  closable?: boolean;
};

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  ariaLabel,
  title,
  headerHidden,
  footer,
  footerStyles,
  contentStyles,
  closable = true,
  children,
}) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={closable}>
      <ModalOverlay />
      <ModalContent
        aria-label={ariaLabel}
        maxW="50rem"
        maxH="90vh"
        overflowY="auto"
        mx={{ base: '0.5rem', sm: '10%', lg: 'auto' }}
        h={['full', 'auto']}
        {...contentStyles}
      >
        <ModalHeader hidden={headerHidden}>{title}</ModalHeader>
        {closable && <ModalCloseButton />}

        <ModalBody>{children}</ModalBody>

        {footer && <ModalFooter {...footerStyles}>{footer}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  );
};
