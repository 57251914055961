import { takeEvery, all, call, put } from 'redux-saga/effects';
import { actionTypes } from './certifications-actions';
import { getCertifications } from 'app/services/reviews-api-service';

function* fetchAll() {
  try {
    const payload = yield call(getCertifications);
    yield put({ type: actionTypes.FETCH_ALL_FULFILLED, payload });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_ALL_FULFILLED, error });
  }
}

function* watchFetchAll() {
  yield takeEvery(actionTypes.FETCH_ALL, fetchAll);
}

export default function* certificationsSaga() {
  yield all([watchFetchAll()]);
}
