import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClipboardIcon, ReviewsIcon, DownloadIcon, BadgeIcon } from '@udacity/chakra-uds-icons';

import { getMentorAccountStatus, getApprovedServices } from 'app/common/domains/session/session-selectors';
import { SidebarNavigationTab } from 'app/queue/components/sidebar-navigation-tab';
import { hasNewOpportunities } from 'app/queue/domains/opportunities/opportunities-helpers';

import styles from './sidebar-navigation.module.scss';
import { useFetchOpportunitiesQuery } from 'app/apis/reviews';
import { useSelector } from 'react-redux';

export const SidebarNavigation = () => {
  const hasMentorAccount = useSelector(getMentorAccountStatus);
  const approvedServices = useSelector(getApprovedServices);
  const { t } = useTranslation();
  const { data: opportunities } = useFetchOpportunitiesQuery();

  return (
    <aside className={styles['sidebar-navigation']}>
      {approvedServices.reviews && (
        <SidebarNavigationTab
          to="/queue/overview"
          text={t('queue.overview.overview')}
          title={t('queue.overview.overview')}
          icon={<ClipboardIcon w={6} h={6} />}
        />
      )}
      {approvedServices.reviews && (
        <SidebarNavigationTab
          to="/queue/history"
          text={t('queue.history.history')}
          title={t('queue.history.history')}
          icon={<ReviewsIcon w={6} h={6} />}
        />
      )}
      {approvedServices.reviews && (
        <SidebarNavigationTab
          to="/queue/statements"
          text={t('queue.monthlyStatements')}
          title={t('queue.monthlyStatements')}
          icon={<DownloadIcon w={6} h={6} />}
        />
      )}
      {hasMentorAccount && (
        <SidebarNavigationTab
          to="/queue/certifications"
          text={t('queue.certifications')}
          title={t('queue.certifications')}
          icon={<BadgeIcon w={6} h={6} />}
          renderNewFlag={hasNewOpportunities(opportunities || [])}
        />
      )}
    </aside>
  );
};
