import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './root-reducer';
import initializeRootSaga from './root-saga';

import { connectGqlApi } from './apis/connect-gql-api';
import { reviewsApi } from './apis/reviews';

export const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(sagaMiddleware)
      .concat(reviewsApi.middleware)
      .concat(connectGqlApi.middleware),
});

initializeRootSaga();

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
