import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Certifications } from './certifications';
import { Opportunities } from './opportunities';

export const CertificationsRoutes: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={match.path}>
        <Certifications />
      </Route>

      <Route exact path={`${match.path}/opportunities/:opportunityId`}>
        <Opportunities />
      </Route>
    </Switch>
  );
};
