import React from 'react';
import styles from 'app/app-container.module.scss';
import { withTranslation } from 'react-i18next';

class NotFoundContainer extends React.PureComponent {
  render() {
    // TODO(mariya): use Udacity template for 404
    const { t } = this.props;

    return (
      <div className={styles['not-found']}>
        <div>
          <h1>{t('error.whoops')}</h1>
          <h4>{t('error.pageNotFound')}</h4>
        </div>
      </div>
    );
  }
}

export default withTranslation()(NotFoundContainer);
