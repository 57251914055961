import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import {
  getAllRecruitmentFamilies,
  getReviewerRecruitmentFamilyInterests,
  submitReviewerInterests,
} from 'app/services/reviews-api-service';
import { selectReviewerId } from 'app/common/domains/session/session-selectors';
import { REVIEWER_STATUSES } from './recruitment-families-constants';
import { actionTypes } from './recruitment-families-actions';

function* fetchAll() {
  try {
    const results = yield call(getAllRecruitmentFamilies);
    yield put({ type: actionTypes.FETCH_ALL_FULFILLED, payload: results });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_ALL_FULFILLED, error });
  }
}

function* fetchMyInterests(action) {
  const { uid } = action.payload;
  try {
    const results = yield call(getReviewerRecruitmentFamilyInterests, uid);
    yield put({
      type: actionTypes.FETCH_MY_INTERESTS_FULFILLED,
      payload: results,
    });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_MY_INTERESTS_FULFILLED, error });
  }
}

function* updateReviewerInterests(action) {
  const reviewer_id = yield select(selectReviewerId);

  const { status, recruitment_family_id } = action.payload.family;

  try {
    const postPayload = {
      recruitment_family_id,
      user_id: reviewer_id,
      active: status === REVIEWER_STATUSES.ACTIVE,
    };

    const response = yield call(submitReviewerInterests, postPayload);

    yield put({
      type: actionTypes.UPDATE_REVIEWER_INTEREST_FULFILLED,
      payload: { family: response },
    });

    // clear success message after three second delay
    yield delay(3000);
    yield put({
      type: actionTypes.UPDATE_REVIEWER_INTEREST_RESET,
      payload: { family: response },
    });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_REVIEWER_INTEREST_FULFILLED,
      payload: { family: { recruitment_family_id } },
      error,
    });
  }
}

function* watchFetchAll() {
  yield takeEvery(actionTypes.FETCH_ALL, fetchAll);
}

function* watchFetchMyInterests() {
  yield takeEvery(actionTypes.FETCH_MY_INTERESTS, fetchMyInterests);
}

function* watchUpdateReviewerInterests() {
  yield takeEvery(actionTypes.UPDATE_REVIEWER_INTEREST, updateReviewerInterests);
}

export default function* RecruitmentFamiliesSagas() {
  yield all([watchFetchAll(), watchFetchMyInterests(), watchUpdateReviewerInterests()]);
}
