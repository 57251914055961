import _ from 'lodash';
import { combineReducers } from 'redux';
import { actionTypes } from './recruitment-families-actions';
import { makeAsyncReducer, STATUSES } from 'app/common/domains/async';

export const initialState = {};

export const updateReviewerInterestsById = (state = initialState, action) => {
  const { type, error } = action;
  const family = _.get(action, 'payload.family', {});

  if (_.isEmpty(family)) {
    return state;
  }

  switch (type) {
    case actionTypes.UPDATE_REVIEWER_INTEREST: {
      return {
        ...state,
        [family.recruitment_family_id]: {
          error: null,
          loading: true,
          status: STATUSES.PENDING,
        },
      };
    }

    case actionTypes.UPDATE_REVIEWER_INTEREST_FULFILLED: {
      if (error) {
        return {
          ...state,
          [family.recruitment_family_id]: {
            loading: false,
            status: STATUSES.REJECTED,
            error,
          },
        };
      }
      return {
        ...state,
        [family.recruitment_family_id]: {
          loading: false,
          status: STATUSES.FULFILLED,
          error: null,
          firstLoad: false,
        },
      };
    }

    case actionTypes.UPDATE_REVIEWER_INTEREST_RESET: {
      return {
        ...state,
        [family.recruitment_family_id]: {
          loading: false,
          status: STATUSES.READY,
          error: null,
        },
      };
    }

    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  fetchAll: makeAsyncReducer(actionTypes.FETCH_ALL, actionTypes.FETCH_ALL_FULFILLED, actionTypes.FETCH_ALL_RESET),
  fetchMyInterests: makeAsyncReducer(
    actionTypes.FETCH_MY_INTERESTS,
    actionTypes.FETCH_MY_INTERESTS_FULFILLED,
    actionTypes.FETCH_MY_INTERESTS_RESET
  ),
  updateReviewerInterestsById,
});

export default reducer;
