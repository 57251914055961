import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _isUndefined from 'lodash/isUndefined';
import auth from '@udacity/ureact-hoth';
import { Spinner } from '@chakra-ui/react';

import sessionActions from 'app/common/domains/session/session-actions';
import { getCurrentUser } from 'app/common/domains/user/user-actions';
import { DashboardSwitcher } from 'app/common/views/dashboard-switcher';
import { getIsSessionLoading, getHasAccess } from 'app/common/domains/session/session-selectors';

import styles from './app-container.module.scss';
import { useAppDispatch } from './store';

export const AppContainer: React.FC = ({ children }) => {
  const hasAccess = useSelector((state) => getHasAccess(state));
  const isLoading = useSelector((state) => getIsSessionLoading(state));

  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(sessionActions.fetchMe());
    dispatch(getCurrentUser(auth.getCurrentUser()));
  }, []);

  const shouldRedirectToError = (hasAccess: boolean) => {
    if (!_isUndefined(hasAccess) && !hasAccess) {
      history.push('/prompt?error=true');
    }
  };

  useEffect(() => {
    shouldRedirectToError(hasAccess);
  }, [hasAccess]);

  return (
    <div className={styles['app-container']}>
      {!isLoading ? (
        children || <DashboardSwitcher />
      ) : (
        <div className={styles['loading-container']}>
          <div className={styles['loading']}>
            <Spinner />
          </div>
        </div>
      )}
    </div>
  );
};
