import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, HStack, Radio, RadioGroup } from '@chakra-ui/react';

import { useUpdateCritiqueMutation } from 'app/apis/reviews';
import { Critique, EditedCritique } from 'app/types/reviews';

import { PlagiarismAuditReason } from './plagiarism-audit-reason';

type ConfirmedPlagiarizedProps = {
  critiques: Critique[];
  caseId: number;
  onSubmitCritique: (payload: EditedCritique) => void;
  isAssigned: boolean;
};

export const ConfirmedPlagiarized = ({
  critiques,
  caseId,
  onSubmitCritique,
  isAssigned,
}: ConfirmedPlagiarizedProps) => {
  const { t } = useTranslation();

  const [updateCritique] = useUpdateCritiqueMutation();

  const fromSource = critiques.filter((c) => c.rubricItemId === 18999)[0];
  const fromAlternative = critiques.filter((c) => c.rubricItemId === 19000)[0];

  return (
    <>
      <FormControl>
        <FormLabel mb={2}>{t('projectReviews.plagiarismAudit.plagiarizedFromSubmitted')}</FormLabel>

        <RadioGroup
          value={fromSource.result === 'failed' ? 'yes' : fromSource.result === 'passed' ? 'no' : undefined}
          onChange={(value) => {
            updateCritique({
              id: fromSource.id,
              result: value === 'yes' ? 'failed' : 'passed',
              observation: fromSource.observation,
              submissionOrCaseId: caseId,
              isPlagiarismAudit: true,
            });
          }}
        >
          <HStack spacing={8}>
            <Radio value="yes">{t('common.yes')}</Radio>
            <Radio value="no">{t('common.no')}</Radio>
          </HStack>
        </RadioGroup>
      </FormControl>

      {fromSource.result === 'failed' && (
        <PlagiarismAuditReason
          label={t('projectReviews.plagiarismAudit.explainHowPlagiarized')}
          helperText={t('projectReviews.plagiarismAudit.explainHowPlagiarizedHelper')}
          critique={fromSource}
          onSubmitCritique={onSubmitCritique}
          isAssigned={isAssigned}
        />
      )}

      {fromSource.result === 'passed' && (
        <PlagiarismAuditReason
          label={t('projectReviews.plagiarismAudit.explainWhyReviewIncorrect')}
          helperText={t('projectReviews.plagiarismAudit.explainWhyReviewIncorrectHelper')}
          critique={fromSource}
          onSubmitCritique={onSubmitCritique}
          isAssigned={isAssigned}
        />
      )}

      <FormControl>
        <FormLabel mb={2}>{t('projectReviews.plagiarismAudit.plagiarizedFromAlternative')}</FormLabel>

        <RadioGroup
          value={fromAlternative.result === 'failed' ? 'yes' : fromAlternative.result === 'passed' ? 'no' : undefined}
          onChange={(value) => {
            updateCritique({
              id: fromAlternative.id,
              result: value === 'yes' ? 'failed' : 'passed',
              observation: fromAlternative.observation,
              submissionOrCaseId: caseId,
              isPlagiarismAudit: true,
            });
          }}
        >
          <HStack spacing={8}>
            <Radio value="yes">{t('common.yes')}</Radio>
            <Radio value="no">{t('common.no')}</Radio>
          </HStack>
        </RadioGroup>
      </FormControl>

      {fromAlternative.result === 'failed' && (
        <PlagiarismAuditReason
          label={t('projectReviews.plagiarismAudit.explainHowPlagiarized')}
          helperText={t('projectReviews.plagiarismAudit.explainHowPlagiarizedHelper')}
          critique={fromAlternative}
          onSubmitCritique={onSubmitCritique}
          isAssigned={isAssigned}
        />
      )}
    </>
  );
};
