import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormLabel, Heading, Switch, Text } from '@chakra-ui/react';

import { formatPrice } from 'app/helpers/format-helper';
import { QuestionQueueOpportunity } from 'app/queue/domains/question-queue/question-queue-types';

import styles from './question-queue-settings.module.scss';

export type QuestionQueueSettingsProps = {
  isOptionsUpdate: boolean;
  projectsEnabledInQueue: { [projectId: string]: boolean };
  projectOpportunities: { [projectId: string]: QuestionQueueOpportunity };
  onSubmit: (projectIds: string[]) => void;
};

export const QuestionQueueSettings: React.FC<QuestionQueueSettingsProps> = ({
  isOptionsUpdate,
  projectsEnabledInQueue,
  projectOpportunities,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const allProjectIds = Object.keys(projectOpportunities);

  const [enabledProjectIdMap, setEnabledProjectIdMap] = useState(projectsEnabledInQueue);
  const areAllProjectsDisabled = allProjectIds.every((id) => !enabledProjectIdMap[id]);

  const onSubmitClick = () => {
    const enabledProjectIds = allProjectIds.filter((id) => enabledProjectIdMap[id]);
    onSubmit(enabledProjectIds);
  };

  return (
    <>
      <section className={styles['settings-section']}>
        <Heading size="h4" as="h4">
          {t('queue.overview.queueForQuestions')}
        </Heading>
      </section>

      <section className={styles['settings-section']}>
        <ul>
          <li className={styles['project-row']}>
            <Heading size="h6" as="h6">
              {t('common.project')}
            </Heading>
            <Heading size="h6" as="h6">
              {t('common.answer')}
            </Heading>
            <div />
          </li>
          {allProjectIds.map((id) => {
            const opportunity = projectOpportunities[id];
            return (
              <li className={styles['project-row']} key={id}>
                <FormLabel htmlFor={`project-${id}-switch`}>{opportunity.project.title}</FormLabel>
                <Text>{formatPrice(opportunity.earnings.answer.amount)}</Text>

                <Switch
                  id={`project-${id}-switch`}
                  isChecked={enabledProjectIdMap[id]}
                  onChange={({ target: { checked } }) => {
                    setEnabledProjectIdMap({
                      ...enabledProjectIdMap,
                      [id]: checked,
                    });
                  }}
                />
              </li>
            );
          })}
        </ul>
      </section>

      <section className={styles['settings-section']}>
        {areAllProjectsDisabled && (
          <Text me={8} color="feedback.negative" data-testid="disabled-reason">
            {t('queue.overview.youMustQueue')}
          </Text>
        )}
        <Button isDisabled={areAllProjectsDisabled} onClick={onSubmitClick}>
          {isOptionsUpdate ? t('queue.overview.updateQueue') : t('queue.overview.enterQueue')}
        </Button>
      </section>
    </>
  );
};
