import { createAction } from 'app/helpers/action-helper';

import { CompletedAuditPageParams } from './audit-queue-types';

export const actionTypes = {
  BOOTSTRAP_AUDIT_QUEUE_START: 'audit-queue:BOOTSTRAP_AUDIT_QUEUE_START',
  BOOTSTRAP_AUDIT_QUEUE_FULFILLED: 'audit-queue:BOOTSTRAP_AUDIT_QUEUE_FULFILLED',
  BOOTSTRAP_AUDIT_QUEUE_REJECTED: 'audit-queue:BOOTSTRAP_AUDIT_QUEUE_REJECTED',
  FETCH_AUDIT_REQUESTS_START: 'audit-queue:FETCH_AUDIT_REQUESTS_START',
  FETCH_AUDIT_REQUESTS_FULFILLED: 'audit-queue:FETCH_AUDIT_REQUESTS_FULFILLED',
  FETCH_AUDIT_REQUESTS_REJECTED: 'audit-queue:FETCH_AUDIT_REQUESTS_REJECTED',
  FETCH_ASSIGNED_AUDITS_START: 'audit-queue:FETCH_ASSIGNED_AUDITS_START',
  FETCH_ASSIGNED_AUDITS_FULFILLED: 'audit-queue:FETCH_ASSIGNED_AUDITS_FULFILLED',
  FETCH_ASSIGNED_AUDITS_REJECTED: 'audit-queue:FETCH_ASSIGNED_AUDITS_REJECTED',
  FETCH_COMPLETED_AUDITS_START: 'audit-queue:FETCH_COMPLETED_AUDITS_START',
  FETCH_COMPLETED_AUDITS_FULFILLED: 'audit-queue:FETCH_COMPLETED_AUDITS_FULFILLED',
  FETCH_COMPLETED_AUDITS_REJECTED: 'audit-queue:FETCH_COMPLETED_AUDITS_REJECTED',
  CLEAR_COMPLETED_AUDITS: 'audit-queue:CLEAR_COMPLETED_AUDITS',
  REFRESH_AUDIT_REQUESTS_START: 'audit-queue:REFRESH_AUDIT_REQUESTS_START',
  REFRESH_AUDIT_REQUESTS_FULFILLED: 'audit-queue:REFRESH_AUDIT_REQUESTS_FULFILLED',
  REFRESH_AUDIT_REQUESTS_REJECTED: 'audit-queue:REFRESH_AUDIT_REQUESTS_REJECTED',
  UPDATE_AUDIT_REQUESTS_START: 'audit-queue:UPDATE_AUDIT_REQUESTS_START',
  UPDATE_AUDIT_REQUESTS_FULFILLED: 'audit-queue:UPDATE_AUDIT_REQUESTS_FULFILLED',
  UPDATE_AUDIT_REQUESTS_REJECTED: 'audit-queue:UPDATE_AUDIT_REQUESTS_REJECTED',
  JOIN_AUDIT_QUEUE_START: 'audit-queue:JOIN_AUDIT_QUEUE_START',
  JOIN_AUDIT_QUEUE_FULFILLED: 'audit-queue:JOIN_AUDIT_QUEUE_FULFILLED',
  JOIN_AUDIT_QUEUE_REJECTED: 'audit-queue:JOIN_AUDIT_QUEUE_REJECTED',
  LEAVE_AUDIT_QUEUE_START: 'audit-queue:LEAVE_AUDIT_QUEUE_START',
  LEAVE_AUDIT_QUEUE_FULFILLED: 'audit-queue:LEAVE_AUDIT_QUEUE_FULFILLED',
  LEAVE_AUDIT_QUEUE_REJECTED: 'audit-queue:LEAVE_AUDIT_QUEUE_REJECTED',
};

export default {
  bootstrap() {
    return createAction(actionTypes.BOOTSTRAP_AUDIT_QUEUE_START);
  },
  fetchCompletedAudits(pageParams: CompletedAuditPageParams) {
    return createAction(actionTypes.FETCH_COMPLETED_AUDITS_START, pageParams);
  },
  clearCompletedAudits() {
    return createAction(actionTypes.CLEAR_COMPLETED_AUDITS);
  },
  refreshRequests() {
    return createAction(actionTypes.REFRESH_AUDIT_REQUESTS_START);
  },
  updateRequests(projectIds: number[]) {
    return createAction(actionTypes.UPDATE_AUDIT_REQUESTS_START, projectIds);
  },
  joinQueue(projectIds: number[]) {
    return createAction(actionTypes.JOIN_AUDIT_QUEUE_START, projectIds);
  },
  leaveQueue() {
    return createAction(actionTypes.LEAVE_AUDIT_QUEUE_START);
  },
};
