import i18n from 'app/i18n';
import { MentorRole } from './types';

type MentorRoleOption = {
  value: MentorRole;
  label: string;
};

export const MENTOR_AGREEMENT_ROLE_OPTIONS: MentorRoleOption[] = [
  { value: MentorRole.SessionLead, label: i18n.t('common.sessionLead') },
  {
    value: MentorRole.CommunityModerator,
    label: i18n.t('common.communityModerator'),
  },
  { value: MentorRole.CareerCoach, label: i18n.t('common.careerCoach') },
  { value: MentorRole.Guru, label: i18n.t('common.guru') },
  { value: MentorRole.Tutor, label: i18n.t('common.tutor') },
];

function findRole(role: MentorRole): MentorRoleOption | undefined {
  return MENTOR_AGREEMENT_ROLE_OPTIONS.find((option) => option.value === role);
}

export function getLabelForRole(role: MentorRole): string {
  return findRole(role)?.label || i18n.t('common.unknownRole');
}
