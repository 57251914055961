import React, { ReactNode } from 'react';
import RequiredActions from 'app/common/components/required-actions';
import { ServicesBar } from 'app/common/components/services-bar';
import styles from './sidebar-layout.module.scss';

type SidebarLayoutProps = {
  leftNav?: ReactNode;
};

export const SidebarLayout: React.FC<SidebarLayoutProps> = ({ leftNav, children }) => {
  return (
    <div className={`${styles.layout} ${leftNav ? styles.hasLeftNav : ''}`}>
      <div className={styles.sidebar}>
        <div className={styles.servicesbar}>
          <ServicesBar />
        </div>
        {!!leftNav && (
          <div data-tst="leftNav" className={styles.leftNav}>
            {leftNav}
          </div>
        )}
      </div>
      <div data-tst="content" className={styles.content}>
        <RequiredActions />
        {children}
      </div>
    </div>
  );
};
