import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Flex, FormControl, FormErrorMessage, FormLabel, Switch, Textarea } from '@chakra-ui/react';
import { WarningIcon } from '@udacity/chakra-uds-icons';
import { Markdown } from '@udacity/ureact-markdown';

import { AttachImageButton } from './attach-image-button';
import { MarkdownPreview } from './markdown-preview';
import { useStateWithCallback } from 'app/project-reviews/hooks/use-state-with-callback';
import { useImageUpload } from 'app/project-reviews/hooks/use-image-upload';

type FeedbackInputProps = {
  id: string;
  feedback: string;
  setFeedback: React.Dispatch<React.SetStateAction<string>>;
  isAssigned: boolean;
  errorMessage?: string;
  saveFeedback?: (newFeedback: string) => void;
} & BoxProps;

let timeout: NodeJS.Timeout;

export const FeedbackInput: React.FC<FeedbackInputProps> = ({
  id,
  feedback,
  setFeedback,
  isAssigned,
  errorMessage,
  saveFeedback,
  ...boxProps
}) => {
  const [showPreview, setShowPreview] = useStateWithCallback(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const { t } = useTranslation();
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const autoSaveFeedback = (newValue: string) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      saveFeedback?.(newValue);
    }, 500);
  };

  const { handleUploadFiles, isUploadingImage } = useImageUpload(setFeedback, autoSaveFeedback);

  const handleFileDrop = (e: React.DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    setIsDraggingOver(false);
    handleUploadFiles(files, e.currentTarget.selectionStart);
  };

  const handleFilePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    Array.from(e.clipboardData.items).forEach((item) => {
      if (item.kind == 'file' && item.type.startsWith('image/')) {
        const file = item.getAsFile();
        if (!file) return;

        handleUploadFiles([file], e.currentTarget.selectionStart);
      }
    });
  };

  return (
    <Box {...boxProps}>
      {isAssigned &&
        (showPreview ? (
          <MarkdownPreview text={feedback} />
        ) : (
          <>
            <Textarea
              ref={inputRef}
              value={feedback}
              onChange={(e) => {
                setFeedback(e.target.value);
                autoSaveFeedback(e.target.value);
              }}
              onBlur={(e) => saveFeedback?.(e.target.value)}
              rows={6}
              isDisabled={!isAssigned}
              sx={{ caretColor: 'black', outline: isDraggingOver ? '2px solid blue' : 'none' }}
              onDragOver={(e) => {
                setIsDraggingOver(true);
                e.preventDefault();
              }}
              onDragLeave={(e) => {
                setIsDraggingOver(false);
                e.preventDefault();
              }}
              onDrop={handleFileDrop}
              onPaste={handleFilePaste}
            />

            <FormErrorMessage>
              <WarningIcon w={6} h={6} color="feedback.negative" />
              {errorMessage}
            </FormErrorMessage>
          </>
        ))}

      {!isAssigned && (
        <Box bgColor="gray.200" border="1px solid" borderColor="gray.600" borderRadius="base" p={4}>
          <Markdown markdownText={feedback} />
        </Box>
      )}

      {isAssigned && (
        <Flex align="center" justify="space-between" mt={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel me={4} htmlFor={`show-${id}-preview`}>
              {t('projectReviews.previewFeedback')}
            </FormLabel>
            <Switch
              id={`show-${id}-preview`}
              isChecked={showPreview}
              onChange={(e) =>
                setShowPreview(e.target.checked, () => {
                  if (!e.target.checked) {
                    inputRef.current?.focus();
                  }
                })
              }
            />
          </FormControl>

          {!showPreview && <AttachImageButton onUpload={handleUploadFiles} isLoading={isUploadingImage} />}
        </Flex>
      )}
    </Box>
  );
};
