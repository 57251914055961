import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { CancelIcon, CheckedIcon, ThumbDownIcon, ThumbUpIcon } from '@udacity/chakra-uds-icons';
import { Markdown } from '@udacity/ureact-markdown';

import { Alert } from 'app/common/components/alert';
import { Critique, EditedCritique, RubricItem } from 'app/types/reviews';

import { ReviewerTip } from './reviewer-tip';
import { FeedbackInput } from './feedback-input';
import { FeedbackTemplateContainer } from './feedback-templates/container';
import { useLocation } from 'react-router-dom';

type FeedbackRubricItemProps = {
  item: RubricItem;
  handleAccordionToggle: () => void;
  isAssigned: boolean;
  critique?: Critique;
  previousCritique?: Critique;
  onSubmitCritique: (payload: EditedCritique) => Promise<void>;
};

export const FeedbackRubricItem: React.FC<FeedbackRubricItemProps> = ({
  item,
  handleAccordionToggle,
  isAssigned,
  critique,
  previousCritique,
  onSubmitCritique,
}) => {
  const [result, setResult] = useState<'passed' | 'failed' | null>(critique?.result || null);
  const [feedback, setFeedback] = useState(critique?.observation || '');

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isHistoryTab = pathname.split('/').pop() === 'history';

  useEffect(() => {
    if (!isAssigned) {
      setFeedback(critique?.observation || '');
    }
  }, [critique?.observation]);

  useEffect(() => {
    if (isAssigned) {
      if (previousCritique?.result === 'passed') {
        setResult('passed');

        if (critique && !critique.result) {
          onSubmitCritique({
            id: critique.id,
            result: 'passed',
            observation: critique.observation ? critique.observation.trim() : null,
          });
        }
      }
    }
  }, [critique, previousCritique?.result, isAssigned]);

  const didPass = useMemo(() => {
    if (critique?.result) {
      return critique.result === 'passed' ? true : critique.result === 'failed' ? false : null;
    } else if (previousCritique?.result) {
      return previousCritique.result === 'passed' ? true : null;
    } else {
      return null;
    }
  }, [critique?.result, previousCritique?.result]);

  const handlePassesCriteria = async () => {
    if (critique) {
      await onSubmitCritique({
        id: critique.id,
        result: 'passed',
        observation: feedback !== '' ? feedback.trim() : null,
      });

      setResult('passed');
    } else {
      console.error(`No critique found for rubric item with id ${item.id}`);
      setResult(null);
    }
  };

  const handleDoesNotPassCriteria = async () => {
    if (critique) {
      await onSubmitCritique({
        id: critique.id,
        result: 'failed',
        observation: feedback !== '' ? feedback.trim() : null,
      });

      setResult('failed');
    } else {
      console.error(`No critique found for rubric item with id ${item.id}`);
      setResult(null);
    }
  };

  const handleApplyTemplate = async (newObservation: string) => {
    if (critique) {
      setFeedback(newObservation);

      await onSubmitCritique({
        id: critique.id,
        result,
        observation: newObservation,
      });
    } else {
      console.error(`No critique found for rubric item with id ${item.id}`);
    }
  };

  const saveFeedback = (newFeedback: string) => {
    if (critique && newFeedback.trim() !== critique.observation) {
      onSubmitCritique({
        id: critique.id,
        result: critique.result,
        observation: newFeedback.trim() ? newFeedback.trim() : null,
      });
    }
  };

  return (
    <AccordionItem _notLast={{ mb: 4 }}>
      <h3>
        <AccordionButton
          onClick={handleAccordionToggle}
          sx={{ borderBottom: '1px solid', borderBottomColor: 'gray.300' }}
          bgColor={didPass === true ? '#EBF3EF' : didPass === false ? '#FBEBEA' : 'inherit'}
        >
          <Flex grow={1} gap={4} align="center" textAlign="start" as="span" fontWeight="semibold">
            {didPass === true ? (
              <CheckedIcon w={8} h={8} color="feedback.positive" />
            ) : didPass === false ? (
              <CancelIcon w={8} h={8} color="feedback.negative" />
            ) : null}
            <Markdown markdownText={item.criteria} />
          </Flex>
          <AccordionIcon ms={4} />
        </AccordionButton>
      </h3>

      <AccordionPanel p={6}>
        {previousCritique?.result && !isHistoryTab && (
          <Alert status={previousCritique.result === 'passed' ? 'success' : 'error'} mb={6}>
            {previousCritique.result === 'passed'
              ? t('projectReviews.projectReview.passedByPreviousReviewer')
              : t('projectReviews.projectReview.rejectedByPreviousReviewer')}
          </Alert>
        )}

        <Text size="label" mb={4}>
          {t('projectReviews.rubric.requirementsToPass')}
        </Text>
        <Box mb={6}>
          <Markdown markdownText={item.requirements} />
        </Box>

        {previousCritique?.observation && !isHistoryTab && (
          <Accordion my={6} allowToggle>
            <AccordionItem>
              <h4>
                <AccordionButton>
                  <Flex grow={1} align="center" as="span">
                    {t('projectReviews.projectReview.previousReviewerFeedback')}
                  </Flex>
                  <AccordionIcon ms={4} />
                </AccordionButton>
              </h4>

              <AccordionPanel p={6}>
                <Markdown markdownText={previousCritique.observation} />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        )}

        {previousCritique?.result !== 'passed' && isAssigned && (
          <>
            <Text size="label" mt={6} mb={2}>
              {t('projectReviews.projectReview.doesProjectMeetSpecs')}
            </Text>
            <Flex align="center" mb={4}>
              <IconButton
                aria-label={t('projectReviews.passes')}
                variant="outline"
                size="icon"
                icon={<ThumbUpIcon w={8} h={8} />}
                isRound
                me={2}
                color={result === 'passed' ? 'white' : 'unset'}
                bgColor={result === 'passed' ? 'blue.500' : 'unset'}
                onClick={handlePassesCriteria}
              />
              <Text me={6}>{t('projectReviews.passes')}</Text>

              <IconButton
                aria-label={t('projectReviews.doesNotPass')}
                variant="outline"
                size="icon"
                colorScheme="blue"
                icon={<ThumbDownIcon w={8} h={8} />}
                isRound
                me={2}
                color={result === 'failed' ? 'white' : 'unset'}
                bgColor={result === 'failed' ? 'blue.500' : 'unset'}
                onClick={handleDoesNotPassCriteria}
              />
              <Text>{t('projectReviews.doesNotPass')}</Text>
            </Flex>
          </>
        )}

        {isAssigned && (
          <FeedbackTemplateContainer
            rubricItemId={item.id}
            meetsRequirements={critique?.result ? result === 'passed' : undefined}
            currentValue={critique?.observation ?? ''}
            onApplyTemplate={handleApplyTemplate}
          />
        )}

        <FormControl mt={6} mb={2} isInvalid={result === 'failed' && !feedback}>
          <FormLabel mb={2}>{t('projectReviews.feedback.feedback')}</FormLabel>
          {previousCritique?.result !== 'passed' ? (
            <FormHelperText mb={2}>{t('projectReviews.projectReview.requiredIfDoesNotPass')}</FormHelperText>
          ) : (
            <FormHelperText mb={2}>{t('projectReviews.projectReview.supportedFeedbackFunctions')}</FormHelperText>
          )}

          <FeedbackInput
            id={`${item.id}`}
            feedback={feedback}
            setFeedback={setFeedback}
            isAssigned={isAssigned}
            saveFeedback={saveFeedback}
            errorMessage={t('projectReviews.projectReview.feedbackRequired')}
            mb={6}
          />
        </FormControl>

        {item.reviewerTip && <ReviewerTip tip={item.reviewerTip} />}
      </AccordionPanel>
    </AccordionItem>
  );
};
