import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { Markdown } from '@udacity/ureact-markdown';

type MarkdownPreviewProps = { text: string } & BoxProps;

export const MarkdownPreview: React.FC<MarkdownPreviewProps> = ({ text, ...rest }) => {
  return (
    <Box
      bgColor="white"
      border="1px solid"
      borderColor="gray.600"
      borderRadius="base"
      p={4}
      maxW="full"
      whiteSpace="normal"
      wordBreak="break-word"
      {...rest}
    >
      <Markdown markdownText={text} />
    </Box>
  );
};
