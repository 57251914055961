import { actionTypes } from './question-queue-actions';
import {
  questionQueueStatusFromReviewer,
  questionAssignmentsFromApiAssigned,
  mergeApiEdgesIntoActionHistory,
  projectOpportunitiesFromApiReviewer,
} from './question-queue-reducer-helpers';
import { QuestionQueue } from './question-queue-types';

export const initialState: QuestionQueue = {
  status: {
    expiresAt: null,
    enabled: false,
    projectEnabledInQueue: {},
  },
  assignments: [],
  projectOpportunities: {},
  history: {
    actions: [],
  },
};

export default function reducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_QUESTION_QUEUE_FULFILLED: {
      const { assigned, reviewer } = payload;

      const status = questionQueueStatusFromReviewer(reviewer);
      const assignments = questionAssignmentsFromApiAssigned(assigned);
      const projectOpportunities = projectOpportunitiesFromApiReviewer(reviewer);

      return { ...state, status, assignments, projectOpportunities };
    }

    case actionTypes.FETCH_QUESTION_QUEUE_ASSIGNMENTS_FULFILLED: {
      return {
        ...state,
        assignments: questionAssignmentsFromApiAssigned(payload),
      };
    }

    case actionTypes.FETCH_QUESTION_QUEUE_HISTORY_FULFILLED: {
      const { totalCount, edges } = payload;
      const {
        history: { actions },
      } = state;
      return {
        ...state,
        history: {
          totalCount,
          actions: mergeApiEdgesIntoActionHistory(edges, actions),
        },
      };
    }

    case actionTypes.CLEAR_QUESTION_QUEUE_HISTORY: {
      return {
        ...state,
        history: initialState.history,
      };
    }

    case actionTypes.FETCH_MONTHLY_EARNINGS_SUMMARY_FULFILLED: {
      return {
        ...state,
        pastMonthEarnings: payload,
      };
    }

    case actionTypes.JOIN_QUESTION_QUEUE_FULFILLED:
    case actionTypes.LEAVE_QUESTION_QUEUE_FULFILLED: {
      const status = questionQueueStatusFromReviewer(payload);
      return { ...state, status };
    }

    case actionTypes.QUESTION_QUEUE_EXPIRED: {
      const { status } = state;
      return {
        ...state,
        status: {
          ...status,
          enabled: false,
          expiresAt: null,
        },
      };
    }
  }

  return state;
}
