import React from 'react';
import { useClaimMeetingsMutation, useGetSeriesDetailsQuery } from 'app/uconnect/queries/enhanced';
import { useAppToast } from 'app/common/hooks/use-app-toast';
import { Button, Flex, ListItem, OrderedList, Skeleton, Text } from '@chakra-ui/react';
import auth from '@udacity/ureact-hoth';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from '@udacity/chakra-uds-icons';
import { dateFormatter } from 'app/helpers/format-helper';

type ClaimSeriesConfirmationProps = {
  seriesId: string;
  onCloseModal: () => void;
  onCancel: () => void;
};

export const ClaimSeriesConfirmation: React.FC<ClaimSeriesConfirmationProps> = ({
  seriesId,
  onCloseModal,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [claimMeetings, { isLoading }] = useClaimMeetingsMutation();
  const toast = useAppToast();
  const { data, isLoading: isLoadingSeries } = useGetSeriesDetailsQuery({
    seriesId,
    facilitatorUserKey: auth.getCurrentUserId(),
  });
  const series = data?.series.series[0];

  function handleClaimClick() {
    const meetingIds = series?.meetings.meetings.map((meeting) => meeting.id);
    claimMeetings({ meetingIds }).then((res) => {
      if ('data' in res && res.data?.claimMeetings) {
        toast({
          variant: 'success',
          description: t('connect.claimMeetingsSuccess'),
        });
      } else {
        toast({
          variant: 'error',
          description: t('connect.claimMeetingsError'),
        });
      }
      onCloseModal();
      onCancel();
    });
  }

  return (
    <Skeleton isLoaded={!isLoadingSeries}>
      <Text fontSize="lg">{t('connect.confirmClaimSeries', { seriesName: series?.name })}</Text>
      <Text mb={4}>{t('connect.confirmClaimSeriesDetails', { count: series?.meetings.totalCount || 0 })}</Text>
      <OrderedList listStyleType="none" margin={0}>
        {series?.meetings.meetings.map((meeting) => (
          <ListItem mb={2} key={meeting.id}>
            <Text as="span" fontWeight="semibold">
              {meeting.name}
            </Text>{' '}
            <CalendarIcon h={4} mb={0.5} w={4} /> {dateFormatter.short(meeting.startAt)}{' '}
            {dateFormatter.localTime(meeting.startAt)} - {dateFormatter.localTime(meeting.endAt)}
          </ListItem>
        ))}
      </OrderedList>
      <Flex gap={4}>
        <Button onClick={handleClaimClick} isLoading={isLoading}>
          {t('connect.claimMeetingCTA')}
        </Button>
        <Button variant="outline" onClick={onCancel} isDisabled={isLoading}>
          {t('common.cancel')}
        </Button>
      </Flex>
    </Skeleton>
  );
};
