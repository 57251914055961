import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertProps as ChakraAlertProps,
  Box,
  CloseButton,
} from '@chakra-ui/react';
import { CancelIcon, CheckedIcon, InfoIcon, WarningIcon } from '@udacity/chakra-uds-icons';

type AlertProps = ChakraAlertProps & {
  isCloseable?: boolean;
  onClose?: () => void;
};

export const Alert: React.FC<AlertProps> = ({ status, children, isCloseable, onClose, ...rest }) => {
  const { t } = useTranslation();

  return (
    <ChakraAlert status={status} {...rest}>
      <Box className="custom-icon" px={1}>
        {iconMap.get(status)}
      </Box>
      <AlertDescription py={4}>{children}</AlertDescription>
      {isCloseable && <CloseButton onClick={onClose} aria-label={t('common.closeBanner')} />}
    </ChakraAlert>
  );
};

const iconMap = new Map();

iconMap.set('success', <CheckedIcon w={8} h={8} />);
iconMap.set('error', <CancelIcon w={8} h={8} />);
iconMap.set('warning', <WarningIcon w={8} h={8} />);
iconMap.set('info', <InfoIcon w={8} h={8} />);
