// Mentor API
export const MENTOR_QUERY_OPERATION_NAME = 'MentorDash_MentorQuery';
export const GET_MENTOR_AGREEMENTS_OPERATION_NAME = 'MentorDash_GetMentorAgreements';
export const UPDATE_MENTOR_AGREEMENT_OPERATION_NAME = 'MentorDash_UpdateMentorAgreement';
export const CREATE_MENTOR_OPERATION_NAME = 'MentorDash_CreateMentor';
export const UPDATE_MENTOR_OPERATION_NAME = 'MentorDash_UpdateMentor';

// Pensieve API
export const FETCH_QUEUE_OPERATION_NAME = 'MentorDash_FetchQueue';
export const FETCH_QUEUE_ASSIGNMENTS_OPERATION_NAME = 'MentorDash_FetchQueueAssignments';
export const FETCH_QUEUE_HISTORY_OPERATION_NAME = 'MentorDash_FetchHistory';
export const FETCH_QUEUE_EARNINGS_OPERATION_NAME = 'MentorDash_FetchEarningsSummary';
export const JOIN_QUEUE_OPERATION_NAME = 'MentorDash_JoinQueue';
export const LEAVE_QUEUE_OPERATION_NAME = 'MentorDash_LeaveQueue';
