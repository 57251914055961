import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useFetchApiTokenQuery } from 'app/apis/reviews';
import { Modal } from 'app/common/components/modal';
import { useAppToast } from 'app/common/hooks/use-app-toast';
import { copyToClipboard } from 'app/helpers/string-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ViewApiToken: React.FC = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { data } = useFetchApiTokenQuery();
  const toast = useAppToast();
  const token = data?.token;

  if (!token) return null;

  return (
    <>
      <Button onClick={onOpen} mb={6}>
        {t('mentorProfile.apiToken.access')}
      </Button>
      <Modal title={t('mentorProfile.apiToken.access')} isOpen={isOpen} onClose={onClose}>
        <Text mb={4} fontWeight="semibold">
          {t('mentorProfile.apiToken.yourToken')}
        </Text>
        <Text bg="gray.100" p="1rem 2rem" mb={4} wordBreak="break-all">
          {token}
        </Text>
        <Flex gap={3}>
          {Boolean(navigator.clipboard) && (
            <Button
              onClick={() =>
                copyToClipboard(token).then(() => {
                  toast({
                    description: t('common.copiedToClip'),
                    variant: 'success',
                  });
                })
              }
            >
              {t('common.copyToClip')}
            </Button>
          )}
          <Button onClick={onClose}>{t('common.close')}</Button>
        </Flex>
      </Modal>
    </>
  );
};
