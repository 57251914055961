import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { GuidelinesContainer } from './guidelines-container';
import { ProfileEditorContainer } from 'app/common/views/profile-editor-container';

export const SpecializedMentor: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/apply`}>
        <ProfileEditorContainer />
      </Route>
      <Route path={`${match.path}/guidelines`}>
        <GuidelinesContainer />
      </Route>
      <Route path={`${match.path}/update`}>
        <ProfileEditorContainer />
      </Route>
    </Switch>
  );
};
