import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Input, Text, FormControl, FormLabel } from '@chakra-ui/react';

import statementOfWorkUrl from 'app/agreements/statementofwork.md';
import agreementUrl from 'app/agreements/agreement.md';
import { getAgreementHeading } from 'app/agreements/helpers/helpers';
import { AGREEMENT_STATUS_SIGNED } from 'app/agreements/status';
import { Agreement, CountryLocale } from 'app/agreements/types';
import MarkdownRenderer from 'app/common/components/markdown-renderer';
import { Modal } from 'app/common/components/modal';
import { agreementDate } from 'app/helpers/format-helper';

import styles from './styles.module.scss';

type AgreementModalProps = {
  agreement: Agreement;
  mentorCountryCode: string;
  countryLocale: CountryLocale | undefined;
  mentorName: string;
  onAccept: (agreement: Agreement, countryLocale: CountryLocale) => void;
  onClose: () => void;
};

export const AgreementModal: React.FC<AgreementModalProps> = ({
  agreement,
  mentorCountryCode,
  countryLocale,
  mentorName,
  onAccept,
  onClose,
}) => {
  const [content, setContent] = useState('');
  const [name, setName] = useState('');
  const title = getAgreementHeading(agreement.role);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchContent = () => {
      const useURL =
        agreement.date_signed && agreement.date_signed < new Date('2023-12-07T16:00:00')
          ? agreementUrl
          : statementOfWorkUrl;
      return fetch(useURL)
        .then((response) => {
          return response.blob();
        })
        .then(async (blob) => {
          const text = await new Response(blob).text().then((text) => {
            const date = agreement.date_signed || new Date();

            const bonus = countryLocale?.bonus;
            const currency = countryLocale?.currency ? countryLocale.currency : 'USD';
            const bonusClaus = t('agreements.externalContractor', {
              currency,
              bonus,
            });

            return text
              .replace(/\[DATE\]/g, agreementDate(date))
              .replace(/\[EXTERNAL_CONTRACTOR_NAME\]/g, mentorName)
              .replace(/\[PAY_RATE_PER_HOUR\]/g, countryLocale?.payRatePerHour || '')
              .replace(/\[UP_TO_HOURS_PER_MONTH\]/g, countryLocale?.upToHoursPerMonth || '')
              .replace(/\[MAX_PAYMENT_PER_MONTH\]/g, countryLocale?.maxPaymentPerMonth || '')
              .replace(/\[BONUS_CLAUS\]/g, bonus ? bonusClaus : '')
              .replace(/\[CURRENCY\]/g, currency);
          });

          setContent(text);
        });
    };
    fetchContent();
  }, [agreementUrl]);

  const modalContent =
    countryLocale !== undefined ? content : t('agreements.yourAccountIsNotConfigured', { mentorCountryCode });

  return (
    <Modal ariaLabel={t('agreements.agreement')} onClose={onClose} isOpen={true}>
      <div className={styles['modal-container']}>
        <div className={styles['modal-heading']}>
          <Heading size="h2" as="h2" mb={4}>
            {title}
          </Heading>
        </div>

        <div className={styles.modal}>
          <MarkdownRenderer content={modalContent} />
        </div>

        {agreement.status != AGREEMENT_STATUS_SIGNED && countryLocale && (
          <div className={styles.signature}>
            <Text>{t('agreements.scrollToBottom')}</Text>
            <FormControl>
              <FormLabel hidden>{t('agreements.nameConfirmation')}</FormLabel>
              <Input
                id="name-confirmation"
                placeholder={t('agreements.yourName')}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </FormControl>

            <Button
              isDisabled={name.trim().toLowerCase() !== mentorName.trim().toLowerCase()}
              onClick={() => onAccept(agreement, countryLocale)}
            >
              {t('agreements.iAcceptTheTerms')}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
