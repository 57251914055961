import { delay } from 'redux-saga';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { actionCreators as uiActions } from 'app/common/domains/ui/ui-actions';
import { createAction } from 'app/helpers/action-helper';
import { errorMessageFromApiError } from 'app/helpers/api-error-helper';
import { getEligibleProjectLanguages } from 'app/common/domains/session/session-selectors';
import {
  getAuditRequests,
  createAuditRequest,
  refreshAuditRequest,
  updateAuditRequest,
  deleteAuditRequest,
  getAssignedAudits,
  getCompletedAudits,
} from 'app/services/reviews-api-service';

import { actionTypes } from './audit-queue-actions';
import constants from './audit-queue-constants';
import {
  alertCreators,
  makeQueueRequestBody,
  haveAuditRequestsCompleted,
  hasNewAssignments,
} from './audit-queue-sagas-helpers';
import { selectAssignedAudits, selectPendingAuditRequests } from './audit-queue-selectors';
import { AuditRequestStatus } from 'app/types/reviews';

const { POLLING_INTERVAL_MS } = constants;

let isPolling = false;
export function* bootstrap() {
  yield put(createAction(actionTypes.FETCH_AUDIT_REQUESTS_START));
  yield put(createAction(actionTypes.FETCH_ASSIGNED_AUDITS_START));
  if (!isPolling) {
    yield fork(pollAuditRequests);
    yield fork(pollAssignedAudits);
    isPolling = true;
  }
}

export function* fetchAuditRequests() {
  try {
    const response = yield call(getAuditRequests);
    yield put(createAction(actionTypes.FETCH_AUDIT_REQUESTS_FULFILLED, response));
  } catch (error) {
    yield put(createAction(actionTypes.FETCH_AUDIT_REQUESTS_REJECTED, error));
    const errorToast = alertCreators.fetchAuditRequestsFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* refreshAuditRequests() {
  try {
    const pendingAuditRequests = yield select(selectPendingAuditRequests);
    const refreshEffects = pendingAuditRequests.map((r) => call(refreshAuditRequest, r.id));
    const refreshedRequests = yield all(refreshEffects);
    yield put(createAction(actionTypes.REFRESH_AUDIT_REQUESTS_FULFILLED, refreshedRequests));
  } catch (error) {
    yield put(createAction(actionTypes.REFRESH_AUDIT_REQUESTS_REJECTED, error));
    const errorToast = alertCreators.refreshAuditRequestsFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* updateAuditRequests(action) {
  try {
    const pendingAuditRequests = yield select(selectPendingAuditRequests);
    const eligibleLanguages = yield select(getEligibleProjectLanguages);
    const requestBody = makeQueueRequestBody(action.payload, eligibleLanguages);
    const updateEffects = pendingAuditRequests.map((r) => call(updateAuditRequest, r.id, requestBody));
    const updatedApiRequests = yield all(updateEffects);
    yield put(createAction(actionTypes.UPDATE_AUDIT_REQUESTS_FULFILLED, updatedApiRequests));

    const updatedAuditRequests = yield select(selectPendingAuditRequests);
    if (haveAuditRequestsCompleted(pendingAuditRequests, updatedAuditRequests)) {
      yield put(createAction(actionTypes.FETCH_ASSIGNED_AUDITS_START));
    }
  } catch (error) {
    yield put(createAction(actionTypes.UPDATE_AUDIT_REQUESTS_REJECTED, error));
    const errorToast = alertCreators.updateAuditRequestsFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* joinQueue(action) {
  try {
    const eligibleLanguages = yield select(getEligibleProjectLanguages);
    const requestBody = makeQueueRequestBody(action.payload, eligibleLanguages);
    const response = yield call(createAuditRequest, requestBody);
    yield put(createAction(actionTypes.JOIN_AUDIT_QUEUE_FULFILLED, response));

    // If the new audit request is already completed then fetch assigned audits,
    // there should be a new audit for the user.
    if (response.status === AuditRequestStatus.FULFILLED) {
      yield put(createAction(actionTypes.FETCH_ASSIGNED_AUDITS_START));
    }
  } catch (error) {
    yield put(createAction(actionTypes.JOIN_AUDIT_QUEUE_REJECTED, error));
    const errorToast = alertCreators.joinQueueFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* leaveQueue() {
  try {
    const pendingAuditRequests = yield select(selectPendingAuditRequests);
    const deleteEffects = pendingAuditRequests.map((r) => call(deleteAuditRequest, r.id));
    yield all(deleteEffects);
    yield put(createAction(actionTypes.LEAVE_AUDIT_QUEUE_FULFILLED));
  } catch (error) {
    yield put(createAction(actionTypes.LEAVE_AUDIT_QUEUE_REJECTED, error));
    const errorToast = alertCreators.leaveQueueFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* pollAuditRequests() {
  while (true) {
    yield delay(POLLING_INTERVAL_MS);
    yield call(checkAuditRequestCompletion);
  }
}

export function* checkAuditRequestCompletion() {
  // If there are no pending requests then do nothing, because there is no
  // pending request that could have completed.
  const previousPendingAuditRequests = yield select(selectPendingAuditRequests);
  if (previousPendingAuditRequests.length <= 0) {
    return;
  }

  // Call the fetch saga directly, which blocks this saga so that the results
  // of the fetch can be compared to the pending requests before the fetch.
  yield call(fetchAuditRequests);

  // If an audit request completed then fetch assigned audits, there should
  // be a new audit for the user.
  const pendingAuditRequests = yield select(selectPendingAuditRequests);
  if (haveAuditRequestsCompleted(previousPendingAuditRequests, pendingAuditRequests)) {
    yield put(createAction(actionTypes.FETCH_ASSIGNED_AUDITS_START));
  }
}

export function* fetchAssignedAudits() {
  try {
    const assignedBeforeFetch = yield select(selectAssignedAudits);

    const response = yield call(getAssignedAudits);
    yield put(createAction(actionTypes.FETCH_ASSIGNED_AUDITS_FULFILLED, response));

    const assignedAfterFetch = yield select(selectAssignedAudits);
    if (hasNewAssignments(assignedBeforeFetch, assignedAfterFetch)) {
      yield put(uiActions.addToast(alertCreators.newAuditAssignedToast()));
      yield put(uiActions.addNotification(alertCreators.newAuditAssignedNotification()));
    }
  } catch (error) {
    yield put(createAction(actionTypes.FETCH_AUDIT_REQUESTS_REJECTED, error));
    const errorToast = alertCreators.fetchAuditRequestsFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* pollAssignedAudits() {
  while (true) {
    yield delay(POLLING_INTERVAL_MS);

    const assignedAudits = yield select(selectAssignedAudits);
    if (assignedAudits.length > 0) {
      yield put(createAction(actionTypes.FETCH_ASSIGNED_AUDITS_START));
    }
  }
}

export function* fetchCompletedAudits(action) {
  try {
    const response = yield call(getCompletedAudits, action.payload);
    const itemsBeforePage = (action.payload.page - 1) * action.payload.per_page;
    // The `x-total` header is misleadingly named, it does not contain the total
    // number of items but instead the total number of items after and including
    // the items in the fetched page (but not before).
    const totalCompletedAudits = itemsBeforePage + parseInt(response.headers['x-total'], 10);
    const results = { apiCompletedAudits: response.data, totalCompletedAudits };
    yield put(createAction(actionTypes.FETCH_COMPLETED_AUDITS_FULFILLED, results));
  } catch (error) {
    yield put(createAction(actionTypes.FETCH_COMPLETED_AUDITS_REJECTED, error));
    const errorToast = alertCreators.fetchCompletedAuditsFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* watchBootstrap() {
  yield takeEvery(actionTypes.BOOTSTRAP_AUDIT_QUEUE_START, bootstrap);
}

export function* watchFetchAuditRequests() {
  yield takeEvery(actionTypes.FETCH_AUDIT_REQUESTS_START, fetchAuditRequests);
}

export function* watchRefreshAuditRequests() {
  yield takeEvery(actionTypes.REFRESH_AUDIT_REQUESTS_START, refreshAuditRequests);
}

export function* watchUpdateAuditRequests() {
  yield takeEvery(actionTypes.UPDATE_AUDIT_REQUESTS_START, updateAuditRequests);
}

export function* watchJoinQueue() {
  yield takeEvery(actionTypes.JOIN_AUDIT_QUEUE_START, joinQueue);
}

export function* watchLeaveQueue() {
  yield takeEvery(actionTypes.LEAVE_AUDIT_QUEUE_START, leaveQueue);
}

export function* watchFetchAssignedAudits() {
  yield takeEvery(actionTypes.FETCH_ASSIGNED_AUDITS_START, fetchAssignedAudits);
}

export function* watchFetchCompletedAudits() {
  yield takeEvery(actionTypes.FETCH_COMPLETED_AUDITS_START, fetchCompletedAudits);
}

export default function* rootSaga() {
  yield all([
    watchBootstrap(),
    watchFetchAuditRequests(),
    watchRefreshAuditRequests(),
    watchUpdateAuditRequests(),
    watchJoinQueue(),
    watchLeaveQueue(),
    watchFetchAssignedAudits(),
    watchFetchCompletedAudits(),
  ]);
}
