import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import _orderBy from 'lodash/orderBy';
import {
  Accordion,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Progress,
  Text,
} from '@chakra-ui/react';
import { SearchIcon } from '@udacity/chakra-uds-icons';

import { UDS_GLYPH_URL } from 'app/apis/service-paths';
import { escapeRegExp } from 'app/project-reviews/helpers';
import { Content } from 'app/types/reviews';

import { CodeFile } from './code-file';

const MINIMUM_REQUIRED_COMMENTS = 5;

type CodeReviewPanelProps = {
  contents: Content[];
  submissionId: number;
  isAssigned: boolean;
};

export const CodeReviewPanel: React.FC<CodeReviewPanelProps> = ({ contents, submissionId, isAssigned }) => {
  const [filteredContents, setFilteredContents] = useState(contents);
  const [fileFilterRegExp, setFileFilterRegExp] = useState<RegExp | null>(null);

  const { t } = useTranslation();
  const hasSorted = useRef<boolean>(false);

  const commentsCount = contents.reduce((count: number, content: Content) => count + content.commentsCount, 0);

  const onFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedPattern = e.target.value.trim();
    if (cleanedPattern.length > 0) {
      setFileFilterRegExp(escapeRegExp(cleanedPattern));
    } else {
      setFileFilterRegExp(null);
    }
  };

  useEffect(() => {
    const filtered = contents.filter((c) => {
      if (fileFilterRegExp) {
        return fileFilterRegExp.test(c.path);
      } else {
        return true;
      }
    });

    const filteredContents = !hasSorted.current ? _orderBy(filtered, 'commentsCount', 'desc') : filtered;
    hasSorted.current = true;

    setFilteredContents(filteredContents);
  }, [fileFilterRegExp, contents]);

  return (
    <>
      {isAssigned && (
        <Flex gap={4} p={6} mb={10} bgColor="white" border="1px solid" borderColor="gray.300" borderRadius="base">
          <Image src={`${UDS_GLYPH_URL}/chat-bubbles-code-one.svg`} alt="" w={16} h={16} />
          <Flex direction="column" gap={4} flex={1}>
            <Text>
              <Trans i18nKey="projectReviews.codeReview.addAtLeast" components={{ bold: <strong /> }} />
            </Text>

            <FormControl>
              <FormLabel mb={1}>{t('projectReviews.codeReview.minimumCommentsRequired')}</FormLabel>
              <Progress size="sm" value={commentsCount} max={MINIMUM_REQUIRED_COMMENTS} />
              <Text size="label" fontSize="sm" align="end">
                {t('projectReviews.codeReview.commentCount', {
                  count: commentsCount,
                  required: MINIMUM_REQUIRED_COMMENTS,
                })}
              </Text>
            </FormControl>
          </Flex>
        </Flex>
      )}

      <FormControl mb={10}>
        <FormLabel mb={2}>{t('projectReviews.codeReview.filterFiles')}</FormLabel>
        <InputGroup>
          <Input
            aria-label={t('projectReviews.codeReview.filterFiles')}
            id="search-input"
            onChange={onFilterInputChange}
            autoComplete="off"
          />
          <InputRightElement pointerEvents="none">
            <SearchIcon w={6} h={6} color="black" />
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <Accordion allowToggle>
        {filteredContents.map((content) => {
          return <CodeFile key={content.id} content={content} submissionId={submissionId} isAssigned={isAssigned} />;
        })}
      </Accordion>
    </>
  );
};
