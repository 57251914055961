import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Spinner, Text } from '@chakra-ui/react';
import DataTable from '@udacity/ureact-data-table';

import { getAgreementHeading } from 'app/agreements/helpers/helpers';
import { AGREEMENT_STATUS_PENDING_SIGNATURE, AGREEMENT_STATUS_SIGNED, getLabelForStatus } from 'app/agreements/status';
import { Agreement, CountryLocale } from 'app/agreements/types';
import { AgreementModal } from 'app/agreements/components/agreement-modal';
import { getCountryLocaleDataForRole } from 'app/agreements/locale-data';
import { selectCountryCode, selectReviewerName } from 'app/common/domains/session/session-selectors';
import { selectUid } from 'app/common/domains/user/user-selectors';
import { agreementDate } from 'app/helpers/format-helper';
import { getMentorAgreements, updateMentorAgreement } from 'app/services/mentor-api-service';

import styles from './styles.module.scss';

export const AgreementsList: React.FC = () => {
  const [agreements, setAgreements] = useState<Agreement[]>([]);
  const [selectedAgreement, setSelectedAgreement] = useState<null | Agreement>(null);
  const [countryLocale, setCountryLocale] = useState<undefined | CountryLocale>();
  const [loading, setLoading] = useState<Boolean>(false);

  const mentorName = useSelector<any, string>(selectReviewerName);
  const mentorId = useSelector<any, string>(selectUid);
  const mentorCountryCode = useSelector<any, string>(selectCountryCode);

  const { t } = useTranslation();

  function selectAgreement(agreement: Agreement) {
    const localeData = getCountryLocaleDataForRole(
      agreement.role,
      mentorCountryCode,
      agreement.currency_override || ''
    );
    setCountryLocale(localeData);
    setSelectedAgreement(agreement);
  }

  function onAccept(agreement: Agreement, countryLocale: CountryLocale) {
    const payRate = parseInt(countryLocale.payRatePerHour.replace('$', '').replace('£', '')).toString();

    const maxPaymentPerMonth = parseInt(countryLocale.maxPaymentPerMonth.replace('$', '').replace('£', '')).toString();

    const bonus = countryLocale.bonus
      ? parseInt(countryLocale.bonus.replace('$', '').replace('£', '')).toString()
      : '0';

    const data: Agreement = {
      ...agreement,
      status: AGREEMENT_STATUS_SIGNED,
      date_signed: new Date(),
      pay_rate: payRate,
      hours_per_month: countryLocale.upToHoursPerMonth,
      max_payments_per_month: maxPaymentPerMonth,
      bonus: bonus,
    };

    updateMentorAgreement(data).then((updatedAgreement) => {
      const newAgreements = agreements.map((agreement) => {
        if (agreement.id === updatedAgreement.id) {
          updatedAgreement.currency_override = agreement.currency_override;
          return updatedAgreement;
        }
        return agreement;
      });

      setSelectedAgreement(null);
      setAgreements(newAgreements);
    });
  }

  useEffect(() => {
    setLoading(true);

    getMentorAgreements(mentorId)
      .then(setAgreements)
      .finally(() => setLoading(false));
  }, [mentorId]);

  const tableData = agreements.map((row) => {
    const title = getAgreementHeading(row.role);
    const buttonLabel = row.status === AGREEMENT_STATUS_SIGNED ? t('common.view') : t('common.review');
    const buttonVariant = row.status === AGREEMENT_STATUS_PENDING_SIGNATURE ? 'solid' : 'outline';
    const status = getLabelForStatus(row.status);
    const date = row.date_signed ? agreementDate(row.date_signed) : '';

    return {
      Name: {
        data: title,
      },
      Status: {
        data: (
          <div>
            {status}
            <br />
            <small>{date}</small>
          </div>
        ),
      },
      Action: {
        data: (
          <Button onClick={() => selectAgreement(row)} variant={buttonVariant} size="sm">
            {buttonLabel}
          </Button>
        ),
      },
    };
  });

  return (
    <div>
      <header className={styles.header}>
        <Heading size="lg" as="h2">
          {t('agreements.yourAgreements')}
        </Heading>
      </header>

      <main className={styles.agreementTableContainer}>
        {loading ? (
          <Spinner />
        ) : agreements.length <= 0 ? (
          <Text>{t('agreements.youDontHaveAny')}</Text>
        ) : (
          <DataTable rows={tableData} data-tst="agreement-table" />
        )}
      </main>

      {selectedAgreement && (
        <AgreementModal
          agreement={selectedAgreement}
          mentorCountryCode={mentorCountryCode}
          countryLocale={countryLocale}
          mentorName={mentorName}
          onAccept={onAccept}
          onClose={() => setSelectedAgreement(null)}
        />
      )}
    </div>
  );
};
