import React from 'react';
import { Trans } from 'react-i18next';

import { ActiveTasks } from './active-tasks';
import Queue from './queue';
import Stats from './stats';
import styles from './overview.module.scss';

export class Overview extends React.Component {
  render() {
    return (
      <div>
        <header className={styles['header']}>
          <div className={styles['header-container']}>
            <div className={styles['header-title']}>
              <h1>
                <Trans i18nKey="queue.overview.welcomeBack" components={{ bold: <strong /> }} />
              </h1>
            </div>
            <div className={styles['header-actions']}>
              <Queue />
            </div>
          </div>
        </header>

        <section className={styles['active-tasks']}>
          <ActiveTasks />
        </section>

        <section className={styles['stats']}>
          <Stats />
        </section>
      </div>
    );
  }
}

export default Overview;
