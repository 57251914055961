import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Spinner } from '@chakra-ui/react';
import DataTable from '@udacity/ureact-data-table';
import dayjs from 'dayjs';
import _get from 'lodash/get';

import constants from 'app/queue/views/history/common/constants';
import { Pagination } from 'app/queue/views/history/pagination';

import styles from './task-history.module.scss';

const { PAGE_SIZE, HISTORY_DATE_FORMAT } = constants;

export const sortComparator = (sortColumn, row) => {
  const data = _get(row, [sortColumn, 'data']);
  if (sortColumn === 'Earned') {
    const price = parseFloat(data.replace('$', ''));
    return price;
  } else if (sortColumn === 'Completed') {
    const date = dayjs(data, HISTORY_DATE_FORMAT);
    return date.format();
  }
  return data;
};

type TaskHistoryProps = {
  title: string;
  isLoading: boolean;
  tableData: any;
  page: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
};

export const TaskHistory: React.FC<TaskHistoryProps> = ({
  title,
  isLoading,
  tableData,
  page,
  onNextPage,
  onPreviousPage,
}) => {
  const { t } = useTranslation();
  const isPaginationVisible = page > 1 || tableData.length >= PAGE_SIZE;
  const hasNoData = !tableData.length && !isLoading;

  return (
    <section>
      <Heading size="h3" as="h3">
        {title}
      </Heading>
      {isLoading ? (
        <Spinner size="lg" />
      ) : (
        <>
          {tableData.length > 0 && (
            <DataTable
              rows={tableData}
              data-tst="projects-table"
              className={styles['submissions-table']}
              sortComparator={sortComparator}
            />
          )}

          {hasNoData && (
            <label className={`${styles['empty']} label`}>{t('queue.history.noRecentlyCompletedTasks')}</label>
          )}

          {isPaginationVisible && (
            <Pagination
              onNextClick={onNextPage}
              onPreviousClick={onPreviousPage}
              page={page}
              className={styles['pagination']}
            />
          )}
        </>
      )}
    </section>
  );
};
