import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  Link,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Markdown } from '@udacity/ureact-markdown';

import { useFetchPlagiarismCritiquesQuery, useUpdateCritiqueMutation } from 'app/apis/reviews';
import { Alert } from 'app/common/components/alert';
import { AsymmetricCard } from 'app/common/components/asymmetric-card';
import { markdownHasMultipleImages } from 'app/project-reviews/helpers';
import type { Critique, EditedCritique } from 'app/types/reviews';

import { ConfirmPlagiarismAuditAlert } from './confirm-plagiarism-audit-alert';
import { ConfirmedPlagiarized } from './confirmed-plagiarized';
import { PlagiarismAuditReason } from './plagiarism-audit-reason';
import { UnassignPlagiarismAuditAlert } from './unassign-plagiarism-audit-alert';

type PlagiarismAuditProps = {
  caseId: number | undefined;
  sourceUrl: string | undefined;
  reviewerNote: string;
  submissionUrl: string | undefined;
  isAssigned: boolean;
};

export const PlagiarismAudit = ({
  caseId,
  sourceUrl,
  reviewerNote,
  submissionUrl,
  isAssigned,
}: PlagiarismAuditProps) => {
  const [isPlagiarized, setIsPlagiarized] = useState<string>('');

  // We save the same observation for both critiques when is determined to be original
  const [tempCritique, setTempCritique] = useState<Critique>({
    id: 0,
    result: 'passed',
    observation: null,
    suggestion: null,
    rubricItemId: 19000,
    sectionId: 6768,
    rubricItemDeletedAt: null,
  });

  const { t } = useTranslation();
  const confirmSubmitDisclosure = useDisclosure();
  const unassignDisclosure = useDisclosure();

  const { data: critiques } = useFetchPlagiarismCritiquesQuery(caseId ? caseId : skipToken);

  const [updateCritique] = useUpdateCritiqueMutation();

  const isDisabled = useMemo(() => {
    return (
      !isPlagiarized ||
      critiques?.map((c) => c.result).some((cr) => !cr) ||
      (isPlagiarized === 'yes' &&
        critiques?.some((cr) => cr.result === 'failed' && !markdownHasMultipleImages(cr.observation || ''))) ||
      (isPlagiarized === 'yes' &&
        critiques?.find((c) => c.rubricItemId === 18999)?.result === 'passed' &&
        !markdownHasMultipleImages(critiques?.find((c) => c.rubricItemId === 18999)?.observation || '')) ||
      (isPlagiarized === 'yes' && critiques?.map((c) => c.result).every((cr) => cr === 'passed')) ||
      (isPlagiarized === 'no' && !markdownHasMultipleImages(tempCritique?.observation || ''))
    );
  }, [isPlagiarized, critiques, tempCritique]);

  if (!critiques || !caseId) return null;

  const fromSource = critiques.filter((c) => c.rubricItemId === 18999)[0];
  const fromAlternative = critiques.filter((c) => c.rubricItemId === 19000)[0];

  const handleSubmitCritique = (payload: EditedCritique) => {
    // Save the same observation for both critiques
    if (payload.id === 0) {
      setTempCritique((current) => ({ ...current, ...payload }));

      updateCritique({
        ...payload,
        id: fromSource?.id,
        submissionOrCaseId: caseId,
        isPlagiarismAudit: true,
      });

      updateCritique({
        ...payload,
        id: fromAlternative.id,
        submissionOrCaseId: caseId,
        isPlagiarismAudit: true,
      });
    } else {
      updateCritique({
        ...payload,
        submissionOrCaseId: caseId,
        isPlagiarismAudit: true,
      });
    }
  };

  return (
    <>
      <GridItem gridColumn={{ base: '3 / -1', lg: '4 / -1' }}>
        <Heading size="h1" as="h1" mb={6}>
          {t('projectReviews.plagiarismAudit.title')}
        </Heading>

        <Box w={{ base: 'full', lg: '78%' }} mb={10}>
          <Heading size="h3" as="h3" mb={2}>
            {t('projectReviews.feedback.instructions')}
          </Heading>
          <Text mb={4}>
            <Trans i18nKey="projectReviews.plagiarismAudit.instructions" />
          </Text>

          <HStack spacing={4} mb={10}>
            <Button as="a" href={submissionUrl} variant="outline" target="_blank" rel="noopener noreferrer" download>
              {t('projectReviews.feedback.downloadProject')}
            </Button>

            {isAssigned && (
              <Button variant="ghost" onClick={unassignDisclosure.onOpen}>
                {t('projectReviews.plagiarismAudit.unassign')}
              </Button>
            )}
          </HStack>

          <Heading size="h3" as="h3" mb={2}>
            {t('projectReviews.plagiarismAudit.priorReview')}
          </Heading>
          <AsymmetricCard bgColor="white" gap={2}>
            <Flex direction="column" maxW="full">
              <Text size="label">{t('projectReviews.plagiarismAudit.reviewerNote')}</Text>
              <Text mb={4}>{t('projectReviews.plagiarismAudit.keyParts')}</Text>
              <Text>
                <Trans
                  i18nKey="projectReviews.plagiarismAudit.potentialSourceUrl"
                  values={{ sourceUrl }}
                  components={{
                    source_link: <Link href={sourceUrl} isExternal />,
                  }}
                />
              </Text>
              <Markdown markdownText={reviewerNote} />
            </Flex>
          </AsymmetricCard>
        </Box>

        <Heading size="h3" as="h3" mb={2}>
          {t('projectReviews.plagiarismAudit.auditResults')}
        </Heading>
        <Flex
          direction="column"
          gap={6}
          bgColor="white"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="base"
          p={6}
          mb={10}
        >
          <FormControl>
            <FormLabel mb={2}>{t('projectReviews.plagiarismAudit.isSubmissionPlagiarized')}</FormLabel>

            <RadioGroup
              value={isPlagiarized}
              onChange={(value) => {
                updateCritique({
                  id: fromSource.id,
                  result: value === 'no' ? 'passed' : null,
                  observation: null,
                  submissionOrCaseId: caseId,
                  isPlagiarismAudit: true,
                });
                updateCritique({
                  id: fromAlternative.id,
                  result: value === 'no' ? 'passed' : null,
                  observation: null,
                  submissionOrCaseId: caseId,
                  isPlagiarismAudit: true,
                });
                setIsPlagiarized(value);
              }}
            >
              <HStack spacing={8}>
                <Radio value="yes">{t('projectReviews.plagiarismAudit.isPlagiarized')}</Radio>
                <Radio value="no">{t('projectReviews.plagiarismAudit.originalSubmission')}</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>

          {isPlagiarized === 'no' && (
            <PlagiarismAuditReason
              label={t('projectReviews.plagiarismAudit.explainWhyReviewIncorrect')}
              helperText={t('projectReviews.plagiarismAudit.explainWhyReviewIncorrectHelper')}
              critique={tempCritique}
              onSubmitCritique={handleSubmitCritique}
              isAssigned={isAssigned}
            />
          )}

          {isPlagiarized === 'yes' && (
            <ConfirmedPlagiarized
              critiques={critiques}
              caseId={caseId}
              onSubmitCritique={handleSubmitCritique}
              isAssigned={isAssigned}
            />
          )}

          {isPlagiarized === 'yes' && critiques?.map((c) => c.result).every((cr) => cr === 'passed') && (
            <Alert status="error">{t('projectReviews.plagiarismAudit.markedAsPlagiarizedError')}</Alert>
          )}

          <Button isDisabled={isDisabled} w="max-content" onClick={confirmSubmitDisclosure.onOpen}>
            {t('projectReviews.plagiarismAudit.submit')}
          </Button>
        </Flex>

        <HStack spacing={4} mb={10}>
          <Button as="a" href={submissionUrl} variant="outline" target="_blank" rel="noopener noreferrer" download>
            {t('projectReviews.feedback.downloadProject')}
          </Button>

          {isAssigned && (
            <Button variant="ghost" onClick={unassignDisclosure.onOpen}>
              {t('projectReviews.plagiarismAudit.unassign')}
            </Button>
          )}
        </HStack>

        <ConfirmPlagiarismAuditAlert
          isOpen={confirmSubmitDisclosure.isOpen}
          onClose={confirmSubmitDisclosure.onClose}
          plagiarismCaseId={caseId}
          isPlagiarized={isPlagiarized === 'yes'}
          isPlagiarizedFromSource={fromSource.result === 'failed'}
          isPlagiarizedFromAlternative={fromAlternative.result === 'failed'}
        />

        <UnassignPlagiarismAuditAlert
          isOpen={unassignDisclosure.isOpen}
          onClose={unassignDisclosure.onClose}
          plagiarismCaseId={caseId}
        />
      </GridItem>
    </>
  );
};
