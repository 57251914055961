import i18n from 'app/i18n';

const parsePathname = (pathname: string) => {
  return pathname.match(/.*\/(.*)/)?.[1] ?? '';
};

const getHeader = (header: string) => {
  switch (header) {
    case 'career':
      return i18n.t('handbook.mentorExpectations');
    case 'community':
      return i18n.t('handbook.mentorCommunity');
    case 'contact':
      return i18n.t('handbook.contactUs');
    case 'project-reviews':
      return i18n.t('handbook.projectReviews');
    case 'knowledge':
      return i18n.t('common.knowledge');
    default:
      return i18n.t('handbook.welcomeToMentorship');
  }
};

export default {
  getHeader,
  parsePathname,
};
