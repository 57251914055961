import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '@chakra-ui/react';
import { CheckIcon } from '@udacity/chakra-uds-icons';

import styles from '../payment-settings.module.scss';

export function PayoneerActive({ logIn }) {
  const { t } = useTranslation();

  return (
    <div className={styles['payoneer-alert']}>
      <p className={styles['payoneer-success']}>
        <CheckIcon title="attention" w={6} h={6} color="feedback.positive" />
        {t('mentorProfile.payoneer.yourAccountIsActive')}
      </p>
      <p>
        <Trans
          i18nKey="mentorProfile.payoneer.updatePaymentMethod"
          components={{
            login_link: <Link href={logIn} isExternal fontSize="sm" />,
          }}
        />
      </p>
    </div>
  );
}

export default PayoneerActive;
