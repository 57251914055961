import apiService from '@udacity/ureact-api-service';
import CONFIG from 'configLoader';
import { GET_MENTOR_AGREEMENTS_OPERATION_NAME, UPDATE_MENTOR_AGREEMENT_OPERATION_NAME } from './constants';

function post(path, data) {
  return apiService.makePostRequest(CONFIG.mentorApiUrl, path, data);
}

export function getMentorAgreements(mentor_id) {
  return gql(GET_MENTOR_AGREEMENTS, GET_MENTOR_AGREEMENTS_OPERATION_NAME, {
    mentor_id,
  }).then((response) => {
    return response.data.agreements;
  });
}

export function updateMentorAgreement(data) {
  return gql(UPDATE_MENTOR_AGREEMENT, UPDATE_MENTOR_AGREEMENT_OPERATION_NAME, data).then((response) => {
    return response.data.updateAgreement;
  });
}

export function gql(query, operationName, variables) {
  return apiService.makeGqlRequest(CONFIG.mentorApiUrl, {
    query,
    operationName,
    variables,
  });
}

export function upload(body) {
  /*
   body consists of an object:
   {
     fileName: string,
     fileType: string,
     image: dataURL base64 from FileReader
   }
  */
  return post('/images/upload', body);
}

export function mentorQuery(uid) {
  return `
  query MentorDash_MentorQuery {
    mentor(uid: "${uid}") {
      uid
      country
      languages
      approved_languages
      bio
      educational_background
      github_url
      linkedin_url
      avatar_url
      application
      agrees_terms_of_service
      agreement_version
      payoneer_status
      phone_number
      available_hours
      timezone
      blocked
      gig_approved
      gov_mentor_agreement_version
      services {
        classroom_mentorship
        connect
        reviews
        community
        government_mentorship
      }
    }
  }
  `;
}

export const createMentorMutation = `
  mutation MentorDash_CreateMentor (
    $uid: String!,
    $languages: [String],
    $bio: String,
    $educational_background: String,
    $github_url: String,
    $linkedin_url: String
    $avatar_url: String,
    $application: JSON,
    $agreement_version: String,
    $phone_number: String,
    $timezone: String,
    $gov_mentor_agreement_version: String
    $available_hours: Int
  ) {
    createMentor(
      uid: $uid,
      languages: $languages,
      bio: $bio,
      educational_background: $educational_background,
      github_url: $github_url,
      linkedin_url: $linkedin_url,
      avatar_url: $avatar_url,
      application: $application,
      agreement_version: $agreement_version,
      phone_number: $phone_number,
      timezone: $timezone
      gov_mentor_agreement_version: $gov_mentor_agreement_version
      available_hours: $available_hours
    ) {
      uid
      languages
      bio
      educational_background
      github_url
      linkedin_url
      avatar_url
      application
      agreement_version,
      phone_number,
      timezone,
      gov_mentor_agreement_version,
      available_hours
    }
  }
`;

export const updateMentorMutation = `
  mutation MentorDash_UpdateMentor(
    $uid: String!,
    $phone_number: String,
    $bio: String,
    $github_url: String,
    $linkedin_url: String,
    $avatar_url: String,
    $agreement_version: String,
    $available_hours: Int,
    $languages: [String],
    $timezone: String,
    $application: JSON,
    $gov_mentor_agreement_version: String
  ) {
    updateMentor(
      uid: $uid,
      phone_number: $phone_number,
      bio: $bio, 
      github_url: $github_url,
      linkedin_url: $linkedin_url,
      avatar_url: $avatar_url,
      agreement_version: $agreement_version,
      available_hours: $available_hours,
      languages: $languages,
      timezone: $timezone,
      application: $application,
      gov_mentor_agreement_version: $gov_mentor_agreement_version
    ) {
      phone_number
      bio
      github_url
      linkedin_url
      avatar_url
      agreement_version
      available_hours
      languages
      timezone
      application
      gov_mentor_agreement_version
    }
  }
`;

const GET_MENTOR_AGREEMENTS = `
  query MentorDash_GetMentorAgreements(
    $mentor_id: String!
  ) {
    agreements(mentor_id: $mentor_id) {
      id
      mentor_id
      role
      status
      date_signed
      pay_rate
      hours_per_month
      max_payments_per_month
      bonus
      created_at
      updated_at
      currency_override
    }
  }
`;

const UPDATE_MENTOR_AGREEMENT = `
  mutation MentorDash_UpdateMentorAgreement(
    $id: String!,
    $mentor_id: String!,
    $status: Status!,
    $date_signed: Date,
    $pay_rate: String,
    $hours_per_month: String,
    $max_payments_per_month: String,
    $bonus: String
  ) {
    updateAgreement(
      id: $id,
      mentor_id: $mentor_id,
      status: $status,
      date_signed: $date_signed,
      pay_rate: $pay_rate,
      hours_per_month: $hours_per_month,
      max_payments_per_month: $max_payments_per_month,
      bonus: $bonus
    ) {
      id
      mentor_id
      role
      status
      date_signed
      pay_rate
      hours_per_month
      max_payments_per_month
      bonus
      created_at
      updated_at
    }
  }
`;
