import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import { SeriesCard } from './series-card';
import { useGetAvailableSeriesQuery } from 'app/uconnect/queries/enhanced';
import auth from '@udacity/ureact-hoth';

type SeriesSelectorProps = {
  onClaimSeriesClick: (seriesId: string | null) => void;
};

export const SeriesSelector: React.FC<SeriesSelectorProps> = ({ onClaimSeriesClick }) => {
  const { t } = useTranslation();

  const { data } = useGetAvailableSeriesQuery({
    facilitatorUserKey: auth.getCurrentUserId(),
  });

  return (
    <Flex direction="column" gap={4}>
      <Text>{t('connect.seriesSelectorInstructions')}</Text>
      <Flex as={UnorderedList} direction="column" gap={4} listStyleType="none" mx={0}>
        {data?.series.series.map((series) => (
          <ListItem key={series.id}>
            <SeriesCard series={series} onClaimSeriesClick={onClaimSeriesClick} />
          </ListItem>
        ))}
      </Flex>
    </Flex>
  );
};
