import { createSelector } from 'reselect';
import storage from 'app/helpers/storage-helper';
import _isEmpty from 'lodash/isEmpty';

import {
  PlagiarismCaseQueue,
  PlagiarismCaseRequest,
  AssignedPlagiarismCase,
  CompletedPlagiarismCase,
} from './plagiarism-case-queue-types';

const selectPlagiarismCaseQueue = (state: any): PlagiarismCaseQueue => state.plagiarismCases.queue;

export const selectPendingPlagiarismCaseRequests = createSelector(
  selectPlagiarismCaseQueue,
  (queue: PlagiarismCaseQueue): PlagiarismCaseRequest[] => queue.pendingPlagiarismCaseRequests
);

export const selectPlagiarismCaseQueueEnabled = createSelector(
  selectPendingPlagiarismCaseRequests,
  (requests: PlagiarismCaseRequest[]): boolean => {
    return requests.length > 0;
  }
);

export const selectPlagiarismCaseQueueExpiration = createSelector(
  selectPendingPlagiarismCaseRequests,
  (requests: PlagiarismCaseRequest[]): number => {
    return requests.reduce((maxClosedAt, r) => Math.max(maxClosedAt, r.closedAt), 0);
  }
);

export const projectsEnabledInPlagiarismCaseQueueSelector = createSelector(
  selectPlagiarismCaseQueue,
  (queue: PlagiarismCaseQueue): { [id: number]: boolean } => queue.projectsEnabledForPlagiarismCaseQueue
);

export const selectProjectsEnabledInPlagiarismCaseQueue = (state: any) => {
  const projectsEnabled = projectsEnabledInPlagiarismCaseQueueSelector(state);

  if (_isEmpty(projectsEnabled)) {
    return storage.get('plagiarism-queue-settings') || {};
  }

  storage.set('plagiarism-queue-settings', projectsEnabled);

  return projectsEnabled;
};

export const selectAssignedPlagiarismCases = createSelector(
  selectPlagiarismCaseQueue,
  (queue: PlagiarismCaseQueue): AssignedPlagiarismCase[] => queue.assignedPlagiarismCases
);

export const selectCompletedPlagiarismCases = createSelector(
  selectPlagiarismCaseQueue,
  (queue: PlagiarismCaseQueue): CompletedPlagiarismCase[] => queue.completedPlagiarismCases
);

export const selectTotalCompletedPlagiarismCases = createSelector(
  selectPlagiarismCaseQueue,
  (queue: PlagiarismCaseQueue): number | undefined => queue.totalCompletedPlagiarismCases
);

export const selectPlagiarismCaseQueueAsync = (state: any) => state.plagiarismCases.queueAsync;

export const selectFetchCompletedPlagiarismCasesAsync = createSelector(
  selectPlagiarismCaseQueueAsync,
  (queueAsync) => queueAsync.fetchCompletedPlagiarismCases
);
