import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import styles from './data-table-cell.module.scss';

export function DataTableCell({ children, id, isFirstChild }) {
  const deepLinkId = parseInt(window.location.hash.replace(/#/, ''));

  const cellClasses = classNames(styles['table-cell'], {
    [styles['highlighted']]: deepLinkId === id,
    [styles['first-child']]: isFirstChild,
  });

  return <div className={cellClasses}>{children}</div>;
}

DataTableCell.propTypes = {
  id: propTypes.number,
  isFirstChild: propTypes.bool,
  children: propTypes.oneOfType([propTypes.arrayOf(propTypes.node), propTypes.node, propTypes.string]),
};

export default DataTableCell;
