import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

export const LoadingPage: React.FC = () => {
  return (
    <Flex justify="center" align="center" w="100vw" h="100vh">
      <Spinner />
    </Flex>
  );
};
