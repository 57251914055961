import { combineReducers } from 'redux';
import auditQueue from './audit-queue/audit-queue-reducer';
import auditQueueAsync from './audit-queue/audit-queue-async-reducer';
import plagiarismCaseQueue from './plagiarism-case-queue/plagiarism-case-queue-reducer';
import plagiarismCaseQueueAsync from './plagiarism-case-queue/plagiarism-case-queue-async-reducer';
import submissions from './submissions/submissions-reducer';
import submissionsAsync from './submissions/submissions-async-reducer';
import statements from './statements/statements-reducer';
import statementsAsync from './statements/statements-async-reducer';
import reviewsQueue from './reviews-queue/reviews-queue-reducer';
import reviewsQueueAsync from './reviews-queue/reviews-queue-async-reducer';
import certifications from './certifications/certifications-reducer';
import certificationsAsync from './certifications/certifications-async-reducer';
import questionQueue from './question-queue/question-queue-reducer';
import questionQueueAsync from './question-queue/question-queue-async-reducer';
import reviewerData from './reviewer-data/reviewer-data-reducer';
import reviewerDataAsync from './reviewer-data/reviewer-data-async-reducer';
import recruitmentFamilies from './recruitment-families/recruitment-families-reducer';
import recruitmentFamiliesAsync from './recruitment-families/recruitment-families-async-reducer';

export const auditsReducer = combineReducers({
  queue: auditQueue,
  queueAsync: auditQueueAsync,
});

export const plagiarismCasesReducer = combineReducers({
  queue: plagiarismCaseQueue,
  queueAsync: plagiarismCaseQueueAsync,
});

export const questionsReducer = combineReducers({
  queue: questionQueue,
  queueAsync: questionQueueAsync,
});

export const reviewsReducer = combineReducers({
  submissions,
  submissionsAsync,
  statements,
  statementsAsync,
  queue: reviewsQueue,
  queueAsync: reviewsQueueAsync,
  certifications,
  certificationsAsync,
  reviewerData,
  reviewerDataAsync,
  recruitmentFamilies,
  recruitmentFamiliesAsync,
});
