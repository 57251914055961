import React, { ReactNode } from 'react';
import { datadogRum } from '@datadog/browser-rum';

interface Props {
  children: ReactNode;
}

export default class ErrorBoundary extends React.Component<Props> {
  async componentDidCatch(error: Error, _errorInfo: { [key: string]: any }) {
    datadogRum.addError(error);
  }

  render() {
    return this.props.children;
  }
}
