/* eslint-disable */ /**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 * instead, edit its related `.gql` file and recreate it with
 *
 * npm run codegen:connect
 */

import * as Types from '../../types/uconnect';

import { api } from 'app/apis/connect-gql-api';
export type GetMeetingsQueryVariables = Types.Exact<{
  seriesId?: Types.InputMaybe<Types.Scalars['UUID']['input']>;
  facilitatorUserKey?: Types.InputMaybe<Types.Scalars['String']['input']>;
  locales?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  startsAfter?: Types.InputMaybe<Types.Scalars['Time']['input']>;
  startsBefore?: Types.InputMaybe<Types.Scalars['Time']['input']>;
}>;

export type GetMeetingsQuery = {
  __typename?: 'Query';
  meetings: {
    __typename?: 'PaginatedMeetings';
    totalCount: number;
    meetings: Array<{
      __typename?: 'Meeting';
      id: any;
      cohortId: any;
      name: string;
      startAt: any;
      endAt: any;
      instructions?: string | null;
      conferenceLink?: string | null;
      locales: Array<string>;
      series?: { __typename?: 'Series'; id: any; name: string; description?: string | null } | null;
      meetingUsers: {
        __typename?: 'PaginatedMeetingUsers';
        totalCount: number;
        meetingUsers: Array<{
          __typename?: 'MeetingUser';
          attendanceRequest?: {
            __typename?: 'MeetingAttendanceRequest';
            createdAt: any;
            judgeUserKey?: string | null;
          } | null;
        }>;
      };
    }>;
  };
};

export const GetMeetingsDocument = `
    query GetMeetings($seriesId: UUID, $facilitatorUserKey: String, $locales: [String!], $startsAfter: Time, $startsBefore: Time) {
  meetings(input: {seriesId: $seriesId, facilitatorUserKey: $facilitatorUserKey, locales: $locales, startsAfter: $startsAfter, startsBefore: $startsBefore, sortBy: START_AT}) {
    totalCount
    meetings {
      id
      cohortId
      name
      startAt
      endAt
      instructions
      conferenceLink
      series {
        id
        name
        description
      }
      meetingUsers {
        totalCount
        meetingUsers {
          attendanceRequest {
            createdAt
            judgeUserKey
          }
        }
      }
      locales
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetMeetings: build.query<GetMeetingsQuery, GetMeetingsQueryVariables | void>({
      query: (variables) => ({ document: GetMeetingsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
