import React from 'react';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Heading } from '@chakra-ui/react';
import auth from '@udacity/ureact-hoth';

import styles from './prompt.module.scss';

export const Prompt: React.FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const error = params.get('error') ?? false;

  const handleClick = () => {
    auth.signOut({ returnUrl: window.location.origin });
  };

  return (
    <main className={styles.prompt}>
      <div className={styles.wrapper}>
        <div className={styles['lock-icon']} />

        <div className={styles.header}>
          {error ? (
            <div className={styles['error-msg']}>
              <h4>{t('error.theresAnErrorWithYourAccount')}</h4>
              <p>
                <Trans
                  i18nKey="error.pleaseContactSupport"
                  components={{
                    support_email: (
                      <a
                        className={styles.help}
                        href="mailto:mentor-support@udacity.com?subject=Issue with my account"
                      />
                    ),
                  }}
                />
              </p>
            </div>
          ) : (
            <Heading as="h1" size="h2">
              {t('error.youDontHavePermission')}
            </Heading>
          )}
        </div>

        <Button onClick={handleClick} mb={4}>
          {t('signinToDifferentAccount')}
        </Button>

        <a className={styles.help} href="https://www.udacity.com/start-mentoring">
          {t('howDoIBecomeAMentor')}
        </a>
      </div>
    </main>
  );
};
