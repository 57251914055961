import React from 'react';
import { useTranslation } from 'react-i18next';

import { SidebarList } from 'app/common/components/sidebar-list/sidebar-list';
import { SidebarRow } from 'app/common/components/sidebar-list/sidebar-row';
import { removeDanglingUrlSlash } from 'app/helpers/format-helper';

type SidebarProps = {
  pathname: string;
};

export const Sidebar: React.FC<SidebarProps> = ({ pathname }) => {
  const { t } = useTranslation();

  const isSelected = (componentName: string): boolean => {
    const parsedPathname = removeDanglingUrlSlash(pathname);
    if (componentName === 'general' && parsedPathname === '/specialized-mentor/guidelines') {
      return true;
    }
    return parsedPathname === `/specialized-mentor/guidelines/${componentName}`;
  };

  return (
    <SidebarList label={t('handbook.specializedMentorsServiceGuidelines')} title={t('common.specializedMentor')}>
      <SidebarRow to="general" label={t('handbook.general')} isSelected={isSelected('general')} isSpecialized />
      <SidebarRow to="career" label={t('common.careerCoach')} isSelected={isSelected('career')} isSpecialized />
      <SidebarRow to="guru" label={t('common.guru')} isSelected={isSelected('guru')} isSpecialized />
      <SidebarRow to="tutor" label={t('common.tutor')} isSelected={isSelected('tutor')} isSpecialized />
      <SidebarRow
        to="moderator"
        label={t('common.communityModerator')}
        isSelected={isSelected('moderator')}
        isSpecialized
      />
      <SidebarRow
        to="session-lead"
        label={t('common.sessionLead')}
        isSelected={isSelected('session-lead')}
        isSpecialized
      />
    </SidebarList>
  );
};
