import { createAction } from 'app/helpers/action-helper';

export const actionTypes = {
  FETCH_ALL: 'recruitment-families:FETCH_ALL',
  FETCH_ALL_FULFILLED: 'recruitment-families:FETCH_ALL_FULFILLED',
  FETCH_ALL_RESET: 'recruitment-families:FETCH_ALL_RESET',
  FETCH_MY_INTERESTS: 'recruitment-families:FETCH_MY_INTERESTS',
  FETCH_MY_INTERESTS_FULFILLED: 'recruitment-families:FETCH_MY_INTERESTS_FULFILLED',
  FETCH_MY_INTERESTS_RESET: 'recruitment-families:FETCH_MY_INTERESTS_RESET',
  UPDATE_REVIEWER_INTEREST: 'recruitment-families:UPDATE_REVIEWER_INTEREST',
  UPDATE_REVIEWER_INTEREST_FULFILLED: 'recruitment-families:UPDATE_REVIEWER_INTEREST_FULFILLED',
  UPDATE_REVIEWER_INTEREST_RESET: 'recruitment-families:UPDATE_REVIEWER_INTEREST_RESET',
};

export const updateReviewerInterest = (family) => {
  return createAction(actionTypes.UPDATE_REVIEWER_INTEREST, { family });
};

export const fetchAllRecruitmentFamilies = () => {
  return createAction(actionTypes.FETCH_ALL);
};

export const fetchMyInterests = (uid) => {
  return createAction(actionTypes.FETCH_MY_INTERESTS, { uid });
};

export const resetFetchAllRecruitmentFamilies = () => {
  return createAction(actionTypes.FETCH_ALL_RESET);
};
