import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoIcon } from '@udacity/chakra-uds-icons';

import styles from '../payment-settings.module.scss';

export function PayoneerExplanation() {
  const { t } = useTranslation();

  return (
    <p className={styles['payoneer-alert']}>
      <InfoIcon title="attention" w={6} h={6} />
      {t('mentorProfile.payoneer.youWillBeAsked')}
    </p>
  );
}

export default PayoneerExplanation;
