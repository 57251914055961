import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from '@chakra-ui/react';

import CONFIG from 'configLoader';
import styles from './terms-of-service-copy.module.scss';

class TermsOfService extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className={styles.contract}>
        <h1 className={styles.title}>{this.props.t('termsOfService.studentReviewer')}</h1>
        <h2 className={styles.subtitle}>{t('termsOfService.independentContractor')}</h2>
        <hr className={styles.line} />
        <div>
          <p className={styles.paragraph}>
            <Trans
              i18nKey="termsOfService.weHaveUpdated"
              components={{
                agreement_link: <Link href={CONFIG.mentorAgreementUrl} isExternal />,
              }}
            />
          </p>
        </div>
      </div>
    );
  }
}

export default withTranslation()(TermsOfService);
