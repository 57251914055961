import React from 'react';
import { useTranslation } from 'react-i18next';

import { AvatarImage } from 'app/common/components/onboarding/avatar-image';
import { TimezoneSelector } from 'app/common/components/onboarding/timezone-selector';
import PaymentSettings from 'app/common/views/profile-editor/payment-settings';
import { MAX_CHARS, HOURS } from 'app/common/views/profile-editor/form-helper';
import { TermsOfService } from 'app/common/components/onboarding/terms-of-service';
import MentorOpportunity from 'app/common/components/onboarding/mentor-opportunity';
import { SpecializedMentorRoles } from 'app/common/components/onboarding/specialized-mentor-roles';
import { FormFields } from 'app/common/views/profile-editor/form-types';
import { specializedApprovedLanguages } from 'app/helpers/form-helper';
import { SpecializedMentorLocale } from 'app/common/components/onboarding/specialized-mentor-locale';

import CONFIG from 'configLoader';
import { MentorFormInput } from './mentor-form-input';
import { MentorFormTextarea } from './mentor-form-textarea';
import styles from '../profile-editor.module.scss';
import { LanguageChangeEvent } from '../profile-editor';

type SpecializedMentorFormProps = {
  fields: FormFields;
  handleFieldChange: ({ id, value }: { id: string; value: string | number }) => void;
  handleTextareaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleMultiCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  handleMultiSelectChange: (e: LanguageChangeEvent) => void;
};

export const SpecializedMentorForm: React.FC<SpecializedMentorFormProps> = ({
  fields,
  handleFieldChange,
  handleTextareaChange,
  handleTextChange,
  handleMultiCheckboxChange,
  handleMultiSelectChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <AvatarImage
        avatarUrl={fields.avatar_url}
        onAvatarChange={(url: string) => handleFieldChange({ id: 'avatar_url', value: url })}
      />

      <PaymentSettings />

      <MentorOpportunity
        label={t('mentorProfile.form.imAbleToDedicate', {
          numOfHours: HOURS.specialized,
        })}
        id="high_touch"
        value={HOURS.specialized}
        isChecked={fields.available_hours === HOURS.specialized}
        handleChange={(e) => {
          handleFieldChange({
            ...e.target,
            id: 'available_hours',
            value: HOURS.specialized,
          });
        }}
      />

      <SpecializedMentorRoles
        label={t('mentorProfile.imInterested')}
        handleMultiCheckboxChange={handleMultiCheckboxChange}
        roles={fields.roles ?? []}
      />

      <MentorFormInput
        id="educational_background"
        label={t('mentorProfile.form.educationalBackground')}
        value={fields.educational_background}
        onChange={handleTextChange}
        maxChars={MAX_CHARS.input}
        isOptional
      />

      <MentorFormInput id="linkedin_url" label="LinkedIn" value={fields.linkedin_url} onChange={handleTextChange} />

      <MentorFormInput
        id="github_url"
        label={t('mentorProfile.form.github')}
        value={fields.github_url}
        onChange={handleTextChange}
        isOptional
      />

      <MentorFormTextarea
        id="bio"
        label={t('mentorProfile.form.biography')}
        helperText={t('mentorProfile.form.addAShortBio')}
        value={fields.bio}
        maxChars={MAX_CHARS.textarea}
        onChange={handleTextareaChange}
      />

      <TimezoneSelector onChange={handleFieldChange} value={fields.timezone} />

      <MentorFormInput
        id="phone_number"
        label={t('mentorProfile.form.phoneNumber')}
        helperText={t('mentorProfile.form.yourPhoneNumber')}
        value={fields.phone_number}
        handleFieldChange={handleFieldChange}
      />

      <SpecializedMentorLocale
        languageOptions={specializedApprovedLanguages}
        languages={fields.languages ?? []}
        onChangeLanguages={(e) => handleMultiCheckboxChange(e, 'languages')}
        onChangeMultiSelectLanguages={(e: LanguageChangeEvent) => handleMultiSelectChange(e)}
      />
      <div className={styles.tos}>
        <TermsOfService
          id="gov_mentor_agreement_version"
          link={CONFIG.mentorAgreementUrl}
          handleTOS={() => {
            handleFieldChange({
              id: 'gov_mentor_agreement_version',
              value: CONFIG.latestAgreementVersionGov,
            });
          }}
          isChecked={!!fields.gov_mentor_agreement_version}
        />
      </div>
    </>
  );
};
