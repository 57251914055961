import { all, takeEvery, call, put } from 'redux-saga/effects';
import { actionTypes } from './statements-actions';
import { getReviewerStatements } from 'app/services/reviews-api-service';

function* fetchAll(action) {
  const { end_date, start_date } = action.payload;

  try {
    const response = yield call(getReviewerStatements, start_date, end_date);
    yield put({
      type: actionTypes.FETCH_ALL_FULFILLED,
      payload: response,
    });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_ALL_FULFILLED, error });
  }
}

function* watchFetchAll() {
  yield takeEvery(actionTypes.FETCH_ALL, fetchAll);
}

export default function* statementsSaga() {
  yield all([watchFetchAll()]);
}
