import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Heading, Link } from '@chakra-ui/react';

import styles from './dashboard-overview-scene.module.scss';

export const DashboardOverviewScene: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <header className={styles.header}>
        <Heading size="h3" as="h1" color="white">
          {t('common.mentorDashboard')}
        </Heading>
      </header>
      <main className={styles.main}>
        <div className={styles.sectionHeader}>
          <Heading size="h5" as="h5" color="gray.800">
            {t('dashboard.studentProgressOverview')}
          </Heading>
        </div>
        <div className={styles.clarifyHeader}>
          <Trans
            i18nKey="dashboard.firstPayment"
            components={{
              udacity_pro_link: <Link href="https://www.udacity.com/legal/en-us/udacity-pro" fontSize="xs" />,
            }}
          />
        </div>
      </main>
    </div>
  );
};
