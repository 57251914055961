import React, { useMemo, useState } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import { EditorState, Extension, Range, StateField } from '@codemirror/state';
import { Decoration, DecorationSet, EditorView, ViewUpdate, WidgetType } from '@codemirror/view';
import { loadLanguage } from '@uiw/codemirror-extensions-langs';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import CodeMirror from '@uiw/react-codemirror';

import { useFetchBlobDataQuery, useFetchCommentsQuery } from 'app/apis/reviews';
import { getFileExtension, getLang } from 'app/project-reviews/helpers';
import { Comment, Content } from 'app/types/reviews';

import { CommentPortals } from './comment-portals';
import { useTranslation } from 'react-i18next';
import { ResizeCollapseIcon, ResizeExpandIcon } from '@udacity/chakra-uds-icons';

type CommentWidgetParams = {
  id: number;
  lineNumber: number;
};

class CommentWidget extends WidgetType {
  readonly id: number;
  readonly lineNumber: number;

  constructor({ id, lineNumber }: CommentWidgetParams) {
    super();

    this.id = id;
    this.lineNumber = lineNumber;
  }

  eq(commentWidget: CommentWidget) {
    return this.lineNumber === commentWidget.lineNumber && this.id === commentWidget.id;
  }

  toDOM() {
    const commentWrapperId = 'comment-wrapper-' + this.id + '-' + this.lineNumber;
    const commentWrapperDiv = document.getElementById(commentWrapperId);

    if (!commentWrapperDiv) {
      const div = document.createElement('div');
      div.setAttribute('id', commentWrapperId);

      return div;
    }

    return commentWrapperDiv;
  }
}

const commentWidgetExtension = (comments: Comment[] | undefined, currentLine: number): Extension => {
  const commentDecoration = (commentWidgetParams: CommentWidgetParams) =>
    Decoration.widget({
      widget: new CommentWidget(commentWidgetParams),
    });

  const decorate = (state: EditorState) => {
    // Add missing aria-label to editor view
    Array.from(document?.getElementsByClassName('cm-content'))?.forEach((e) => {
      e.setAttribute('aria-label', 'file contents');
    });

    const widgets: Range<Decoration>[] = [];

    state.doc.toJSON().forEach((_, lineNumber) => {
      // Add one for 1-based line numbers
      const line = lineNumber + 1;
      const comment = comments?.find((c) => c.lineNumber === line);

      if (comment) {
        widgets.push(
          commentDecoration({
            id: comment.id,
            lineNumber: comment.lineNumber,
          }).range(state.doc.line(line).to)
        );
      }

      if (currentLine === line) {
        widgets.push(
          commentDecoration({
            id: currentLine,
            lineNumber: currentLine,
          }).range(state.doc.line(line).to)
        );
      }
    });

    return widgets.length > 0 ? Decoration.set(widgets) : Decoration.none;
  };

  const commentsField = StateField.define<DecorationSet>({
    create(state) {
      return decorate(state);
    },
    update(comments, transaction) {
      if (transaction.docChanged) return decorate(transaction.state);

      return comments.map(transaction.changes);
    },
    provide(field) {
      return EditorView.decorations.from(field);
    },
  });

  return [commentsField];
};

type CodeFileProps = {
  content: Content;
  submissionId: number;
  isAssigned: boolean;
};

export const CodeFile: React.FC<CodeFileProps> = ({ content, submissionId, isAssigned }) => {
  const [currentLine, setCurrentLine] = useState(0);
  const [shouldAddComment, setShouldAddComment] = useState(false);
  const [visibleRangeStart, setVisibleRangeStart] = useState(0);
  const [visibleRangeEnd, setVisibleRangeEnd] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const { t } = useTranslation();

  const { data: blobData } = useFetchBlobDataQuery(content.blob);
  const { data: comments } = useFetchCommentsQuery(content.id, {
    skip: !content?.commentsCount,
  });

  const codeLang = useMemo(() => {
    const ext = getFileExtension(content.path);

    if (ext) return getLang(ext);

    return 'shell';
  }, [content.path]);

  const toggleFullScreen = () => {
    setIsFullScreen((currState) => !currState);
  };

  return blobData ? (
    <AccordionItem _notLast={{ mb: 2 }}>
      {({ isExpanded }) => (
        <>
          <h3>
            <AccordionButton sx={{ borderBottom: '1px solid', borderBottomColor: 'gray.300' }}>
              <Flex grow={1} gap={4} align="center" as="span">
                {content.commentsCount > 0 && (
                  <Flex justify="center" align="center" bgColor="blue.500" borderRadius="0.75rem" w={6} h={6}>
                    <Text fontSize="xs" color="white" fontWeight="bold" align="center">
                      {content.commentsCount}
                    </Text>
                  </Flex>
                )}
                <Text size="label">{content.path}</Text>
              </Flex>
              <AccordionIcon ms={4} />
            </AccordionButton>
          </h3>

          <AccordionPanel p="2rem 2rem 2rem 3.75rem">
            {isExpanded && (
              <>
                <Box
                  sx={
                    isFullScreen
                      ? {
                          pos: 'absolute',
                          top: 0,
                          left: 0,
                          w: '100%',
                          h: 'calc(100% - 5rem)',
                          maxH: 'calc(100% - 5rem)',
                          zIndex: 'docked',
                          overflowY: 'scroll',
                        }
                      : {}
                  }
                >
                  <CodeMirror
                    id={`code-editor-${content.id}`}
                    value={Buffer.from(blobData, 'base64').toString('ascii')}
                    extensions={[loadLanguage(codeLang) ?? [], commentWidgetExtension(comments, currentLine)]}
                    theme={vscodeDark}
                    onClick={() => {
                      if (isAssigned) setShouldAddComment(true);
                    }}
                    editable={isAssigned}
                    readOnly={!isAssigned}
                    indentWithTab={false}
                    onUpdate={(viewUpdate: ViewUpdate) => {
                      const selection = viewUpdate.view.state.selection.main.to;
                      const cursorLine = viewUpdate.view.state.doc.lineAt(selection).number;
                      if (cursorLine !== currentLine && shouldAddComment) {
                        setCurrentLine(cursorLine);
                      }
                      const startPos = viewUpdate.view.visibleRanges[0]?.from;
                      const startLine = viewUpdate.view.state.doc.lineAt(startPos);
                      const endPos = viewUpdate.view.visibleRanges[0]?.to;
                      const endLine = viewUpdate.view.state.doc.lineAt(endPos);
                      setVisibleRangeStart(startLine.number);
                      setVisibleRangeEnd(endLine.number);
                    }}
                  />
                </Box>

                <Button
                  mt={4}
                  zIndex="docked"
                  variant="outline"
                  onClick={toggleFullScreen}
                  leftIcon={isFullScreen ? <ResizeCollapseIcon w={6} h={6} /> : <ResizeExpandIcon w={6} h={6} />}
                  sx={isFullScreen ? { pos: 'absolute', bottom: 4, left: 4 } : {}}
                >
                  {isFullScreen ? t('common.collapse') : t('common.expand')}
                </Button>
                <CommentPortals
                  comments={!content.commentsCount ? [] : comments}
                  currentLine={currentLine}
                  contentId={content.id}
                  submissionId={submissionId}
                  visibleRangeStart={visibleRangeStart}
                  visibleRangeEnd={visibleRangeEnd}
                  isAssigned={isAssigned}
                  onCancel={() => {
                    setCurrentLine(0);
                    setShouldAddComment(false);
                  }}
                />
              </>
            )}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  ) : null;
};
