import { actionTypes } from './user-actions';

const defaultInitialState = {
  roles: [],
};

export const reducer = (state = defaultInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_CURRENT_USER: {
      return payload;
    }
    default:
      break;
  }
  return state;
};
