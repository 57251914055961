import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { getPayoneerIntegration } from 'app/common/domains/session/session-selectors';

import CONFIG from 'configLoader';
import PayoneerActive from './payoneer-active';
import PayoneerExplanation from './payoneer-explanation';
import PayoneerPending from './payoneer-pending';
import { PayoneerStageOne } from './payoneer-stage-one';
import styles from './payment-settings.module.scss';

const mapStateToProps = (state) => ({
  payoneer: getPayoneerIntegration(state),
});

export class PaymentSettings extends Component {
  static propTypes = {
    payoneer: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  render() {
    const {
      payoneer: { status },
      t,
    } = this.props;

    const { payoneerLoginUrl, payoneerRegistrationUrl } = CONFIG;

    let payoneerActions;

    switch (status) {
      // no payoneer account linked
      case null:
        payoneerActions = <PayoneerStageOne logIn={payoneerLoginUrl} signUp={payoneerRegistrationUrl} />;
        break;
      // payoneer account pending
      case 'INACTIVE':
        payoneerActions = <PayoneerPending logIn={payoneerLoginUrl} />;
        break;
      // successfully linked payoneer account
      case 'ACTIVE':
        payoneerActions = <PayoneerActive logIn={payoneerLoginUrl} />;
        break;
      // application page
      default:
        payoneerActions = <PayoneerExplanation />;
    }

    return (
      <section className={styles['payment-information']}>
        <Text size="label" as="h5" mb={2}>
          {t('mentorProfile.payoneer.paymentSettings')}
        </Text>
        <Text color="gray.700" mb={2}>
          <Trans
            i18nKey="mentorProfile.payoneer.udacityHasPartnered"
            components={{
              read_more_link: (
                <Link
                  href="https://payouts.payoneer.com/Partners/Default/faq.aspx?pid=100094130"
                  isExternal
                  fontSize="sm"
                />
              ),
            }}
          />
        </Text>
        {payoneerActions}
      </section>
    );
  }
}

export default withTranslation()(connect(mapStateToProps, {})(PaymentSettings));
