import React, { useRef } from 'react';

import { createMarkdownHTML } from './markdown-renderer-helpers';
import styles from './markdown-renderer.module.scss';

export const MarkdownRenderer: React.FC<{ content: string | null }> = ({ content }) => {
  const nodeElementRef = useRef<HTMLDivElement | null>(null);

  if (!content) {
    return null;
  }

  // hmtl is sanitized by markedjs
  const html = createMarkdownHTML(content);

  return <div dangerouslySetInnerHTML={{ __html: html }} className={styles.markdown} ref={nodeElementRef} />;
};
