import React from 'react';
import { Heading } from '@chakra-ui/react';
import _get from 'lodash/get';

import Card from 'app/common/components/card/card';
import { getEligibleProjectLanguages, getCanJoinPlagiarismQueue } from 'app/common/domains/session/session-selectors';
import styles from './queue.module.scss';
import { useSelector } from 'react-redux';
import { ReviewQueue } from './review-queue';
import { PlagiarismQueue } from './plagiarism-queue';
import { QuestionQueue } from './question-queue';
import { useTranslation } from 'react-i18next';

export const Queue: React.FC = () => {
  const { t } = useTranslation();
  const eligibleProjectLanguages = useSelector(getEligibleProjectLanguages);
  const canJoinPlagiarismQueue = useSelector(getCanJoinPlagiarismQueue);
  // We'll probably add the audit queue back in at some point...
  // const auditCertifications = useSelector(getCompletedAuditCertifications);

  const canQueueForQuestions = eligibleProjectLanguages.includes('en-us');

  return (
    <Card className={styles['queue-controls']}>
      <div className={styles['header-row']}>
        <div className={styles['heading-container']}>
          <Heading size="h6" as="h6" color="gray.600" fontSize="sm" textTransform="uppercase">
            {t('queue.queue')}
          </Heading>
        </div>
        <div className={styles['heading-container']}>
          <Heading size="h6" as="h6" color="gray.600" fontSize="sm" textTransform="uppercase">
            {t('queue.time')}
          </Heading>
        </div>
      </div>

      <ReviewQueue />

      {canJoinPlagiarismQueue && <PlagiarismQueue />}

      {/* {auditCertifications.length > 0 && <AuditQueue />} */}

      {canQueueForQuestions && <QuestionQueue />}
    </Card>
  );
};

export default Queue;
