import { combineReducers } from 'redux';
import { actionTypes } from './question-queue-actions';
import { makeAsyncReducerWithFulfilledAndRejected } from 'app/common/domains/async/async-reducer';

const reducer = combineReducers({
  history: makeAsyncReducerWithFulfilledAndRejected(
    actionTypes.FETCH_QUESTION_QUEUE_HISTORY_START,
    actionTypes.FETCH_QUESTION_QUEUE_HISTORY_FULFILLED,
    actionTypes.FETCH_QUESTION_QUEUE_HISTORY_REJECTED
  ),
});

export default reducer;
