import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/react';

import { markdownHasMultipleImages } from 'app/project-reviews/helpers';
import { Critique, EditedCritique } from 'app/types/reviews';

import { FeedbackInput } from '../feedback-input';

type PlagiarismAuditReasonProps = {
  label: string;
  helperText: string;
  critique: Critique;
  onSubmitCritique?: (payload: EditedCritique) => void;
  isAssigned: boolean;
};

export const PlagiarismAuditReason: React.FC<PlagiarismAuditReasonProps> = ({
  label,
  helperText,
  critique,
  onSubmitCritique,
  isAssigned,
}) => {
  const [feedback, setFeedback] = useState(critique?.observation || '');

  const { t } = useTranslation();

  const observationHasImage = useMemo(() => markdownHasMultipleImages(feedback), [feedback]);

  const saveFeedback = (newFeedback: string) => {
    if (critique && newFeedback.trim() !== critique.observation) {
      onSubmitCritique?.({
        id: critique.id,
        result: critique.result,
        observation: newFeedback.trim() ? newFeedback.trim() : null,
      });
    }
  };

  return (
    <FormControl isInvalid={!observationHasImage}>
      <FormLabel>{label}</FormLabel>
      <FormHelperText mb={2}>{helperText}</FormHelperText>

      <FeedbackInput
        id={`${critique.id}`}
        feedback={feedback}
        setFeedback={setFeedback}
        isAssigned={isAssigned}
        errorMessage={t('projectReviews.plagiarismAudit.pleaseAttachComparison')}
        saveFeedback={saveFeedback}
      />
    </FormControl>
  );
};
