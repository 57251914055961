import { actionTypes } from './audit-queue-actions';
import {
  auditRequestFromApiRequest,
  projectsToAuditFromApiRequests,
  assignedAuditFromApi,
  completedAuditFromApi,
  mergeCompletedAuditsIntoHistory,
} from './audit-queue-reducer-helpers';
import { AuditQueue } from './audit-queue-types';
import { AuditRequestStatus } from 'app/types/reviews';

export const initialState: AuditQueue = {
  pendingAuditRequests: [],
  projectsToAudit: {},
  assignedAudits: [],
  completedAudits: [],
};

export default function reducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_AUDIT_REQUESTS_FULFILLED:
    case actionTypes.REFRESH_AUDIT_REQUESTS_FULFILLED:
    case actionTypes.UPDATE_AUDIT_REQUESTS_FULFILLED: {
      const pendingAuditRequests = payload
        .map((r) => auditRequestFromApiRequest(r))
        .filter((r) => r.status === AuditRequestStatus.AVAILABLE);
      return {
        ...state,
        pendingAuditRequests,
        projectsToAudit: projectsToAuditFromApiRequests(payload),
      };
    }

    case actionTypes.JOIN_AUDIT_QUEUE_FULFILLED: {
      const newRequest = auditRequestFromApiRequest(payload);
      const pendingAuditRequests =
        newRequest.status === AuditRequestStatus.AVAILABLE
          ? [...state.pendingAuditRequests, newRequest]
          : state.pendingAuditRequests;
      return {
        ...state,
        pendingAuditRequests,
        projectsToAudit: projectsToAuditFromApiRequests([payload]),
      };
    }

    case actionTypes.LEAVE_AUDIT_QUEUE_FULFILLED: {
      return { ...state, pendingAuditRequests: [] };
    }

    case actionTypes.FETCH_ASSIGNED_AUDITS_FULFILLED: {
      return {
        ...state,
        assignedAudits: payload.map((apiAudit) => assignedAuditFromApi(apiAudit)),
      };
    }

    case actionTypes.FETCH_COMPLETED_AUDITS_FULFILLED: {
      const newCompletedAudits = payload.apiCompletedAudits.map((apiAudit) => completedAuditFromApi(apiAudit));
      return {
        ...state,
        completedAudits: mergeCompletedAuditsIntoHistory(state.completedAudits, newCompletedAudits),
        totalCompletedAudits: payload.totalCompletedAudits,
      };
    }

    case actionTypes.CLEAR_COMPLETED_AUDITS: {
      return { ...state, completedAudits: [] };
    }
  }

  return state;
}
