import React, { FormEvent } from 'react';
import { Input } from '@chakra-ui/react';

import { PaginationQueryParams } from '../types';
import { useHistory, useParams } from 'react-router';
import { getQueryParams, updateQueryParams } from '../utils';

type Props = {
  placeholder?: string;
};

export const SearchInput: React.FC<Props> = ({ placeholder }) => {
  const history = useHistory();
  const queryParams = getQueryParams();

  const updateSearch = (formEvt: FormEvent<HTMLFormElement>) => {
    formEvt.preventDefault();
    const value = (formEvt.target as any).query.value;
    updateQueryParams(history, {
      [PaginationQueryParams.Search]: value || false,
      [PaginationQueryParams.Page]: false,
    });
  };
  return (
    <form onSubmit={updateSearch}>
      <Input
        mb="1rem"
        placeholder={placeholder}
        size="sm"
        minWidth="25rem"
        defaultValue={queryParams.search}
        id="query"
        name="query"
      />
    </form>
  );
};
