import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Image, Link } from '@chakra-ui/react';
import _get from 'lodash/get';

import AppIntroManager from 'app/common/components/onboarding/application-intro-manager';
import useFormFields from 'app/common/views/profile-editor/form-hooks/useFormFields';
import { getInvalidFields, isValid } from 'app/common/views/profile-editor/form-helper';
import { MAX_CHARS } from 'app/common/views/profile-editor/form-helper';
import { FormFields, EditorType } from 'app/common/views/profile-editor/form-types';
import {
  SpecializedMentorForm,
  AbbreviatedSpecializedMentorForm,
  StandardMentorForm,
  UpdateMentorForm,
} from 'app/common/views/profile-editor/forms';
import { useAppToast } from 'app/common/hooks/use-app-toast';
import { Session } from 'app/common/domains/session/session-types';
import RecruitmentFamilies from 'app/queue/components/recruitment-families';
import UdacityLogo from 'assets/images/udacity-logo.svg';

import styles from './profile-editor.module.scss';
import { ViewApiToken } from './api-token/view';

type ProfileEditorProps = {
  session: Session;
  handleSubmit: (form: FormFields) => void;
  isLoading: boolean;
  editorType: EditorType;
  requiredFields: string[];
};

export type LanguageChangeEvent = {
  id: string;
  name: string;
  value: string[];
};

export const ProfileEditor: React.FC<ProfileEditorProps> = ({
  session,
  handleSubmit,
  editorType,
  requiredFields,
  isLoading,
}) => {
  const [fields, handleFieldChange] = useFormFields({
    uid: session.uid || '',
    avatar_url: session.avatar_url || '',
    bio: session.bio || '',
    educational_background: session.educational_background || '',
    github_url: session.github_url || '',
    languages: session.languages || [],
    linkedin_url: session.linkedin_url || '',
    phone_number: session.phone_number || '',
    timezone: session.timezone || '',
    available_hours: session.available_hours || 0,
    agreement_version: session.agreement_version || '',
    gov_mentor_agreement_version: session.gov_mentor_agreement_version || '',
    roles: _get(session, ['application', 'roles'], []),
  });

  const { t } = useTranslation();
  const toast = useAppToast();

  const addValidationToasts = () => {
    getInvalidFields(fields, requiredFields).forEach((f) =>
      toast({
        variant: 'error',
        description: t('mentorProfile.fieldCantBeBlank', { field: f }),
      })
    );
  };

  // Submission
  const submit = () => {
    try {
      const application = {
        services: _get(session, ['application', 'services'], []),
        roles: _get(session, ['application', 'roles'], []),
      };

      if (editorType === EditorType.Standard) {
        application.services = [...application.services, 'reviews'];
      } else if (editorType === EditorType.Specialized || editorType == EditorType.AbbreviatedSpecialized) {
        if (!application.services.includes('government_mentorship')) {
          application.services = [...application.services, 'government_mentorship'];
        }

        application.roles = fields.roles;
      }
      const form = { ...fields, application };

      handleSubmit(form);
    } catch (e: any) {
      if (e.message) {
        toast({
          variant: 'error',
          description: e.message,
        });
      }
    }
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (isValid(fields, requiredFields)) {
      submit();
    } else {
      addValidationToasts();
    }
  };

  // Field Handlers
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= MAX_CHARS.input) {
      handleFieldChange(e);
    }
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= MAX_CHARS.textarea) {
      handleFieldChange(e);
    }
  };

  const handleMultiCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { value } = e.target;
    handleFieldChange({
      ...e.target,
      id,
      value: (fields[id] || []).includes(value)
        ? (fields[id] || []).filter((l) => l !== value)
        : [...(fields[id] || []), value],
    });
  };

  const handleMultiSelectChange = (e: LanguageChangeEvent) => {
    handleFieldChange(e);
  };

  // Rendering
  const getFormByAppType = (editorType: EditorType) => {
    switch (editorType) {
      case EditorType.Specialized:
        return (
          <SpecializedMentorForm
            handleMultiCheckboxChange={handleMultiCheckboxChange}
            handleTextareaChange={handleTextareaChange}
            handleTextChange={handleTextChange}
            handleFieldChange={handleFieldChange}
            fields={fields}
            handleMultiSelectChange={handleMultiSelectChange}
          />
        );
      case EditorType.AbbreviatedSpecialized:
        return (
          <AbbreviatedSpecializedMentorForm
            handleFieldChange={handleFieldChange}
            handleMultiCheckboxChange={handleMultiCheckboxChange}
            fields={fields}
            handleMultiSelectChange={handleMultiSelectChange}
          />
        );
      case EditorType.Update:
        return (
          <UpdateMentorForm
            handleTextareaChange={handleTextareaChange}
            handleTextChange={handleTextChange}
            handleFieldChange={handleFieldChange}
            fields={fields}
            hasTOSUpdate={false}
          />
        );
      default:
        return (
          <StandardMentorForm
            handleMultiCheckboxChange={handleMultiCheckboxChange}
            handleTextareaChange={handleTextareaChange}
            handleTextChange={handleTextChange}
            handleFieldChange={handleFieldChange}
            fields={fields}
          />
        );
    }
  };

  return (
    <>
      <header className={styles.header}>
        <Link href="https://udacity.com">
          <Image src={UdacityLogo} alt={t('common.udacityLogo')} w="12.5rem" h="2.25rem" />
        </Link>
      </header>
      <Box as="main" w="50em" m="1rem auto">
        <RecruitmentFamilies />
        <Box as="form" bg="white" borderRadius="base" boxShadow="lg" p="60px 80px" mb={10}>
          <AppIntroManager editorType={editorType} />
          <div className={styles.form}>{getFormByAppType(editorType)}</div>
          <Button onClick={onSubmit} isDisabled={isLoading}>
            {t('common.submit')}
          </Button>
        </Box>
        <ViewApiToken />
      </Box>
    </>
  );
};
