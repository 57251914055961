/* eslint-disable */ /**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 * instead, edit its related `.gql` file and recreate it with
 *
 * npm run codegen:connect
 */

import * as Types from '../../types/uconnect';

import { api } from 'app/apis/connect-gql-api';
export type UpdateAttendancesMutationVariables = Types.Exact<{
  attendances: Array<Types.UpdateMeetingAttendance> | Types.UpdateMeetingAttendance;
}>;

export type UpdateAttendancesMutation = { __typename?: 'Mutation'; updateMeetingAttendance: boolean };

export const UpdateAttendancesDocument = `
    mutation UpdateAttendances($attendances: [UpdateMeetingAttendance!]!) {
  updateMeetingAttendance(input: $attendances)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateAttendances: build.mutation<UpdateAttendancesMutation, UpdateAttendancesMutationVariables>({
      query: (variables) => ({ document: UpdateAttendancesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
