import { combineReducers } from 'redux';
import { actionTypes } from './submissions-actions';
import { makeAsyncReducer } from 'app/common/domains/async';

const reducer = combineReducers({
  fetchAllAssigned: makeAsyncReducer(
    actionTypes.FETCH_ALL_ASSIGNED,
    actionTypes.FETCH_ALL_ASSIGNED_FULFILLED,
    actionTypes.FETCH_ALL_ASSIGNED_RESET
  ),
  fetchCompleted: makeAsyncReducer(
    actionTypes.FETCH_COMPLETED,
    actionTypes.FETCH_COMPLETED_FULFILLED,
    actionTypes.FETCH_COMPLETED_RESET
  ),
  unassign: makeAsyncReducer(actionTypes.UNASSIGN, actionTypes.UNASSIGN_FULFILLED, actionTypes.UNASSIGN_RESET),
});

export default reducer;
