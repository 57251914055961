import React from 'react';

export default function StylizedWrap(styleName, styles) {
  function StyledComponent({ children }) {
    return <div className={styleName}>{children}</div>;
  }
  StyledComponent.displayName = formatDisplayName(styleName);
  return StyledComponent;
}

function formatDisplayName(styleName) {
  return styleName
    .split('-')
    .map((name) => {
      return name.charAt(0).toUpperCase() + name.substr(1).toLowerCase();
    })
    .join('');
}
