import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@chakra-ui/react';
import auth from '@udacity/ureact-hoth';

import { useAppToast } from 'app/common/hooks/use-app-toast';
import { upload } from 'app/services/mentor-api-service';

import styles from './avatar-image.module.scss';

type AvatarImageProps = {
  avatarUrl?: string;
  onAvatarChange: (url: string) => void;
};

export const AvatarImage: React.FC<AvatarImageProps> = ({ avatarUrl, onAvatarChange }) => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const toast = useAppToast();
  const { t } = useTranslation();

  const handleAvatarClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.size > 400000) {
      toast({
        variant: 'error',
        description: t('mentorProfile.imageTooLarge'),
      });
      setError('image file size');
      return;
    }

    const fr = new FileReader();

    fr.onload = () => {
      uploadFile(file, fr);
    };
    fr.readAsDataURL(file);
  };

  const uploadFile = (file: File, fileReader: FileReader) => {
    setIsLoading(true);
    setError('');

    upload({
      fileName: `${auth.getCurrentUserId()}_${file.name}`,
      fileType: file.type,
      image: fileReader.result,
    })
      .then((response) => {
        if (response.err) {
          return Promise.reject(response.err);
        }
        onAvatarChange(response.url);
        setIsLoading(false);
        return;
      })
      .catch((err: any) => {
        toast({
          variant: 'error',
          description: t('mentorProfile.uploadingError'),
        });

        setIsLoading(false);
        setError(err ?? 'upload error');
      });
  };

  return (
    <section className={styles['avatar-section']}>
      <div className={styles['flex']}>
        <div className={styles['upload-image-container']}>
          <label
            htmlFor="avatar-input"
            className={`${styles['upload-image']} ${error ? styles.error : ''} ${avatarUrl ? styles.full : ''}`}
          >
            {isLoading ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <div className={`${styles.camera} ${avatarUrl ? styles.overlay : ''}`} />
            )}
            {avatarUrl && <img src={avatarUrl} alt="avatar" className={styles['avatar']} />}
          </label>
          <input
            id="avatar-input"
            className={styles['invisible-input']}
            type="file"
            accept=".jpg, .png"
            onChange={handleAvatarClick}
          />
        </div>
      </div>
      <div className={styles['prompt-text']}>
        <p>{t('mentorProfile.clickTheImageToUpload')}</p>
        <p>{t('mentorProfile.jpgOrPng')}</p>
      </div>
    </section>
  );
};
