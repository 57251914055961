import React from 'react';
import { Flex } from '@chakra-ui/react';

import { CurrentItemsDisplay } from './current-items-display';
import { PageLinks } from './page-links';
import { PageSizeSelector } from './page-size-selector';

type Props = {
  pageSize: number;
  totalRows: number;
  currentPage: number;
  onGoToPage: (newPageIndex: number) => void;
  onChangePageSize: (newPageSize: number) => void;
};

export const TablePagination: React.FC<Props> = ({
  pageSize,
  totalRows,
  currentPage,
  onChangePageSize,
  onGoToPage,
}) => {
  const pageEnd = Math.min(totalRows, currentPage * pageSize);
  const pageStart = currentPage * pageSize - (pageSize - 1);

  return (
    <Flex alignItems="center" justifyContent="space-between" padding="1rem">
      <CurrentItemsDisplay pageEnd={pageEnd} pageStart={pageStart} totalRows={totalRows} />
      {totalRows > 0 && <PageSizeSelector pageSize={pageSize} onChangePageSize={onChangePageSize} />}
      <PageLinks pageSize={pageSize} currentPage={currentPage} totalRows={totalRows} onGoToPage={onGoToPage} />
    </Flex>
  );
};
