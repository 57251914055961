import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Analytics from '@udacity/ureact-analytics';
import { Button, Flex, FormControl, FormLabel, Radio, Spinner, Switch, Text } from '@chakra-ui/react';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';

import { selectUid } from 'app/common/domains/user/user-selectors';
import { formatPrice, formatAlternatePrice } from 'app/helpers/format-helper';
import {
  getCompletedCertificationsSelector,
  getCertificationsAsync,
} from 'app/queue/domains/certifications/certifications-selectors';
import { selectCountryCode } from 'app/common/domains/session/session-selectors';
import { QUEUE_VOLUME } from 'app/queue/domains/reviews-queue/reviews-queue-constants';
import { eventTypes } from 'app/queue/views/overview/queue/queue-constants';
import { Certification } from 'app/types/reviews';
import { RootState } from 'app/store';

import styles from './review-queue-settings.module.scss';

type ReviewQueueSettingsProps = {
  handleSubmit: ({
    certifications,
    queueVolume,
  }: {
    certifications: Certification[];
    queueVolume: keyof typeof QUEUE_VOLUME;
  }) => void;
  selectedProjectIds?: number[];
  queueVol?: keyof typeof QUEUE_VOLUME;
  isUpdate?: boolean;
};

export const ReviewQueueSettings: React.FC<ReviewQueueSettingsProps> = ({
  handleSubmit,
  selectedProjectIds = [],
  queueVol,
  isUpdate = false,
}) => {
  const certifications = useSelector((state: RootState) => getCompletedCertificationsSelector(state));
  const certificationsAsync = useSelector((state: RootState) => getCertificationsAsync(state, 'fetchAll'));
  const currentUserUid = useSelector((state) => selectUid(state));
  const mentorCountryCode = useSelector<any, string>(selectCountryCode);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectedCertifications, setSelectedCertifications] = useState<Certification[]>([]);
  const [queueVolume, setQueueVolume] = useState(queueVol || QUEUE_VOLUME.single);

  const { t } = useTranslation();
  const prevCerts = useRef<Certification[]>(certifications);

  const updateSelectedCertifications = (selectedProjectIds: number[] = [], certs: Certification[]) => {
    const selectedCertifications = _reduce(
      selectedProjectIds,
      (acc, projectId) => {
        const certification = certs.find((c) => c.project_id === projectId);
        if (certification) {
          acc.push(certification);
        }
        return acc;
      },
      [] as Certification[]
    );

    setSelectedCertifications(selectedCertifications);
  };

  useEffect(() => {
    updateSelectedCertifications(selectedProjectIds, certifications);
  }, []);

  const mapSelectedCertifications = (certifications: Certification[] = []) => {
    if (selectedProjectIds.length) {
      return selectedProjectIds;
    }
    return _map(certifications, 'project_id');
  };

  useEffect(() => {
    if (certifications.length > prevCerts.current.length) {
      const updatedSelectedProjectIds = mapSelectedCertifications(certifications);
      updateSelectedCertifications(updatedSelectedProjectIds, certifications);
    }

    prevCerts.current = certifications;
  }, [certifications]);

  const submit = () => {
    setIsSubmitLoading(true);

    const payload = {
      certifications: [...selectedCertifications],
      queueVolume,
    };

    handleSubmit(payload);

    Analytics.track(eventTypes.REVIEW_QUEUE_ON, {
      timestamp: Date.now(),
      reviewerId: currentUserUid,
    });
  };

  const toggleCertification = (cert: Certification) => {
    let updatedCertifications: Certification[] = [];

    if (_find(selectedCertifications, { id: cert.id })) {
      updatedCertifications = selectedCertifications.filter((c) => c.id !== cert.id);
    } else {
      updatedCertifications = [...selectedCertifications, cert];
    }

    setSelectedCertifications(updatedCertifications);
  };

  const isValid = useMemo(() => {
    return selectedCertifications.length > 0;
  }, [selectedCertifications]);

  const enableSingleQueueVolume = () => setQueueVolume(QUEUE_VOLUME.single);
  const enableMaxQueueVolume = () => setQueueVolume(QUEUE_VOLUME.max);

  const { loading: isCertificationsLoading, firstLoad } = certificationsAsync;

  const isSubmitDisabled = isCertificationsLoading || !isValid;
  const isErrorTextVisible = !isValid && !firstLoad;

  if (!firstLoad && !certifications.length) {
    return (
      <section data-tst="empty-wrapper" className={styles['queue-settings-empty']}>
        <p>{t('queue.overview.noActiveCertifications')}</p>
        <Button as={Link} data-tst="certifications-button" to="/queue/certifications">
          {t('queue.certifications')}
        </Button>
      </section>
    );
  }

  return (
    <section className={styles['queue-settings']}>
      {isSubmitLoading || isCertificationsLoading ? (
        <Flex justify="center" align="center" w="full" h="full">
          <Spinner />
        </Flex>
      ) : (
        <>
          <div className={styles['body']}>
            <div className={styles['queue-size']}>
              <FormControl display="flex" justifyContent="start" alignItems="center" gap={8}>
                <FormLabel>{t('queue.overview.queueForReviews')}</FormLabel>
                <Radio
                  id="queue-volume-min"
                  isChecked={queueVolume === QUEUE_VOLUME.single}
                  name="queueSize"
                  onChange={enableSingleQueueVolume}
                  data-tst="queue-volume-min"
                >
                  {t('queue.overview.oneReview')}
                </Radio>
                <Radio
                  id="queue-volume-max"
                  isChecked={queueVolume === QUEUE_VOLUME.max}
                  name="queueSize"
                  onChange={enableMaxQueueVolume}
                  data-tst="queue-volume-max"
                >
                  {t('queue.overview.keepMyQueueFull')}
                </Radio>
              </FormControl>
            </div>

            <ul className={styles['certifications']}>
              {certifications.map((cert) => {
                const price = formatPrice(cert.project.price);

                const alternatePrice =
                  cert.project.exchange_currency_country &&
                  mentorCountryCode &&
                  mentorCountryCode.toLowerCase() === cert.project.exchange_currency_country.toLowerCase()
                    ? formatAlternatePrice(
                        cert.project.exchange_currency ?? '',
                        cert.project.exchange_currency_price ?? '0'
                      )
                    : '';

                const checked = !!_find(selectedCertifications, {
                  id: cert.id,
                });

                return (
                  <li key={cert.id} data-tst="certification-row" className={styles['certification']}>
                    <FormLabel htmlFor={cert.project.name}>{cert.project.name}</FormLabel>
                    <Flex align="center" justify="end" mb={0} p={2}>
                      <Text>{price}</Text>
                      {alternatePrice && <Text ms={4}>{alternatePrice}</Text>}
                    </Flex>
                    <Switch isChecked={checked} onChange={() => toggleCertification(cert)} id={cert.project.name} />
                  </li>
                );
              })}
            </ul>
          </div>

          <footer className={styles['actions']}>
            {isErrorTextVisible && <p className={styles['error-text']}>{t('queue.overview.youMustQueue')}</p>}
            <Button data-tst="submit-button" onClick={submit} disabled={isSubmitDisabled}>
              {isUpdate ? t('queue.overview.updateQueue') : t('queue.overview.enterQueue')}
            </Button>
          </footer>
        </>
      )}
    </section>
  );
};
