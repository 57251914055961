import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { SheetCheckedIcon } from '@udacity/chakra-uds-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { formatPrice } from 'app/helpers/format-helper';
import RaisedGlyph from 'app/queue/components/raised-glyph';
import ListItem from 'app/queue/components/list-item';
import { AssignedPlagiarismCase } from 'app/queue/domains/plagiarism-case-queue/plagiarism-case-queue-types';

import styles from './assigned-plagiarism-case-list-item.module.scss';

type AssignedPlagiarismCaseListItemProps = {
  plagiarismCase: AssignedPlagiarismCase;
};
const closeToExpiringNumHours = 3;

export const AssignedPlagiarismCaseListItem: React.FC<AssignedPlagiarismCaseListItemProps> = ({ plagiarismCase }) => {
  const { t } = useTranslation();

  const isExpired = dayjs().isAfter(plagiarismCase.expiresAt);
  const isCloseToExpiring = dayjs().add(closeToExpiringNumHours, 'h').isAfter(plagiarismCase.expiresAt);
  const timeRemaining = isExpired ? t('common.expired') : dayjs(plagiarismCase.expiresAt).fromNow(true);

  const listItemClass = classNames(styles['assigned-plagiarism-case-list-item'], {
    [styles['expired-plagiarism-case']]: isExpired,
  });

  const completeInClass = classNames({
    [styles['complete-in-expiring']]: isCloseToExpiring,
  });

  return (
    <ListItem className={listItemClass}>
      <div className={styles['glyph']}>
        <RaisedGlyph icon={<SheetCheckedIcon w={8} h={8} />} />
      </div>

      <div data-tst="title" className={styles['title']}>
        <p>{plagiarismCase.project.name}</p>
      </div>

      <div className={styles['cell-group']}>
        <div data-tst="plagiarism-case-price" className={styles['price']}>
          <label>{t('common.earn')}</label>
          <p>{formatPrice(plagiarismCase.price)}</p>
        </div>

        <div data-tst="complete-in" className={completeInClass}>
          <label>{t('queue.reassignedIn')}</label>
          <p>{timeRemaining}</p>
        </div>
      </div>

      <div className={styles['actions']}>
        {!isExpired && (
          <Button as={ReactRouterLink} to={plagiarismCase.url} variant="outline" size="sm">
            {t('queue.reviewPlagiarism')}
          </Button>
        )}
      </div>
    </ListItem>
  );
};
