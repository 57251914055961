import { createAction } from 'app/helpers/action-helper';
import storage from 'app/helpers/storage-helper';

export const actionTypes = {
  FETCH_ALL_REQUESTS: 'queue:FETCH_ALL_ASSIGNED',
  FETCH_ALL_REQUESTS_FULFILLED: 'queue:FETCH_ALL_REQUESTS_FULFILLED',
  FETCH_ALL_REQUESTS_RESET: 'queue:FETCH_ALL_REQUESTS_RESET',
  CREATE_REQUEST: 'queue:CREATE_REQUEST',
  CREATE_REQUEST_FULFILLED: 'queue:CREATE_REQUEST_FULFILLED',
  CREATE_REQUEST_RESET: 'queue:CREATE_REQUEST_RESET',
  DELETE_REQUEST: 'queue:DELETE_REQUEST',
  DELETE_REQUEST_FULFILLED: 'queue:DELETE_REQUEST_FULFILLED',
  DELETE_REQUEST_RESET: 'queue:DELETE_REQUEST_RESET',
  DELETE_ALL_REQUESTS: 'queue:DELETE_ALL_REQUESTS',
  DELETE_ALL_REQUESTS_FULFILLED: 'queue:DELETE_ALL_REQUESTS_FULFILLED',
  REFRESH_REQUEST: 'queue:REFRESH_REQUEST',
  REFRESH_REQUEST_FULFILLED: 'queue:REFRESH_REQUEST_FULFILLED',
  REFRESH_REQUEST_RESET: 'queue:REFRESH_REQUEST_RESET',
  REFRESH_ALL_REQUESTS: 'queue:REFRESH_ALL_REQUESTS',
  REFRESH_ALL_REQUESTS_FULFILLED: 'queue:REFRESH_ALL_REQUESTS_FULFILLED',
  UPDATE_REQUEST: 'queue:UPDATE_REQUEST',
  UPDATE_REQUEST_FULFILLED: 'queue:UPDATE_REQUEST_FULFILLED',
  UPDATE_REQUEST_RESET: 'queue:UPDATE_REQUEST_RESET',
  UPDATE_ALL_REQUESTS: 'queue:UPDATE_ALL_REQUESTS',
  UPDATE_ALL_REQUESTS_FULFILLED: 'queue:UPDATE_ALL_REQUESTS_FULFILLED',
  UPDATE_ALL_REQUESTS_RESET: 'queue:UPDATE_ALL_REQUESTS_RESET',
  SET_SETTINGS: 'queue:SET_SETTINGS',
  POLL_ALL_REQUESTS: 'queue:POLL_ALL_REQUESTS',
  CANCEL_POLL_ALL_REQUESTS: 'queue:CANCEL_POLL_ALL_REQUESTS',
};

export const fetchAll = () => {
  return createAction(actionTypes.FETCH_ALL_REQUESTS);
};

export const create = (payload = {}) => {
  return createAction(actionTypes.CREATE_REQUEST, payload);
};

export const resetCreate = () => {
  return createAction(actionTypes.CREATE_REQUEST_RESET);
};

export const destroy = (requestId) => {
  return createAction(actionTypes.DELETE_REQUEST, requestId);
};

export const destroyAll = () => {
  return createAction(actionTypes.DELETE_ALL_REQUESTS);
};

export const resetDestroy = () => {
  return createAction(actionTypes.DELETE_REQUEST_RESET);
};

export const update = (submissionRequestId, submissionRequest = {}) => {
  return createAction(actionTypes.UPDATE_REQUEST, {
    submissionRequestId,
    submissionRequest,
  });
};

export const updateAll = (settings) => {
  return createAction(actionTypes.UPDATE_ALL_REQUESTS, settings);
};

export const resetUpdate = () => {
  return createAction(actionTypes.UPDATE_REQUEST_RESET);
};

export const refresh = (submissionRequestId) => {
  return createAction(actionTypes.REFRESH_REQUEST, submissionRequestId);
};

export const refreshAll = () => {
  return createAction(actionTypes.REFRESH_ALL_REQUESTS);
};

export const resetRefresh = () => {
  return createAction(actionTypes.REFRESH_REQUEST_RESET);
};

export const setSettings = (settings = {}) => {
  storage.set('queue-settings', settings);

  return createAction(actionTypes.SET_SETTINGS, settings);
};

export const startPollingSubmissionRequests = (queueSettings) => {
  return createAction(actionTypes.POLL_ALL_REQUESTS, queueSettings);
};

export const stopPollingSubmissionRequests = () => {
  return createAction(actionTypes.CANCEL_POLL_ALL_REQUESTS);
};
