import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Flex, FormControl, FormHelperText, FormLabel, Heading, Link, Spinner, Text } from '@chakra-ui/react';
import { AddIcon, SubtractIcon } from '@udacity/chakra-uds-icons';
import { Markdown } from '@udacity/ureact-markdown';

import { useSubmitGeneralCommentMutation } from 'app/apis/reviews';
import { Alert } from 'app/common/components/alert';
import { AsymmetricCard } from 'app/common/components/asymmetric-card';
import { Critique, Rubric, Submission } from 'app/types/reviews';

import { RubricSection } from './rubric-section';
import { FeedbackTemplateContainer } from './feedback-templates/container';
import { FeedbackInput } from './feedback-input/feedback-input';
import { MarkdownPreview } from './feedback-input';

type ProjectReviewPanelProps = {
  rubric?: Rubric;
  critiques?: Critique[];
  submission: Submission;
  isAssigned: boolean;
  previouslyFlaggedForPlagiarism?: boolean;
  previousCritiques?: Critique[];
};

export const ProjectReviewPanel: React.FC<ProjectReviewPanelProps> = ({
  rubric,
  critiques,
  submission,
  isAssigned,
  previouslyFlaggedForPlagiarism,
  previousCritiques,
}) => {
  const reviewerComment = submission.reviewerComment || '';

  const [isExpanded, setIsExpanded] = useState<boolean | null>(false);
  const [generalComment, setGeneralComment] = useState(reviewerComment);

  const { t } = useTranslation();

  const [submitGeneralComment] = useSubmitGeneralCommentMutation();

  useEffect(() => {
    if (!isAssigned) {
      setGeneralComment(reviewerComment);
    }
  }, [reviewerComment]);

  const handleExpandAll = (shouldExpandAll: boolean | null) => {
    setIsExpanded(shouldExpandAll);
  };

  const handleSaveComment = async (newValue: string) => {
    if (newValue.trim() && newValue.trim() !== reviewerComment) {
      setGeneralComment(newValue);

      await submitGeneralComment({
        submissionId: submission.id,
        generalComment: newValue,
      });
    }
  };

  const saveGeneralComment = (newFeedback: string) => {
    if (newFeedback.trim() !== reviewerComment) {
      submitGeneralComment({
        submissionId: submission.id,
        generalComment: newFeedback.trim() ? newFeedback.trim() : null,
      });
    }
  };

  const flaggedForPlagiarism = useMemo(() => {
    return (submission.ungradeableTag === 'plagiarism' && submission.isDemerit) || submission.plagiarismConfirmed;
  }, [submission]);

  if (submission.resultReason) {
    return (
      <Flex direction="column" gap={2}>
        <Heading size="h5" as="h5">
          {t('projectReviews.history.unableToReview')}
        </Heading>
        <AsymmetricCard bgColor="white" gap={2}>
          <Flex direction="column" maxW="full">
            <Text size="label">{t('projectReviews.plagiarismAudit.reviewerNote')}</Text>
            {submission.ungradeableTag === 'plagiarism' && (
              <>
                <Text mb={4}>{t('projectReviews.plagiarismAudit.keyParts')}</Text>
                <Text>
                  <Trans
                    i18nKey="projectReviews.plagiarismAudit.potentialSourceUrl"
                    values={{ sourceUrl: submission.plagiarismSourceUrl }}
                    components={{
                      source_link: <Link href={submission.plagiarismSourceUrl} isExternal />,
                    }}
                  />
                </Text>
              </>
            )}

            <Markdown markdownText={submission.resultReason} />
          </Flex>
        </AsymmetricCard>
      </Flex>
    );
  }

  if (!critiques || !rubric) {
    return (
      <Flex justify="center" align="center">
        <Spinner size="lg" />
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap={10}>
      {previouslyFlaggedForPlagiarism ? (
        <Alert status="warning" title={t('projectReviews.plagiarism.plagiarismWarning')}>
          <Text>{t('projectReviews.plagiarism.previousPlagiarismWarning')}</Text>
        </Alert>
      ) : flaggedForPlagiarism ? (
        <Alert status="warning" title={t('projectReviews.plagiarism.plagiarismWarning')}>
          <Text>{t('projectReviews.plagiarism.thisVersionPlagiarismWarning')}</Text>
        </Alert>
      ) : null}

      <Flex gap={4} wrap="wrap">
        <Button
          variant="outline"
          rightIcon={<AddIcon w={6} h={6} />}
          isDisabled={isExpanded === true}
          onClick={() => handleExpandAll(true)}
        >
          {t('projectReviews.projectReview.expandAllCriteria')}
        </Button>
        <Button
          variant="outline"
          rightIcon={<SubtractIcon w={6} h={6} />}
          isDisabled={isExpanded === false}
          onClick={() => handleExpandAll(false)}
        >
          {t('projectReviews.projectReview.collapseAllCriteria')}
        </Button>
      </Flex>

      {rubric.sections.map((s, ind) => (
        <RubricSection
          key={ind}
          section={s}
          isAllExpanded={isExpanded}
          handleExpandAll={handleExpandAll}
          critiques={critiques}
          previousCritiques={previousCritiques}
          submissionId={submission.id}
          isAssigned={isAssigned}
        />
      ))}

      {isAssigned && (
        <FormControl mb={2}>
          <FormLabel>{t('projectReviews.projectReview.generalFeedback')}</FormLabel>
          {isAssigned && (
            <FormHelperText mb={2}>{t('projectReviews.projectReview.pleaseProvideGeneralComments')}</FormHelperText>
          )}
          <FeedbackTemplateContainer
            currentValue={submission.reviewerComment || ''}
            onApplyTemplate={handleSaveComment}
            showAll
          />

          <FormHelperText mb={2}>{t('projectReviews.projectReview.supportedFeedbackFunctions')}</FormHelperText>
          <FeedbackInput
            id="general-comment"
            feedback={generalComment}
            setFeedback={setGeneralComment}
            isAssigned={isAssigned}
            saveFeedback={saveGeneralComment}
          />
        </FormControl>
      )}

      {!isAssigned && generalComment && (
        <div>
          <Text size="label" mb={2}>
            {t('projectReviews.projectReview.generalFeedback')}
          </Text>
          <MarkdownPreview text={generalComment} bgColor="gray.200" />
        </div>
      )}
    </Flex>
  );
};
