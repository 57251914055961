/* eslint-disable */ /**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 * instead, edit its related `.gql` file and recreate it with
 *
 * npm run codegen:connect
 */

import * as Types from '../../types/uconnect';

import { api } from 'app/apis/connect-gql-api';
export type ClaimMeetingsMutationVariables = Types.Exact<{
  meetingIds: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input'];
}>;

export type ClaimMeetingsMutation = { __typename?: 'Mutation'; claimMeetings: boolean };

export const ClaimMeetingsDocument = `
    mutation ClaimMeetings($meetingIds: [UUID!]!) {
  claimMeetings(meetingIDs: $meetingIds)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ClaimMeetings: build.mutation<ClaimMeetingsMutation, ClaimMeetingsMutationVariables>({
      query: (variables) => ({ document: ClaimMeetingsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
