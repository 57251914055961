import { AssignedAudit, CompletedAudit, AuditRequest } from './audit-queue-types';
import { AuditRequest as ApiAuditRequest, Audit as ApiAudit } from 'app/types/reviews';

export function auditRequestFromApiRequest(apiRequest: ApiAuditRequest): AuditRequest {
  return {
    id: apiRequest.id,
    auditId: apiRequest.audit_id || undefined,
    status: apiRequest.status,
    closedAt: Date.parse(apiRequest.closed_at),
  };
}

export function projectsToAuditFromApiRequests(apiRequests: ApiAuditRequest[]): { [id: number]: boolean } {
  return apiRequests.reduce((projectsEnabled, r) => {
    r.audit_request_projects.forEach((p) => {
      projectsEnabled[p.project_id] = true;
    });
    return projectsEnabled;
  }, {});
}

export function assignedAuditFromApi(apiAudit: ApiAudit): AssignedAudit {
  return {
    id: apiAudit.id,
    url: `/project-reviews/${apiAudit.submission_id}/audit`,
    price: apiAudit.price,
    assignedAt: Date.parse(apiAudit.assigned_at),
    project: {
      id: apiAudit.submission_project.id,
      name: apiAudit.submission_project.name,
    },
  };
}

export function completedAuditFromApi(apiAudit: ApiAudit): CompletedAudit {
  if (!apiAudit.completed_at || !apiAudit.result) {
    throw new Error('Cannot generate completed audit from incomplete API object');
  }

  return {
    id: apiAudit.id,
    completedAt: Date.parse(apiAudit.completed_at),
    result: apiAudit.result,
    price: apiAudit.price,
    project: {
      id: apiAudit.submission_project.id,
      name: apiAudit.submission_project.name,
    },
  };
}

export function mergeCompletedAuditsIntoHistory(
  history: CompletedAudit[],
  newCompleted: CompletedAudit[]
): CompletedAudit[] {
  const historyIds = history.map((audit) => audit.id);
  const completedNotInHistory = newCompleted.filter((audit) => !historyIds.includes(audit.id));
  return history.concat(completedNotInHistory).sort((a, b) => {
    return a.completedAt > b.completedAt ? -1 : a.completedAt < b.completedAt ? 1 : 0;
  });
}
