import React from 'react';
import { connect } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { Link, Spinner, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { formatPrice, formatAlternatePrice } from 'app/helpers/format-helper';
import { getSubmissionsPeerVotes, getSubmissionsStudentFeedbacks } from 'app/services/reviews-api-service';
import auditQueueActions from 'app/queue/domains/audit-queue/audit-queue-actions';
import plagiarismCaseQueueActions from 'app/queue/domains/plagiarism-case-queue/plagiarism-case-queue-actions';
import {
  selectAssignedAudits,
  selectCompletedAudits,
  selectTotalCompletedAudits,
  selectFetchCompletedAuditsAsync,
} from 'app/queue/domains/audit-queue/audit-queue-selectors';
import {
  selectAssignedPlagiarismCases,
  selectCompletedPlagiarismCases,
  selectTotalCompletedPlagiarismCases,
  selectFetchCompletedPlagiarismCasesAsync,
} from 'app/queue/domains/plagiarism-case-queue/plagiarism-case-queue-selectors';
import {
  getCompletedAuditCertifications,
  getCompletedPlagiarismCaseCertifications,
} from 'app/queue/domains/certifications/certifications-selectors';
import { getCanJoinPlagiarismQueue } from 'app/common/domains/session/session-selectors';
import questionQueueActions from 'app/queue/domains/question-queue/question-queue-actions';
import {
  selectQuestionQueueAssignments,
  selectQuestionQueueHistory,
  selectQuestionQueueHistoryAsync,
} from 'app/queue/domains/question-queue/question-queue-selectors';
import { QuestionQueueActionType } from 'app/queue/domains/question-queue/question-queue-types';
import constants from 'app/queue/views/history/common/constants';
import { fetchAllAssigned, fetchCompleted, clearAllCompleted } from 'app/queue/domains/submissions/submissions-actions';
import {
  getCompletedSubmissions,
  getAssignedSubmissions,
  getSubmissionsAsync,
} from 'app/queue/domains/submissions/submissions-selectors';
import List from 'app/queue/components/list';
import { AssignedAuditListItem } from 'app/queue/components/assigned-audit-list-item';
import { AssignedPlagiarismCaseListItem } from 'app/queue/components/assigned-plagiarism-case-list-item';
import { AssignedQuestionListItem } from 'app/queue/components/assigned-question-list-item';
import { AssignedSubmissionListItem } from 'app/queue/components/assigned-submission-list-item';

import CONFIG from 'configLoader';
import { TaskHistory } from './task-history';
import { FeedbackModal } from './task-history/feedback-modal';
import styles from './history.module.scss';

const { PAGE_SIZE, HISTORY_DATE_FORMAT } = constants;

export const getReviewsPageParams = (page = 1) => {
  return { page, per_page: PAGE_SIZE };
};

export const getQuestionPageParams = (page = 1) => {
  return {
    start: (page - 1) * PAGE_SIZE,
    end: page * PAGE_SIZE,
  };
};

const mapStateToProps = (state) => ({
  completedSubmissions: getCompletedSubmissions(state),
  assignedSubmissions: getAssignedSubmissions(state),
  completedAsync: getSubmissionsAsync(state, 'fetchCompleted'),
  assignedAsync: getSubmissionsAsync(state, 'fetchAllAssigned'),
  assignedQuestions: selectQuestionQueueAssignments(state),
  questionHistory: selectQuestionQueueHistory(state),
  questionHistoryAsync: selectQuestionQueueHistoryAsync(state),
  assignedPlagiarismCases: selectAssignedPlagiarismCases(state),
  completedPlagiarismCases: selectCompletedPlagiarismCases(state),
  totalCompletedPlagiarismCases: selectTotalCompletedPlagiarismCases(state),
  completedPlagiarismCaseCertifications: getCompletedPlagiarismCaseCertifications(state),
  fetchCompletedPlagiarismCasesAsync: selectFetchCompletedPlagiarismCasesAsync(state),
  canJoinPlagiarismQueue: getCanJoinPlagiarismQueue(state),
  assignedAudits: selectAssignedAudits(state),
  completedAudits: selectCompletedAudits(state),
  totalCompletedAudits: selectTotalCompletedAudits(state),
  completedAuditCertifications: getCompletedAuditCertifications(state),
  fetchCompletedAuditsAsync: selectFetchCompletedAuditsAsync(state),
});

const mapDispatchToProps = {
  fetchAllAssigned,
  fetchCompleted,
  clearAllCompleted,
  clearQuestionQueueHistory: questionQueueActions.clearQueueHistory,
  fetchQuestionQueueHistory: questionQueueActions.fetchQueueHistory,
  clearCompletedAudits: auditQueueActions.clearCompletedAudits,
  fetchCompletedAudits: auditQueueActions.fetchCompletedAudits,
  clearCompletedPlagiarismCases: plagiarismCaseQueueActions.clearCompletedPlagiarismCases,
  fetchCompletedPlagiarismCases: plagiarismCaseQueueActions.fetchCompletedPlagiarismCases,
};

export class History extends React.Component {
  static propTypes = {
    // state
    completedSubmissions: PropTypes.array.isRequired,
    assignedSubmissions: PropTypes.array.isRequired,
    completedAsync: PropTypes.shape({
      loading: PropTypes.bool,
      firstLoad: PropTypes.bool,
      status: PropTypes.string,
    }),
    assignedAsync: PropTypes.shape({
      loading: PropTypes.bool,
      firstLoad: PropTypes.bool,
      status: PropTypes.string,
    }),
    assignedQuestions: PropTypes.array,
    questionHistory: PropTypes.object,
    questionHistoryAsync: PropTypes.object,
    assignedPlagiarismCases: PropTypes.array,
    completedPlagiarismCases: PropTypes.array,
    totalCompletedPlagiarismCases: PropTypes.number,
    completedPlagiarismCaseCertifications: PropTypes.array,
    canJoinPlagiarismQueue: PropTypes.bool,
    fetchCompletedPlagiarismCasesAsync: PropTypes.object,
    assignedAudits: PropTypes.array,
    completedAudits: PropTypes.array,
    totalCompletedAudits: PropTypes.number,
    completedAuditCertifications: PropTypes.array,
    fetchCompletedAuditsAsync: PropTypes.object,
    // dispatch
    fetchAllAssigned: PropTypes.func.isRequired,
    fetchCompleted: PropTypes.func.isRequired,
    clearAllCompleted: PropTypes.func.isRequired,
    clearQuestionQueueHistory: PropTypes.func.isRequired,
    fetchQuestionQueueHistory: PropTypes.func.isRequired,
    clearCompletedAudits: PropTypes.func.isRequired,
    fetchCompletedAudits: PropTypes.func.isRequired,
    clearCompletedPlagiarismCases: PropTypes.func.isRequired,
    fetchCompletedPlagiarismCases: PropTypes.func.isRequired,
  };

  static defaultProps = {
    completedSubmissions: [],
    assignedSubmissions: [],
    assignedQuestions: [],
  };

  state = {
    submissionPage: 1,
    questionPage: 1,
    auditPage: 1,
    plagiarismCasePage: 1,
    peerFeedbacks: [],
    studentFeedbacks: [],
  };

  componentDidMount() {
    const {
      fetchAllAssigned,
      fetchCompleted,
      clearAllCompleted,
      clearQuestionQueueHistory,
      fetchQuestionQueueHistory,
      clearCompletedPlagiarismCases,
      fetchCompletedPlagiarismCases,
      clearCompletedAudits,
      fetchCompletedAudits,
    } = this.props;

    fetchAllAssigned();

    clearAllCompleted();
    fetchCompleted(getReviewsPageParams(1));

    clearQuestionQueueHistory();
    fetchQuestionQueueHistory(PAGE_SIZE);

    clearCompletedAudits();
    fetchCompletedAudits(getReviewsPageParams(1));

    clearCompletedPlagiarismCases();
    fetchCompletedPlagiarismCases(getReviewsPageParams(1));

    this.setPeerFeedbacks();
    this.setStudentFeedbacks();
  }

  componentDidUpdate(prevProps) {
    const { completedSubmissions } = this.props;
    if (!_isEqual(completedSubmissions, prevProps.completedSubmissions)) {
      this.setPeerFeedbacks();
      this.setStudentFeedbacks();
    }
  }

  async setPeerFeedbacks() {
    const { completedSubmissions } = this.props;
    if (completedSubmissions.length === 0) {
      return;
    }

    const peerFeedbacks = await getSubmissionsPeerVotes(completedSubmissions.map((s) => s.id));
    this.setState({ peerFeedbacks });
  }

  async setStudentFeedbacks() {
    const { completedSubmissions } = this.props;
    if (completedSubmissions.length === 0) {
      return;
    }

    const studentFeedbacks = await getSubmissionsStudentFeedbacks(completedSubmissions.map((s) => s.id));
    this.setState({ studentFeedbacks });
  }

  filterPeerFeedbacks(submissionId) {
    const { peerFeedbacks } = this.state;
    return peerFeedbacks.filter((f) => f.submission_id === submissionId);
  }

  findStudentFeedback(submissionId) {
    const { studentFeedbacks } = this.state;
    return studentFeedbacks.find((f) => f.submission_id === submissionId) || {};
  }

  _makeFeedbackObj(id) {
    const { t } = this.props;
    const peerFeedbacks = this.filterPeerFeedbacks(id);
    const studentFeedback = this.findStudentFeedback(id);
    const hasFeedback = peerFeedbacks.length > 0 || !_isEmpty(studentFeedback);

    return hasFeedback
      ? {
          data: t('queue.history.seeFeedback'),
          previewComponent: FeedbackModal,
          previewProps: {
            peerFeedbacks,
            studentFeedback,
          },
        }
      : { data: '-' };
  }

  getCompletedSubmissionsTableData() {
    const { completedSubmissions, t } = this.props;
    const { submissionPage } = this.state;

    const end = Math.min(submissionPage * PAGE_SIZE, completedSubmissions.length);
    const lastPageSize = end % PAGE_SIZE || PAGE_SIZE;
    const start = Math.max(end - lastPageSize, 0);

    return completedSubmissions.slice(start, end).map((submission) => {
      const completed = dayjs(submission.completed_at).format(HISTORY_DATE_FORMAT);

      const price =
        submission.exchange_currency_price && submission.exchange_currency_price !== '0'
          ? formatAlternatePrice(submission.exchange_currency ?? '', submission.exchange_currency_price ?? '0')
          : formatPrice(submission.price);

      const url = `/project-reviews/${submission.id}/feedback`;
      return {
        'Project Name': { data: submission.project.name },
        Earned: { data: price },
        Completed: { data: completed },
        Result: {
          data: submission.result.slice(0, 1).toUpperCase() + submission.result.slice(1),
        },
        Feedback: this._makeFeedbackObj(submission.id),
        Link: {
          data: (
            <Link href={url} isExternal>
              {t('queue.history.viewReview')}
            </Link>
          ),
        },
      };
    });
  }

  nextSubmissionPage = () => {
    return this.fetchSubmissionPage(this.state.submissionPage + 1);
  };
  previousSubmissionPage = () => {
    return this.fetchSubmissionPage(this.state.submissionPage - 1);
  };
  fetchSubmissionPage = (page) => {
    this.setState({ submissionPage: page });
    const params = getReviewsPageParams(page);
    this.props.fetchCompleted(params);
  };

  getQuestionHistoryTableData() {
    const { questionHistory, t } = this.props;
    const { questionPage } = this.state;

    let { start, end } = getQuestionPageParams(questionPage);
    end = Math.min(end, questionHistory.actions.length);
    const lastPageSize = end % PAGE_SIZE || PAGE_SIZE;
    start = Math.max(end - lastPageSize, 0);

    return questionHistory.actions.slice(start, end).map((action) => {
      const completed = dayjs(action.createdAt).format(HISTORY_DATE_FORMAT);
      const price = formatPrice(action.payment.amount);
      const type = action.type === QuestionQueueActionType.COMMENT ? 'Comment' : 'Answer';
      const url = `${CONFIG.knowledge_url}/questions/${action.questionID}`;

      return {
        'Project Name': { data: action.projectTitle },
        Earned: { data: price },
        Completed: { data: completed },
        Type: { data: type },
        Link: {
          data: (
            <Link href={url} isExternal>
              {t('queue.history.viewQuestion')}
            </Link>
          ),
        },
      };
    });
  }

  nextQuestionPage = () => {
    return this.loadQuestionPage(this.state.questionPage + 1);
  };
  previousQuestionPage = () => {
    return this.loadQuestionPage(this.state.questionPage - 1);
  };
  loadQuestionPage = (page) => {
    const { fetchQuestionQueueHistory, questionHistory } = this.props;

    const { start, end } = getQuestionPageParams(page);
    if (start > questionHistory.totalCount) {
      return;
    }

    this.setState({ questionPage: page });
    if (end > questionHistory.actions.length && questionHistory.totalCount > questionHistory.actions.length) {
      const lastAction = questionHistory.actions[questionHistory.actions.length - 1];
      const afterCursor = lastAction ? lastAction.cursor : undefined;
      fetchQuestionQueueHistory(PAGE_SIZE, afterCursor);
    }
  };

  getCompletedAuditTableData() {
    const { completedAudits } = this.props;
    const { auditPage } = this.state;

    const end = Math.min(auditPage * PAGE_SIZE, completedAudits.length);
    const lastPageSize = end % PAGE_SIZE || PAGE_SIZE;
    const start = Math.max(end - lastPageSize, 0);

    return completedAudits.slice(start, end).map((audit) => {
      const completedAt = dayjs(audit.completedAt).format(HISTORY_DATE_FORMAT);
      const price = formatPrice(audit.price);
      const resultWithSpaces = audit.result.replace('_', ' ');
      const formattedResult = resultWithSpaces.charAt(0).toUpperCase() + resultWithSpaces.substring(1);

      return {
        'Project Name': { data: audit.project.name },
        Earned: { data: price },
        Completed: { data: completedAt },
        Result: { data: formattedResult },
      };
    });
  }

  nextAuditPage = () => {
    return this.loadAuditPage(this.state.auditPage + 1);
  };
  previousAuditPage = () => {
    return this.loadAuditPage(this.state.auditPage - 1);
  };

  loadAuditPage = (page) => {
    const { fetchCompletedAudits, completedAudits, totalCompletedAudits = 0 } = this.props;
    const start = (page - 1) * PAGE_SIZE;
    if (start > totalCompletedAudits) {
      return;
    }
    this.setState({ auditPage: page });

    const end = start + PAGE_SIZE;
    if (end > completedAudits.length && totalCompletedAudits > completedAudits.length) {
      fetchCompletedAudits(getReviewsPageParams(page));
    }
  };

  getCompletedPlagiarismCaseTableData() {
    const { completedPlagiarismCases } = this.props;
    const { plagiarismCasePage } = this.state;

    const end = Math.min(plagiarismCasePage * PAGE_SIZE, completedPlagiarismCases.length);
    const lastPageSize = end % PAGE_SIZE || PAGE_SIZE;
    const start = Math.max(end - lastPageSize, 0);

    return completedPlagiarismCases.slice(start, end).map((plagiarismCase) => {
      const completedAt = dayjs(plagiarismCase.completedAt).format(HISTORY_DATE_FORMAT);
      const price = formatPrice(plagiarismCase.price);
      const resultWithSpaces = plagiarismCase.result.replace('_', ' ');
      const formattedResult = resultWithSpaces.charAt(0).toUpperCase() + resultWithSpaces.substring(1);

      return {
        'Project Name': { data: plagiarismCase.project.name },
        Earned: { data: price },
        Completed: { data: completedAt },
        Result: { data: formattedResult },
      };
    });
  }

  nextPlagiarismCasePage = () => {
    return this.loadPlagiarismCasePage(this.state.plagiarismCasePage + 1);
  };
  previousPlagiarismCasePage = () => {
    return this.loadPlagiarismCasePage(this.state.plagiarismCasePage - 1);
  };

  loadPlagiarismCasePage = (page) => {
    const { fetchCompletedPlagiarismCases, completedPlagiarismCases, totalCompletedPlagiarismCases = 0 } = this.props;
    const start = (page - 1) * PAGE_SIZE;
    if (start > totalCompletedPlagiarismCases) {
      return;
    }
    this.setState({ plagiarismCasePage: page });

    const end = start + PAGE_SIZE;
    if (end > completedPlagiarismCases.length && totalCompletedPlagiarismCases > completedPlagiarismCases.length) {
      fetchCompletedPlagiarismCases(getReviewsPageParams(page));
    }
  };

  render() {
    const {
      assignedSubmissions,
      completedAsync,
      assignedAsync,
      assignedQuestions,
      questionHistoryAsync,
      assignedPlagiarismCases,
      completedPlagiarismCaseCertifications,
      canJoinPlagiarismQueue,
      fetchCompletedPlagiarismCasesAsync,
      assignedAudits,
      completedAuditCertifications,
      fetchCompletedAuditsAsync,
      t,
    } = this.props;
    const { submissionPage, questionPage, auditPage, plagiarismCasePage } = this.state;
    const isAllLoading = completedAsync.firstLoad && assignedAsync.firstLoad;
    const isCompletedLoading = completedAsync.loading;
    const isAssignedLoading = assignedAsync.loading;
    const isQuestionHistoryLoading = questionHistoryAsync.loading;
    const hasActiveTasks =
      assignedSubmissions.length > 0 ||
      assignedQuestions.length > 0 ||
      assignedPlagiarismCases.length > 0 ||
      assignedAudits.length > 0;
    const submissionTableData = this.getCompletedSubmissionsTableData();
    const questionHistoryTableData = this.getQuestionHistoryTableData();
    const completedAuditTableData = this.getCompletedAuditTableData();
    const completedPlagiarismCaseTableData = this.getCompletedPlagiarismCaseTableData();

    return (
      <div>
        <div className={styles['title-bar']}>
          <h1>{t('queue.history.history')}</h1>
        </div>

        {isAllLoading ? (
          <div className={styles['loader']}>
            <div>
              <Spinner />
            </div>
          </div>
        ) : (
          <div className={styles.content}>
            <section>
              <h3>{t('common.active')}</h3>
              {hasActiveTasks && (
                <List>
                  {assignedSubmissions.map((sub) => {
                    return <AssignedSubmissionListItem key={sub.id} submission={sub} />;
                  })}
                  {assignedAudits.map((audit) => {
                    return <AssignedAuditListItem key={audit.id} audit={audit} />;
                  })}
                  {assignedPlagiarismCases.map((plagiarismCase) => {
                    return <AssignedPlagiarismCaseListItem key={plagiarismCase.id} plagiarismCase={plagiarismCase} />;
                  })}
                  {assignedQuestions.map((assignment) => {
                    return (
                      <AssignedQuestionListItem key={`question-${assignment.questionID}`} assignment={assignment} />
                    );
                  })}
                </List>
              )}
              {!hasActiveTasks && !isAssignedLoading && (
                <label className={`${styles['empty']} label`}>{t('queue.history.noActiveTasks')}</label>
              )}
              {!hasActiveTasks && isAssignedLoading && (
                <div className={styles['loader']}>
                  <Spinner />
                </div>
              )}
            </section>

            <TaskHistory
              title={t('queue.history.reviewsHistory')}
              isLoading={isCompletedLoading}
              tableData={submissionTableData}
              page={submissionPage}
              onNextPage={this.nextSubmissionPage}
              onPreviousPage={this.previousSubmissionPage}
            />

            {completedAuditCertifications.length > 0 && (
              <TaskHistory
                title={t('queue.history.reviewsAuditHistory')}
                isLoading={fetchCompletedAuditsAsync.loading}
                tableData={completedAuditTableData}
                page={auditPage}
                onNextPage={this.nextAuditPage}
                onPreviousPage={this.previousAuditPage}
              />
            )}

            {completedPlagiarismCaseCertifications.length > 0 && canJoinPlagiarismQueue && (
              <TaskHistory
                title={t('queue.history.reviewsPlagiarismCaseHistory')}
                isLoading={fetchCompletedPlagiarismCasesAsync.loading}
                tableData={completedPlagiarismCaseTableData}
                page={plagiarismCasePage}
                onNextPage={this.nextPlagiarismCasePage}
                onPreviousPage={this.previousPlagiarismCasePage}
              />
            )}

            <TaskHistory
              title={t('queue.history.questionHistory')}
              isLoading={isQuestionHistoryLoading}
              tableData={questionHistoryTableData}
              page={questionPage}
              onNextPage={this.nextQuestionPage}
              onPreviousPage={this.previousQuestionPage}
            />

            {questionHistoryTableData.length > 0 && (
              <Text fontSize="xs" color="gray.600">
                <Trans
                  i18nKey="queue.history.questionHistoryIncludes"
                  components={{
                    handbook_link: <Link as={ReactRouterLink} to="/guidelines/knowledge" isExternal fontSize="xs" />,
                  }}
                />
              </Text>
            )}
          </div>
        )}
      </div>
    );
  }
}

// TODO(GURU-3567): Consolidate into only one queue.
// - Refactor submissions state to persist history rather than just current
//   page.
// - Interleave results of submissions/questions history.
// TODO(GURU-3452): Always show question history when enabled for all review
// certified mentors, even if empty.
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(History));
