import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import { theme } from 'assets/styles/theme';
import { bffClient } from 'app/apollo-clients';
import ErrorBoundary from 'app/common/components/error-boundary';
import { Routes } from 'app/routes';
import { initDatadog } from 'app/services/datadog-service';
import store from 'app/store';

import i18n from './i18n';

export const App: React.FC = () => {
  useEffect(() => {
    initDatadog();
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <ChakraProvider theme={theme}>
        <ApolloProvider client={bffClient}>
          <Provider store={store}>
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </Provider>
        </ApolloProvider>
      </ChakraProvider>
    </I18nextProvider>
  );
};
