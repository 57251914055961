import React from 'react';
import styles from './list.module.scss';

export class List extends React.PureComponent {
  render() {
    return <ul className={styles.list}>{this.props.children}</ul>;
  }
}

export default List;
