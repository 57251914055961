import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { CloseIcon } from '@udacity/chakra-uds-icons';

import { FilterDropdown } from './filter-dropdown';
import { Filter } from '../types';
import { useHistory, useParams } from 'react-router';
import { getQueryParams, updateQueryParams } from '../utils';

type Props = { filters: Filter[] };

export const Filters: React.FC<Props> = ({ filters }) => {
  const history = useHistory();
  const queryParams = getQueryParams();

  const activeFilterIds = filters.reduce<string[]>((ids, filter) => {
    if (queryParams[filter.id]) ids.push(filter.id);
    return ids;
  }, []);

  const clearFilters = () => {
    const filtersToClear = activeFilterIds.reduce((toClear, filterId) => {
      toClear[filterId] = false;

      return toClear;
    }, {});
    updateQueryParams(history, filtersToClear);
  };

  return (
    <Flex mb="1rem">
      {filters.map((filter) => (
        <FilterDropdown key={filter.id} filter={filter} />
      ))}
      {activeFilterIds.length > 0 && (
        <Button marginLeft={3} onClick={clearFilters} variant="ghost">
          <CloseIcon h={6} w={6} color="red.600" />
          Clear filters
        </Button>
      )}
    </Flex>
  );
};
