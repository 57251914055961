import { extendTheme } from '@chakra-ui/react';
import { theme as udsTheme } from '@udacity/chakra-uds-theme';

export const theme = extendTheme(udsTheme, {
  styles: {
    global: {
      a: {
        color: 'blue.500',
        textDecoration: 'none',
      },
      // Needed to see the cursor when adding a code review comment
      '.ͼ4 .cm-line ::selection': {
        backgroundColor: '#6199ff2f !important;',
      },
    },
  },
});
