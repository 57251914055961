import _ from 'lodash';
import { createSelector } from 'reselect';
import { getEligibleProjectLanguages } from 'app/common/domains/session/session-selectors';
import { getAssignedSubmissions } from 'app/queue/domains/submissions/submissions-selectors';
import constants from './reviews-queue-constants';

const { MAX_QUEUE_VOLUME, SUBMISSION_REQUEST_STATUS } = constants;

export const getSubmissionRequests = (state) => state.reviews.queue.entities;
export const getActiveSubmissionRequestIds = (state) => state.reviews.queue.active;

export const getQueueSettings = createSelector(
  (state) => state.reviews.queue.queueSettings,
  (queueSettings) => queueSettings
);

export const getQueueAsync = createSelector(
  (state, action) => {
    if (action) {
      return state.reviews.queueAsync[action];
    }
    return state.reviews.queueAsync;
  },
  (state) => state
);

export const getActiveSubmissionRequests = createSelector(
  getSubmissionRequests,
  getActiveSubmissionRequestIds,
  (requests, activeRequestIds) => {
    return activeRequestIds.map((request) => requests[request]);
  }
);

export const getQueuingAvailability = createSelector(
  getActiveSubmissionRequestIds,
  getAssignedSubmissions,
  (submissionRequestIds, assignedSubmissions) => {
    const requested = submissionRequestIds.length;
    const assigned = assignedSubmissions.length;
    let available = MAX_QUEUE_VOLUME - requested - assigned;
    if (available < 0) {
      available = 0;
    }

    return {
      requested,
      assigned,
      available,
    };
  }
);

export const getQueueExpiration = createSelector(getActiveSubmissionRequests, (submissionRequests) => {
  const activeSubmissionRequests = submissionRequests.filter((request) => {
    return request.status === SUBMISSION_REQUEST_STATUS.available;
  });
  if (!activeSubmissionRequests.length) {
    return null;
  }
  const oldestRequest = _.first(activeSubmissionRequests);
  return new Date(oldestRequest.closed_at).getTime();
});

export const getActiveSubmissionRequestProjects = createSelector(
  getActiveSubmissionRequests,
  (activeSubmissionRequests = []) => {
    if (!activeSubmissionRequests.length) {
      return [];
    }
    const firstSubmissionRequestProjects = activeSubmissionRequests[0].submission_request_projects;
    return _.uniqBy(firstSubmissionRequestProjects.map((request) => request.project_id));
  }
);

export const getSubmissionRequestBody = createSelector(
  getQueueSettings,
  getEligibleProjectLanguages,
  (settings, languages) => {
    const projects = _.uniqBy(settings.projects || []);

    return {
      projects: _.flatMap(projects, (project_id) => {
        return languages.map((language) => ({
          project_id,
          language,
        }));
      }),
    };
  }
);
