import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { Alert } from 'app/common/components/alert';
import { useGetAvailableSeriesQuery } from 'app/uconnect/queries/enhanced';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClaimMeetingsModal } from './claim-meetings-modal';
import auth from '@udacity/ureact-hoth';

export const ConnectNotifications: React.FC = () => {
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { data } = useGetAvailableSeriesQuery({
    facilitatorUserKey: auth.getCurrentUserId(),
  });

  const areMeetingsAvailableToClaim = (data?.series.totalCount || 0) > 0;

  return (
    <Flex gap={4} mb={4} direction="column">
      {areMeetingsAvailableToClaim && (
        <>
          <Alert status="info">
            <Flex justifyContent="space-between" alignItems="center" flex={1}>
              <Text as="span">{t('connect.meetingsToClaim')}</Text>
              <Button variant="ghost" onClick={onOpen}>
                {t('connect.claimMeetings')}
              </Button>
            </Flex>
          </Alert>
          <ClaimMeetingsModal isOpen={isOpen} onClose={onClose} />
        </>
      )}
    </Flex>
  );
};
