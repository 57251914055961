import React from 'react';
import { useToast } from '@chakra-ui/react';
import { Toast } from '../components/toast';

export function useAppToast() {
  const toast = useToast();

  return ({
    variant,
    title,
    description,
  }: {
    variant: 'success' | 'error' | 'warning' | 'info';
    title?: string;
    description?: string;
  }) => {
    toast({
      status: variant,
      title,
      position: 'bottom',
      duration: 4000,
      isClosable: true,
      render: ({ onClose }) => <Toast variant={variant} title={title} description={description} onClose={onClose} />,
    });
  };
}
