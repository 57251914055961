import { actionTypes } from './certifications-actions';

export const initialState = {
  entities: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  if (error || !payload) {
    return state;
  }

  switch (type) {
    case actionTypes.FETCH_ALL: {
      return {
        ...state,
      };
    }
    case actionTypes.FETCH_ALL_FULFILLED: {
      const entities = payload.reduce(
        (acc, entity) => {
          acc[entity.id] = entity;
          return acc;
        },
        { ...state.entities }
      );

      return {
        ...state,
        entities,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
