import {
  Button,
  Flex,
  IconButton,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { EditIcon, InfoIcon } from '@udacity/chakra-uds-icons';
import { Markdown } from '@udacity/ureact-markdown';
import { FeedbackTemplate } from 'app/types/reviews';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FeedbackTemplateFormModal } from './feedback-template-form-modal';

type TemplateListItemProps = {
  template: FeedbackTemplate;
  onApply: (text: string) => void;
};

export const TemplateListItem: React.FC<TemplateListItemProps> = ({ template, onApply }) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  function handleApplyTemplate() {
    onApply(template.text);
  }

  return (
    <ListItem
      key={template.id}
      mb={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={2}
      borderRadius="base"
      border="1px solid"
      borderColor="gray.300"
      bg="white"
      me={2}
    >
      <Flex alignItems="center" gap={4}>
        <Button onClick={handleApplyTemplate}>{t('projectReviews.template.apply')}</Button>
        {template.is_shared && (
          <Text as="span" fontWeight="bold">
            {t('projectReviews.template.udacityTemplate')}
          </Text>
        )}
        <Text as="span">{template.title}</Text>
        <Popover isLazy>
          <PopoverTrigger>
            <IconButton
              aria-label={t('projectReviews.template.preview')}
              variant="ghost"
              size="icon"
              isRound
              icon={<InfoIcon h={6} w={6} />}
            />
          </PopoverTrigger>
          <PopoverContent w="lg">
            <PopoverArrow />
            <Markdown markdownText={template.text} />
          </PopoverContent>
        </Popover>
      </Flex>
      {!template.is_shared && (
        <>
          <IconButton
            aria-label={t('projectReviews.template.edit')}
            variant="outline"
            onClick={onOpen}
            size="icon"
            isRound
            icon={<EditIcon h={8} w={8} />}
            colorScheme="blue"
          />
          <FeedbackTemplateFormModal isOpen={isOpen} onClose={onClose} template={template} />
        </>
      )}
    </ListItem>
  );
};
