import React from 'react';
import { useUploadImageMutation } from 'app/apis/reviews';
import { useTranslation } from 'react-i18next';
import { getFileMarkdown } from '../helpers';
import { useAppToast } from 'app/common/hooks/use-app-toast';

export function useImageUpload(
  setFeedback: React.Dispatch<React.SetStateAction<string>>,
  saveFeedback?: (feedback: string) => void
) {
  const { t } = useTranslation();
  const toast = useAppToast();
  const isUploadingImageMarkdown = t('projectReviews.isUploadingImageMarkdown');

  const [uploadImage, { isLoading: isUploadingImage }] = useUploadImageMutation();

  const handleUploadFiles = async (files: FileList | File[] | null, selectionStart?: number) => {
    if (!files) return;

    Array.from(files).forEach(async (file) => {
      try {
        setFeedback((currentFeedback) => {
          return getSplicedImageText(currentFeedback, isUploadingImageMarkdown, selectionStart);
        });
        const isImage = String(file.type).startsWith('image/');
        const isPdf = String(file.type).includes('pdf');
        if (!(isImage || isPdf)) {
          throw new Error(`Invalid file type: ${file.type}`);
        }

        const formData = new FormData();
        formData.append('file', file);

        const response = await uploadImage(formData).unwrap();

        if (response.url) {
          setFeedback((currentFeedback) => {
            const imageMarkdown = getFileMarkdown(file, response.url);
            const newFeedback = getSplicedImageText(currentFeedback, imageMarkdown, selectionStart).replace(
              isUploadingImageMarkdown,
              ''
            );
            saveFeedback?.(newFeedback);
            return newFeedback;
          });
        }
      } catch (e) {
        setFeedback((currentFeedback) => {
          return currentFeedback.replace(isUploadingImageMarkdown, '');
        });

        console.error(e);

        toast({
          variant: 'error',
          description: t('projectReviews.uploadError', { message: (e as any).message }),
        });
      }
    });
  };

  return { handleUploadFiles, isUploadingImage };
}

function getSplicedImageText(currentFeedback: string, imageMarkdown: string, selectionStart?: number) {
  if (!currentFeedback) return imageMarkdown;

  if (selectionStart === undefined) return currentFeedback + '\n' + imageMarkdown;

  return [currentFeedback.slice(0, selectionStart), imageMarkdown, currentFeedback.slice(selectionStart)].join('\n');
}
