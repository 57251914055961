import React from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { AgreementsList } from 'app/agreements/components/agreement-list';
import questionQueueActions from 'app/queue/domains/question-queue/question-queue-actions';
import { selectQuestionQueueMonthlyEarningsSummary } from 'app/queue/domains/question-queue/question-queue-selectors';
import { fetchEarnings, fetchRatingsMatrix } from 'app/queue/domains/reviewer-data/reviewer-data-actions';
import { getReviewerEarnings, getRatingsMatrix } from 'app/queue/domains/reviewer-data/reviewer-data-selectors';

import { EarningsCard } from './earnings-card';
import { getReadablePSTDate } from './stats-helpers';
import styles from './stats.module.scss';

const mapStateToProps = (state) => ({
  earnings: getReviewerEarnings(state),
  ratingsMatrix: getRatingsMatrix(state),
  questionQueueEarnings: selectQuestionQueueMonthlyEarningsSummary(state),
});

const mapDispatchToProps = {
  fetchEarnings,
  fetchRatingsMatrix,
  fetchQuestionQueueEarnings: questionQueueActions.fetchMonthlyEarningsSummary,
};

export class Stats extends React.Component {
  static propTypes = {
    earnings: PropTypes.object,
    ratingsMatrix: PropTypes.array,

    // dispatch
    fetchEarnings: PropTypes.func,
    fetchRatingsMatrix: PropTypes.func,
  };

  static defaultProps = {
    earnings: null,
    ratingsMatrix: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      startDate: dayjs().subtract(60, 'days'),
      endDate: dayjs(),
    };
  }

  componentDidMount() {
    const { startDate, endDate } = this.state;

    this.props.fetchEarnings();
    this.props.fetchRatingsMatrix(startDate, endDate);
    this.props.fetchQuestionQueueEarnings();
  }

  render() {
    const { earnings, questionQueueEarnings, t } = this.props;
    const { startDate, endDate } = this.state;

    const totalEarnings =
      (earnings ? (earnings['earnings'] ? parseFloat(earnings['earnings']) : 0) : 0) +
      (questionQueueEarnings ? parseFloat(questionQueueEarnings) : 0);

    const exchangeCurrencyEarnings = earnings ? earnings['exchange_currency_earnings'] : null;

    const monthText = endDate.format('MMMM');

    return (
      <div className={styles['stats']}>
        <header className={styles['header']}>
          <h2>{t('queue.overview.yourEarnings')}</h2>
        </header>
        <main>
          <EarningsCard key="usd_earnings_card" earned={totalEarnings.toString()} monthText={monthText} />

          {exchangeCurrencyEarnings &&
            exchangeCurrencyEarnings.map((exchangeCurrencyEarning) => {
              const exchangeCurrency = exchangeCurrencyEarning.exchange_currency;
              const exchangeCurrencyTotal = exchangeCurrencyEarning.exchange_currency_total;

              if (exchangeCurrency && exchangeCurrencyTotal) {
                return (
                  <EarningsCard
                    key={`${exchangeCurrency}_earnings_card`}
                    earned={exchangeCurrencyTotal}
                    monthText={monthText}
                    currency={exchangeCurrency}
                  />
                );
              }

              return null;
            })}
        </main>

        <header className={styles['header']}>
          <h2> {t('queue.overview.yourRatings')} </h2>
        </header>
        <main>
          <div className={styles['stats-period']}>
            <p>
              <Trans
                i18nKey="queue.overview.period"
                values={{
                  startDate: getReadablePSTDate(startDate),
                  endDate: getReadablePSTDate(endDate),
                }}
                components={{
                  start_date_time: <time dateTime={startDate} />,
                  end_date_time: <time dateTime={endDate} />,
                }}
              />{' '}
            </p>
          </div>
          <p className={styles['ratings-placeholder']}>{t('queue.overview.wereRevisitingRatings')}</p>
        </main>

        <AgreementsList />
      </div>
    );
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Stats));
