/* eslint-disable */ /**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 * instead, edit its related `.gql` file and recreate it with
 *
 * npm run codegen:connect
 */

import * as Types from '../../types/uconnect';

import { api } from 'app/apis/connect-gql-api';
export type GetMeetingUsersQueryVariables = Types.Exact<{
  meetingId: Types.Scalars['UUID']['input'];
}>;

export type GetMeetingUsersQuery = {
  __typename?: 'Query';
  meetings: {
    __typename?: 'PaginatedMeetings';
    meetings: Array<{
      __typename?: 'Meeting';
      id: any;
      cohortId: any;
      name: string;
      startAt: any;
      endAt: any;
      instructions?: string | null;
      conferenceLink?: string | null;
      locales: Array<string>;
      series?: { __typename?: 'Series'; id: any; name: string; description?: string | null } | null;
      meetingUsers: {
        __typename?: 'PaginatedMeetingUsers';
        totalCount: number;
        meetingUsers: Array<{
          __typename?: 'MeetingUser';
          userKey: string;
          meetingId: any;
          user?: { __typename?: 'UserDetails'; firstName: string; lastName: string; email: string } | null;
          attendance?: {
            __typename?: 'MeetingAttendance';
            meetingId: any;
            userKey: string;
            status: Types.MeetingAttendanceStatus;
            note?: string | null;
          } | null;
          attendanceRequest?: {
            __typename?: 'MeetingAttendanceRequest';
            createdAt: any;
            meetingId: any;
            userKey: string;
            requestedAttendanceStatus: Types.MeetingAttendanceStatus;
            requestApproved?: boolean | null;
            judgeUserKey?: string | null;
            note?: string | null;
            updatedAt: any;
          } | null;
        }>;
      };
    }>;
  };
};

export const GetMeetingUsersDocument = `
    query GetMeetingUsers($meetingId: UUID!) {
  meetings(input: {meetingId: $meetingId}) {
    meetings {
      id
      cohortId
      name
      startAt
      endAt
      instructions
      conferenceLink
      series {
        id
        name
        description
      }
      meetingUsers {
        meetingUsers {
          userKey
          meetingId
          user {
            firstName
            lastName
            email
          }
          attendance {
            meetingId
            userKey
            status
            note
          }
          attendanceRequest {
            createdAt
            meetingId
            userKey
            requestedAttendanceStatus
            requestApproved
            judgeUserKey
            note
            updatedAt
          }
        }
        totalCount
      }
      locales
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetMeetingUsers: build.query<GetMeetingUsersQuery, GetMeetingUsersQueryVariables>({
      query: (variables) => ({ document: GetMeetingUsersDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
