import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import auth from '@udacity/ureact-hoth';

import {
  ApiComment,
  ApiContent,
  ApiCritique,
  ApiPlagiarismCase,
  ApiRating,
  ApiReviewerToolkit,
  ApiRubric,
  ApiSubmission,
  Certification,
  Comment,
  Content,
  CreateCommentPayload,
  CreateFeedbackTemplatePayload,
  Critique,
  DeleteCommentPayload,
  FeedbackTemplate,
  FetchSubmissionsPayload,
  Opportunity,
  PlagiarismCase,
  Rating,
  ReviewerToolkit,
  Rubric,
  Submission,
  SubmitGeneralCommentPayload,
  SubmitRatingPayload,
  UnassignPayload,
  UngradeablePayload,
  UpdateCommentPayload,
  UpdateCritiquePayload,
  UpdateFeedbackTemplatePayload,
} from 'app/types/reviews';

import {
  sanitizeApiComment,
  sanitizeApiContent,
  sanitizeApiCritique,
  sanitizeApiPlagiarismCase,
  sanitizeApiRating,
  sanitizeApiReviewerToolkit,
  sanitizeApiRubric,
  sanitizeApiSubmission,
  updateCritique,
  updateSubmission,
} from './helpers/reviews-helpers';

import { REVIEWS_API_PATH } from './service-paths';

export const reviewsApi = createApi({
  reducerPath: 'reviewsApi',
  tagTypes: ['Reviews'],
  baseQuery: fetchBaseQuery({
    baseUrl: REVIEWS_API_PATH,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${auth.getJWT()}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchApiToken: builder.query<{ token: string }, void>({
      query: () => {
        return {
          url: '/me/api_token',
        };
      },
      providesTags: () => [{ type: 'Reviews', id: 'ApiToken' }],
    }),

    fetchSubmission: builder.query<Submission, number>({
      query: (submissionId) => {
        return {
          url: `/submissions/${submissionId}.json`,
        };
      },
      transformResponse: (response: ApiSubmission) => {
        return sanitizeApiSubmission(response);
      },
      providesTags: (_, __, submissionId) => [{ type: 'Reviews', id: `Submission-${submissionId}` }],
    }),

    fetchSubmissions: builder.query<Submission[], FetchSubmissionsPayload>({
      query: ({ userId, rubricId, enrollmentId }) => {
        const params = { rubric_id: rubricId };
        if (enrollmentId) {
          params['enrollment_id'] = enrollmentId;
        }

        return {
          url: `/users/${userId}/submissions.json`,
          params,
        };
      },
      transformResponse: (response: ApiSubmission[]) => {
        return response.map(sanitizeApiSubmission);
      },
      providesTags: (_, __, rubricId) => [{ type: 'Reviews', id: `Submissions-${rubricId}` }],
    }),

    fetchCertifications: builder.query<Certification[], void>({
      query: () => {
        return {
          url: '/me/certifications',
        };
      },
      providesTags: () => [{ type: 'Reviews', id: 'Certifications' }],
    }),

    fetchAssignedSubmissions: builder.query<Submission[], void>({
      query: () => {
        return {
          url: '/me/submissions/assigned.json',
        };
      },
      transformResponse: (response: ApiSubmission[]) => {
        return response.map(sanitizeApiSubmission);
      },
      providesTags: (_, __, rubricId) => [{ type: 'Reviews', id: `AssignedSubmissions-${rubricId}` }],
    }),

    fetchReviewerToolkits: builder.query<ReviewerToolkit[], { language: string; projectId: number }>({
      query: ({ language, projectId }) => {
        return {
          url: '/reviewer_toolkits.json',
          params: {
            language: language,
            project_id: projectId,
          },
        };
      },
      transformResponse: (response: ApiReviewerToolkit[]) => {
        return response.map(sanitizeApiReviewerToolkit);
      },
      providesTags: (_, __, { projectId }) => [{ type: 'Reviews', id: `ReviewerToolkits-${projectId}` }],
    }),

    acceptOpportunity: builder.mutation<unknown, number>({
      query: (opportunityId) => {
        return {
          url: `/opportunities/${opportunityId}`,
          method: 'PUT',
          body: {
            opportunity: { accepted: true },
          },
        };
      },
    }),

    fetchOpportunities: builder.query<Opportunity[], void>({
      query: () => {
        return {
          url: '/me/opportunities',
          params: { active: true },
        };
      },
      providesTags: () => [{ type: 'Reviews', id: 'Opportunities' }],
    }),

    fetchRubric: builder.query<Rubric, number>({
      query: (rubricId) => {
        return {
          url: `/rubrics/${rubricId}.json`,
          params: {
            for_eligible_grader: 1,
          },
        };
      },
      transformResponse: (response: ApiRubric) => {
        return sanitizeApiRubric(response);
      },
      providesTags: (_, __, rubricId) => [{ type: 'Reviews', id: `Rubric-${rubricId}` }],
    }),

    uploadImage: builder.mutation<{ url: string }, FormData>({
      query: (formData) => {
        return {
          url: '/attachments.json',
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${auth.getJWT()}`,
            Accept: 'application/json, text/plain, */*',
          },
        };
      },
    }),

    unassignSubmission: builder.mutation<unknown, UnassignPayload>({
      query: ({ submissionId, reason }) => {
        return {
          url: `/submissions/${submissionId}/unassign.json`,
          method: 'PUT',
          body: {
            reason,
          },
        };
      },
    }),

    markUngradeable: builder.mutation<unknown, UngradeablePayload>({
      query: ({ submissionId, notes, tag, plagiarismSourceUrl }) => {
        return {
          url: `/submissions/${submissionId}/ungradeable.json`,
          method: 'PUT',
          body: {
            notes: notes,
            tag: tag,
            plagiarism_source_url: plagiarismSourceUrl,
          },
        };
      },
    }),

    fetchCritiques: builder.query<Critique[], number>({
      query: (submissionId) => {
        return {
          url: `/submissions/${submissionId}/critiques.json`,
        };
      },
      transformResponse: (response: ApiCritique[]) => {
        return response.map(sanitizeApiCritique);
      },
      providesTags: (_, __, submissionId) => [{ type: 'Reviews', id: `Critiques-${submissionId}` }],
    }),

    updateCritique: builder.mutation<Critique, UpdateCritiquePayload>({
      query: ({ id, result, observation }) => {
        return {
          url: `/critiques/${id}.json`,
          method: 'PUT',
          body: {
            id,
            result,
            observation,
          },
        };
      },
      transformResponse: (response: ApiCritique) => {
        return sanitizeApiCritique(response);
      },
      async onQueryStarted({ id, submissionOrCaseId, isPlagiarismAudit }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedCritique } = await queryFulfilled;
          dispatch(updateCritique(submissionOrCaseId, updatedCritique, isPlagiarismAudit));
        } catch {
          console.error(`Attempted to update critique with id ${id}`);
        }
      },
    }),

    fetchContents: builder.query<Content[], number>({
      query: (submissionId) => {
        return {
          url: `/submissions/${submissionId}/contents.json`,
        };
      },
      transformResponse: (response: ApiContent[]) => {
        return response.map(sanitizeApiContent);
      },
      providesTags: (_, __, submissionId) => [{ type: 'Reviews', id: `Contents-${submissionId}` }],
    }),

    fetchBlobData: builder.query<string, string>({
      queryFn: async (blobUrl) => {
        const response = await fetch(blobUrl);
        const data = await response.text();
        return { data };
      },
      providesTags: (_, __, blobUrl) => [{ type: 'Reviews', id: `BlobData-${blobUrl}` }],
    }),

    fetchComments: builder.query<Comment[], number>({
      query: (contentId) => {
        return {
          url: `/contents/${contentId}/comments.json`,
        };
      },
      transformResponse: (response: ApiComment[]) => {
        return response.map(sanitizeApiComment);
      },
      providesTags: (_, __, contentId) => [{ type: 'Reviews', id: `Comments-${contentId}` }],
    }),

    createComment: builder.mutation<Comment, CreateCommentPayload>({
      query: ({ contentId, ...body }) => {
        return {
          url: `/contents/${contentId}/comments.json`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: ApiComment) => {
        return sanitizeApiComment(response);
      },
      invalidatesTags: (_, __, { submissionId, contentId }) => [
        { type: 'Reviews', id: `Contents-${submissionId}` },
        { type: 'Reviews', id: `Comments-${contentId}` },
      ],
    }),

    updateComment: builder.mutation<Comment, UpdateCommentPayload>({
      query: ({ commentId, body, category, position }) => {
        return {
          url: `/comments/${commentId}.json`,
          method: 'PUT',
          body: {
            body,
            category,
            position,
          },
        };
      },
      transformResponse: (response: ApiComment) => {
        return sanitizeApiComment(response);
      },
      invalidatesTags: (_, __, { contentId }) => [{ type: 'Reviews', id: `Comments-${contentId}` }],
    }),

    deleteComment: builder.mutation<Comment, DeleteCommentPayload>({
      query: ({ commentId }) => {
        return {
          url: `/comments/${commentId}.json`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_, __, { contentId, submissionId }) => [
        { type: 'Reviews', id: `Contents-${submissionId}` },
        { type: 'Reviews', id: `Comments-${contentId}` },
      ],
    }),

    fetchFeedbackTemplates: builder.query<FeedbackTemplate[], { projectId: number; submissionId: number }>({
      query: ({ projectId }) => {
        return {
          url: '/feedback_templates',
          params: { project_id: projectId },
        };
      },
      providesTags: (_, __, { submissionId }) => [{ type: 'Reviews', id: `FeedbackTemplates-${submissionId}` }],
    }),

    createFeedbackTemplate: builder.mutation<
      FeedbackTemplate,
      { body: CreateFeedbackTemplatePayload; submissionId: number }
    >({
      query: ({ body }) => {
        return {
          url: `/feedback_templates`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (_, __, { submissionId }) => [{ type: 'Reviews', id: `FeedbackTemplates-${submissionId}` }],
    }),

    updateFeedbackTemplate: builder.mutation<Comment, { body: UpdateFeedbackTemplatePayload; submissionId: number }>({
      query: ({ body }) => {
        return {
          url: `/feedback_templates/${body.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_, __, { submissionId }) => [{ type: 'Reviews', id: `FeedbackTemplates-${submissionId}` }],
    }),

    deleteFeedbackTemplate: builder.mutation<Comment, { templateId: number; submissionId: number }>({
      query: ({ templateId }) => {
        return {
          url: `/feedback_templates/${templateId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_, __, { submissionId }) => [{ type: 'Reviews', id: `FeedbackTemplates-${submissionId}` }],
    }),

    submitGeneralComment: builder.mutation<Submission, SubmitGeneralCommentPayload>({
      query: ({ submissionId, generalComment }) => {
        return {
          url: `/submissions/${submissionId}/general_comment.json`,
          method: 'PUT',
          body: {
            general_comment: generalComment,
          },
        };
      },
      transformResponse: (response: ApiSubmission) => {
        return sanitizeApiSubmission(response);
      },
      async onQueryStarted({ submissionId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedSubmission } = await queryFulfilled;
          dispatch(updateSubmission(submissionId, updatedSubmission));
        } catch {
          console.error(`Attempted to update submission with id ${submissionId}`);
        }
      },
    }),

    submitFeedback: builder.mutation<unknown, number>({
      query: (submissionId) => {
        return {
          url: `/submissions/${submissionId}/submit`,
          method: 'PUT',
        };
      },
    }),

    fetchRating: builder.query<Rating, number>({
      query: (submissionId) => {
        return {
          url: `/submissions/${submissionId}/vote.json`,
        };
      },
      transformResponse: (response: ApiRating) => {
        return sanitizeApiRating(response);
      },
      providesTags: (_, __, submissionId) => [{ type: 'Reviews', id: `Rating-${submissionId}` }],
    }),

    submitRating: builder.mutation<unknown, SubmitRatingPayload>({
      query: ({ submissionId, value, feedback }) => {
        return {
          url: `/submissions/${submissionId}/vote.json`,
          method: 'POST',
          body: {
            value,
            feedback,
          },
        };
      },
    }),

    fetchAllPlagiarismCases: builder.query<PlagiarismCase[], number>({
      query: (submissionId) => {
        return {
          url: `/submissions/${submissionId}/plagiarism_cases.json`,
        };
      },
      transformResponse: (response: ApiPlagiarismCase[]) => {
        return response.map(sanitizeApiPlagiarismCase);
      },
      providesTags: (_, __, submissionId) => [{ type: 'Reviews', id: `PlagiarismCases-${submissionId}` }],
    }),

    fetchPlagiarismCase: builder.query<PlagiarismCase, number>({
      query: (plagiarismCaseId) => {
        return {
          url: `/plagiarism_cases/${plagiarismCaseId}.json`,
        };
      },
      transformResponse: (response: ApiPlagiarismCase) => {
        return sanitizeApiPlagiarismCase(response);
      },
      providesTags: (_, __, plagiarismCaseId) => [{ type: 'Reviews', id: `PlagiarismCase-${plagiarismCaseId}` }],
    }),

    fetchPlagiarismCritiques: builder.query<Critique[], number>({
      query: (plagiarismCaseId) => {
        return {
          url: `/plagiarism_cases/${plagiarismCaseId}/critiques.json`,
        };
      },
      transformResponse: (response: ApiCritique[]) => {
        return response.map(sanitizeApiCritique);
      },
      providesTags: (_, __, plagiarismCaseId) => [{ type: 'Reviews', id: `Plagiarism-Critiques-${plagiarismCaseId}` }],
    }),

    unassignPlagiarismAudit: builder.mutation<unknown, number>({
      query: (plagiarismCaseId) => {
        return {
          url: `/plagiarism_cases/${plagiarismCaseId}/unassign.json`,
          method: 'PUT',
        };
      },
    }),

    submitPlagiarismAudit: builder.mutation<unknown, number>({
      query: (plagiarismCaseId) => {
        return {
          url: `/plagiarism_cases/${plagiarismCaseId}/submit`,
          method: 'PUT',
        };
      },
    }),
  }),
});

export const {
  useFetchApiTokenQuery,
  useFetchSubmissionQuery,
  useFetchSubmissionsQuery,
  useFetchAssignedSubmissionsQuery,
  useFetchCertificationsQuery,
  useFetchReviewerToolkitsQuery,
  useAcceptOpportunityMutation,
  useFetchOpportunitiesQuery,
  useFetchRubricQuery,
  useUploadImageMutation,
  useUnassignSubmissionMutation,
  useMarkUngradeableMutation,
  useFetchCritiquesQuery,
  useUpdateCritiqueMutation,
  useFetchContentsQuery,
  useSubmitGeneralCommentMutation,
  useSubmitFeedbackMutation,
  useFetchRatingQuery,
  useSubmitRatingMutation,
  useFetchBlobDataQuery,
  useFetchCommentsQuery,
  useCreateCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useFetchFeedbackTemplatesQuery,
  useCreateFeedbackTemplateMutation,
  useUpdateFeedbackTemplateMutation,
  useDeleteFeedbackTemplateMutation,
  useFetchAllPlagiarismCasesQuery,
  useFetchPlagiarismCaseQuery,
  useFetchPlagiarismCritiquesQuery,
  useUnassignPlagiarismAuditMutation,
  useSubmitPlagiarismAuditMutation,
} = reviewsApi;
