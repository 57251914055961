import dayjs from 'dayjs';

import { createAction } from 'app/helpers/action-helper';

export const actionTypes = {
  FETCH_ALL: 'statements:FETCH_ALL',
  FETCH_ALL_FULFILLED: 'statements:FETCH_ALL_FULFILLED',
};

export function fetchAll(startDate, endDate) {
  const start_date = startDate || dayjs().startOf('month').subtract(2, 'years');
  const end_date = endDate || null;
  return createAction(actionTypes.FETCH_ALL, { start_date, end_date });
}
