import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControl, FormHelperText, FormLabel } from '@chakra-ui/react';

import styles from './locale.module.scss';

type LocaleProps = {
  languages: string[]; // language codes for all previously submitted languages
  onChangeLanguages: (event: React.ChangeEvent<HTMLInputElement>) => void;
  languageOptions: { code: string; name: string; value?: string }[];
};

export const Locale: React.FC<LocaleProps> = ({ languages, onChangeLanguages, languageOptions }) => {
  const { t } = useTranslation();
  const isLanguageChecked = (locale: string) => {
    return languages && languages.includes(locale);
  };

  return (
    <section>
      <FormControl my={5}>
        <FormLabel mb={2}>{t('mentorProfile.spokenLanguages')}</FormLabel>
        <FormHelperText mb={2}>{t('mentorProfile.theFollowingLanguages')}</FormHelperText>

        <ul className={styles['list']}>
          {languageOptions.map(({ name, code }) => {
            return (
              <li className={styles['language-checklist']} key={code}>
                <Checkbox
                  id={`${name}-checkbox`}
                  isChecked={isLanguageChecked(code)}
                  value={code}
                  onChange={onChangeLanguages}
                >
                  {name}
                </Checkbox>
              </li>
            );
          })}
        </ul>
      </FormControl>
    </section>
  );
};
