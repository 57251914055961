import { RootState } from 'app/store';
import { createSelector } from 'reselect';

export const getSubmissionsAsync = createSelector(
  (state: RootState, action: string) => {
    if (action) {
      return state.reviews.submissionsAsync[action];
    }
    return state.reviews.submissionsAsync;
  },
  (state) => state
);

export const getAssignedSubmissions = (state: RootState) => {
  return state.reviews.submissions.assigned;
};

export const getCompletedSubmissions = (state: RootState) => {
  return state.reviews.submissions.completed;
};
