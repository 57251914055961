import { createAction } from 'app/helpers/action-helper';

import { CompletedPlagiarismCasePageParams } from './plagiarism-case-queue-types';

export const actionTypes = {
  BOOTSTRAP_PLAGIARISM_CASE_QUEUE_START: 'plagiarism-case-queue:BOOTSTRAP_PLAGIARISM_CASE_QUEUE_START',
  BOOTSTRAP_PLAGIARISM_CASE_QUEUE_FULFILLED: 'plagiarism-case-queue:BOOTSTRAP_PLAGIARISM_CASE_QUEUE_FULFILLED',
  BOOTSTRAP_PLAGIARISM_CASE_QUEUE_REJECTED: 'plagiarism-case-queue:BOOTSTRAP_PLAGIARISM_CASE_QUEUE_REJECTED',
  FETCH_PLAGIARISM_CASE_REQUESTS_START: 'plagiarism-case-queue:FETCH_PLAGIARISM_CASE_REQUESTS_START',
  FETCH_PLAGIARISM_CASE_REQUESTS_FULFILLED: 'plagiarism-case-queue:FETCH_PLAGIARISM_CASE_REQUESTS_FULFILLED',
  FETCH_PLAGIARISM_CASE_REQUESTS_REJECTED: 'plagiarism-case-queue:FETCH_PLAGIARISM_CASE_REQUESTS_REJECTED',
  FETCH_ASSIGNED_PLAGIARISM_CASES_START: 'plagiarism-case-queue:FETCH_ASSIGNED_PLAGIARISM_CASES_START',
  FETCH_ASSIGNED_PLAGIARISM_CASES_FULFILLED: 'plagiarism-case-queue:FETCH_ASSIGNED_PLAGIARISM_CASES_FULFILLED',
  FETCH_ASSIGNED_PLAGIARISM_CASES_REJECTED: 'plagiarism-case-queue:FETCH_ASSIGNED_PLAGIARISM_CASES_REJECTED',
  FETCH_COMPLETED_PLAGIARISM_CASES_START: 'plagiarism-case-queue:FETCH_COMPLETED_PLAGIARISM_CASES_START',
  FETCH_COMPLETED_PLAGIARISM_CASES_FULFILLED: 'plagiarism-case-queue:FETCH_COMPLETED_PLAGIARISM_CASES_FULFILLED',
  FETCH_COMPLETED_PLAGIARISM_CASES_REJECTED: 'plagiarism-case-queue:FETCH_COMPLETED_PLAGIARISM_CASES_REJECTED',
  CLEAR_COMPLETED_PLAGIARISM_CASES: 'plagiarism-case-queue:CLEAR_COMPLETED_PLAGIARISM_CASES',
  REFRESH_PLAGIARISM_CASE_REQUESTS_START: 'plagiarism-case-queue:REFRESH_PLAGIARISM_CASE_REQUESTS_START',
  REFRESH_PLAGIARISM_CASE_REQUESTS_FULFILLED: 'plagiarism-case-queue:REFRESH_PLAGIARISM_CASE_REQUESTS_FULFILLED',
  REFRESH_PLAGIARISM_CASE_REQUESTS_REJECTED: 'plagiarism-case-queue:REFRESH_PLAGIARISM_CASE_REQUESTS_REJECTED',
  UPDATE_PLAGIARISM_CASE_REQUESTS_START: 'plagiarism-case-queue:UPDATE_PLAGIARISM_CASE_REQUESTS_START',
  UPDATE_PLAGIARISM_CASE_REQUESTS_FULFILLED: 'plagiarism-case-queue:UPDATE_PLAGIARISM_CASE_REQUESTS_FULFILLED',
  UPDATE_PLAGIARISM_CASE_REQUESTS_REJECTED: 'plagiarism-case-queue:UPDATE_PLAGIARISM_CASE_REQUESTS_REJECTED',
  JOIN_PLAGIARISM_CASE_QUEUE_START: 'plagiarism-case-queue:JOIN_PLAGIARISM_CASE_QUEUE_START',
  JOIN_PLAGIARISM_CASE_QUEUE_FULFILLED: 'plagiarism-case-queue:JOIN_PLAGIARISM_CASE_QUEUE_FULFILLED',
  JOIN_PLAGIARISM_CASE_QUEUE_REJECTED: 'plagiarism-case-queue:JOIN_PLAGIARISM_CASE_QUEUE_REJECTED',
  LEAVE_PLAGIARISM_CASE_QUEUE_START: 'plagiarism-case-queue:LEAVE_PLAGIARISM_CASE_QUEUE_START',
  LEAVE_PLAGIARISM_CASE_QUEUE_FULFILLED: 'plagiarism-case-queue:LEAVE_PLAGIARISM_CASE_QUEUE_FULFILLED',
  LEAVE_PLAGIARISM_CASE_QUEUE_REJECTED: 'plagiarism-case-queue:LEAVE_PLAGIARISM_CASE_QUEUE_REJECTED',
};

export default {
  bootstrap() {
    return createAction(actionTypes.BOOTSTRAP_PLAGIARISM_CASE_QUEUE_START);
  },
  fetchCompletedPlagiarismCases(pageParams: CompletedPlagiarismCasePageParams) {
    return createAction(actionTypes.FETCH_COMPLETED_PLAGIARISM_CASES_START, pageParams);
  },
  clearCompletedPlagiarismCases() {
    return createAction(actionTypes.CLEAR_COMPLETED_PLAGIARISM_CASES);
  },
  refreshRequests() {
    return createAction(actionTypes.REFRESH_PLAGIARISM_CASE_REQUESTS_START);
  },
  updateRequests(projectIds: number[]) {
    return createAction(actionTypes.UPDATE_PLAGIARISM_CASE_REQUESTS_START, projectIds);
  },
  joinQueue(projectIds: number[]) {
    return createAction(actionTypes.JOIN_PLAGIARISM_CASE_QUEUE_START, projectIds);
  },
  leaveQueue() {
    return createAction(actionTypes.LEAVE_PLAGIARISM_CASE_QUEUE_START);
  },
};
