import React from 'react';
import queryString from 'query-string';
import _sortBy from 'lodash/sortBy';
import _range from 'lodash/range';
import _get from 'lodash/get';
import _omitBy from 'lodash/omitBy';
import _intersection from 'lodash/intersection';

import { ASC, CheckboxConfig, DataTableColumn, DESC, Orders, PaginationQueryParams } from './types';
import { useHistory } from 'react-router';

export function getVisibleData<T>({
  data,
  isApiPaginated,
  columns,
  pageSize,
  pageIndex,
  orderBy,
  order,
}: {
  data: T[];
  isApiPaginated: boolean;
  pageSize: number;
  columns: DataTableColumn<T>[];
  pageIndex: number;
  orderBy: string | undefined;
  order: Orders | undefined;
}): T[] {
  let result = data;
  if (isApiPaginated) return result;

  const startIndex = pageIndex * pageSize;

  result = sortData({ data, columns, order, orderBy });

  return result.slice(startIndex, startIndex + pageSize);
}

function sortData<T>({
  data,
  columns,
  orderBy,
  order,
}: {
  data: T[];
  columns: DataTableColumn<T>[];
  orderBy: string | undefined;
  order: Orders | undefined;
}): T[] {
  let result = data;
  if (orderBy) {
    const sortColumn = columns.find((col) => col.id === orderBy);
    const comparator = (item: T): string | number => {
      const itemRow = sortColumn?.getRow(item);
      const itemData = itemRow?.sortValue || itemRow?.data;

      return itemData || '';
    };

    result = _sortBy(data, comparator);
    if (order === DESC) result.reverse();
  }
  return result;
}

export function getNumberOfPages(totalRows: number, pageSize: number) {
  return Math.ceil(totalRows / pageSize);
}

export const RANGE_ELLIPSIS = -1;

export function getTruncatedRange(numberOfPages: number, currentPage: number): number[] {
  if (numberOfPages <= 1) return [];
  // returns an array of numbers indicating the pages to be linked to. A `-1`
  // indicates an ellipsis

  // always return the first page
  let numbers = [1];

  if (currentPage <= 3) {
    // if on an early page, include the first 4 pages
    const max = Math.min(numberOfPages, 5);
    numbers = numbers.concat(_range(2, max));
    if (numberOfPages > max) {
      numbers.push(RANGE_ELLIPSIS);
    }
  } else if (currentPage >= numberOfPages - 2) {
    // if on a late page, return the last 4 pages
    const min = numberOfPages > 2 ? numberOfPages - 2 : 2;
    if (min > 2) {
      numbers.push(RANGE_ELLIPSIS);
    }
    numbers = numbers.concat(_range(Math.max(2, min - 1), numberOfPages + 1));
    return numbers;
  } else {
    // return the current page and adjacent ones surrounded by ellipses
    numbers.push(RANGE_ELLIPSIS);
    numbers = numbers.concat([currentPage - 1, currentPage, currentPage + 1]);
    numbers.push(RANGE_ELLIPSIS);
  }

  // always include the last page
  numbers.push(numberOfPages);
  return numbers;
}

export type InternalTableState = {
  pageIndex: number;
  pageSize: number;
  order: Orders;
  orderBy: string | undefined;
  handleSort: (newOrderBy: string, newOrder: Orders) => void;
  handleGoToPage: (newPageNumber: number) => void;
  handleChangePageSize: (newPageSize: number) => void;
};

export function getInternalTableState({
  internalPageIndex,
  internalPageSize,
  internalSortColumn,
  internalSortOrder,
  setInternalPageIndex,
  setInternalPageSize,
  setInternalSortColumn,
  setInternalSortOrder,
}: {
  internalPageSize: number;
  internalPageIndex: number;
  internalSortOrder: Orders;
  internalSortColumn: string | undefined;
  setInternalPageIndex: (idx: number) => void;
  setInternalPageSize: (idx: number) => void;
  setInternalSortColumn: (col: string) => void;
  setInternalSortOrder: (order: Orders) => void;
}): InternalTableState {
  const handleSort = (newOrderBy: string, newOrder: Orders) => {
    setInternalPageIndex(0);
    setInternalSortColumn(newOrderBy);
    setInternalSortOrder(newOrder);
  };

  const handleGoToPage = (newPageNumber: number) => {
    setInternalPageIndex(newPageNumber - 1);
  };

  const handleChangePageSize = (newPageSize: number) => {
    setInternalPageIndex(0);
    setInternalPageSize(newPageSize);
  };

  return {
    pageSize: internalPageSize,
    pageIndex: internalPageIndex,
    order: internalSortOrder,
    orderBy: internalSortColumn,
    handleSort,
    handleGoToPage,
    handleChangePageSize,
  };
}

export function getTableState({
  defaultPageSize,
  history,
  paginationSuffix,
}: {
  defaultPageSize: number;
  history: ReturnType<typeof useHistory>;
  paginationSuffix: string;
}): InternalTableState {
  const pageSizeParam = `${PaginationQueryParams.PageSize}${paginationSuffix}`;
  const pageParam = `${PaginationQueryParams.Page}${paginationSuffix}`;
  const orderParam = `${PaginationQueryParams.Order}${paginationSuffix}`;
  const orderByParam = `${PaginationQueryParams.OrderBy}${paginationSuffix}`;
  const params = getQueryParams();

  const pageSize = Number(params[pageSizeParam]) || defaultPageSize;
  const pageIndex = (Number(params[pageParam]) || 1) - 1;
  const order = (params[orderParam] as Orders | undefined) || ASC;
  const orderBy = params[orderByParam] as string | undefined;

  const handleSort = (newOrderBy: string, newOrder: Orders) => {
    const query: Record<string, string | false> = {
      [orderByParam]: newOrderBy,
      [pageParam]: false,
      [orderParam]: newOrder,
    };

    updateQueryParams(history, query);
  };

  const handleGoToPage = (newPageNumber: number) => {
    const query = {
      ...(params as Record<string, string>),
      [pageParam]: String(newPageNumber),
    };
    updateQueryParams(history, query);
  };

  const handleChangePageSize = (newPageSize: number) => {
    updateQueryParams(history, {
      [pageSizeParam]: String(newPageSize),
      [pageParam]: false,
    });
  };

  return {
    pageIndex,
    pageSize,
    order,
    orderBy,
    handleSort,
    handleGoToPage,
    handleChangePageSize,
  };
}

export function getTableColumnsLocalStorageKey(tableName: string): string {
  return `dataTableCustomColumns_${tableName}`;
}

export function getVisibleColumns<T>(
  columns: DataTableColumn<T>[],
  data: T[],
  id: string,
  checkboxConfig: CheckboxConfig | undefined
) {
  const tableColumns = [...columns];

  if (checkboxConfig) {
    tableColumns.unshift(getCheckboxColumn(checkboxConfig, data, id));
  }

  return {
    tableColumns,
  };
}

export function getCheckboxColumn<T>(checkboxConfig: CheckboxConfig, data: T[], name: string): DataTableColumn<T> {
  const { selectedIds, onSelectRow, onSelectAll, checkboxId, checkboxTitleId } = checkboxConfig;

  const selectedSet = new Set<string | number>(selectedIds);

  function handleSelectRow(e: React.ChangeEvent<HTMLInputElement>) {
    const id = e.target.value;
    const shouldSelect = e.target.checked;
    onSelectRow(id, shouldSelect);
  }

  function handleSelectAll(e: React.ChangeEvent<HTMLInputElement>) {
    const shouldSelect = e.target.checked;
    if (onSelectAll) {
      onSelectAll(shouldSelect);
    } else {
      data.map((item) => onSelectRow(item[checkboxId], shouldSelect));
    }
  }

  const currentPageSelectedIds = _intersection<string | number>(
    selectedIds,
    data.map((item) => item[checkboxId])
  );

  const shouldHeaderCheckboxBeChecked = data.length > 0 && currentPageSelectedIds.length === data.length;

  return {
    id: `checkbox-${name}`,
    disableSorting: true,
    title: (
      <input
        data-testid="select-all-table-checkbox"
        checked={shouldHeaderCheckboxBeChecked}
        onChange={handleSelectAll}
        type="checkbox"
      />
    ),
    width: '2rem',
    getRow: (data: T) => {
      const id = data[checkboxId];
      const isSelected = selectedSet.has(id);

      const title = determineCheckboxTitle({
        id,
        checkboxTitleId,
        isSelected,
        data,
      });

      return {
        data: title,
        component: (
          <input title={title} checked={isSelected} key={id} onChange={handleSelectRow} type="checkbox" value={id} />
        ),
      };
    },
  };
}

function determineCheckboxTitle<T>({
  checkboxTitleId,
  id,
  isSelected,
  data,
}: {
  checkboxTitleId?: string;
  id: string;
  isSelected: boolean;
  data: T;
}): string {
  return `${isSelected ? 'Deselect' : 'Select'} ${_get(data, checkboxTitleId || id)}`;
}

export function standardOnSelect<T>(selectedIds: T[], setSelectedIds: (ids: T[]) => void) {
  const handleSelect = (id: T, shouldSelect: boolean) => {
    const selectedSet = new Set(selectedIds);
    if (shouldSelect) {
      selectedSet.add(id);
    } else {
      selectedSet.delete(id);
    }
    setSelectedIds(Array.from(selectedSet));
  };
  return handleSelect;
}

export function standardOnSelectAll<T>(allIds: T[], selectedIds: T[], setSelectedIds: (ids: T[]) => void) {
  const handleSelectAll = (shouldSelect: boolean) => {
    if (shouldSelect) {
      const selectedSet = new Set(selectedIds);
      allIds.forEach((id) => selectedSet.add(id));
      setSelectedIds(Array.from(selectedSet));
    } else {
      setSelectedIds([]);
    }
  };
  return handleSelectAll;
}

export function getQueryParams(): {
  [key: string]: string;
} {
  const query = window.location.search;
  return queryString.parse(query) as {
    [key: string]: string;
  };
}

/*
    history.replace calls the @@router/LOCATION_CHANGE action
    which updates state.router.location.search
  */

export function updateQueryParams(history: ReturnType<typeof useHistory>, queryObj: Record<string, string | boolean>) {
  const parsedQuery = getQueryParams();
  const formattedQuery = _omitBy({ ...parsedQuery, ...queryObj }, (value: string | boolean) => {
    return !value;
  });

  const stringifiedQuery = queryString.stringify(formattedQuery);

  history.replace({
    pathname: window.location.pathname,
    search: stringifiedQuery,
  });
}
