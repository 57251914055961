import i18n from 'app/i18n';

export const AGREEMENT_STATUS_SIGNED = 'signed';
export const AGREEMENT_STATUS_PENDING_SIGNATURE = 'pending_signature';
export const AGREEMENT_STATUS_WITHDRAWN = 'withdrawn';

export const AGREEMENT_STATUS_OPTIONS = [
  { value: AGREEMENT_STATUS_SIGNED, label: i18n.t('agreements.signed') },
  {
    value: AGREEMENT_STATUS_PENDING_SIGNATURE,
    label: i18n.t('agreements.pendingSignature'),
  },
  { value: AGREEMENT_STATUS_WITHDRAWN, label: i18n.t('agreements.withdrawn') },
];

function findStatus(status: string): { value: string; label: string } | undefined {
  return AGREEMENT_STATUS_OPTIONS.find((option) => option.value === status);
}

export function getLabelForStatus(status: string): string {
  return findStatus(status)?.label || i18n.t('agreements.unknownStatus');
}
