import React from 'react';

import { Grid, GridProps } from '@chakra-ui/react';

export const UdsGridContainer: React.FC<GridProps> = ({ children, ...rest }) => {
  return (
    <Grid
      gridTemplateColumns={{
        base: 'repeat(4, 1fr)',
        md: 'repeat(8, 1fr)',
        lg: 'repeat(12, 1fr)',
      }}
      columnGap={{ base: 6, xl: 8 }}
      maxW="100rem"
      minH="100%"
      px={{ base: 5, md: 6, lg: 12, xl: '10rem' }}
      py={8}
      m="0 auto"
      {...rest}
    >
      {children}
    </Grid>
  );
};
