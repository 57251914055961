import CONFIG from 'configLoader';
import { QuestionQueueAssignment } from './question-queue-types';

export const alertCreators = {
  fetchQueueFailedToast: (reason) => ({
    id: 'question-queue-fetch-failed',
    type: 'error',
    message: `Could not fetch question queue state: ${reason}`,
  }),
  fetchAssignmentsFailedToast: (reason) => ({
    id: 'question-queue-assignments-fetch-failed',
    type: 'error',
    message: `Could not fetch question queue assignments: ${reason}`,
  }),
  fetchQueueHistoryFailedToast: (reason) => ({
    id: 'question-queue-history-fetch-failed',
    type: 'error',
    message: `Could not fetch question queue history: ${reason}`,
  }),
  fetchMonthlyEarningsSummaryFailedToast: (reason) => ({
    id: 'question-queue-monthly-earnings-fetch-failed',
    type: 'error',
    message: `Could not fetch question queue earnings summary: ${reason}`,
  }),
  joinQueueFailedToast: (reason) => ({
    id: 'join-question-queue-failed',
    type: 'error',
    message: `Could not join queue: ${reason}`,
  }),
  leaveQueueFailedToast: (reason) => ({
    id: 'leave-question-queue-failed',
    type: 'error',
    message: `Could not leave queue: ${reason}`,
  }),
  newQuestionsAssignedToast: () => ({
    id: 'new-question-queue-assignments',
    type: 'notification',
    message: 'You have been assigned a new question!',
  }),
  newQuestionsAssignedNotification: () => ({
    title: 'New assignment!',
    body: 'You have been assigned a new question!',
    linkTo: `${CONFIG.dashboardUrl}/queue/overview`,
  }),
};

export function hasNewAssignments(previous: QuestionQueueAssignment[], current: QuestionQueueAssignment[]): boolean {
  const previousQuestionIds = previous.map((assignment) => assignment.questionID);
  return current.some((assignment) => {
    return !previousQuestionIds.includes(assignment.questionID);
  });
}
