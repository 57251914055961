import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { handleAuthorization } from 'app/helpers/auth-helper';
import { Prompt } from 'app/common/views/prompt';
import NotFound from 'app/common/views/not-found';
import { AppContainer } from './app-container';
import { QueueApp } from 'app/queue/views';
import { Handbook } from 'app/handbook';
import { AwaitingApproval } from 'app/common/views/awaiting-approval';
import { LoadingPage } from 'app/common/views/loading-page';
import { TermsOfService } from 'app/common/views/terms-of-service';
import { MentorshipApp } from 'app/mentorship/scenes/app';
import { ProfileEditorContainer } from 'app/common/views/profile-editor-container';
import { SpecializedMentor } from './specialized-mentor/guidelines';
import { ProjectReviews } from './project-reviews';
import { ConnectLayout, ConnectRoutes } from './uconnect';

export const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/robots.txt"
          render={() => {
            window.location.reload();
            return null;
          }}
        />

        <Route path="/loading">
          <LoadingPage />
        </Route>

        <Route
          exact
          path="/"
          render={() => {
            handleAuthorization();
            return <AppContainer />;
          }}
        />

        <Route
          path="/"
          render={() => {
            handleAuthorization();
            return (
              <AppContainer>
                <Switch>
                  <Route path="/dashboard*">
                    <Redirect to="/" />
                  </Route>

                  <Route path="/mentorship">
                    <MentorshipApp />
                  </Route>

                  <Route path="/guidelines">
                    <Handbook />
                  </Route>

                  <Route
                    path="/handbook/:article"
                    render={({ match }) => {
                      return <Redirect to={`/guidelines/${match.params.article}`} />;
                    }}
                  />

                  <Route path="/reviews*">
                    <Redirect to="queue/overview" />
                  </Route>

                  <Route path="/queue">
                    <QueueApp />
                  </Route>

                  <Route path="/specialized-mentor">
                    <SpecializedMentor />
                  </Route>

                  <Route path="/prompt">
                    <Prompt />
                  </Route>

                  <Route path="/apply">
                    <ProfileEditorContainer />
                  </Route>
                  <Route path="/update">
                    <ProfileEditorContainer />
                  </Route>

                  <Route path="/in-review">
                    <AwaitingApproval type="apply" />
                  </Route>
                  <Route path="/updated">
                    <AwaitingApproval type="update" />
                  </Route>
                  <Route path="/reviewer-agreement">
                    <TermsOfService />
                  </Route>

                  <Route path="/terms-of-service">
                    <Redirect to="reviewer-agreement" />
                  </Route>

                  <Route path="/project-reviews/:submissionId">
                    <ProjectReviews />
                  </Route>

                  <Route path="/connect">
                    <ConnectLayout />
                  </Route>

                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              </AppContainer>
            );
          }}
        />
      </Switch>
    </BrowserRouter>
  );
};
