import _ from 'lodash';
// https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API

export default class BrowserNotification {
  constructor(NotificationObj) {
    this.Notification = NotificationObj;
  }

  isNotificationPermissionGranted() {
    return this.Notification.permission === 'granted';
  }

  isNotificationPermissionDenied() {
    return this.Notification.permission === 'denied';
  }

  requestNotificationPermission(allowCallback, deniedCallback) {
    if (!this.isNotificationPermissionGranted() && !this.isNotificationPermissionDenied()) {
      this.handleNotificationPermission(allowCallback, deniedCallback);
    }
  }

  handleNotificationPermission(allowCallback, deniedCallback) {
    this.Notification.requestPermission((permission) => {
      if (!('permission' in this.Notification)) {
        this.Notification.permission = permission;
      }
      if (this.isNotificationPermissionGranted() && allowCallback) {
        allowCallback();
      } else if (this.isNotificationPermissionDenied() && deniedCallback) {
        deniedCallback();
      }
    });
  }

  notify(content) {
    if (this.isNotificationPermissionGranted()) {
      this.sendNotify(content);
    }
  }

  sendNotify({ body, title, duration = 5000, linkTo }) {
    if (_.isEmpty(body) || _.isEmpty(title)) {
      throw Error('Notification must have a body and title');
    }

    const notification = new Notification(title, {
      body,
      icon: 'src/assets/images/udacity-logo.png',
      tag: 'uReviewTag',
    });

    if (linkTo) {
      notification.onclick = (evt) => {
        evt.preventDefault();
        window.open(linkTo, '_blank');
        notification.close();
      };
    }

    setTimeout(() => notification.close(), duration);
  }
}
