import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import auth from '@udacity/ureact-hoth';

import sessionActions from 'app/common/domains/session/session-actions';
import { getSession } from 'app/common/domains/session/session-selectors';
import useApplicationRedirect from 'app/common/views/application-redirect';
import { ProfileEditor } from 'app/common/views/profile-editor';
import { EditorType, FormFields } from 'app/common/views/profile-editor/form-types';
import { REQUIRED_FIELDS } from 'app/common/views/profile-editor/form-helper';
import { useAppDispatch } from 'app/store';

export const ProfileEditorContainer: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const session = useSelector((state) => getSession(state));

  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  useApplicationRedirect(pathname, session);

  const handleSubmitWithCreate = async (form: FormFields, path: string) => {
    try {
      setIsLoading(true);
      await dispatch(sessionActions.postCreate(form));
      history.push(path);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitWithUpdate = async (form: FormFields, path: string) => {
    try {
      setIsLoading(true);
      await dispatch(sessionActions.postUpdate(form));
      history.push(path);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  switch (pathname) {
    case '/specialized-mentor/apply': {
      const appliedForSpecialized = _get(session, ['application', 'services'], []).includes('government_mentor');
      // Has mentor account but has not applied for specialized mentor
      if (session.has_mentor_account && !appliedForSpecialized) {
        return (
          <ProfileEditor
            editorType={EditorType.AbbreviatedSpecialized}
            session={{
              ...session,
              uid: session.uid || auth.getCurrentUserId(),
            }}
            handleSubmit={(form: FormFields) => {
              handleSubmitWithUpdate(form, '/in-review');
            }}
            requiredFields={REQUIRED_FIELDS.abbreviatedSpecialized}
            isLoading={isLoading}
          />
        );
      } else {
        // Does not have a mentor account and wants to apply for specialized mentor
        return (
          <ProfileEditor
            editorType={EditorType.Specialized}
            session={{
              ...session,
              uid: session.uid || auth.getCurrentUserId(),
            }}
            handleSubmit={(form: FormFields) => {
              handleSubmitWithCreate(form, '/in-review');
            }}
            requiredFields={REQUIRED_FIELDS.specialized}
            isLoading={isLoading}
          />
        );
      }
    }
    case '/specialized-mentor/update': {
      // Has a specialized mentor account
      return (
        <ProfileEditor
          editorType={EditorType.Specialized}
          session={{
            ...session,
            uid: session.uid || auth.getCurrentUserId(),
          }}
          handleSubmit={(form: FormFields) => {
            handleSubmitWithUpdate(form, '/in-review');
          }}
          requiredFields={REQUIRED_FIELDS.specialized}
          isLoading={isLoading}
        />
      );
    }
    case '/update': {
      // Has a mentor account
      return (
        <ProfileEditor
          editorType={EditorType.Update}
          session={{ ...session, uid: session.uid || auth.getCurrentUserId() }}
          handleSubmit={(form: FormFields) => {
            handleSubmitWithUpdate(form, '/queue/certifications');
          }}
          requiredFields={REQUIRED_FIELDS.update}
          isLoading={isLoading}
        />
      );
    }
    default: {
      // Does not have a mentor account and wants to apply for a standard mentor
      return (
        <ProfileEditor
          editorType={EditorType.Standard}
          session={{ ...session, uid: session.uid || auth.getCurrentUserId() }}
          handleSubmit={(form: FormFields) => {
            handleSubmitWithCreate(form, '/queue/certifications');
          }}
          requiredFields={REQUIRED_FIELDS.standard}
          isLoading={isLoading}
        />
      );
    }
  }
};
