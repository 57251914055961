import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _sortBy from 'lodash/sortBy';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  IconButton,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { Select, SingleValue } from 'chakra-react-select';
import { ThumbDownIcon, ThumbUpIcon } from '@udacity/chakra-uds-icons';
import { getChakraReactSelectStyles } from '@udacity/chakra-uds-theme';
import { Markdown } from '@udacity/ureact-markdown';

import {
  useFetchContentsQuery,
  useFetchCritiquesQuery,
  useFetchRatingQuery,
  useSubmitRatingMutation,
} from 'app/apis/reviews';
import { UDS_GLYPH_URL } from 'app/apis/service-paths';
import { AsymmetricCard } from 'app/common/components/asymmetric-card';
import { selectReviewerId } from 'app/common/domains/session/session-selectors';
import { Option } from 'app/helpers/form-helper';
import { getTimeSince } from 'app/project-reviews/helpers';
import { Rubric, Submission } from 'app/types/reviews';

import { AnnotationsPanel } from './annotations-panel';
import { ProjectReviewPanel } from './project-review-panel';
import { CodeReviewPanel } from './code-review-panel';

type HistoryProps = {
  currentSubmissionId: number;
  allSubmissions?: Submission[];
  rubric?: Rubric;
  isAssigned: boolean;
};

export const History: React.FC<HistoryProps> = ({ currentSubmissionId, allSubmissions, rubric, isAssigned }) => {
  const [submission, setSubmission] = useState<Submission | null>(null);
  const [vote, setVote] = useState(0);
  const [ratingFeedback, setRatingFeedback] = useState('');
  const [hasSubmittedRating, setHasSubmittedRating] = useState(false);
  const currentReviewerUserId = useSelector(selectReviewerId);

  const { t } = useTranslation();

  const { data: critiques } = useFetchCritiquesQuery(submission?.id ? submission.id : skipToken);
  const { data: contents } = useFetchContentsQuery(submission?.id ? submission.id : skipToken);
  const { data: rating } = useFetchRatingQuery(submission?.id ? submission.id : skipToken);
  const [submitRating] = useSubmitRatingMutation();

  const previousReviews = useMemo(
    () =>
      allSubmissions ? _sortBy(allSubmissions, 'id').filter((s) => !isAssigned || s.id !== currentSubmissionId) : [],
    [allSubmissions, isAssigned, currentSubmissionId]
  );

  useEffect(() => {
    setSubmission(previousReviews[0]);
  }, [previousReviews]);

  const options = previousReviews.map((s, index) => ({
    value: String(s.id),
    label:
      s.graderId === currentReviewerUserId
        ? t('projectReviews.history.reviewNumberYours', { index: index + 1 })
        : t('projectReviews.history.reviewNumber', { index: index + 1 }),
  }));
  const selectedReview = options.find((opt) => !!submission && opt.value === String(submission.id));

  const timeSinceLastReviewed = useMemo(() => {
    return submission?.completedAt ? getTimeSince(submission.completedAt) : null;
  }, [submission]);

  const hasAnnotations = useMemo(() => {
    if (submission?.url) return true;

    if (contents)
      return contents.some((c) => {
        const ext = c.path.toLowerCase().split('.').pop();
        return !!ext && ['pdf', 'docx'].includes(ext) && !!submission?.annotationsUrl;
      });

    return false;
  }, [submission?.url, submission?.annotationsUrl, contents]);

  const nonAnnotationContents = useMemo(() => {
    if (contents)
      return contents.filter((c) => {
        const ext = c.path.toLowerCase().split('.').pop();
        return !!ext && !['pdf', 'docx'].includes(ext);
      });

    return [];
  }, [contents]);

  const showRating = useMemo(
    () => !rating?.value && submission?.graderId !== currentReviewerUserId,
    [rating, submission?.graderId, currentReviewerUserId]
  );

  const onOptionChange = (newValue: SingleValue<Option>) => {
    if (newValue) {
      const newSubmission = allSubmissions?.find((s) => s.id === Number(newValue.value));

      if (newSubmission) {
        setSubmission(newSubmission);
      }
    }
  };

  if (!submission) return null;

  const onSubmitRating = async () => {
    try {
      await submitRating({
        submissionId: submission.id,
        value: vote,
        feedback: ratingFeedback,
      });
      setHasSubmittedRating(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <GridItem gridColumn={{ base: '3 / -1', lg: '4 / -3' }}>
        <Heading size="h1" as="h1" mb={2}>
          {t('projectReviews.history.history')}
        </Heading>
        <Text mb={10}>{t('projectReviews.history.youMayReference')}</Text>

        <Heading size="h3" as="h3" mb={isAssigned ? 0 : 2}>
          {t('projectReviews.history.previousReviews')}
        </Heading>
        {isAssigned && (
          <Text mb={6}>
            {t('projectReviews.history.youAreCurrently', {
              count: previousReviews.length + 1,
            })}
          </Text>
        )}

        <FormControl w={{ base: 'full', lg: '57%' }}>
          <FormLabel hidden>{t('projectReviews.history.previousReviews')}</FormLabel>
          <Box mb={timeSinceLastReviewed ? 2 : 6}>
            <Select<Option>
              aria-label={t('projectReviews.history.previousReviews')}
              chakraStyles={getChakraReactSelectStyles()}
              options={options}
              value={selectedReview}
              onChange={onOptionChange}
              isClearable={false}
            />
          </Box>
          {timeSinceLastReviewed && (
            <Text size="caption" mb={4}>
              {t('projectReviews.history.reviewedTimeSince', {
                timeSince: timeSinceLastReviewed,
              })}
            </Text>
          )}
        </FormControl>

        <Button
          as="a"
          variant="outline"
          href={submission.archiveUrl || submission.url || undefined}
          target="_blank"
          rel="noopener noreferrer"
          mb={10}
        >
          {submission.url ? t('projectReviews.feedback.projectLink') : t('projectReviews.feedback.downloadProject')}
        </Button>

        {submission.studentNote && (
          <AsymmetricCard bgColor="white" gap={2} mb={10}>
            <Image src={`${UDS_GLYPH_URL}/chat-bubbles-chat-bubbles-one.svg`} alt="" w={10} h={10} />
            <Flex direction="column">
              <Text size="label" mb={4}>
                {t('projectReviews.feedback.studentNote')}
              </Text>
              <Markdown markdownText={submission.studentNote} />
            </Flex>
          </AsymmetricCard>
        )}
      </GridItem>

      <GridItem gridColumn={{ base: '3 / -1', lg: '4 / -1' }}>
        <Tabs mb={10}>
          <TabList>
            <Tab>{t('projectReviews.projectReview.projectReview')}</Tab>
            {!!nonAnnotationContents.length && !submission.resultReason && (
              <Tab>{t('projectReviews.codeReview.codeReview')}</Tab>
            )}
            {hasAnnotations && <Tab>{t('projectReviews.annotations.fileAnnotations')}</Tab>}
          </TabList>

          <TabPanels>
            <TabPanel p={0} pt={10}>
              <ProjectReviewPanel rubric={rubric} critiques={critiques} submission={submission} isAssigned={false} />
            </TabPanel>

            {!!nonAnnotationContents.length && !submission.resultReason && (
              <TabPanel p={0} pt={10}>
                <CodeReviewPanel contents={nonAnnotationContents} submissionId={submission.id} isAssigned={false} />
              </TabPanel>
            )}

            {hasAnnotations && (
              <TabPanel p={0} py={10}>
                <AnnotationsPanel annotationsUrl={submission.annotationsUrl} isAssigned={false} />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </GridItem>

      {showRating && (
        <GridItem gridColumn={{ base: '3 / -1', lg: '4 / 9' }}>
          <Heading size="h5" as="h5" mb={2}>
            {t('projectReviews.rating.rating')}
          </Heading>

          <Flex
            direction="column"
            gap={4}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="base"
            bgColor="white"
            mb={6}
            py={3}
            px={4}
          >
            {hasSubmittedRating && <Text size="label">{t('projectReviews.rating.thankYou')}</Text>}
            {!hasSubmittedRating && (
              <>
                <Text size="label">{t('projectReviews.rating.howWasThisReview')}</Text>
                <Flex align="center" gap={2}>
                  <IconButton
                    aria-label={t('projectReviews.rating.upVote')}
                    variant="outline"
                    size="icon"
                    icon={<ThumbUpIcon w={8} h={8} />}
                    isRound
                    color={vote === 1 ? 'white' : 'unset'}
                    bgColor={vote === 1 ? 'blue.500' : 'unset'}
                    onClick={() => setVote(1)}
                  />

                  <IconButton
                    aria-label={t('projectReviews.rating.downVote')}
                    variant="outline"
                    size="icon"
                    colorScheme="blue"
                    icon={<ThumbDownIcon w={8} h={8} />}
                    isRound
                    color={vote === -1 ? 'white' : 'unset'}
                    bgColor={vote === -1 ? 'blue.500' : 'unset'}
                    onClick={() => setVote(-1)}
                  />
                </Flex>
              </>
            )}
          </Flex>

          {!!vote && !hasSubmittedRating && (
            <>
              <FormControl mb={6}>
                <FormLabel mb={2}>{t('projectReviews.rating.pleaseAddAdditional')}</FormLabel>
                <Textarea value={ratingFeedback} onChange={(e) => setRatingFeedback(e.target.value)} rows={8} />
              </FormControl>

              <Button variant="outline" onClick={onSubmitRating} w="max-content">
                {t('common.submit')}
              </Button>
            </>
          )}
        </GridItem>
      )}
    </>
  );
};
