import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import _isArray from 'lodash/isArray';
import _map from 'lodash/map';

import {
  getSession,
  getMentorAccountStatus,
  getReviewerCertifiedStatus,
} from 'app/common/domains/session/session-selectors';
import { MentorTypeCard } from 'app/common/components/dashboard-switcher/mentor-type-card';
import dashboardHelper from 'app/helpers/dashboard-helper';

import styles from './dashboard-switcher.module.scss';

export const DashboardSwitcher: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const services = useSelector((state) => getSession(state).services);
  const hasMentorAccount = useSelector((state) => getMentorAccountStatus(state));
  const isReviewsCertified = useSelector((state) => getReviewerCertifiedStatus(state));

  const navigateToService = (service: string) => {
    history.push(dashboardHelper.getRouteForService(service));
  };

  const autoRedirect = () => {
    const enabledServices = dashboardHelper.getEnabledServices({
      ...services,
      opportunities: hasMentorAccount,
    });

    const redirectTo = dashboardHelper.selectServiceForRedirection(enabledServices, isReviewsCertified);

    if (!_isArray(redirectTo)) {
      navigateToService(redirectTo ?? '');
    }
  };

  useEffect(() => {
    autoRedirect();
  }, []);

  return (
    <section className={styles['switcher-container']}>
      <h1>{t('common.welcome')}</h1>
      <p>{t('selectADashboard')}</p>
      <div className={styles['card-container']}>
        {_map(services, (enabled, serviceName) => {
          return enabled ? <MentorTypeCard key={serviceName} type={serviceName} /> : null;
        })}
      </div>
    </section>
  );
};
