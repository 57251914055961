import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { CaretDownIcon, CaretUpIcon } from '@udacity/chakra-uds-icons';
import { Checkbox, Grid, GridItem, IconButton, Spinner } from '@chakra-ui/react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { REVIEWER_STATUSES } from 'app/queue/domains/recruitment-families/recruitment-families-constants';
import { STATUSES as ASYNC_STATUSES } from 'app/common/domains/async';

import styles from './recruitment-family-item.module.scss';

export class RecruitmentFamilyItem extends Component {
  static propTypes = {
    family: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    asyncStatus: PropTypes.string,
    isDisabled: PropTypes.bool,
  };

  state = {
    showDescription: false,
  };

  handleDescriptionToggle = () => {
    const { showDescription } = this.state;

    this.setState({ showDescription: !showDescription });
  };

  render() {
    const { family, asyncStatus, handleChange, isDisabled, t } = this.props;
    const { showDescription } = this.state;

    const reapplyTimestamp = new Date(family.reapply_at);

    const isRejected = family.status === REVIEWER_STATUSES.REJECTED && reapplyTimestamp > Date.now();

    return (
      <Grid key={family.id} as="li" py={2} gridTemplateColumns="max-content 1fr" gridColumnGap={3} alignItems="center">
        <GridItem>
          {asyncStatus === ASYNC_STATUSES.PENDING ? (
            <div className={styles['loading-wrapper']}>
              <Spinner size="sm" />
            </div>
          ) : (
            <Checkbox
              id={`family${family.id}`}
              value={`${family.id}`}
              onChange={handleChange}
              isChecked={family.status === REVIEWER_STATUSES.ACTIVE}
              isDisabled={isRejected || isDisabled}
              fontWeight="semibold"
              alignItems="start"
            >
              {family.name}
            </Checkbox>
          )}
        </GridItem>
        <GridItem>
          <IconButton
            variant="ghost"
            w="max-content"
            aria-label={
              showDescription
                ? t('mentorProfile.collapseFamily', { familyName: family.name })
                : t('mentorProfile.expandFamily', { familyName: family.name })
            }
            icon={showDescription ? <CaretUpIcon w={10} h={10} /> : <CaretDownIcon w={10} h={10} />}
            onClick={this.handleDescriptionToggle}
          />
        </GridItem>

        <GridItem colSpan={2}>
          {showDescription && (
            <div
              data-testid="description"
              className={styles['family-description']}
              dangerouslySetInnerHTML={{ __html: family.description }}
            />
          )}
          {isRejected && (
            <div data-testid="rejection-message" className={styles['reapply-message']}>
              {t('mentorProfile.rejected')}{' '}
              {t('mentorProfile.eligibleToReapply', {
                date: format(reapplyTimestamp, 'MMMM Do, YYYY'),
              })}
            </div>
          )}
        </GridItem>
      </Grid>
    );
  }
}

export default withTranslation()(RecruitmentFamilyItem);
