import React from 'react';
import DatePicker from 'react-datepicker';
import { Flex, IconButton, Text } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@udacity/chakra-uds-icons';
import { useTranslation } from 'react-i18next';

import { dateFormatter } from 'app/helpers/format-helper';

import './meeting-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs, { Dayjs } from 'dayjs';

type MeetingCalendarProps = {
  onSelectMonth: (date: Dayjs) => void;
  onSelectDate: (date: Date) => void;
  datesToHighlight: string[];
};

export const MeetingCalendar: React.FC<MeetingCalendarProps> = ({ onSelectMonth, onSelectDate, datesToHighlight }) => {
  const { t } = useTranslation();

  const highlightedDates = datesToHighlight.map((d) => new Date(d));

  return (
    <DatePicker
      fixedHeight
      inline
      highlightDates={highlightedDates}
      onChange={onSelectDate}
      renderCustomHeader={({ decreaseMonth, increaseMonth, date }) => {
        function handleIncrease() {
          onSelectMonth(dayjs(date).add(1, 'month'));
          increaseMonth();
        }
        function handleDecrease() {
          onSelectMonth(dayjs(date).subtract(1, 'month'));
          decreaseMonth();
        }
        return (
          <Flex justify="space-between" align="center" px={4} pt={2}>
            <IconButton
              icon={<ArrowLeftIcon h={6} w={6} />}
              variant="outline"
              borderRadius="100%"
              h="48px"
              w="48px"
              aria-label={t('connect.prevMonth')}
              onClick={handleDecrease}
            />
            <Text as="span" fontWeight="600" fontSize="1.25rem">
              {dateFormatter.monthAndYear(date)}
            </Text>
            <IconButton
              icon={<ArrowRightIcon h={6} w={6} />}
              variant="outline"
              borderRadius="100%"
              h="48px"
              w="48px"
              aria-label={t('connect.nextMonth')}
              onClick={handleIncrease}
            />
          </Flex>
        );
      }}
    />
  );
};
