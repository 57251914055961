import { countries } from 'country-data';
import CONFIG from 'configLoader';
import ISO6391 from 'iso-639-1';

const payoneerSupportedCountryNames = [
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua And Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia, Plurinational State Of',
  'Bonaire, Saint Eustatius And Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'British Indian Ocean Territory',
  'British Virgin Islands',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Myanmar',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Democratic Republic Of Congo',
  'Republic Of Congo',
  'Cook Islands',
  'Costa Rica',
  "Côte d'Ivoire",
  'Croatia',
  'Curacao',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Timor-Leste, Democratic Republic of',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'Gabon',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-bissau',
  'Guyana',
  'Haiti',
  'Vatican City State',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iraq',
  'Ireland',
  'Isle Of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, Republic Of',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Macedonia, The Former Yugoslav Republic Of',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint Barthélemy',
  'Saint Helena, Ascension And Tristan Da Cunha',
  'Saint Kitts And Nevis',
  'Saint Lucia',
  'Saint Martin',
  'Saint Pierre And Miquelon',
  'Saint Vincent And The Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'Tunisia',
  'South Africa',
  'Spain',
  'Sri Lanka',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic Of',
  'Thailand',
  'Bahamas',
  'Gambia',
  'Togo',
  'Tonga',
  'Trinidad And Tobago',
  'Turkey',
  'Turkmenistan',
  'Turks And Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela, Bolivarian Republic Of',
  'Viet Nam',
  'Virgin Islands (US)',
  'Virgin Islands (British)',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

const embargoedCountries = [
  'SY', // Syria
  'IR', // Iran
  'KP', // North Korea
  'SS', // South Sudan
  'SD', // Sudan
  'BA', // Bosnia & Herzegovina
  'IQ', // Iraq
  'LB', // Lebanon
  'LY', // Libya
  'BU', // Burma
  'CF', // Central African Republic
  'CU', // Cuba
];

export const commonLanguages = [
  { code: 'ar', name: 'Arabic (Modern Standard)', value: 'ar' },
  // { code: 'bn', name: 'Bengali' },
  { code: 'zh_cn', name: 'Chinese', value: 'zh_cn' },
  { code: 'en-us', name: 'English', value: 'en-us' },
  { code: 'fr', name: 'French', value: 'fr' },
  { code: 'de', name: 'German', value: 'de' },
  // { code: 'gu', name: 'Gujarati' },
  // { code: 'he', name: 'Hebrew' },
  // { code: 'hi', name: 'Hindi' },
  // { code: 'it', name: 'Italian' },
  // { code: 'id', name: 'Indonesian' },
  { code: 'ja', name: 'Japanese', value: 'ja' },
  // { code: 'ko', name: 'Korean' },
  { code: 'pt-br', name: 'Portuguese', value: 'pt-br' },
  { code: 'ru', name: 'Russian', value: 'ru' },
  { code: 'es', name: 'Spanish', value: 'es' },
  // { code: 'tr', name: 'Turkish' },
  // { code: 'ur', name: 'Urdu' }
];

function getApplicableCountries() {
  const filtered = countries.all.filter((c) => {
    const isEmbargoed = embargoedCountries.includes(c.alpha2);
    return !isEmbargoed && c.status.includes('assigned');
    // c.status is referring to the ISO 3166 standard. Options are:
    // assigned - properly in ISO 3166
    // reserved - code is prevented from being used
    // deleted  - used to be in ISO 3166 but has been deleted
    // user assigned - for some use cases it is required
  });
  // sort alphabetically by name
  return filtered.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
}

export function getCountriesForDropdown() {
  /* returns options for selector with countries that don't support paypal
   * payments from the US as un-clickable options
   */
  const applicableCountries = getApplicableCountries();
  return applicableCountries.map((country) => {
    const isSupported = payoneerSupportedCountryNames.includes(country.name);
    return {
      value: country.alpha2,
      label: `${country.name} ${country.emoji}${isSupported ? '' : ' - no Payoneer support'}`,
      disabled: !isSupported,
    };
  });
}

export const nanodegree_list = {
  'AI for Trading': 'nd880',
  'AI Programming With Python': 'nd089',
  'Android Basics': 'nd803',
  'Android Developer': 'nd801',
  'Artificial Intelligence': 'nd898',
  'Blockchain Developer': 'nd1309',
  'Business Analytics': 'nd098',
  Careers: 'career',
  'Computer Vision': 'nd891',
  Cybersecurity: 'nd1337',
  'Data Analyst': 'nd002',
  'Data Scientist': 'nd025',
  'Deep Learning Foundations': 'nd101',
  'Deep Reinforcement Learning': 'nd893',
  'Design Sprint Foundations': 'nd201',
  'Digital Marketing': 'nd018',
  'Flying Car': 'nd787',
  'Front-End Web Developer': 'nd001',
  'Full-Stack Web Developer': 'nd004',
  'Google AdWords': 'nd124',
  'Google Analytics': 'nd253',
  'Intro to Programming': 'nd000',
  'Intro to Self Driving Car': 'nd113',
  'iOS Developer': 'nd003',
  'Learn Unreal VR': 'nd117',
  'Machine Learning Engineer': 'nd009',
  'Mobile Web Specialist': 'nd024',
  'Natural Language Processing': 'nd892',
  'Predictive Analytics': 'nd008t',
  'Programming for Data Science': 'nd104',
  React: 'nd019',
  Robotics: 'nd209',
  'Self Driving Car Engineer': 'nd013',
  'VR Developer': 'nd017',
};

export const mentorship_services = {
  'Classroom Mentor': 'classroom_mentorship',
  'Project Reviewer': 'reviews',
};

export const redirectToMentorAgreement = () => (window.location = CONFIG.mentorAgreementUrl);

// Specialized Mentor
export const specializedApprovedLanguages = [
  { code: 'en-us', name: 'English', value: 'en-us' },
  { code: 'es', name: 'Spanish', value: 'es' },
  { code: 'ar', name: 'Arabic (Modern Standard)' },
  { code: 'fr', name: 'French', value: 'fr' },
  { code: 'ru', name: 'Russian', value: 'ru' },
  { code: 'de', name: 'German', value: 'de' },
  { code: 'ko', name: 'Korean', value: 'ko' },
];

export type Option<T = string, K = string> = {
  label: T;
  value: K;
};

const allLanguageCodes: string[] = ISO6391.getAllCodes();

const codeToOption = (code: string): Option => {
  return {
    label: ISO6391.getName(code),
    value: code,
  };
};

export const getAllSelectLanguageOptions = () => {
  const selectLanguageOptions: Option[] = allLanguageCodes.map(codeToOption);
  const languageFilter: string[] = specializedApprovedLanguages.map(({ name }) => name);

  return selectLanguageOptions
    .filter((option) => !languageFilter.includes(option.label))
    .sort((a, b) => {
      const langA = a.label;
      const langB = b.label;
      if (langA < langB) {
        return -1;
      }
      if (langA > langB) {
        return 1;
      }

      return 0;
    });
};

export const getSelectLanguages = (codes: string[]) => {
  return getAllSelectLanguageOptions().filter(({ value }) => codes.includes(value));
};
