import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { createAction, createAsyncAction } from 'app/helpers/action-helper';
import auth from '@udacity/ureact-hoth';
import { gql, mentorQuery, createMentorMutation, updateMentorMutation } from 'app/services/mentor-api-service';
import { MENTOR_QUERY_OPERATION_NAME } from 'app/services/constants';
import { getCertifications, getMe } from 'app/services/reviews-api-service';
import { initialState } from './session-reducer';
import { CREATE_MENTOR_OPERATION_NAME, UPDATE_MENTOR_OPERATION_NAME } from '../../../services/constants';

export const actionTypes = {
  FETCH_ME: 'FETCH_ME',
  FETCH_ME_FULFILLED: 'FETCH_ME_FULFILLED',
  POST_CREATE: 'POST_CREATE',
  POST_CREATE_FULFILLED: 'POST_CREATE_FULFILLED',
  POST_UPDATE: 'POST_UPDATE',
  POST_UPDATE_FULFILLED: 'POST_UPDATE_FULFILLED',
  UPDATE_USER_AGREEMENT: 'UPDATE_USER_AGREEMENT',
};

export default {
  fetchMe() {
    return createAsyncAction(actionTypes.FETCH_ME, actionTypes.FETCH_ME_FULFILLED, async () => {
      const mentorPromise = gql(mentorQuery(auth.getCurrentUserId()), MENTOR_QUERY_OPERATION_NAME);
      const reviewsMePromise = getMe();
      const reviewsCertsPromise = getCertifications();

      const [mentorRes, reviewsMe, reviewsCerts] = await Promise.all([
        mentorPromise,
        reviewsMePromise,
        reviewsCertsPromise,
      ]);

      if (mentorRes === null) {
        return initialState;
      }

      const mentor = _get(mentorRes.data, 'mentor');

      const hasMentorAccount = !_isEmpty(mentor);

      reviewsMe.isCertified = reviewsCerts && reviewsCerts.length > 0;

      return {
        ...mentor,
        has_mentor_account: hasMentorAccount,
        reviewsMe,
      };
    });
  },
  postCreate(form) {
    return createAsyncAction(actionTypes.POST_CREATE, actionTypes.POST_CREATE_FULFILLED, () =>
      gql(createMentorMutation, CREATE_MENTOR_OPERATION_NAME, form)
    );
  },
  postUpdate(form) {
    return createAsyncAction(actionTypes.POST_UPDATE, actionTypes.POST_UPDATE_FULFILLED, () =>
      gql(updateMentorMutation, UPDATE_MENTOR_OPERATION_NAME, form)
    );
  },
  updateUserAgreement(data) {
    return createAction(actionTypes.UPDATE_USER_AGREEMENT, data);
  },
};
