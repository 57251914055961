import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Button, Flex, Heading, Link, Text, useDisclosure } from '@chakra-ui/react';

import { ClampedText } from 'app/common/components/clamped-text';
import { UdsCard } from 'app/common/components/uds-card/card';
import { CalendarIcon, ChatIcon, CohortIcon, TimeIcon } from '@udacity/chakra-uds-icons';
import { dateFormatter } from 'app/helpers/format-helper';
import { commonLanguages } from 'app/helpers/form-helper';
import { FetchedAvailableSeries } from 'app/uconnect/helpers/types';

type SeriesCardProps = {
  series: FetchedAvailableSeries;
  onClaimSeriesClick: (seriesId: string | null) => void;
};

const DEFAULT_NO_OF_LINES = 2;

export const SeriesCard: React.FC<SeriesCardProps> = ({ series, onClaimSeriesClick }) => {
  const { t } = useTranslation();
  const firstMeeting = series.firstMeeting.meetings[0];
  const firstMeetingDate = dateFormatter.short(firstMeeting.startAt);
  const meetingCount = series.firstMeeting.totalCount;
  const lastMeetingDate = meetingCount > 1 ? dateFormatter.short(series.lastMeeting.meetings[0].startAt) : undefined;
  const languages = firstMeeting.locales.map((locale) => commonLanguages.find((lang) => lang.code === locale));

  const learnerCount = firstMeeting.meetingUsers.totalCount || 0;
  const handleClaim = () => {
    onClaimSeriesClick(series.id);
  };

  return (
    <UdsCard as="figure">
      <Flex
        align={{ base: 'flex-start', sm: 'center' }}
        bg="accent.sky.100"
        borderBottom="1px solid"
        borderColor="gray.300"
        borderTopRadius="base"
        direction={{ base: 'column', sm: 'row' }}
        gap="8px"
        justify="space-between"
        p={4}
      >
        <Flex alignItems="center" gap={2}>
          <Heading as="figcaption" size="h3">
            {series?.name || t('connect.noSeries')}
          </Heading>
          <Text fontSize="sm" fontWeight="semibold">
            ({t('connect.meetingCount', { count: meetingCount })})
          </Text>
        </Flex>
        <Button
          aria-label={t('connect.claimMeetingAriaLabel', {
            seriesName: series?.name,
          })}
          variant="outline"
          onClick={handleClaim}
        >
          {t('connect.claimMeetingCTA')}
        </Button>
      </Flex>
      <Flex align="flex-start" direction="column" gap={4} p={4}>
        <Flex gap={2}>
          <Badge>
            <CalendarIcon h={4} mb={0.5} w={4} /> {firstMeetingDate} {lastMeetingDate && `- ${lastMeetingDate}`}
          </Badge>
          <Badge>
            <TimeIcon h={4} mb={0.5} w={4} /> {dateFormatter.localTime(firstMeeting.startAt)} -{' '}
            {dateFormatter.localTime(firstMeeting.endAt)}
          </Badge>
          {languages.map((language) => (
            <Badge key={language?.code}>
              <ChatIcon h={4} mb={0.5} w={4} /> {language?.name}
            </Badge>
          ))}
          {learnerCount > 0 && (
            <Badge>
              <CohortIcon h={4} mb={0.5} w={4} />{' '}
              {t('connect.meetingUserCount', {
                count: learnerCount,
              })}
            </Badge>
          )}
        </Flex>
        <ClampedText buttonSlot={<Link as="div">{t('common.readMore')}</Link>} numberOfLines={DEFAULT_NO_OF_LINES}>
          {series?.description}
        </ClampedText>
      </Flex>
    </UdsCard>
  );
};
