import {
  QuestionQueueAction,
  QuestionQueueAssignment,
  QuestionQueueOpportunity,
  QuestionQueueStatus,
} from './question-queue-types';

const QUEUE_ENABLED_STATUS = 'ACTIVE';

export function questionQueueStatusFromReviewer(reviewer: any): QuestionQueueStatus {
  const projectEnabledInQueue = reviewer.options.reduce((acc, option) => {
    acc[option.opportunity.project.projectID] = option.enabled;
    return acc;
  }, {});

  return {
    enabled: reviewer.status === QUEUE_ENABLED_STATUS,
    expiresAt: reviewer.expiresAt && parseInt(reviewer.expiresAt, 10),
    projectEnabledInQueue,
  };
}

export function questionAssignmentsFromApiAssigned(assigned: any): QuestionQueueAssignment[] {
  return assigned.map((task) => {
    const {
      expiresAt,
      opportunity,
      atRisk,
      question: { id },
    } = task;
    return {
      questionID: id,
      atRisk,
      expiresAt: expiresAt && parseInt(expiresAt, 10),
      opportunity: opportunityFromApi(opportunity),
    };
  });
}

export function projectOpportunitiesFromApiReviewer(reviewer: any): {
  [projectID: string]: QuestionQueueOpportunity;
} {
  return reviewer.options.reduce((acc, option) => {
    const opp = option.opportunity;
    acc[opp.project.projectID] = opportunityFromApi(opp);
    return acc;
  }, {});
}

function opportunityFromApi(opportunity: any): QuestionQueueOpportunity {
  return {
    project: opportunity.project,
    earnings: {
      answer: opportunity.answerPayment,
      comment: opportunity.commentPayment,
    },
  };
}

export function mergeApiEdgesIntoActionHistory(edges: any[], history: QuestionQueueAction[]): QuestionQueueAction[] {
  const historyCursors = history.map((action) => action.cursor);
  const newActions = edges
    .filter((edgeToFilter) => !historyCursors.includes(edgeToFilter.cursor))
    .map((edgeToMap) => ({
      createdAt: parseInt(edgeToMap.node.createdAt, 10),
      cursor: edgeToMap.cursor,
      payment: edgeToMap.node.payment,
      projectTitle: edgeToMap.node.project.title,
      questionID: edgeToMap.node.question.id,
      type: edgeToMap.node.type,
    }));
  return history.concat(newActions).sort((a, b) => {
    return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
  });
}
