import React from 'react';
import { useTranslation } from 'react-i18next';
import { Markdown } from '@udacity/ureact-markdown';
import { Box, Flex, Text } from '@chakra-ui/react';

import { RubricItem as RubricItemType } from 'app/types/reviews';

type RubricItemProps = {
  item: RubricItemType;
};

export const RubricItem: React.FC<RubricItemProps> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" bgColor="white" borderY="1px solid" borderColor="gray.200">
      <Box
        p={4}
        ps={8}
        bgColor="accent.sky.100"
        borderBottom="1px solid"
        borderBottomColor="gray.200"
        fontWeight="semibold"
      >
        <Markdown markdownText={item.criteria} />
      </Box>

      <Flex px={8} py={4} gap={8}>
        <Flex direction="column" gap={4} maxW={item.reviewerTip ? '50%' : 'full'}>
          <Text size="label">{t('projectReviews.rubric.requirementsToPass')}</Text>
          <Markdown markdownText={item.requirements} />
        </Flex>

        {item.reviewerTip && (
          <Flex direction="column" gap={4} maxW="50%">
            <Text size="label">{t('projectReviews.rubric.reviewerTip')}</Text>
            <Markdown markdownText={item.reviewerTip} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
