import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import styles from './sidebar-navigation-tab.module.scss';

type SidebarNavigationTabProps = {
  to: string;
  title: string;
  text?: string;
  icon?: ReactNode;
  renderNewFlag?: Boolean;
};

export const SidebarNavigationTab: React.FC<SidebarNavigationTabProps> = ({ to, title, text, icon, renderNewFlag }) => {
  const { t } = useTranslation();

  return (
    <NavLink className={styles['tab']} title={title} activeClassName={styles.active} to={to}>
      {icon}
      {!!text && <span className={styles.text}>{text}</span>}
      {renderNewFlag && (
        <span data-ref="new-flag" className={styles['new']}>
          {t('common.new')}
        </span>
      )}
    </NavLink>
  );
};
