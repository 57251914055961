import { createAction } from 'app/helpers/action-helper';

export const actionTypes = {
  FETCH_ALL: 'certifications:FETCH_ALL',
  FETCH_ALL_FULFILLED: 'certifications:FETCH_ALL_FULFILLED',
  FETCH_ALL_RESET: 'certifications:FETCH_ALL_RESET',
};

export const fetchAll = () => {
  return createAction(actionTypes.FETCH_ALL);
};

export const resetFetchAll = () => {
  return createAction(actionTypes.FETCH_ALL_RESET);
};
