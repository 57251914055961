import { delay, Task } from 'redux-saga';
import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionCreators as uiActions } from 'app/common/domains/ui/ui-actions';
import { createAction } from 'app/helpers/action-helper';
import { errorMessageFromApiError } from 'app/helpers/api-error-helper';
import PensieveApi from 'app/services/pensieve-api-service';
import actions, { actionTypes } from './question-queue-actions';
import constants from './question-queue-constants';
import { hasNewAssignments, alertCreators } from './question-queue-sagas-helpers';

import {
  selectQuestionQueueEnabled,
  selectQuestionQueueExpiration,
  selectQuestionQueueAssignments,
} from './question-queue-selectors';

const { ASSIGNMENTS_POLLING_INTERVAL_MS } = constants;

let pollingTask: Task | undefined;
export function* bootstrapQueue() {
  yield put(actions.fetchQueue());
  if (!pollingTask) {
    pollingTask = yield fork(pollAssignments);
  }
}

export function* fetchQueue() {
  try {
    const queue = yield call(PensieveApi.fetchQueue);
    yield put(createAction(actionTypes.FETCH_QUESTION_QUEUE_FULFILLED, queue));
  } catch (error) {
    yield put(createAction(actionTypes.FETCH_QUESTION_QUEUE_REJECTED, error));
    const errorToast = alertCreators.fetchQueueFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* fetchQueueAssignments() {
  try {
    const assignmentsBeforeFetch = yield select(selectQuestionQueueAssignments);

    const assigned = yield call(PensieveApi.fetchQueueAssignments);
    yield put(createAction(actionTypes.FETCH_QUESTION_QUEUE_ASSIGNMENTS_FULFILLED, assigned));

    const assignmentsAfterFetch = yield select(selectQuestionQueueAssignments);
    if (hasNewAssignments(assignmentsBeforeFetch, assignmentsAfterFetch)) {
      yield put(uiActions.addToast(alertCreators.newQuestionsAssignedToast()));
      yield put(uiActions.addNotification(alertCreators.newQuestionsAssignedNotification()));
    }
  } catch (error) {
    yield put(createAction(actionTypes.FETCH_QUESTION_QUEUE_ASSIGNMENTS_REJECTED, error));
  }
}

export function* fetchQueueHistory(action) {
  const {
    payload: { count, afterCursor },
  } = action;
  try {
    const history = yield call(PensieveApi.fetchQueueHistory, count, afterCursor);
    yield put(createAction(actionTypes.FETCH_QUESTION_QUEUE_HISTORY_FULFILLED, history));
  } catch (error) {
    yield put(createAction(actionTypes.FETCH_QUESTION_QUEUE_HISTORY_REJECTED, error));
    const errorToast = alertCreators.fetchQueueHistoryFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* fetchMonthlyEarningsSummary(action) {
  const {
    payload: { start },
  } = action;
  try {
    const earnings = yield call(PensieveApi.fetchQueueEarningsSummary, start);
    yield put(createAction(actionTypes.FETCH_MONTHLY_EARNINGS_SUMMARY_FULFILLED, earnings));
  } catch (error) {
    yield put(createAction(actionTypes.FETCH_MONTHLY_EARNINGS_SUMMARY_REJECTED, error));
    const errorToast = alertCreators.fetchMonthlyEarningsSummaryFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* joinQueue(action) {
  try {
    const queueStatus = yield call(PensieveApi.joinQueue, action.payload);
    yield put(createAction(actionTypes.JOIN_QUESTION_QUEUE_FULFILLED, queueStatus));
  } catch (error) {
    yield put(createAction(actionTypes.JOIN_QUESTION_QUEUE_REJECTED, error));
    const errorToast = alertCreators.joinQueueFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* maybeDisableQueueOnExpiration(action) {
  const isEnabled = yield select(selectQuestionQueueEnabled);
  if (!isEnabled) {
    return;
  }

  const expiration = yield select(selectQuestionQueueExpiration);
  const timeUntilExpirationMs = Math.max((expiration || 0) - Date.now(), 0);
  yield delay(timeUntilExpirationMs);
  yield put(createAction(actionTypes.QUESTION_QUEUE_EXPIRED));
}

export function* leaveQueue() {
  try {
    const queueStatus = yield call(PensieveApi.leaveQueue);
    yield put(createAction(actionTypes.LEAVE_QUESTION_QUEUE_FULFILLED, queueStatus));
  } catch (error) {
    yield put(createAction(actionTypes.LEAVE_QUESTION_QUEUE_REJECTED, error));
    const errorToast = alertCreators.leaveQueueFailedToast(errorMessageFromApiError(error));
    yield put(uiActions.addToast(errorToast));
  }
}

export function* pollAssignments() {
  while (true) {
    yield delay(ASSIGNMENTS_POLLING_INTERVAL_MS);
    yield put(createAction(actionTypes.FETCH_QUESTION_QUEUE_ASSIGNMENTS_START));
  }
}

export function* watchBootstrapQueue() {
  yield takeEvery(actionTypes.BOOTSTRAP_QUESTION_QUEUE, bootstrapQueue);
}

export function* watchFetchQueue() {
  yield takeEvery(actionTypes.FETCH_QUESTION_QUEUE_START, fetchQueue);
}

export function* watchFetchQueueAssignments() {
  yield takeEvery(actionTypes.FETCH_QUESTION_QUEUE_ASSIGNMENTS_START, fetchQueueAssignments);
}

export function* watchFetchQueueHistory() {
  yield takeEvery(actionTypes.FETCH_QUESTION_QUEUE_HISTORY_START, fetchQueueHistory);
}

export function* watchFetchMonthlyEarningsSummary() {
  yield takeEvery(actionTypes.FETCH_MONTHLY_EARNINGS_SUMMARY_START, fetchMonthlyEarningsSummary);
}

export function* watchJoinQueue() {
  yield takeEvery(actionTypes.JOIN_QUESTION_QUEUE_START, joinQueue);
}

export function* watchJoinedQueue() {
  yield takeLatest(
    [actionTypes.JOIN_QUESTION_QUEUE_FULFILLED, actionTypes.FETCH_QUESTION_QUEUE_FULFILLED],
    maybeDisableQueueOnExpiration
  );
}

export function* watchLeaveQueue() {
  yield takeEvery(actionTypes.LEAVE_QUESTION_QUEUE_START, leaveQueue);
}

export default function* rootSaga() {
  yield all([
    watchBootstrapQueue(),
    watchFetchQueue(),
    watchFetchQueueAssignments(),
    watchFetchQueueHistory(),
    watchFetchMonthlyEarningsSummary(),
    watchJoinQueue(),
    watchJoinedQueue(),
    watchLeaveQueue(),
  ]);
}
