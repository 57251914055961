import _ from 'lodash';
export default {
  getEnabledServices(servicesObj) {
    return _.reduce(
      servicesObj,
      (result, bool, serviceName) => {
        if (bool && serviceName !== 'classroom_mentorship') {
          result.push(serviceName);
        }
        return result;
      },
      []
    );
  },
  selectServiceForRedirection(services, isReviewsCertified) {
    if (services.length === 1 && services[0] === 'opportunities') {
      return 'opportunities';
    }
    if (services.length === 1 && services[0] === 'connect') {
      return 'connect';
    }
    const filteredServices = _.pull(services, 'opportunities');
    if (filteredServices.length === 1) {
      return filteredServices[0];
    } else if (filteredServices.length > 1) {
      return [];
    }
    if (isReviewsCertified) {
      return 'needs-mentor-profile';
    }
    return null;
  },
  getRouteForService(serviceName) {
    switch (serviceName) {
      case 'connect':
        return '/connect';
      case 'reviews':
        return '/queue';
      case 'opportunities':
        return '/queue/certifications';
      case 'community':
        return '/mentorship';
      case 'needs-mentor-profile':
        return '/apply';
      default:
        return '/prompt';
    }
  },
};
