import './assets/styles/index.module.scss';

import 'app/initializers/axios';
import 'app/initializers/analytics';
import 'app/initializers/dayjs';
import 'app/i18n';

import React from 'react';
import ReactDOM from 'react-dom';

import { App } from 'app/app';

ReactDOM.render(<App />, document.getElementById('root'));
