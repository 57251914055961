import _omit from 'lodash/omit';
import { actionTypes } from './reviews-queue-actions';
import storage from 'app/helpers/storage-helper';
import constants, { QUEUE_VOLUME } from './reviews-queue-constants';

const { SUBMISSION_REQUEST_STATUS } = constants;
const { queueVolume, projects } = storage.get('queue-settings') || {};

export const initialState = {
  certificationsStatus: {}, // { '1': true, '2': false }
  entities: {},
  active: [],
  queueSettings: {
    queueVolume: queueVolume || QUEUE_VOLUME.single,
    projects: projects || [],
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  if (error || !payload) {
    return state;
  }

  switch (type) {
    case actionTypes.FETCH_ALL_REQUESTS_FULFILLED: {
      const entities = payload.reduce(
        (acc, ent) => {
          acc[ent.id] = ent;
          return acc;
        },
        { ...state.entities }
      );

      const active = payload.map((ent) => ent.id);

      return {
        ...state,
        entities,
        active,
      };
    }
    case actionTypes.CREATE_REQUEST_FULFILLED: {
      const entities = { ...state.entities };
      entities[payload.id] = payload;

      let active = [...state.active];
      if (payload.status === SUBMISSION_REQUEST_STATUS.available) {
        active = [...state.active, payload.id];
      }

      return {
        ...state,
        entities,
        active,
      };
    }
    case actionTypes.DELETE_REQUEST_FULFILLED: {
      const requestId = payload;
      const entities = _omit(state.entities, [requestId]);

      const active = state.active.filter((id) => id !== requestId);

      return {
        ...state,
        entities,
        active,
      };
    }
    case actionTypes.UPDATE_REQUEST_FULFILLED: {
      const entities = { ...state.entities };
      entities[payload.requestId] = {
        ...entities[payload.requestId],
        ...payload.submissionRequest,
      };

      return {
        ...state,
        entities,
      };
    }
    case actionTypes.SET_SETTINGS: {
      return {
        ...state,
        queueSettings: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
