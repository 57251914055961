import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { GuidelinePage } from 'app/common/components/guideline-page';
import reviewsUrl from './content/reviews.md';
import knowledgeModerationUrl from './content/knowledge-moderation.md';
import careerUrl from './content/career-support.md';
import communityUrl from './content/community.md';
import contactUrl from './content/contact-us.md';
import generalUrl from './content/general.md';
import HandbookContainer from './handbook-container';

export const Handbook: React.FC = () => {
  const match = useRouteMatch();

  return (
    <HandbookContainer>
      <Switch>
        <Route exact path={`${match.path}`}>
          <GuidelinePage contentUrl={generalUrl} />
        </Route>
        <Route path={`${match.path}/project-reviews`}>
          <GuidelinePage contentUrl={reviewsUrl} />
        </Route>
        <Route path={`${match.path}/knowledge`}>
          <GuidelinePage contentUrl={knowledgeModerationUrl} />
        </Route>
        <Route path={`${match.path}/community`}>
          <GuidelinePage contentUrl={communityUrl} />
        </Route>
        <Route path={`${match.path}/career`}>
          <GuidelinePage contentUrl={careerUrl} />
        </Route>
        <Route path={`${match.path}/contact`}>
          <GuidelinePage contentUrl={contactUrl} />
        </Route>
        <Route path={`${match.path}/*`}>
          <Redirect to={`${match.path}`} />
        </Route>
      </Switch>
    </HandbookContainer>
  );
};
